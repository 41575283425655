import initialState from "./initialState";

export default (state = initialState.raceEvents, action) => {
  switch (action.type) {
    case "SET_RACE_EVENTS":
      return action.events;
    default:
      return state;
  }
};
