import React, { Component } from "react";
import IntervalInfoBlock from "./IntervalInfoBlock";
import {secondsToTimeStrip, roundDistance} from '../../timeUtils'
// import classnames from "classnames";
// import clockimg from "../../../images/clock.svg";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'
import classNames from 'classnames'

class Interval extends Component {
  state = {
    showInfo: false
  }
  handleClick = () => {
    // this.props.setSelectedInterval(this.props.interval);
  };

  showInfoBlock = () => {
    this.setState({showInfo:true})
    this.props.settingsActions.setBeaconDone("resultsSummaryRaceCourse");
    //this.beaconDone("resultsSummaryRaceCourse")();
  }
  hideInfoBlock = () => {
    this.setState({showInfo:false})
  }

  // beaconDone = beaconId => e => {
  //   // console.log('beaconDone onclick', beaconId);
  //   this.props.settingsActions.setBeaconDone(beaconId)
  //   // this.props.settingsActions.setBeaconDone("resultsSummaryRaceCourse")
  // }

  

  render() {
    const { interval, units, race, last } = this.props;
    
    // console.log("Interval", interval)
    const { showInfo } = this.state;
    // const { distance, FlexRatio } = interval;
    const { FlexRatio } = interval;
    // console.log("interval",interval);
    const intervalCumulTime = secondsToTimeStrip(interval.IntervalCumulativeTimeSeconds);
    const intervalTime = secondsToTimeStrip(interval.IntervalTimeSeconds);
    const intervalDistance = units === 'metric' ? roundDistance(interval.IntervalDistanceKm,2) : roundDistance(interval.IntervalDistanceMi,2);
    const intervalCumulDistance = units === 'metric' ? roundDistance(interval.CumulativeDistanceKm,2) : roundDistance(interval.CumulativeDistanceMi,2);
    return (
      <div className={classNames("Interval", {last})} onClick={this.handleClick} style={{
        flexGrow: (FlexRatio * 100)
      }}
      onMouseOver={this.showInfoBlock}
      onMouseLeave={this.hideInfoBlock}
      >
        
        <section className="IntervalLabel Out Top">{intervalCumulTime}</section>
        <section className="IntervalLabel In Top">{intervalTime}</section>
        <section className="IntervalLabel In Bottom">{intervalDistance}</section>
        <section className="IntervalLabel Out Bottom">{intervalCumulDistance}</section>

        { showInfo && <IntervalInfoBlock {...interval} race={race} units={units} /> }
      </div>
    );
  }
}

const mapDispatch = dispatch => ({
  settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
})

export default connect(null,mapDispatch)(Interval);

