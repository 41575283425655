import React, { Component } from "react";
import * as Sentry from '@sentry/browser';
// import LogRocket from 'logrocket';

import SecuredRoute from './SecuredRoute/SecuredRoute';
import SecuredSuperAdminRoute from './SecuredSuperAdminRoute/SecuredSuperAdminRoute';
// import ReactTooltip from 'react-tooltip'
import {withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux'
import { IntervalAnalysis, AccountLogin, ConfirmEmail, ResetPassword } from "./pages";
import { Welcome } from "./pages";
import { ResultsSummary } from "./pages";
import { CourseMap } from "./pages";
import CourseMapTest from "./pages/CourseMapTest/CourseMapTest";
import { PassingStatisticsDetails } from "./pages";
import { Finish } from "./pages";
// import { SelectEvent } from "./pages";
import { HomeEventsPage } from "./pages";
import { HomeRacesPage } from "./pages";
import { HomeAthletesPage } from "./pages";
import { AthleteHomeTimelinePage } from "./pages";
import { AthleteHomeRaceListPage } from "./pages";
import { AccountProfile } from "./pages";
import { GarminConnect } from "./pages";
import { AccountFavorites } from "./pages";
import { SuperAdminPage } from "./pages";
import { SelectAthlete } from "./pages";
import { OverallRaceResults } from "./pages";

import "./App.scss";
import Spinner from './components/Spinner/Spinner'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import WindowWidthListener from './WindowWidthListener'
import axios from 'axios';
import classNames from 'classnames'
import {
  Switch,
  Route
} from "react-router-dom";

import firebase, { db, auth, storage } from './config/firebase'
import { db as portalFirestoreDb } from './config/firebase-portal'
// import AccountEmailVerify from "./pages/delete2_AccountHome/AccountEmailVerifylVerify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from "redux";
import { initBeacon, destroyBeacon } from './actions/fbuser'
import { getAvatarAndRaces } from './actions/users'
import { setFbUser } from './actions/fbUsers'
import PartnerModalForm from './components/HomeBib/PartnerModalForm'
import RequestRaceModalForm from './components/HomeBib/RequestRaceModalForm'
import steeltoe from 'steeltoe'
import SignUpModal from './pages/AthleteHome/SignUpModal'

import CookieConsent from "react-cookie-consent";

import lazyLoadScript from 'lazyload-script';
import Airship from "./components/Airship/Airship";
import AirshipFramer from "./components/Airship/AirshipFramer";


const CloseButton = ({ closeToast }) => <i className="Toastify__close-button" onClick={closeToast}>×</i>;


class App extends Component {

  
  componentDidMount() {
    
    this.props.setPathname(this.props.location.pathname);
    // console.log("path",this.props.location)
    Sentry.init({dsn: "https://6ed2485e23724487b138a7e30b412a13@sentry.io/1832772"});
    // LogRocket.init('1q6txx/myrace');

    // LogRocket.getSessionURL(sessionURL => {
    //   Sentry.configureScope(scope => {
    //     scope.setExtra("sessionURL", sessionURL);
    //   });
    // });
    // this.setupSegment(); //segment added on index.html
    // Sentry.captureMessage('MyRace session started!', 'debug');
    // this.setupPrivateRaces();
    this.setupFirebaseAuth();
    this.setupBeacon();
    this.setupHotJar();
    this.getAvtrImg();
    this.setupGeoip();
  }

  componentDidUpdate(prevProps){
    if( !steeltoe(prevProps).get('fbuserProfile.username') && steeltoe(this.props).get('fbuserProfile.username') ){
        this.getAvtrImg();
    }
    
    if(prevProps.location.pathname !== this.props.location.pathname){
      this.props.setPathname(this.props.location.pathname);
    }
  }
  setupGeoip = () => {
    lazyLoadScript('https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js', 'geoip2')
      .then(()=> {
        window.geoip2.city(geoData => {
          this._userGeoData = geoData;
          // console.log('[geoData]', this._userGeoData);

          const logGeoData = () => {
            if(!auth.currentUser){
              return window.setTimeout(logGeoData, 500);
            }
            // console.log("about to log")
            const uid = auth.currentUser.uid;
            const isAnonymous = auth.currentUser.isAnonymous;
            const created = firebase.firestore.FieldValue.serverTimestamp();
            const saveLog = {
                uid, isAnonymous, created,
                payload: this._userGeoData
            };
            portalFirestoreDb.collection('locationLogs').add(saveLog).catch(err=>console.log('error adding log', err, saveLog));
          };
          logGeoData();


        }, err=>{

        });
      })
      .catch(err => {})
  }



  setupFirebaseAuth = () => {
    auth.onAuthStateChanged( user => {
      // console.log('[onAuthStateChanged]', user)
      this.props.setFbUser(user);


      user && Sentry.setUser(
        { "id":user.uid,
          "email": user.email
        });

      // user && LogRocket.identify(user.uid, {
      //     // name: 'James Morrison',
      //     email: user && user.email,
        
      //     // Add your own custom user variables here, ie:
      //     // emailHash: user && user.emailHash
      //   });

      // user && Sentry.captureMessage('MyRace User Logged in!', 'debug');
      
      this.getAvtrImg();

      if(user){
        this.startListeningToProfileChanges(user)
        
      //   this.props.fbuserActions.destroyBeacon();
        
      //   window.analytics.identify(user.uid, {
      //     // name: "Peter Gibbons", 
      //     email: user.email,
      //     createdAt: 1552535249
      //   });
      } else {
        this.stopListeningToProfileChanges()
        // Sentry.captureMessage('MyRace User Logged Out!', 'debug');
        // this.props.fbuserActions.initBeacon();

        // window.analytics.reset();
      }
    })
  }

  // initBeacon = () => {
  //   window.Beacon('config', { color: "#008dfb" })
  //   window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
  //   window.Beacon("once", "close", ()=>window.Beacon('destroy'));
  // }
  // destroyBeacon = () => {
  //   console.log('DESTROY!!! beacon')
  //   window.Beacon('destroy');
  // }

    
  getAvtrImg = () => {
    const {fbuser, fbuserProfile} = this.props;
    // console.log("[App:getAvtrImg]", fbuserProfile && fbuserProfile.username)

        // console.log("fbuserProfile",fbuserProfile)
    if(!fbuser) {
      // fbuser is either null or 0 - we pass the value through to avatar
      // this.props.setAvatarImageUrl(fbuser);
      return;
    }
    if(!fbuserProfile || !fbuserProfile.username){
      return;
    }

    const {username} = this.props.fbuserProfile;

    this.props.usersActions.getAvatarAndRaces({username});
  }


  createNewUserProfileDoc = user =>{
    // initial document creation for a new user

    let docData = {
      uid: user.uid,
    };

    // let secureDocData = {
    //   email: user.email
    // };


    if(user.displayName){

      docData.displayName = user.displayName;

      let names = user.displayName.split(' ');
      docData.firstname = names.shift();
      docData.lastname = names.join(' ');
    }



    db
      .collection('users')
      .doc(user.uid)
      .set(docData, {merge: true})
      .then(res=>{

        // db
        // .collection('users')
        // .doc(user.uid)
        // .collection('secure')
        // .doc('secure')
        // .set(secureDocData, {merge: true})
        // .then(res=>{
          
        // })
        // .catch(err=>{
        //   console.warn('error creating user profile doc', err)
        // })
  
        
        
      })
      .catch(err=>{
        console.warn('error creating user profile doc', err)
      })

  }

  checkUserProfileDoc = (user) => { // <-- only if there's no profile doc already
    // console.log('[checkUserProfileDoc]', user);
    if (user.isAnonymous) {
      return;
    }

    db
    .collection('users')
    .doc(user.uid)
    .get()
    .then(doc => {
        if(doc.exists){
          // console.log('[checkUserProfileDoc] doc exists', user.uid);
          
        } else {
          // document doesn't exist
          // console.log('[checkUserProfileDoc] doc does not exists', user.uid);
          this.createNewUserProfileDoc(user)
        }
      })
      .catch( err => {
        console.warn('Error getting user doc in [checkUserProfileDoc]', err)
      })
  }
  
  startListeningToProfileChanges = (user) => {
    
    this.checkUserProfileDoc(user);

    

    if(this._unsubFromProfileChanges) {
      return;
    }
    this._unsubFromProfileChanges = db
      .collection('users')
      .doc(user.uid)
      .onSnapshot(this.onProfileChange)
  }
  stopListeningToProfileChanges = () => {
    if(typeof this._unsubFromProfileChanges === 'function'){
      this._unsubFromProfileChanges();
      this._unsubFromProfileChanges = null;
      this.props.updateProfile(null);
    }
  }

  onProfileChange = (doc) => {
    // console.log('PROFILE CHANGED:', doc, doc.data());
    // console.log("FBUSER",this.props.fbuser)
    const {fbuser} = this.props;

    this.props.updateProfile(doc.data())

    
  }





  setupSegment = () => {
    // eslint-disable-next-line
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
      analytics.load("ySejgrRQEPyEhS8GmXUAVGUy4glEIVuq");
      }}();
  }

  setupGoogleAnalytic = () => {

  }

  setupHotJar = () => {

    // console.log("this.props",this.props);
    // console.log("this.props.location",this.props.location);

    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    !isLocalhost && (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1306196,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
  setupBeacon = () => {

    // window.setTimeout(()=>{
    //   console.log('CONFIGGG')
    //   window.Beacon('config', { color: 'red' })
    // },5000)

    // eslint-disable-next-line
    !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});

    // window.Beacon('config', { color: "#008dfb" })
    
    // window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
  


  }

  // setupPrivateRaces = () => {

  //   const isPrivate = getQueryVariable('private')==='true';
  //   const orgId = getQueryVariable('orgid');

  //   console.log('private settings set');
  //   this.props.setSettings({ isPrivate, orgId });
    
  // }



  // state = {
  //   currentPage: "ReportSample1",
  //   currentUnits: "English"
  // };

  

  pageRoutes = [
    { path: "welcome", component: Welcome },
    { path: "results-summary", component: ResultsSummary },
    { path: "segment-analysis", component: IntervalAnalysis },
    { path: "course-map", component: CourseMap },
    { path: "passing-statistics-details", component: PassingStatisticsDetails },
    { path: "finish", component: Finish },
    { path: "overall-race-results", component: OverallRaceResults }
  ];

  render() {
    const {requestsPending} = this.props;
    const {showAds, airshipType} = this.props.settings;
    // const showAds = this.props.settings && this.props.settings.showAd;

    return (
      <div className={classNames("App", {
        'requests-pending': requestsPending > 0
      })}>
        <WindowWidthListener />
        <ToastContainer closeButton={<CloseButton YouCanPassAnyProps="foo" />}  />
        <Spinner />

        {/* <ErrorBoundary> */}
          <Switch>
            {/* <Route exact path="/" render={() => true ? ( <Redirect push to="/welcome/boston-marathon-2017/athletes/10"/> ) : ("/") } /> */}
   
            {/* <Route exact path="/" component={SelectEvent} /> */}
            {/* <Route exact path="/Home" component={HomePage} /> */}
            {/* <Route exact path="/Home" component={SelectEvent} /> */}
            <Route exact path="/" component={HomeEventsPage} />
            <Route exact path="/races" component={HomeRacesPage} />
            {/* <Route exact path="/athletes" component={HomeAthletesPage} /> */}
            {/* <Route exact path="/athlete/:athleteNameGenderSlug/:athleteHometownSlug" render={() => <AthleteHome />} /> */}
            <Route exact path="/athlete/:athleteHash" render={() => <AthleteHomeTimelinePage />} />
            <Route exact path='/select-athlete/:eventCode' component={SelectAthlete} />
            <Route exact path='/select-athlete/:eventCode/:raceCode' component={SelectAthlete} />
            <Route exact path='/select-athlete/:eventCode/:raceCode/:athleteFilter' component={SelectAthlete} />
            {/* <Route exact path='/login' component={AccountLogin} /> */}
            <Route exact path='/login' render={()=>{
              this.props.setLoginPopupOpen('login');
              return <Redirect to='/' />
            }} />
            <Route exact path='/forgotpassword' render={()=>{
              this.props.setLoginPopupOpen('forgotpassword');
              return <Redirect to='/' />
            }} />
            <Route exact path='/signup' render={()=>{
              this.props.setSignupPopupOpen();
              return <Redirect to='/' />
            }} />
            
            <Route exact path='/login/confirmemail' component={ConfirmEmail} />
            <Route exact path='/login/resetpassword' component={ResetPassword} />
            <Route exact path='/course-map-test/:raceCode/:athleteBib' component={CourseMapTest} />
           


            <SecuredRoute exact path={`/overall-race-results/:raceCode`} component={OverallRaceResults} />} />
            <SecuredRoute exact path={`/account/profile`} component={AccountProfile} />} />
            <SecuredRoute exact path={`/account/garmin`} component={GarminConnect} />} />
            <SecuredRoute exact path={`/account/favorites`} component={AccountFavorites} />} />
            <SecuredRoute exact path={`/admin/superadmin`} component={SuperAdminPage} />} />
            {/* <SecuredRoute exact path={`/account/verifyemail`} component={AccountEmailVerify} />} /> */}

            {/* <SecuredRoute exact path={`/account/profileold`} component={AccountHome} />} /> */}
            {/* <SecuredSuperAdminRoute exact path={`/admin/home`} component={AccountAdmin} />} /> */}
            {/* <SecuredRoute exact path={`/account/favorites`} component={AccountFavorites} />} /> */}

            {this.pageRoutes.map(pageRoute => {
              return (
                <Route
                key={pageRoute.path}
                exact
                path={`/${pageRoute.path}/:raceCode/athletes/:athleteBib`}
                component={pageRoute.component}
                />
                );
              })}

            {/* <Route exact path='/callback' component={Callback}/> */}
            <Route exact path='/error' component={ErrorPage}/>
            <Route exact path="/:username/racelist" render={() => <AthleteHomeRaceListPage />} />
            <Route exact path="/athlete/:athleteHash/racelist" render={() => <AthleteHomeRaceListPage />} />
            <Route exact path="/:username" render={() => <AthleteHomeTimelinePage />} />

            <Route render={() => <div>Sorry, this page does not exist</div>} />
          </Switch>
        {/* </ErrorBoundary> */}

        <PartnerModalForm />
        <RequestRaceModalForm />
        <SignUpModal />
        {/* <Airship /> */}
        <AirshipFramer showAds={showAds} airshipType={airshipType} />
        <CookieConsent
            // style={{ fontFamily: "Montserrat, sans-serif;", background: "#2B373B" }}
            buttonText="I agree"
            containerClasses="myCookieContainer"
            contentClasses="myCookieContent"
            buttonClasses="myCookieButton"
            // style={{ background: "#2B373B" }}
            // buttonStyle={{ marginRight: "75px", color: "#4e503b", fontSize: "13px" }}
            cookieName="MyRacePrivacy"
            // acceptOnScroll={true}
            // onAccept={({ acceptedByScrolling }) => {
                // if (acceptedByScrolling) {
                //     // triggered if user scrolls past threshold
                //     alert("Accept was triggered by user scrolling");
                // } else {
                //     alert("Accept was triggered by clicking the Accept button");
                // }
            // }}
        >
          {/* <span style={{ marginLeft: "75px", fontSize: "14px" }}> */}
          <span>
          <span className="CookiesTitle">Cookies</span>
          MyRace uses cookies. Some may have been set already. If you continue to use the site, we assume you are happy to accept the cookies anyway. More details at <a target="_blank" href="https://about.myrace.com/article/30-privacy-policy">Privacy Policy</a>.
          {/* MyRace uses cookies to improve the user experience. By continuing to use this site you agree to the use of cookies in accordance with our <a target="_blank" href="https://about.myrace.com/article/30-privacy-policy">Privacy Policy</a>. */}
          {/* MyRace uses cookies to improve the user experience. By continuing to use this site, you agree to the use of <a  href="https://about.myrace.com/article/30-privacy-policy">cookies</a>. */}
          </span>

      </CookieConsent>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  requestsPending: state.requestsPending,
  fbuser: state.fbuser,
  fbuserProfile: state.fbuserProfile,
  settings: state.settings,
})
const mapDispatchToProps = (dispatch) => ({
  updateProfile: payload => dispatch({ type:'SET_PROFILE_DOC', payload }),
  setFbUser: (user) => dispatch(setFbUser(user)),/// user => dispatch({ type: 'SET_FB_USER', user }),
  // setAvatarImageUrl: userAvatarImageUrl => dispatch({type:'SET_SETTING', userAvatarImageUrl}),
  fbuserActions: bindActionCreators({initBeacon, destroyBeacon}, dispatch),
  usersActions: bindActionCreators({getAvatarAndRaces}, dispatch),
  incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
  decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
  setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
  setSignupPopupOpen: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: true}),
  // setSettings: (settings)=>dispatch({type:'SET_SETTING', ...settings}),
  setPathname: pathname => dispatch({type:'SET_PATHNAME', pathname}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

