import { combineReducers } from "redux";
import settings from "./settingsReducer";
import athleteSelected from "./athleteSelectedReducer";
// import athleteRaceSearch from "./athleteRaceSearchReducer";
import raceSelected from "./raceSelectedReducer";
import intervalAnalysis from "./intervalAnalysisReducer";
// import intervalCourseMapAnalysis from "./intervalCourseMapReducer";

import raceAthleteFilter from "./raceAthleteFilterReducer";
// import athleteSearchResults from "./athleteSearchResultsReducer";

import athleteFilterResults from "./athleteFilterResultsReducer";



import athletesByRacebib from "./athletesByRacebibReducer";
import athletesByFilter from "./athletesByFilterReducer";

import athletesBySlug from "./athletesBySlugReducer";
import allRaces from "./allRacesReducer";
import raceEvents from "./raceEventsReducer";
import raceEventsFilter from "./raceEventsFilterReducer";
import sponsors from "./sponsorsReducer";
import requestsPending from "./requestsPendingReducer";
import fbuser from "./fbUserReducer";
import fbuserProfile from "./fbUserProfileReducer";

import pathname from "./pathnameReducer";

import favoriteAthletes from './favoriteAthletesReducer'
import users from './usersReducer'

const rootReducer = combineReducers({
  settings,
  athleteSelected,
  raceSelected,
  allRaces,

  raceAthleteFilter,
  // athleteSearchResults,
  
  athleteFilterResults,

  athletesByRacebib,
  athletesByFilter,
  
  athletesBySlug,
  intervalAnalysis,
  pathname,
  // intervalCourseMapAnalysis,
  // athleteRaceSearch,
  raceEvents,
  raceEventsFilter,
  requestsPending,
  sponsors,
  fbuser,
  fbuserProfile,
  favoriteAthletes,
  users
});

export default rootReducer;
