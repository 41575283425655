import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore';
import 'firebase/storage';
import "firebase/performance";
import "firebase/functions";
import "firebase/analytics";


const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCarZ5tKaoCq__EVw-NdVkU94RUy04uY-U",
  authDomain: "my-race-com.firebaseapp.com",
  databaseURL: "https://my-race-com.firebaseio.com",
  projectId: "my-race-com",
  storageBucket: "my-race-com.appspot.com",
  messagingSenderId: "610453497672",
  appId: "1:610453497672:web:33d84eca3cb4ed7e",
  measurementId: "G-T0XXGL3JCK"
});

// const firestore = firebase.firestore();
window.firebase = firebaseApp;

// const perf = firebase.performance();

// firestore.settings({ timestampsInSnapshots: true });

firebaseApp.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });


export const db = firebaseApp.firestore();
export const analytics = firebaseApp.analytics();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const FieldValue = firebase.firestore.FieldValue;
export const TaskEvent = firebase.storage.TaskEvent;
// const perf = firebase.performance();

export default firebase;