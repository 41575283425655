import React, { Component, useState, useEffect } from 'react'
import {Link, withRouter} from 'react-router-dom'
import Headroom from 'react-headroom';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as fbuserActions from '../../actions/fbuser'
import { clearUserStatusProfileFlag } from '../../actions/users'
import debounce from 'lodash.debounce'
import TooltipText from '../../components/Tooltip/TooltipText'
// import MainAccountProfileBlock from './MainAccountProfileBlock-deleteme';
import './AccountProfile.scss'
import imgLandscape from '../../images/generic-mobile.svg';
import checkIcon from '../../images/check.svg';
import ToggleGeneric from '../../components/ToggleButton/ToggleGeneric'
import classNames from 'classnames'
import CountryDropdown from './CountryDropdown'
import statesList from './statesList'
import moment from 'moment'
import ProfileImage from './ProfileImage/ProfileImage'
import ChangePasswordForm from './ChangePasswordForm'
import { useForm } from "react-hook-form";

import imgGeo from '../../images/geo-03.svg';


import iconUserBlue from "../../images/user-04.svg"
import iconUserRed from "../../images/user-05.svg"
import iconUserBlack from "../../images/user-06.svg"
import iconDateBlue from "../../images/date-01.svg"
import iconDateRed from "../../images/date-02.svg"
import iconDateBlack from "../../images/date-03.svg"
import iconGeoBlue from "../../images/geo-01.svg"
import iconGeoRed from "../../images/geo-02.svg"
import iconGeoBlack from "../../images/geo-03.svg"

import avatars, {getAvatarImage, getOwnAvatarImage, defaultAvatars} from './avatars'
import steeltoe from 'steeltoe'

import imgUserBlack from '../../images/user-06.svg';
import imgMailBlack from '../../images/mail-03.svg';


const ManageAthleteContent = props => {

    const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
    const { register, errors, triggerValidation } = useForm();

    // const []
    // const [selectedState, setSelectedState] = useState('');

    // useEffect(()=>{
    //     if(props.fbuserProfile&&props.fbuserProfile.stateCode){
    //         setSelectedState(props.fbuserProfile.stateCode);
    //     }
    // }, [props.fbuserProfile&&props.fbuserProfile.stateCode])
    
    const { fbuserProfile, updateField, parentState } = props;
    const { countryCode, stateCode, city, firstname, lastname, birthday } = parentState;
    // console.log("fbuserProfile",fbuserProfile)

    if(!fbuserProfile) {
        return null;
    }

    // console.log("birthday",birthday)
    const checkBirthdatePreview = moment(birthday).format('MMM DD, YYYY');
    const birthdatePreview = birthday.length > 0 ? checkBirthdatePreview : 'Type any date format';

    
    return (
        
    <div className="ManageAthleteContent">


        <div className={classNames("control", {
            //  invalid: !!this.state.errors.firstname 
             })}>
            <label> 
                <span className="label">Country</span>
                <CountryDropdown countryCode={countryCode} onChange={updateField('countryCode')} />
            </label>
        </div>
        
        

        <div className={classNames("control", {
            invalid: !!steeltoe(errors).get('city.message'),
            valid: !!city,
            open: stateDropdownOpen
        })}>
            <label> 
                <span className="label">
                    <span>Hometown</span>
                    {countryCode==='US' && <span>State</span> }
                </span>

                <input name="city" type="text" 
                    placeholder={ 'Your Hometown...'}
                    // onChange={updateField('city')} 
                    onFocus={e=>setStateDropdownOpen(false)}
                    value={city||''} 
                    // style={{
                    //     backgroundImage: `url(${imgGeo})`
                    // }}
                    onChange={(e)=>{
                        updateField('city')(e);
                        triggerValidation('city');
                    }} 
                    ref={register({
                        validate: value => value ? false : 'Enter Hometown'
                    })}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconGeoBlue})` }} />
                        <div className="invalid" style={{ backgroundImage: `url(${iconGeoRed})` }} />
                        <div className="valid" style={{ backgroundImage: `url(${iconGeoBlack})` }} />
                    </div>
                </div>
            </label>
            { countryCode==='US' &&  <div className="HometownState" onClick={e=>{setStateDropdownOpen(!stateDropdownOpen)}}>{stateCode}</div> }
            { stateDropdownOpen && (
                <div className="StatesListAnchor">
                    <div className="StatesList DropdownList">
                        <div className="DropdownListInner">
                            {statesList.map( s =>(
                                <div key={s.code} className={classNames("DropdownOption",{
                                    selected: s.code === stateCode
                                })} onClick={()=>{
                                    // setSelectedState(s.code);
                                    setStateDropdownOpen(false);
                                    updateField('stateCode')(s.code)
                                    }}>
                                    <div className="title">{s.name}</div>
                                    <div className="checkbox">{s.code === stateCode && <img src={checkIcon} />}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) }
        </div>
        

        <div className={classNames("control", {
            invalid: !!steeltoe(errors).get('firstname.message'),
            valid: !!firstname
             })}>
            <label> 
                <span className="label">First Name</span>
                <input name="firstname" type="text" 
                    placeholder={ 'Your First Name...'}
                    onChange={(e)=>{
                        updateField('firstname')(e);
                        triggerValidation('firstname');
                    }} 
                    ref={register({
                        validate: value => value ? false : 'Enter First Name'
                    })}
                    value={firstname}
                    // style={{
                    //     backgroundImage: `url(${iconUserBlue})`
                    // }}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconUserBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${iconUserRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${iconUserBlack})`}}/>
                    </div>
                </div>
            </label>
        </div>
        
        <div className={classNames("control", {
            invalid: !!steeltoe(errors).get('lastname.message'),
            valid: !!lastname
             })}>
            <label> 
                <span className="label">Last Name</span>
                <input name="lastname" type="text" 
                    placeholder={ 'Your Last Name...'}
                    onChange={(e)=>{
                        updateField('lastname')(e);
                        triggerValidation('lastname');
                    }} 
                    value={lastname} 
                    ref={register({
                        validate: value => value ? false : 'Enter Last Name'
                    })}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconUserBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${iconUserRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${iconUserBlack})`}}/>
                    </div>
                </div>
            </label>
        </div>
        

        <div className={classNames("control", {
            /*RED*/invalid: !!steeltoe(errors).get('birthday.message'),
            /*GREEN*/valid: !steeltoe(errors).get('birthday.message') && (typeof errors.birthday !== 'undefined' || !!birthday)
             })}>
            <label> 
                <span className="label"><span>Date of Birth</span></span>
                {/* <span className="label"><span>Date of Birth</span></span> */}
                <input name="birthday" type="text" 
                    placeholder={ 'Your Date of Birth...'}
                    value={birthday||''} 
                    onChange={(e)=>{
                        updateField('birthday')(e);
                        triggerValidation('birthday');
                    }} 
                    ref={register({
                        validate: v => {
                            const m = moment(v).format('MMMM DD, YYYY');
                            console.log('m',m)
                            return m  === 'Invalid date' ? 'Invalid date' : null;
                        }
                    })}
                />
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${iconDateBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${iconDateRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${iconDateBlack})`}}/>
                    </div>
                </div>
            </label>
        </div>
        <p className="DatePreview">{birthdatePreview}</p>
        


        {/* { !!errors.form && <p className="error"> {errors.form} </p>} */}
    </div>
    )
}



const AvatarItem = ({onClick, onAvatarPreview, ava: {name, img}}) => (
    <li className="AvatarItem"
        onClick={onClick(name)}
        onMouseEnter={onAvatarPreview(name)}
        onMouseLeave={onAvatarPreview(null)}
    >
            

            <img src={img} alt="user avatar" />

    </li>
)

const ManageAvatarContent = ({ onAvatarClick, onAvatarPreview, updateField, gender, userAvatarImageUrl,
    uploadedImage // previously uploaded avatar image
 }) => {
     const [cropperMode, setCropperMode] = useState(false);
    // const gender =  'male';
    // console.log("props",props);
    // const gender = props.gender;

    // const { onAvatarClick, onAvatarPreview, updateField, gender } = props;


    let genderAvatars = avatars.filter(a => a.type===gender);

    const defaultGenderAvatar = genderAvatars.pop();

    const landscapeAvatars = avatars.filter(a => a.type==='landscape')

    
    
    return (
        <div className="ManageAvatarContent">


            <div className="PersonalAvatar">
                <ProfileImage
                    defaultGenderAvatar={defaultGenderAvatar}
                    onClick={onAvatarClick}
                    onAvatarPreview={onAvatarPreview} 
                    uploadedImage={uploadedImage}
                    setCropperMode={setCropperMode}
                />
            </div>

                
            
            <ul className={classNames("AvatarList" , { disabled: cropperMode } )}>
                {genderAvatars.map( (ava,i) => {
                    return <AvatarItem key={i} ava={ava} onClick={onAvatarClick} onAvatarPreview={onAvatarPreview} />
                })}
            </ul>
            <ul className={classNames("AvatarList" , { disabled: cropperMode } )}>
                {landscapeAvatars.map( (ava,i) => {
                    return <AvatarItem key={i} ava={ava} onClick={onAvatarClick} onAvatarPreview={onAvatarPreview} />
                })}
            </ul>

        </div>
    )
}




// const ManageAdminContent = props => {
const ManageAdminContent = ({ fbuser, fbuserProfile, parentState, showChangePasswordForm, showChangePasswordFormCall, hideChangePasswordFormCall }) => {

    // const username = "amatheou"
    // const email = "angelo@myrace.com"
    // console.log("fbuser1",fbuser)
    const username = fbuserProfile && fbuserProfile.username;
    const email = fbuser && fbuser.email;

    return (
        <div className="ManageAdminContent">
            


            <div className={classNames("control", {
            //  invalid: !!this.state.errors.firstname 
            })}>
                <label> 
                    <span className="label with-right-label">
                        <span>User Name</span>
                        <Link to={`/${username}`}><span>myrace.com/<strong>{username}</strong></span></Link>
                    </span>
                    <input name="username" type="text" 
                        value={username}
                        disabled
                        // onChange={this.updateField('firstname')} 
                        // value={fbuserProfile.firstname} 
                        style={{
                            // backgroundImage: `url(${iconUserBlue})`
                        }}
                        />
                </label>
            </div>
            

            <div className={classNames("control", {
            //  invalid: !!this.state.errors.firstname 
            })}>
                <label> 
                    <span className="label">Email Address</span>
                    <input name="email" type="text" 
                        value={email}
                        disabled
                        // onChange={this.updateField('firstname')} 
                        // value={fbuserProfile.firstname} 
                        style={{
                            // backgroundImage: `url(${iconUserBlue})`
                        }}
                        />
                </label>
            </div>
        
            


            {/* <a href="#" className="change-password-link">Change Password</a> */}

            { showChangePasswordForm ? (
                <ChangePasswordForm onHideRequest={hideChangePasswordFormCall} />
            ) : 
                <div className="change-password-link control" onClick={showChangePasswordFormCall}>Change Password</div>
            }
            



        </div>
    )
}

const ManageDevices = ({ fbuser, fbuserProfile, parentState }) => {

    // const username = "amatheou"
    // const email = "angelo@myrace.com"
    // console.log("fbuser1",fbuser)
    // const username = fbuserProfile && fbuserProfile.username;
    // const email = fbuser && fbuser.email;

    return (
        <div className="ManageAdminContent">
            


            <div className={classNames("control", {
            //  invalid: !!this.state.errors.firstname 
            })}>
                <label> 
                    <span className="label with-right-label">
                        <span>Garmin Connect</span>
                        <Link to={`/account/garmin`}><span>Connect or Check/<strong>Status</strong></span></Link>
                    </span>

                </label>
            </div>
            
        

            {/* <div className="change-password-link control" onClick={showChangePasswordFormCall}>Change Password</div> */}

            



        </div>
    )
}





class AccountProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            showChangePasswordForm: false,
            firstname: '',
            lastname: '',
            city: '',
            statecode: '',
            countrycode: '',
            birthday: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',


            activeTab: 'Avatar',
            avatarName: '',
            avatarPreviewName: null
        }
        this.save = debounce(this.save, 2000)
    }

    componentDidMount(){

        this.getInitialProfileData();

        
    }


    componentDidUpdate(prevProps){
        if(!prevProps.fbuserProfile && this.props.fbuserProfile){
            this.getInitialProfileData();
        }
    }


    getInitialProfileData = () => {
        const {fbuserProfile, fbuser} = this.props;
        
        if(!fbuserProfile) { return; }
        // const { firstname, lastname, birthday } = fbuserProfile;
        // console.log("fbuserProfile",fbuserProfile)

        let set = {};

        const fields = ['firstname', 'lastname', 'birthday', 'city', 'countryCode', 'stateCode', 'defaultAvatar'];
        fields.forEach(field => {
            if (fbuserProfile[field]) { set[field] = fbuserProfile[field]; }
        })
        

        if (!set['stateCode'] || set['stateCode'].length === 0 ) {
            set['stateCode']='--'; //default it to dashes so the drop down shows up
        }

        this.setState(set);
        
    }
    
    setTab = tabTitle => this.setState({activeTab: tabTitle})

    setAvatar = avatarName => e => {
        this.setState({avatarName: avatarName});
        this.updateField('defaultAvatar')(avatarName);
    }
    setAvatarPreview = avatarPreviewName => e => {
        const { avatarName } = this.state;
        this.setState({avatarPreviewName});
        this.props.setSetting({isProfileAvatarHovered: !!avatarPreviewName && avatarName!==avatarPreviewName })
    }

    updateField = field => e => {
        this.setState({ [field]: e&&e.target ? e.target.value : e }, this.save)
    }

    save = () => {
        const {fbuser, fbuserActions, userActions, fbuserProfile} = this.props;
        const {firstname, lastname, birthday, countryCode, stateCode, city, defaultAvatar } = this.state;

        const checkBirthdatePreview = moment(birthday).format('MMMM DD, YYYY');
        // console.log("Checking checkBirthdatePreview",checkBirthdatePreview)
        let birthdayToUse = checkBirthdatePreview;
        if (checkBirthdatePreview === 'Invalid date') {
            birthdayToUse = '';
            console.log("Going to use emptyString for birthdate",birthdayToUse)
        } 

        fbuserActions.saveProfileFields({
            fbuser, 
            data:{ firstname, lastname, birthday: birthdayToUse, countryCode, stateCode, city, defaultAvatar  }
        })

        userActions.clearUserStatusProfileFlag(fbuserProfile.username)
        
    }

    showChangePasswordFormCall = () => { this.setState({ showChangePasswordForm: true }) }
    hideChangePasswordFormCall = () => { this.setState({ showChangePasswordForm: false }) }

    
    render(){
        const {activeTab, avatarName, avatarPreviewName, showChangePasswordForm} = this.state;
        const { fbuserProfile, fbuser, settings, users} = this.props;

        const username = steeltoe(fbuserProfile).get('username');

        // const firstName = fbuserProfile && fbuserProfile.firstname ? fbuserProfile.firstname : '';
        // const lastName = fbuserProfile && fbuserProfile.lastname ? fbuserProfile.lastname : '';


        
        const gender = fbuserProfile && fbuserProfile.gender ? fbuserProfile.gender : 'M';
        // const city = fbuserProfile && fbuserProfile.city ? fbuserProfile.city : '';
        // const birthday = fbuserProfile && fbuserProfile.birthday ? fbuserProfile.birthday : '';
        // const stateCode = fbuserProfile && fbuserProfile.stateCode ? fbuserProfile.stateCode : '';
        // const countryCode = fbuserProfile && fbuserProfile.countryCode ? fbuserProfile.countryCode : '';
        // const defaultAvatar = fbuserProfile && fbuserProfile.defaultAvatar ? fbuserProfile.defaultAvatar : '';


        const athleteName = this.state.firstname + ' ' + this.state.lastname;
        const cityState = this.state.city.length > 0 ? this.state.city + ', ' + this.state.stateCode : this.state.stateCode;
  
        const countryFlag = this.state.countryCode ? this.state.countryCode.replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) : null;

      
        const ageFromBirthday = this.state.birthday && Math.abs(moment(this.state.birthday).diff(moment(), 'years'));
        // console.log("Age",ageFromBirthday);

        const page = this.props.match.path.split('/')[1];
        // console.log("page",page);

        const EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );

        const uploadedImage = steeltoe(this.props).get(`users.${EmailHash}.avatarImageUrl`);
        const avatarImage = getOwnAvatarImage({
            fbuserProfile, avatarName, avatarPreviewName,
            uploadedImage
        });

        let avatarNameToShow = avatarPreviewName || avatarName || steeltoe(fbuserProfile).get('defaultAvatar');
        const isDefaultImageSelected = avatarNameToShow==='imgM7'||avatarNameToShow==='imgF7';
        // const isDefaultImageHovered = avatarPreviewName==='imgM7'||avatarPreviewName==='imgF7';

        let nameLink = null;

        if (username) {
            nameLink =  <Link to={`/${username}`} className="athleteName tooltip">
            {athleteName}
                <TooltipText>My Performances <em>Home</em></TooltipText>
            </Link>;
        } else {
            nameLink =  <span className="athleteName">{athleteName}</span>;

        }

       
        let isMale = true;
        let athleteAge = null;
        
        
        const athleteRaceResults = steeltoe(this.props).get(`users.${EmailHash}.athleteRaceResults`);
        
        const numRaces = athleteRaceResults ? athleteRaceResults.length : null;
        
        
        isMale = steeltoe(fbuserProfile).get('gender');
        
        if (!isMale){
            isMale=true;
        }
        
        
        let oldestRaceDate = null;
        athleteRaceResults && athleteRaceResults.forEach(athlete => {
            if (athlete.RaceDetails) {
              // console.log("date to check",moment(athlete.RaceDetails.RaceDateStart).format("YYYY"))
              if (oldestRaceDate === null) {
                // console.log("race",race);
                oldestRaceDate = moment(athlete.RaceDetails.RaceDateStart);
                // oldestRaceDate = moment.unix(athlete.RaceDate.seconds);
              } else {
                const curRaceDate = moment(athlete.RaceDetails.RaceDateStart);
                // const curRaceDate = moment.unix(athlete.RaceDate.seconds);
      
                if (curRaceDate < oldestRaceDate) {
                  oldestRaceDate = curRaceDate;
                }
              }
            }
        });

        // console.log('athleteRaceResults:', athleteRaceResults)
        // let oldestRaceDate = athleteRaceResults && athleteRaceResults.reduce((oldestDate, athlete)=>{
        //     console.log('LOOP', steeltoe(athlete).get('RaceDetails.RaceDateStart'))
        //     if (athlete.RaceDetails) {
        //         if (!oldestDate) {
        //           return moment(athlete.RaceDetails.RaceDateStart);
        //         } else {
        //           const curRaceDate = moment(athlete.RaceDetails.RaceDateStart);
        //           if (curRaceDate < oldestRaceDate) {
        //             return curRaceDate;
        //           }
        //         }
        //     }
        //     return oldestDate;
        // });

        // console.log('oldestRaceDate:', oldestRaceDate)
        const oldestRaceDateVal =  oldestRaceDate ? oldestRaceDate.format('YYYY') : '';
        // const oldestRaceDateVal =    '';

     
        

        return (
            <div className="AccountProfile ">
                <Headroom>
                    <Header title={`ACCOUNT PROFILE`} page={page} isMale={isMale} />
                </Headroom>

                <div className="MainAthleteProfileBlock">

                    <div className="ProfileCard">

                        <div className="UserCard" style={{ backgroundImage: `url(${imgLandscape})`}}>

                            <div className="UserInfoTable">
                                {numRaces && <div className="left-aligned">
                                    <div> Performances <i className="dot">•</i> <strong>{numRaces}</strong> </div>
                                    <div> since <i className="dot">•</i> <strong>{oldestRaceDateVal}</strong> </div>
                                </div>}
                                {/* <div className="right-aligned"> */}
                                {
                                    ageFromBirthday && <div className="left-aligned">
                                        <div> Age <i className="dot">•</i> <strong>{ageFromBirthday}</strong> </div>
                                    </div>
                                }
                            </div>


                            <span className="Flag">{countryFlag}</span>

                            <p className="Location"> {cityState}</p>

                            {/* <h1>{athleteName}</h1> */}
                            <h1><span>
                                    {nameLink}
                                </span>
                            </h1>

                            { avatarImage && (
                                <Link to={`/${username}`} className={classNames("userAvatarContainer tooltip", {
                                    uploadedImage: uploadedImage&&isDefaultImageSelected
                                })}>
                                    <TooltipText>My Performances <em>Home</em></TooltipText>
                                    <img src={ avatarImage } alt="user-avatar" className="userAvatar" />

                                    { settings.isProfileAvatarHovered &&
                                        <div className="UserAvatarHoverMessage">Click to change<br/>to this avatar</div>
                                    }
                                    
                                </Link>
                            )}


                        </div>

                        <div className="ProfileManageCard">


                            <ToggleGeneric
                                titles={['Avatar', 'Athlete', 'Admin','Devices']}
                                value={activeTab}
                                onChange={this.setTab}
                            />

                            { activeTab==='Athlete' && (
                                
                                    <ManageAthleteContent
                                        fbuserProfile={fbuserProfile}
                                        updateField={this.updateField}
                                        parentState={this.state}
                                    />

                            )}

                            { activeTab==='Avatar' && (
                                <ManageAvatarContent 
                                    onAvatarClick={this.setAvatar} 
                                    onAvatarPreview={this.setAvatarPreview} 
                                    updateField={this.updateField}
                                    gender={gender}
                                    userAvatarImageUrl={settings.userAvatarImageUrl}
                                    uploadedImage={uploadedImage} // previously uploaded avatar image
                                />
                                
                            )}

                            { activeTab==='Admin' && (

                                <ManageAdminContent 
                                    fbuser={fbuser}
                                    fbuserProfile={fbuserProfile}
                                    parentState={this.state}
                                    showChangePasswordForm = {showChangePasswordForm}
                                    showChangePasswordFormCall = {this.showChangePasswordFormCall}
                                    hideChangePasswordFormCall = {this.hideChangePasswordFormCall}
                                />
                                
                            )}
                            { activeTab==='Devices' && (

                                <ManageDevices 
                                    fbuser={fbuser}
                                    fbuserProfile={fbuserProfile}
                                    parentState={this.state}
                                 
                                />
                                
                            )}

                            


                        </div>
                    </div>
                </div>

                <div className="container flexgrow">

                </div>


                <Footer />
                
            </div>
        )
    }
}

const mapState = ({fbuser, fbuserProfile, settings, users})=>({fbuser, fbuserProfile, settings, users})
const mapDispatch = dispatch => ({
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    userActions: bindActionCreators({clearUserStatusProfileFlag}, dispatch),
    setSetting: (props) => dispatch({ type: 'SET_SETTING', ...props }),
})
export default withRouter(connect(mapState, mapDispatch)(AccountProfile))