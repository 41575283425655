import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
// import firebase from 'firebase/app'
import {auth} from '../../config/firebase'
import {withRouter} from 'react-router-dom'
import { bindActionCreators } from "redux";
import { initBeacon } from '../../actions/fbuser'
import { connect } from 'react-redux'


import imgHelp from '../../images/question-03.svg';
import imgHelpHover from '../../images/question-01.svg';
import imgWhatIs from '../../images/more-03.svg';
import imgWhatIsHover from '../../images/more-01.svg';
import imgMail from '../../images/mail-03.svg';
import imgMailHover from '../../images/mail-01.svg';
import imgFeedback from '../../images/hotjar-03.svg';
import imgFeedbackHover from '../../images/hotjar-01.svg';
// import imgFeedback from '../../images/comment-03.svg';
// import imgFeedbackHover from '../../images/comment-01.svg';
import imgPartner from '../../images/mr-02.svg';
import imgPartnerHover from '../../images/mr-01.svg';
import imgRequest  from '../../images/login-05.svg';
import imgRequestHover  from '../../images/login-03.svg';


const Option = ({title, onClick, primary, secondary, bold, icon, iconHover}) => (
    <div className={classNames("Option", {primary, secondary, bold})} onClick={onClick}>
        {icon && (
            <>
            <div className="icon"><img src={icon} /></div>
            <div className="icon hover"><img src={iconHover} /></div>
            </>
        )}
        <div className="title">{title}</div>
    </div>
)

class DropdownMenu extends Component {
    constructor(props){
        super(props);
        this.dropdownContentEl = React.createRef();
    }

    componentDidMount(){
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        this.dropdownEl = window.document.createElement('div');
        window.document.body.appendChild(this.dropdownEl);
    }
    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        window.document.body.removeChild(this.dropdownEl);
    }

    // componentDidUpdate(prevProps){
    //     // if(!prevProps.fbuser && this.props.fbuser){
    //         this.props.onCloseRequest();
    //     // }
    // }
    renderModalCover = () => {
        return (
            ReactDOM.createPortal(
                <div className="LoginDropdownCover" onClick={this.props.onCloseRequest}></div>
            , this.coverEl)
        )
    }

    renderDropdown = () => {
        return (
            ReactDOM.createPortal(
                this.renderDropdownContent()
            , this.dropdownEl)
        )
    }


    openLogin = () => {
        this.props.onCloseRequest();
        this.props.openLoginRequest();
    }
    openSignup = () => {
        this.props.onCloseRequest();
        this.props.openSignupRequest();
    }
    
    openSignupModal = () => {
        this.props.openSignupModal();
        this.props.onCloseRequest();
    }





    handleOption = optionName => e => {
        // console.log('selected:', optionName);
        const {fbuserProfile, history, location, onCloseRequest, fbuserActions,
            openKeepInTouch,//: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),  
            openPartnerWithUs,//: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),  
            openRequestRace,//: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),  
        } = this.props;
        
        onCloseRequest();

        switch(optionName) {
            case 'home':
                history.push('/');
                break;
            case 'athlete-home': {
                if(!fbuserProfile) { return; }
                const { username } = fbuserProfile;
                history.push(`/${username}`);
                break;
            }
            case 'about': 
                window.open(`https://about.myrace.com/article/4-what-is-myrace`);
                // onCloseRequest();
                break;
            case 'profile':
                history.push({
                    pathname: '/account/profile',
                    state: { from: location }
                });
                break;
            case 'profileold':
                history.push({
                    pathname: '/account/profileold',
                    state: { from: location }
                });
                break;
            case 'favorites':
                history.push({
                    pathname: '/account/favorites',
                    state: { from: location }
                });
                break;
            case 'logout':
                auth.signOut();
                history.push('/');
                break;
            case 'help':
                fbuserActions.initBeacon();
        
                // window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
                window.Beacon("open");
                window.Beacon("once", "close", ()=>window.Beacon('destroy'));
                // window.Beacon('348e8d61-f31b-49a6-8dd0-22b721d423f2');
                break;
            case 'feedback':
                window.hj('trigger', 'hotjar-feedback');
                break;
            case 'keep-in-touch':
                openKeepInTouch();
                break;
            case 'request-race':
                openRequestRace();
                break;
            case 'partner':
                openPartnerWithUs();
                break;
            case 'superAdmin':
                history.push({
                    pathname: '/admin/superadmin',
                    state: { from: location }
                });
                break;
            default:
                // no-op
                break;

        } // switch
    }

    renderDropdownContent = () => {
        const { fbuser, fbuserProfile, show, anchorEl, settings: { windowW } } = this.props;

        // console.log('[renderDropdownContent] anchorEl:', anchorEl.current)
        // console.log("fbuserProfile",fbuserProfile);

        const anchorPos = anchorEl.current.getBoundingClientRect();

        let left = anchorPos.left + (anchorPos.width/2) - 140;
        let top = anchorPos.top + (anchorPos.height) + 10;

        if(windowW<600){
            left -= 40;
        }
        
        // const dropdownContentElPos = this.dropdownContentEl.current && this.dropdownContentEl.current.getBoundingClientRect();
        // if(dropdownContentElPos){
        //     left -= (dropdownContentElPos.width/2);
        // }


        let style = {
            left,
            top,            
        }


        

        return (
            <div
                ref={this.dropdownContentEl}
                className={classNames("DropdownMenu", { show })} style={style}>

                { ((fbuser && fbuser.isAnonymous) || !fbuser) && 
                    ( <>
                        <Option title="LOG IN" onClick={this.openLogin} bold />
                        
                        <Option title="SIGN UP" onClick={this.openSignupModal} bold />
                        
                    </>
                    )
                }

                { fbuser && !fbuser.isAnonymous && 
                    ( <>
                        {/* <Option title="My Home" onClick={this.handleOption('home')} /> */}
                        <Option title="My Home" onClick={this.handleOption('athlete-home')} />
                        <Option title="My Account" onClick={this.handleOption('profile')} />
                        {/* <Option title="Favorites" primary onClick={this.handleOption('favorites')} /> */}
                        <Option title="Favorites" onClick={this.handleOption('favorites')} />

                        
                        {fbuserProfile && fbuserProfile.accessRoles && fbuserProfile.accessRoles.length > 0 &&
                        (
                            <>
                                <div className="hr" />
                                {fbuserProfile.accessRoles.includes("SuperAdmin") && <Option title="Super Admin • Home" onClick={this.handleOption('superAdmin')} /> }
                                {fbuserProfile.accessRoles.includes("Investor") && <Option title="Investor • Home" onClick={this.handleOption('investor')} /> }
                                {fbuserProfile.accessRoles.includes("EventAdmin") && <Option title="Event Admin • Home" onClick={this.handleOption('eventAdmin')} /> }
                                {fbuserProfile.accessRoles.includes("EventMarketing") && <Option title="Event Marketing • Home" onClick={this.handleOption('eventMarketing')} /> }
                                {fbuserProfile.accessRoles.includes("EventTiming") && <Option title="Event Timing • Home" onClick={this.handleOption('eventTiming')} /> }
                                {fbuserProfile.accessRoles.includes("EventSponsor") && <Option title="Event Sponsor • Home" onClick={this.handleOption('eventSponsor')} /> }
                                <div className="hr" />
                            </>
                        )
                            
                        }
                        <Option title="LOG OUT" onClick={this.handleOption('logout')} bold />
                        
                    </>
                    )
                }

                <div className="hr spacer" />

                <Option title="Help" icon={imgHelp} iconHover={imgHelpHover} secondary onClick={this.handleOption('help')} />
                <Option title="What is MyRace?" icon={imgWhatIs} iconHover={imgWhatIsHover} secondary onClick={this.handleOption('about')} />

                <div className="hr" />

                <Option title="Let's Stay in Touch" icon={imgMail} iconHover={imgMailHover} secondary onClick={this.handleOption('keep-in-touch')} />
                <Option title="Leave Feedback" icon={imgFeedback} iconHover={imgFeedbackHover} secondary onClick={this.handleOption('feedback')} />

                <div className="hr" />

                <Option title="Partner with Us" icon={imgPartner} iconHover={imgPartnerHover} secondary onClick={this.handleOption('partner')} />
                <Option title="Request a Race" icon={imgRequest} iconHover={imgRequestHover} secondary onClick={this.handleOption('request-race')} />
                
                {/* <a href={aboutPageLink} onClick={this.handleOption('about')} className="Option" target="_blank" alt="About MyRace" title="About MyRace" rel="noopener noreferrer">About MyRace</a> */}

            </div>
        )
    }
    
    
    render(){
        const { show } = this.props;

        return (
            <>
            {/* <SignUpModal onClick={this.props.onCloseRequest} /> */}
            
            { show && this.renderDropdown() }
            { show && this.renderModalCover() }
            </>
        )
    }
}



const mapState = ({settings, fbuserProfile}) => ({settings, fbuserProfile})



const mapDispatch = dispatch => ({
    fbuserActions: bindActionCreators({initBeacon}, dispatch),
    // openAboutThisPage: ()=>dispatch({type:'SET_PAGE_INFO_MODAL_OPEN', open: true}),  
    openSignupModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: true}),
    closeSignupModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: false}),
    openKeepInTouch: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),  
    openPartnerWithUs: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),  
    openRequestRace: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),   
})
export default connect(mapState, mapDispatch)(withRouter(DropdownMenu))
