import React, { Component } from "react";
import {connect} from 'react-redux'
import "./MainAthleteBlock.scss";
import nextEnabled from '../../images/next-01.svg';
// import nextDisabled from '../../images/next-03.svg';
import prevEnabled from '../../images/back-01.svg';
// import prevDisabled from '../../images/back-03.svg';
import classNames from 'classnames'

// import sf_skyline from '../../images/sf-skyline-large@3x_cropped.png';
import sf_skyline from '../../images/san-francisco@3x.png';
import sac_skyline from '../../images/sacramento@3x.png';
import maui__oceanside_skyline from '../../images/maui@3x.png';
import generic_skyline from '../../images/generic@3x.png';
import berkeley_skyline from '../../images/berkeley@3x.png';


import {Link, withRouter} from 'react-router-dom';
import navNext from '../../images/next-big-03.svg';
import Beacon from '../Beacon/Beacon'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'

import { Swipeable } from 'react-swipeable'



import TooltipText from '../Tooltip/TooltipText'


let skylines = { // EventCode -> image
  defaultBg: generic_skyline,
  'bhm': berkeley_skyline,
  'tsfm': sf_skyline,
  'cim': sac_skyline,
  'mauiom': maui__oceanside_skyline

}

class MainAthleteBlock extends Component {

  componentDidMount(){
    this.startListeners();
  }

  componentWillUnmount(){
      this.stopListeners();
      if(this.timeoutId) window.clearTimeout(this.timeoutId);
  }

  startListeners = () => {
      window.document.addEventListener('keyup', this.onKeyup)
  }
  stopListeners = () => {
      window.document.removeEventListener('keyup', this.onKeyup)
  }

  beaconDone = beaconId => e => {
    // console.log("beacondId",beaconId);
    this.props.settingsActions.setBeaconDone(beaconId)
  }

  pages = [
      {
          // http://localhost:3000/select-athlete/tsfm-2018/tsfm-ma-2018/All-Genders?stayintouch=true
          // url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders?stayintouch=true&bib=${Bib}`,
          url: ({Bib, RaceCode, EventCode}) => `/`,
          handle: "home",
          title: "Home"
      },
      {
          // http://localhost:3000/select-athlete/tsfm-2018/tsfm-ma-2018/All-Genders
          // url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders?stayintouch=true&bib=${Bib}`,
          // url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders?stayintouch=true`,
          url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders`,
          handle: "select-athlete",
          title: "Event & Athletes"
      },
      {
          // http://localhost:3000/welcome/tsfm-ma-2018/athletes/1
          url: ({Bib, RaceCode, EventCode}) => `/welcome/${RaceCode}/athletes/${Bib}`,
          handle: "welcome",
          title: "Welcome"
      },
      {
          // http://localhost:3000/results-summary/tsfm-ma-2018/athletes/1
          url: ({Bib, RaceCode, EventCode}) => `/results-summary/${RaceCode}/athletes/${Bib}`,
          handle: "results-summary",
          title: "Results Summary"
      },
      {
          url: ({Bib, RaceCode, EventCode}) => `/segment-analysis/${RaceCode}/athletes/${Bib}`,
          handle: "segment-analysis",
          title: "Segment Analysis"
      },
      // {
      //     url: ({Bib, RaceCode, EventCode}) => `/course-map/${RaceCode}/athletes/${Bib}`,
      //     handle: "course-map",
      // },
      {
          url: ({Bib, RaceCode, EventCode}) => `/finish/${RaceCode}/athletes/${Bib}`,
          handle: "finish",
          title: "Finish Certificate"
      },
  ];

  onKeyup = (e) => {
    // console.log('KEYUP', e.key, e);
    const activePageIndex = this.pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));
    const isSegmentAnalysisPage = this.pages[activePageIndex].handle === "segment-analysis";
 
    const { athlete, intervalAnalysis } = this.props;
    const { distanceDisplayOption } = intervalAnalysis;

    const intervals = !!athlete && athlete["run-event-intervals-data"];

    const lastInterval = intervals ? intervals.length-1 : 0;
    const isFirstInterval = distanceDisplayOption ? intervalAnalysis.currentEndIntervalIndex === 0 : intervalAnalysis.currentStartIntervalIndex === 0;
   

    const { key } = e;
    if(key==='ArrowLeft'){

      if (isSegmentAnalysisPage && !isFirstInterval) {
        this.gotoPrevInterval();
      } else {
        const link = this.getNextPrevLink('prev');
        if(!link) return;
            this.props.history.push(link);
        }
      }
    if(key==='ArrowRight'){

      // console.log("isSegmentAnalysisPage",isSegmentAnalysisPage)
      // console.log("intervalAnalysis.currentEndIntervalIndex",intervalAnalysis.currentEndIntervalIndex)
      // console.log("lastInterval",lastInterval)
      if (isSegmentAnalysisPage && intervalAnalysis.currentEndIntervalIndex !== lastInterval) {
        this.gotoNextInterval();
      }  else {

        const link = this.getNextPrevLink('next');
        if(!link) return;
        this.props.history.push(link);
      }


    }
  }

  getNextPrevLink = (nextOrPrev) => {
      const {settings: { lastSelectedAthleteBib: Bib, lastSelectedRaceCode: RaceCode, lastSelectedEventCode: EventCode }} = this.props;

      const activePageIndex = this.pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));
      // console.log("Bib1",Bib);
      // console.log("RaceCode1",RaceCode);
      // console.log("EventCode1",EventCode);
      if(!Bib || !RaceCode || !EventCode) { // Bib, RaceCode, EventCode
          return null;
      }

      //console.log('PAGE URL', this.props.location.href)
      // const pageIndex = pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));

      // console.log("pageIndex",pageIndex);
      if(activePageIndex === -1) { 
          return null;
      }

      // const pageNumber = activePageIndex ? activePageIndex + 1 : 1;



      let nextLinkUrl = ''; let prevLinkUrl = '';
      // let isFirst = true;
      // let isLast = true;

      // console.log("pages",this.pages);
      if(this.pages[activePageIndex+1]) {
          nextLinkUrl = this.pages[activePageIndex+1].url ;
          // isLast = false;
          // nextImg = next;
      }
      

      // console.log("nextOrPrev",nextOrPrev);
      if(nextOrPrev==='prev'){
        if(this.pages[activePageIndex-1]){
          prevLinkUrl = this.pages[activePageIndex-1].url;
          // isFirst = false
          // prevImg = back;
        }
        if(typeof prevLinkUrl==='function') { 
          return prevLinkUrl({Bib, RaceCode, EventCode}); 
        }
        
      }
      if(nextOrPrev==='next'){
          // console.log("activePageIndex",activePageIndex);
          // console.log("this.pages.length",this.pages.length);
          if(activePageIndex >= this.pages.length - 1){
              return null;
          }
          if(typeof nextLinkUrl==='function') { 
            // return prevLinkUrl({Bib, RaceCode, EventCode}); 
            return nextLinkUrl({Bib, RaceCode, EventCode});
          }

        
      }

  }



  gotoPrevInterval = () => {console.log('[gotoPrevInterval]');this.props.gotoPrev();}
  gotoNextInterval = () => {
    console.log('[gotoNextInterval]');
      const { athlete, intervalAnalysis } = this.props;
      // console.log("athlete from sidebar",athlete);
      const intervals = !!athlete && athlete["run-event-intervals-data"];
      const lastInterval = intervals ? intervals.length-1 : 0;
      // console.log('INTERVALS', intervals)

      this.props.settingsActions.setBeaconDone("segmentNavigation")
      
      
      if(intervalAnalysis.currentEndIntervalIndex === lastInterval){
          return;
      }

      const max = intervals ? intervals.length - 1 : 0;
      this.props.gotoNext(max);
  }

  


  render() {

    // console.log("this.props.location.pathname",this.props.location.pathname)
    const activePageIndex = this.pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));

    const { children, nextStartDistance, nextEndDistance, prevStartDistance, prevEndDistance, unitsToShow, defaultBg, athlete, isSelectEvent, intervalAnalysis, settings: {lastSelectedEventCode: EventCode } } = this.props;
    
    const { distanceDisplayOption, currentStartIntervalIndex, currentEndIntervalIndex } = intervalAnalysis;

    const intervals = !!athlete && athlete["run-event-intervals-data"];

    const lastInterval = intervals ? intervals.length-1 : 0;
    

    if(!isSelectEvent && !athlete) {
      return <div>... Loading ...</div>
    }

    let RaceCode;
    let Bib;


    if (athlete){
      RaceCode = athlete.RaceCode;
      Bib = athlete.Bib;
    }
    // console.log("EventCode",EventCode)
    // console.log("RaceCode",RaceCode)
    // console.log("Bib",Bib)
    // console.log("isSelectEvent",isSelectEvent)

    if (isSelectEvent) {

    }
    else if(!Bib || !RaceCode || !EventCode) { // Bib, RaceCode, EventCode
        return null;
    }

    
    // console.log("activePageIndex",activePageIndex)
    // if(activePageIndex === -1) { 
    //     return null;
    // }

    const pageNumber = activePageIndex ? activePageIndex + 1 : 1;


    let nextLinkUrl = ''; let prevLinkUrl = '';
    let isFirst = true;
    // let isSecond = false;
    // let isThird = false;
    // let isFourth = false;
    let isLast = true;
    let prevPageTooltipText = 'Previous Page';
    let nextPageTooltipText = 'Next Page';



    if(this.pages[activePageIndex+1]) {
      nextLinkUrl = this.pages[activePageIndex+1].url ;
      isLast = false;
      nextPageTooltipText = this.pages[activePageIndex+1].title;
      // nextImg = next;
    }
    
    if(typeof nextLinkUrl==='function') { 
      nextLinkUrl = nextLinkUrl({Bib, RaceCode, EventCode}); 
    }
    
    const isSegmentAnalysisPage = this.pages[activePageIndex].handle === "segment-analysis";
    
    if(this.pages[activePageIndex-1]){
      prevLinkUrl = this.pages[activePageIndex-1].url;
      isFirst = false
      // prevImg = back;
    }
    // if(pageNumber === 2) {
    //   isSecond = true;
    // }
    // if(pageNumber === 3) {
    //   isThird = true;
    // }
    // if(pageNumber === 4) {
    //   isFourth = true;
    // }
    if(typeof prevLinkUrl==='function') { 
      prevLinkUrl = prevLinkUrl({Bib, RaceCode, EventCode}); 
      prevPageTooltipText = this.pages[activePageIndex-1].title;
    }
    
    const EventCodeSplit = EventCode ? EventCode.split('-') : [];
    const EventCodeBase = EventCodeSplit.length ? EventCodeSplit[0] : '';

    let bgImg = defaultBg || (!EventCodeBase || !skylines[EventCodeBase]) ? skylines.defaultBg : skylines[EventCodeBase];

    // console.log("bgImg",bgImg);

    // console.log("isFirst",isFirst);
    // console.log("isSecond",isSecond);
    // console.log("isThird",isThird);
    // console.log("isFourth",isFourth);
    // console.log("isLast",isLast);

    const isFirstInterval = distanceDisplayOption ? intervalAnalysis.currentEndIntervalIndex === 0 : intervalAnalysis.currentStartIntervalIndex === 0;
   

    return (
      <>
    
      
      <div className="MainAthleteBackground" style={{
        backgroundImage: `url(${bgImg})`
      }} />
      
      <div className="MainAthleteBlock">


      <div className="imgLeft">
        <div className="backgroundImage"></div>
      </div>

      <div className="card">

          
      {
        isSegmentAnalysisPage && !isFirstInterval ? // prev interval
          <div className={classNames("IntervalControlBtn prevPage tooltip")} onClick={this.gotoPrevInterval}>
              <span className="background" />
              <img src ={navNext } />
              <TooltipText><span className="lightBlueTooltip">{prevStartDistance} – {prevEndDistance} {unitsToShow}</span><br/>{currentEndIntervalIndex} &nbsp;&nbsp;of&nbsp;&nbsp; {lastInterval}</TooltipText>
              
          </div>

        : // prev page

          (isFirst ? // is first page
            null
            :
            <Link 
              // data-tip="Go to Results Summary" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDark" 
                // to={`/results-summary/${RaceCode}/athletes/${Bib}`} 
                to={prevLinkUrl}
                className="prevPage tooltip" 
                // onClick={this.beaconDone("pageNavigationWelcomeArrow")}
                >
                {/* <Beacon id="pageNavigationWelcomeArrow" text='Proceed to Results Summary' direction="left" style={{ top:'12px', left:'50px'}} /> */}
                <span className="background" />
                <img src ={navNext } />
                <TooltipText pageNumber={pageNumber}>Back to<em>{prevPageTooltipText}</em></TooltipText>
            </Link>)
      }
          


          <div className="cardcontent">
            <Swipeable onSwiped={ event => { 
              if(event.dir==='Right'){
                console.log("GO TO PREV");

                if (isSegmentAnalysisPage && !isFirstInterval) {
                  this.gotoPrevInterval();
                } else {
                  const link = this.getNextPrevLink('prev');
                  if(!link) return;
                    this.props.history.push(link);
                }


                
              } else if(event.dir==='Left'){
                console.log("GO TO NEXT");

                if (isSegmentAnalysisPage && intervalAnalysis.currentEndIntervalIndex !== lastInterval) {
                  this.gotoNextInterval();
                }  else {
          
                  const link = this.getNextPrevLink('next');
                  if(!link) return;
                  this.props.history.push(link);
                }
                

              }
              console.log('[SWIPE]', event.dir, event)}}>
              {children}
            </Swipeable>
          </div>

          

          { isSegmentAnalysisPage && intervalAnalysis.currentEndIntervalIndex !== lastInterval ? // NEXT INTERVAL BUTTON

            <div className={classNames("IntervalControlBtn nextPage tooltip",{
                disabled: intervalAnalysis.currentEndIntervalIndex === lastInterval
            })} onClick={this.gotoNextInterval}>
                <span className="background" />
                <img src ={navNext } />
                <TooltipText><span className="lightBlueTooltip">{nextStartDistance} – {nextEndDistance} {unitsToShow}</span><br/>{currentEndIntervalIndex+2} &nbsp;&nbsp;of&nbsp;&nbsp; {lastInterval+1}</TooltipText>
            </div>  

          : // NEXT PAGE BUTTON

          ( activePageIndex !== -1 && !isLast && 
            <Link 
            // data-tip="Go to Results Summary" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDark" 
            // to={`/results-summary/${RaceCode}/athletes/${Bib}`} 
            to={nextLinkUrl}
            className="nextPage tooltip" 
            onClick={this.beaconDone("pageNavigationWelcomeArrow")}
            >
              {
                  activePageIndex === 2 ? 
                  <>
                  <Beacon id="pageNavigationWelcomeArrow" text='' direction="up" style={{ top:'55px', left:'21px'}} />
                  </>
                  // <span ref="reffoo" data-tip={hotspotText} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" className="beacon"></span>
                  :
                  null
              }
            
              <span className="background" />
              <img src ={navNext } />
              <TooltipText>Go to <em>{nextPageTooltipText}</em></TooltipText>
          </Link>)
          
          }



            

      </div>

      <div className="imgRight">
        <div className="backgroundImage"></div>
      </div>

      </div>
      

      
      </>
    );
  }
}

// const mapState = ({raceSelected}) => ({raceSelected})

const mapState = state => ({
  settings: state.settings,
  intervalAnalysis: state.intervalAnalysis,
  raceSelected: state.raceSelected
})



const mapDispatch = dispatch => ({
  settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
  gotoPrev: () => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_PREV' }),
  gotoNext: (max) => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_NEXT', max }),
})

export default withRouter(connect(mapState, mapDispatch)(MainAthleteBlock));
