import React, {Component} from 'react'
import icoElevationLoss from '../../images/elev-05.svg';
import icoElevationGain from '../../images/elev-04.svg';
import icoElevationNeutral from '../../images/elev-03.svg';
import icoElevationRolling from '../../images/elev-01.svg';
import icoElevationNotRolling from '../../images/elev-02.svg';
import './ElevationGainIcon.scss'

class ElevationGainIcon extends Component {

    render(){
        // <ElevationGainIcon gain={} isRolling={true} />
        const { gain, isRolling } = this.props;
        
        let icon;
        // console.log("gain",gain);

        if( gain > 0 ) {
            icon = icoElevationGain;
        } else if(gain < 0) {
            icon = icoElevationLoss;
        } else if (gain || gain == -0){
            icon = icoElevationNeutral;
        }

        // console.log("isRolling",isRolling)
        let iconRolling;
        if (isRolling === true) {
            iconRolling = icoElevationRolling;
        } else if(isRolling === false) {
            iconRolling = icoElevationNotRolling;
        } 

        
        return (
            <span className="ElevationGainIcon">

                <img src={iconRolling} className="RollingIconImg" />

                
                <img src={icon} style={{ transform: `rotate(${gain}deg)` }} className="GainIconImg" />



            </span>
        )
    }
    
}

export default ElevationGainIcon;