import React, { Component } from "react";
import classnames from 'classnames'
import "./ToggleButton.scss"

class ToggleDivision extends Component {

    handleClick = value => e => {
        this.props.onChange(value)

    }
    
    render(){
        const { titles, value } = this.props;

        return (
            <>
                <div className="ToggleButton">
                    <div data-tip="Male Divisions" data-delay-show='500' onClick={ this.handleClick(0) } className={classnames({ on: !value })}>{ titles[0] }</div>
                    <div data-tip="Female Divisions" data-delay-show='500' onClick={ this.handleClick(1) } className={classnames({ on: value===1 })}>{ titles[1] }</div>
                </div>
            </>
            
        )
    }
}

export default ToggleDivision
