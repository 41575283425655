import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'
import Interval from './Interval'
import D3ElevationMap from '../../components/D3ElevationMap/D3ElevationMap'
import ReactTooltip from 'react-tooltip'
// import throttle from 'lodash.throttle'
import classNames from 'classnames'
import Beacon from '../../components/Beacon/Beacon'

class RaceCourse extends Component {
    // constructor(props){
    //     super(props);
    //     this.onResize = throttle(this.onResize, 333);
    //   }
    // state = {
    //     // windowW: 0,
    // }    

    // componentDidMount(){
    //     this.onResize();
    //     window.addEventListener('resize', this.onResize);
    // }
    // componentWillUnmount(){
    //     window.removeEventListener('resize', this.onResize);
    // }

    // onResize = () => {
        
    //     // console.log('window resized:', window.innerWidth, 'x', window.innerHeight );
    //     this.setState({
    //         windowW: window.innerWidth
    //     })
    // }


    // gotoStart = () => this.props.gotoStart()
    // gotoFinish = () => {
    //     const { athlete } = this.props;
    //     const intervals = !!athlete && athlete["event-intervals-data"];
    //     const max = intervals ? intervals.length - 1 : 0;
    //     this.props.gotoFinish(max);
    // }

    gotoPrev = () => this.props.gotoPrev();
    gotoNext = () => {
        const { athleteMainData, intervalAnalysis } = this.props;
        const intervals = !!athleteMainData && athleteMainData["event-intervals-data"];
        const lastInterval = intervals ? intervals.length-1 : 0;
        
        if(intervalAnalysis.currentEndIntervalIndex === lastInterval){
            return;
        }

        const max = intervals ? intervals.length - 1 : 0;
        this.props.gotoNext(max);

        this.props.settingsActions.setBeaconDone("segmentNavigation")
    }



      
    render(){
        const { athleteMainData, race, settings, intervalAnalysis, distanceDisplayOption, last } = this.props;
        const { units, windowW } = settings;

        const AGon = settings.ageGrade === "age_grade_on";
        // const intervals = !!athlete && athlete["event-intervals-data"];

        const intervals = (athleteMainData && athleteMainData['run-event-intervals-ag-data']) ? (AGon ? athleteMainData['run-event-intervals-ag-data'] : athleteMainData['run-event-intervals-data']) : null;

        // const intervals = !!athlete && athlete["interval-5km-data"];
        // const margin = { top: 0, bottom: 0, left: 0, right: 0 };
        // console.log("about to render intervals",intervals);

        // current index
        // intervalAnalysis.currentStartIntervalIndex --- intervalAnalysis.currentEndIntervalIndex;
        const lastInterval = intervals ? intervals.length-1 : 0;

        
        let raceChartWidth = 1240; //1100
        if( windowW > 1000 && windowW < (1200+60)  ){
            // console.log("CHANGING raceChartWidth!")
            raceChartWidth = windowW-60;
        } else if ( windowW <= 1000) {
            raceChartWidth = 940;
        }

        //1480 -> 1260 (subtract 120)
        //1400 -> 1240 (subtract 160)
        //1320 -> 1240 (subtract 140)
        // console.log("windowW",windowW)
        // console.log("raceChartWidth",raceChartWidth)
        
        return (
            <div className="RaceCourseContainerOuter">
            <div className="RaceCourseContainer">
            <div className="RaceCourse">
                <ReactTooltip />
                <div className={classNames("start",{
                    disabled: intervalAnalysis.currentStartIntervalIndex === 0
                })} onClick={this.gotoPrev}>
                  <div className="vertical-text">START</div>
                  <div className="half-marathon-bar"></div>
                </div>

                <div className="RaceCourseIntervals">

                    <Beacon id="intervalSummaryRaceCourse" text={"Select each of the segments below"} direction="down" style={{ top:'-30px', left:'15px'}} />

                     <D3ElevationMap isHiddenNavigation={true}   
                        // showTooltipLabels = {true}
                        units={settings.units}  
                        intervalsData={athleteMainData ? athleteMainData["run-event-intervals-data"] : []} 
                        width={raceChartWidth}  
                        height={143}
                        margin={{ top: 0, bottom: 0, left: 0, right: 0 }} 
                        elevationData={race ? race.elevations : []} 
                        // elevationData={raceElevations} 
                        />
                    <section className="IntervalLabel IntervalLabelZero">0</section>

                    {intervals && intervals.map((interval, i) => (
                        <Interval 
                        key={i}
                        index={i}
                        interval={interval}
                        units={units}
                        distanceDisplayOption = {distanceDisplayOption}
                        last={i+1===intervals.length}
                    />
                    ))}
                </div>

                <div className={classNames("finish",{
                    disabled: intervalAnalysis.currentEndIntervalIndex === lastInterval
                })} onClick={this.gotoNext}>
                  <div className="vertical-text">FINISH</div>
                </div>
              </div>
              </div>
              </div>
        )
    }
}

const mapState = state => ({
    intervalAnalysis: state.intervalAnalysis,
    settings: state.settings
});
const mapDispatch = dispatch => ({
    gotoStart: () => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_START' }),
    gotoFinish: (max) => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_FINISH', max }),
    gotoPrev: () => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_PREV' }),
    gotoNext: (max) => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_NEXT', max }),
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
});
  
export default connect(mapState, mapDispatch)(RaceCourse);

