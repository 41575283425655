import React, {Component} from 'react'
import {roundDistance} from '../../timeUtils'
import classNames from 'classnames'
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'
import Beacon from '../Beacon/Beacon'

import nextEnabled from '../../images/next-01.svg';
import nextDisabled from '../../images/next-03.svg';
import prevEnabled from '../../images/back-01.svg';
import prevDisabled from '../../images/back-03.svg';
// import nextEnabled from '../../images/next-2.svg';
// import nextDisabled from '../../images/next-1.svg';
// import prevEnabled from '../../images/back-2.svg';
// import prevDisabled from '../../images/back-1.svg';

import './SegmentNavigation.scss';

class SegmentNavigation extends Component {

    gotoPrev = () => this.props.gotoPrev();
    gotoNext = () => {
        const { athleteMainData, intervalAnalysis } = this.props;
        // console.log("athlete from sidebar",athleteMainData);
        const intervals = !!athleteMainData && athleteMainData["event-intervals-data"];
        const lastInterval = intervals ? intervals.length-1 : 0;

        this.props.settingsActions.setBeaconDone("segmentNavigation")
        
        
        if(intervalAnalysis.currentEndIntervalIndex === lastInterval){
            return;
        }

        const max = intervals ? intervals.length - 1 : 0;
        this.props.gotoNext(max);
    }

    render(){

        const { raceCode, athleteMainData, athleteRankingData, settings, closeSidebar, race, sponsors, intervalAnalysis, setDistanceDisplayOption, allGeoPoints } = this.props;

        const { distanceDisplayOption, currentStartIntervalIndex, currentEndIntervalIndex } = intervalAnalysis;
    
    
        // const { raceLogo } = sponsors[raceCode] || {};

        // const eventSeriesCode = race.EventSeriesCode;
    
        const AGon = settings.ageGrade === "age_grade_on";
        const intervals = (athleteMainData && athleteMainData['event-intervals-ag-data']) ? (AGon ? athleteMainData['event-intervals-ag-data'] : athleteMainData['event-intervals-data']) : null;
    
        const lastInterval = intervals ? intervals.length-1 : 0;
    
        // const sponsorsData = sponsors[raceCode] || {};

        const intervalData = (athleteMainData && athleteMainData['event-intervals-ag-data']) ? (AGon ? athleteMainData['event-intervals-ag-data'] : athleteMainData['event-intervals-data']) : null;
    

        let endDistance = "N/A";
        let startDistance = "N/A";
        if (intervalData) {
            if (settings.units === 'metric') {
                    
                if (distanceDisplayOption) {
                    startDistance = currentStartIntervalIndex === 0 ? 0 : intervalData[currentStartIntervalIndex-1] && roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceKm,3); 
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,3); 
                }  else {  
                    startDistance = currentStartIntervalIndex === 0 ? 0 : intervalData[currentStartIntervalIndex-1] && roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceKm,3);  
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,3);  
                }
            } else {
                if (distanceDisplayOption) {
                    startDistance = currentStartIntervalIndex === 0 ? 0 : intervalData[currentStartIntervalIndex-1] && roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceMi,3); 
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,3); 
                }  else {  
                    startDistance = currentStartIntervalIndex === 0 ? 0 : intervalData[currentStartIntervalIndex-1] && roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceMi,3);  
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,3);  
                }
                
            }
        }

        // const startLabel = <strong>${currentStartIntervalIndex+1}</strong> ;
    
        
        return (
            <div className="SegmentNavigation IntervalControls">
                {/* <div className="label">{startDistance} {settings.units === "metric" ? "km" : "mi"}</div> */}
                {/* <Beacon id="segmentNavigation" text={'Click Arrow for<br/>Next Segment'} style={{ top:'15px', left:'95px'}} /> */}
                <Beacon id="segmentNavigation" text='Next Segment' direction="left" style={{ top:'5px', left:'95px'}} />
                
                <div className={classNames("IntervalControlBtn prev",{
                        disabled: distanceDisplayOption ? intervalAnalysis.currentEndIntervalIndex === 0 : intervalAnalysis.currentStartIntervalIndex === 0
                    })} onClick={this.gotoPrev}>
                    <div className="BtnImage active"><img src={prevEnabled} alt='Previous Interval'/></div>
                    <div className="BtnImage disabled"><img src={prevDisabled} alt='Previous Interval'/></div>
                </div>


                <div className="IntervalIndex">
                    {distanceDisplayOption === 0? 
                    `${currentStartIntervalIndex+1} of ${intervals.length}` : 
                    `${endDistance} ${settings.units === 'metric' ? 'km' : 'mi'}`}
                    {/* `1 thru ${intervalAnalysis.currentEndIntervalIndex+1}`} */}
                    
                </div>

                <div className={classNames("IntervalControlBtn next",{
                    disabled: intervalAnalysis.currentEndIntervalIndex === lastInterval
                })} onClick={this.gotoNext}>
                    <div className="BtnImage active"><img src={nextEnabled} alt='Next Interval'/></div>
                    <div className="BtnImage disabled"><img src={nextDisabled} alt='Next Interval'/></div>
                </div>                

                {/* <div className="label">{endDistance} {settings.units === "metric" ? "km" : "mi"}</div> */}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    athletesByRacebib: state.athletesByRacebib,
    race: state.raceSelected,
    settings: state.settings,
    sponsors: state.sponsors,
    intervalAnalysis: state.intervalAnalysis,
    user: state.user
  })

const mapDispatchToProps = dispatch => ({
    setDistanceDisplayOption: value => dispatch({type:'INTERVAL_ANALYSIS_SET_DISTANCE_DISPLAY_OPTION', value}),
    
    setIntervalIndex: intervalIndex => dispatch({type:'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX', intervalIndex}),
    gotoPrev: () => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_PREV' }),
    gotoNext: (max) => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_NEXT', max }),
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)

    // closeSidebar: ()=>dispatch({type:'SET_SETTING', isMapSidebarOpen: false}),
    // sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
  })
  


export default connect(mapStateToProps, mapDispatchToProps)(SegmentNavigation);