import initialState from "./initialState";

export default (state = initialState.raceEventsFilter, action) => {
  switch (action.type) {
    case "RACE_EVENTS_FILTER/SET_FILTER_TYPE": {
        let newState = {...state,
            filterType: action.payload
        };
        if(newState.filterType===10){
            newState.year = (new Date()).getFullYear()-1;
        }
        return newState;
    }
    case "RACE_EVENTS_FILTER/SET_YEAR":
      return {...state, 
        year: action.payload
      };
    default:
      return state;
  }
};
