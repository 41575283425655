import initialState from "./initialState";

export default (state = initialState.fbuserProfile, action) => {
  switch (action.type) {

    case 'SET_PROFILE_DOC': {
      if(typeof action.payload === 'undefined'){
        return null;
      }
      return action.payload;
    }

    // case 'SET_PROFILE_DOC_PROPERTY': {
    //   // action.payload - { key: value }
    //   return {...(state || {}), ...action.payload};
    // }

    default:
      return state;
  }
};
