import React, { Component, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import Dropdown from '../../components/Dropdown/Dropdown'
import moment from "moment";
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
// import landscape from "/images/city-generic/generic-02.svg";
import "./HomeEventsPage.scss";
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'

import * as allRacesActions from '../../actions/allRaces'


// const EventCard = ({EventName}) => (

  
//     <div className="EventCard">
//         <div className="EventImage">
//             <img alt="event logo" src={logoimg} />
//         </div>
//         <div className="EventTitle">{EventName}</div>
//         <div className="EventInfo">
//             <div>5,200</div>
//             <div>Races • 5</div>
//             <div>San Francisco, CA</div>
//             <div>Jul 27-28, 2019</div>
//         </div>
//     </div>
// )

function EventCard(props) {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const dateOptions = props.events.map( event => {
    
    const startDate = moment(event.EventDateStart);
    const endDate = moment(event.EventDateEnd);

    const duration = endDate.diff(startDate, 'days');
    // console.log('DURATION:', duration )
    
    

    let eventDate = duration >= 1 ? 
      startDate.format("MMM D") + `–` + endDate.format("D • YYYY") : 
      eventDate = endDate.format('MMM D • YYYY');

      return {
        id: event.EventCode,
        title: `${eventDate}`
      }

  });
  // const dateOptions = props.events.map( event => ({
    

  //   id: event.EventCode,
  //   title: `${event.RaceDateStart}`
  // }));

  

  const selectedEventCode =  selectedEvent || props.events[0].EventCode;

  
  
  const raceEvent = props.events[0];

  const startDate = moment(raceEvent.EventDateStart);
  const endDate = moment(raceEvent.EventDateEnd);

  const duration = endDate.diff(startDate, 'days');
  // const logoSrc= `/images/${raceEvent.EventSeriesCode}/partners/${raceEvent.sponsorImages[0].src}`;

  const raceImageSrc = raceEvent.sponsorImages[0].src;

  // const logoSrc=  raceImageSrc.includes('https') ? raceImageSrc : `/images/${race.RaceDetails.EventSeriesCode}/partners/${race.RaceDetails.sponsorImages[0].src}`;
  const logoSrc =  raceImageSrc.includes('https') ? raceImageSrc : null;


  let totalAthleteCountPerEvent = 0;
  for(var i = 0; i < raceEvent.races.length; i++) {
    // console.log("raceEvent.races[i]",raceEvent.races[i])
    totalAthleteCountPerEvent += raceEvent.races[i].Participants ? raceEvent.races[i].Participants : 0;
  } 
  

  let eventDate = duration >= 1 ? 
    startDate.format("MMM D") + `–` + endDate.format("D • YYYY") : 
    eventDate = endDate.format('MMM D • YYYY');


    return (
      <div className="EventCard" onClick={()=>{

        const event = props.events.find(event => event.EventCode === selectedEventCode)

        
        
        const uri = `/select-athlete/${event.EventCode}/${event.DefaultRaceCode}/All-Genders`

        
        
        // console.log('GO TO SELECTED DATE EVENT', selectedEventCode, '->', uri);

        props.history.push(uri);

        
      } }>
        {
          logoSrc && <div className="EventImage">
              <img alt="event logo" src={logoSrc} />
          </div>
        }
        
        <div className="EventTitle">{raceEvent.EventName}</div>
        <div className="EventInfo">
            <div>{totalAthleteCountPerEvent}</div>
            <div>Races • {raceEvent.TotalRaces}</div>
            <div>{raceEvent.Location}</div>
            <div>
              {
                dateOptions.length > 1 ?
                <Dropdown 
                  value={selectedEventCode}
                  onChange={setSelectedEvent}
                  options={dateOptions}/>
                :
                <span className="EventInfoDate">{dateOptions[0].title}</span>
              }
            </div>
        </div>
      </div>
    )
    
}

EventCard = withRouter(EventCard);


const eventTypes = [
  {
    id: 'all',
    title: 'All'
  }, {
    id: 'running',
    title: 'Running'
  }, {
    id: 'multi-sport',
    title: 'Multi-sport'
  }, {
    id: 'ultra',
    title: 'Ultra'
  },
]


class HomeAthletesPage extends Component {

  state = {
    selectedEventType: 'all'
  }
  
  componentDidMount() {

    this.props.allRacesActions.getAllRaces();

  }


  setEventType = selectedEventType => e => this.setState({ selectedEventType })


  render() {


    const {
      requestsPending
    } = this.props;


    if (requestsPending) {
      return null;
    }

    const { selectedEventType } = this.state;
    const { raceEvents, raceEventsFilter } = this.props;



    const page = this.props.match.path.split('/')[1];

    const eventCodes = Object.keys(raceEvents);

    let eventSeries = {
      // [EventSeriesCode]: [ ... raceEvents ]
    };
    let eventSeriesCodes = []

    eventCodes.map( (eventCode, i) => {
      const event = raceEvents[eventCode];
      const { EventSeriesCode } = event;

      if(typeof eventSeries[EventSeriesCode] === 'undefined'){
        eventSeries[EventSeriesCode] = [];
        eventSeriesCodes.push(EventSeriesCode);
      }

      eventSeries[EventSeriesCode].push(event);
    });

    

    return (
      <div className="HomePage">
        <Header page={page}/>

        <div className="MainContent">
            <div className="container">

              <div className="EventTypeOptions">
              { eventTypes.map( (eventType, i) => {
                return (
                  <>
                  <span
                    className={classNames("EventType", {
                      selected: selectedEventType===eventType.id
                    })}
                    onClick={this.setEventType(eventType.id)}
                  >{eventType.title}</span>
                  { eventTypes.length !== i+1 && (
                    <span className="dot">•</span>
                  )}
                  </>
                )
              })}
              </div>

                <div className="EventCards">
                    {
                      // eventCards.map( eventCard => (
                      //     <EventCard key={eventCard.title} {...eventCard} />
                      // ))

                      eventSeriesCodes.map( (EventSeriesCode, i) => {

                        // console.log("eventname",event)
                        // return <EventRow key={eventCode} index={i} event={event} setSelectedRace={this.props.setSelectedRace} />
                        return <EventCard 
                          key={eventSeries[EventSeriesCode][0].RaceCode} 
                          events={eventSeries[EventSeriesCode]} 
                          />
                    })
                    }
                </div>
                
            </div>
          
        </div>

        <img className="footerImage" alt="MyRace" src={`/images/city-generic/generic-02.svg` } style={{
          marginTop: 50,
        }} />

        <Footer />

      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   // race: state.raceSelected,
//   requestsPending: state.requestsPending,
// //   sponsors: state.sponsors,
//   // races: state.races,
// })


const mapStateToProps = ({raceEvents, raceEventsFilter, requestsPending}) => ({raceEvents, raceEventsFilter, requestsPending})


// const mapState = ({fbuser, fbuserProfile})=>({fbuser, fbuserProfile})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
//   setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
//   incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
//   decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
//   sponsorsActions: bindActionCreators(sponsorsActions, dispatch),

    allRacesActions: bindActionCreators(allRacesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeAthletesPage);
// export default connect(mapState, mapDispatchToProps)(SelectEvent);




