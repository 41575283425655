export default {
  settings: {
    units: "english", // or 'metric'
    ageGradeFactor: 1,
    ageGrade: "age_grade_off",
    isSidebarOpen: false,
    isMapSidebarOpen: true,
    isSearchModalOpen: false,
    isFinishSidebarOpen: false,
    // isSidebarOpen: false,
    isPageInfoModalOpen: false,
    isElementInfoModalOpen: false,
    isMenuDropdownOpen: false, // the top menu dropdown, opened on the user icon
    
    // isLoginPopupOpen: 'resetpassword',
    isLoginPopupOpen: false, // false | 'login' | 'signup'

    windowW: window.innerWidth,
    beaconsDone: {},
    modalsDone: {},
    userAvatarImageUrl: 0,

    isPrivate: getQueryVariable('private')==='true',
    orgId: getQueryVariable('orgid'),
    showAds: getQueryVariable('showAds')==='true',
    airshipType: +getQueryVariable('airshipType')
  },

  pathname: '',

  allRaces: [],
  
  requestsPending: 0,
  athleteSelected: {
    Bib: null
  },

  athletesByRacebib: { // i.e. racecode-bib-slug
    /*'mvm-ma-2018--100': { // EXAMPLE
        status: 'pending' OR 'received', 
        data: {} 
        ranking_status: 'pending' OR 'received', 
        ranking_data: {} 
      }
    }*/
  },

  athletesByFilter: {
    /*
      COMBINED_FILTER_KEY will include filters (gender, division, location...), sort (field, direction), number of items per page
    
      [COMBINED_FILTER_KEY]: {
        status: '', -- 'pending', 'received', 'error'
        
        totalItems: 1234,
                // EXAMPLE for totalItems:
                // - athletesByFilter[FILTERKEY].totalItems - maybe check this here too (or maybe in pagination action), and request totalItems from mongodb
                // totalItems - undefined - need to make the request
                // totalItems - "pending" - string - 
                // totalItems - 0 - number
                // totalItems - 1234 - number

        totalPages: 24,
        pages: {
          1: [ {Bib:"1"}, {Bib:"2"} ]
        }
      } 
    */
  },
  
  
  raceSelected: {
    RaceCode: null
  },
  athletesBySlug: { // i.e. name-gender-slug
    //'emanuel-french-m': { status: 'pending' OR status: 'received', data: {} } // <-- EXAMPLE
  },
  intervalAnalysis: {
    currentStartIntervalIndex: 0,
    currentEndIntervalIndex: 0,
    distanceDisplayOption: 1, // 0 - segment, 1 - cumulative
  },
  intervalCourseMap: {
    currentStartIntervalIndex: 0,
    currentEndIntervalIndex: 0,
    distanceDisplayOption: 0, // 0 - interval, 1 - cumulative
  },
  // athleteRaceSearch: {
  //   athleteRaceSearchDisplayOption: 0, // 0 - athlete, 1 - race
  // },

  
  
  /*
    raceCode-overallFilter-divisionFilter-splitTypeFilter-itemsPerPage
  */
  athleteFilterResults: {
    // 'raceCode-overallFilter-divisionFilter-splitTypeFilter-itemsPerPage' : {  } // <-- EXAMPLE
    // 'raceCode|overall|all|all-splits|50': {  } // <-- EXAMPLE


  },

  raceAthleteFilter: {
    page: 1,
    perPage: 50,
    overallFilter: 'All-Genders',
    divisionFilter: 'All-DivisionType1s',
    locationFilter: 'All-Locations',
    splitTypeFilter: 'All-Splits',
    sorterCol: 'IndexFinish',
    sorterColDir: 'down',
    onlyFavorite: false,
    isDirty: false
  },
  // athleteSearchResults: {
  //   //  perPage: 50, it willl be in filter
  //   totalPages: 0,
  //   lastIndexes: [0],
  //   totalItems: 0,
  //   pages: {
  //     // 1: [ athlete objects here ]
  //   }
  // },





  raceEvents: {
    // eventKey:{
    // totalRaces: 0,
    // totalParticipants: 0,
    // races: []
    // }
  },
  raceEventsFilter: {
    filterType: 10, // 10 latest, or "all"
    // year: (new Date()).getFullYear(), // only when filterType is "all"
    year: (new Date()).getFullYear() - 1, // only when filterType is "all"
  },



  sponsors: {
    /*
    [raceCode]: {
      images: [],
      tabs: [],
      raceLogo: {}
    }
    */
  },

  
  fbuser: 0,
  fbuserProfile: null,

  favoriteAthletes: {
    /*
      [raceCode]: [ array of bibs ]
    */
  },


  users: {
    /*
      [EmailHash] : {
        username: '',
        profile: {},
        avatarImageUrl: '',
        athleteRaceResults: []

        status: {
          username: '', -- 'pending', 'received', 'error'
          profile: '', -- 'pending', 'received', 'error'
          avatarImageUrl: '', -- 'pending', 'received', 'error'
        }
      }
    */
  }

  
};




function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  console.log('Query variable %s not found', variable);
}