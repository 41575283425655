import { db, auth, storage } from '../config/firebase'

export const setFbUser = user => {

    // return { type: 'SET_FB_USER', user }
    return (dispatch)=>{
        dispatch({ type: 'SET_FB_USER', user });


        if(user){
            // user has just logged in
        } else {
            // user has just logged out

            auth.signInAnonymously().then(user => {
                console.log('Logged the user in ANONYMOUSLY', user)
            }).catch(function(error) {
                // Handle Errors here.
                // var errorCode = error.code;
                // var errorMessage = error.message;
                console.log('could not log user in anonymously', error)
            });

        }
        
    }
;}