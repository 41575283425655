import initialState from "./initialState";

export default (state = initialState.requestsPending, action) => {
  switch (action.type) {
    case "INCREMENT_PENDING_REQUESTS":
      return state + 1;
    case "DECREMENT_PENDING_REQUESTS":
      if(state===0){
        console.warn('DECREMENT_PENDING_REQUESTS from zero!')
      }
      return state - 1;
    default:
      return state;
  }
};
