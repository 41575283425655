import React, { Component } from "react";
import './Sponsors.scss';
import classNames from 'classnames';
import openIcon from "../../images/open-2.svg";

class Tab extends Component {
    onClick = e => {
        // console.log("clicked index",this.props.index);
        this.props.onTabSelected(this.props.index)
    }
    render(){
        const {title, selected} = this.props;
        // console.log("selected",selected);
        return (
            <div onClick={this.onClick} className={classNames("Tab", {selected})}>
                {title}
            </div>
        )
    }
}

const SponsorImage = ({ eventSeriesCode, raceCode, bib, src, altText, caption,captionLine2, appendBib, linkURL, width }) => {
    
    if (appendBib) {
        linkURL += bib;
    }
    let image = (
        // <img src={`/images/${eventSeriesCode}/partners/${src}`} className="sponsor" alt={altText} title={altText} />
        <img src={`${src}`} className="sponsor" alt={altText} title={altText} />
    );
    if(linkURL){
        image = <a href={linkURL} target="_blank" rel="noopener noreferrer">{image}</a>
    } else {
        image = <span>{image}</span>
    }

    // caption = `Presenting 
    // Partner`;
    // console.log("caption",caption);
    // !!caption

    let rootstyle = {};
    if(width){ rootstyle.width = width; rootstyle.maxWidth = width; }
    
    return (
        <div className="SponsorImage" style={rootstyle}>
            {image}
            { !!caption && <div className="Caption">{caption}<br/>{captionLine2}</div> }
            <img src={openIcon} className="openIcon" alt={`${image.altText}`} />
        </div>
    )
}

class Sponsors extends Component {

    state = {
        selectedTabIndex: 0,
        fadeHidden: true,
    }


    componentDidMount(){
        this.timeoutId = window.setTimeout(()=>{
            this.setState({ fadeHidden: false });
            this.startAutoloop();
        }, 2000)
    }
    
    componentWillUnmount(){
        this.stopAutoloop();
        if(this.timeoutId) window.clearTimeout(this.timeoutId);
    }
    
    handleEnter = () => this.stopAutoloop();
    handleLeave = () => this.startAutoloop();
    
    
    startAutoloop = () => {
        if(this.interval) return null;
        this.interval = window.setInterval(this.next, 5000);
    }
    
    stopAutoloop = () => {
        if(!this.interval) return null;
        window.clearInterval(this.interval);
        this.interval=null;
    }
    
    next = () => {
        const { selectedTabIndex } = this.state;
        const { tabs } = this.props;
        if(!tabs || !tabs.length) { return }
        // console.log('NEXT', currIndex, images.length)
        this.setState({
            selectedTabIndex: (selectedTabIndex+1) % tabs.length
        })
    }

    onTabSelected = index => { this.setState({ selectedTabIndex: index }) }

    renderImage = img => {
        const { eventSeriesCode } = this.props;
        let image = (
            <img src={`/images/${eventSeriesCode}/partners/${img.src}`} alt={img.altText} title={img.altText} />
        );
        if(img.linkURL){
            return <a href={img.linkURL} target="_blank" rel="noopener noreferrer">{image}</a>
        }
        return image;
    }
    
    render(){
        const { tabs, raceCode, eventSeriesCode, images, athlete } = this.props;

        const { selectedTabIndex, fadeHidden } = this.state;
        // const titleSponsor = images.find( img => img.tabIndex===0 );

        // athlete.MyRace.Bib
        const bib = (athlete && athlete.MyRace && athlete.MyRace.Bib) ? athlete.MyRace.Bib : '';

        if(!tabs || !tabs[selectedTabIndex]) { return null; }

        console.log("tabs",tabs)

        const selectedTab = tabs[selectedTabIndex];

        return (
            <div className={classNames("Sponsors", {fadeHidden})}>
                <div className="Tabs">
                    { tabs.map( (tab, index) => {
                        if(!images.find(img => img.tabIndex === index)) {
                            return null;
                        }
                        return (
                            <Tab key={index} index={index} selected={selectedTabIndex===index} onTabSelected={this.onTabSelected} {...tab} />
                        )
                    }) }
                </div>

                <div className="TabContent" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
                    
                    <div className="ContentSponsor" style={{
                        marginTop: typeof selectedTab.marginTop==='number' ? selectedTab.marginTop : 0
                    }}>
                        { (images && !!images.length) && images.filter( img => img.tabIndex===selectedTabIndex).map( (img,i) => {
                            return ( <SponsorImage key={i} bib={bib} eventSeriesCode={eventSeriesCode} raceCode={raceCode} {...img} /> )
                        })}
                    </div>
                </div>


                {/* <div className="TabContent">
                    {
                        selectedTabIndex===0 ? (
                                titleSponsor ? (
                                    <div className="ContentTitleSponsor">
                                        {this.renderImage(titleSponsor)}
                                    </div>
                                    ) : null
                                    ) : (
                                        <div className="ContentSponsor" style={{
                                            marginTop: typeof selectedTab.marginTop==='number' ? selectedTab.marginTop : 0
                                        }}>
                                    { images.filter( img => img.tabIndex===selectedTabIndex).map( (img,i) => {
                                        return (
                                            <div key={i} className="Image">
                                                {this.renderImage(img)}
                                                <div className="Caption">{img.caption}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                        )
                    }
                </div> */}
            </div>
        )
    }
}

export default Sponsors;