import initialState from "./initialState";

const stub = {
    raceLogo: null,
    images: [],
    tabs: []
}

export default (state = initialState.sponsors, action) => {
  switch (action.type) {

    case "SPONSORS/SET_PENDING": {
        // expected: action.slug
        let nextState = {...state};
        if(!nextState[action.slug]) { nextState[action.slug] = stub; }
        nextState[action.slug] = {...nextState[action.slug]}; // clone it
        nextState[action.slug].status = 'pending';
        return nextState;
    }
    case "SPONSORS/SET_DATA": {
        // expected: action.slug, action.data
        const { slug, data } = action;

        let nextState = {...state};
        nextState[slug] = {...nextState[slug]}; // clone it
        nextState[slug].status = 'received';
        // nextState[slug].data = data;

        const images = data && data.images;
        const tabs = data && data.sponsorTabs;
        if(images && images.length){
            nextState[slug].images = images;
            nextState[slug].raceLogo = images.find(img => img.role==='top');
        }
        if(tabs && tabs.length){ nextState[slug].tabs = tabs; }
        return nextState;
    }



    default:
      return state;
  }
};
