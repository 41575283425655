// import firebase from 'firebase/app'
import {db, FieldValue} from '../config/firebase'

import {getAthletesByFilter} from './athletesByFilter'



export const getFav = (RaceCode, attemptNo=1) => {
    // const { fbuser, raceCode, bib, setFav} = o;
    // if(!fbuser){ return false; }

    return (dispatch, getState) => {
        // console.log('dispatch getFav', RaceCode);
        
        const { raceSelected, fbuser } = getState();

        if(!fbuser && attemptNo<12) {
            window.setTimeout(()=>{
                dispatch(getFav(RaceCode, attemptNo+1))
            }, 800)
            return;
        }
        
        // console.log('[getFav]', fbuser, RaceCode)
    
        // const bibKey = `favoriteAthletes.${raceCode}|${bib}`;
        if (!fbuser) {
            return  null;
        }

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.warn('FIREBASE ERROR:', e)
        }
    
        dispatch({type:'INCREMENT_PENDING_REQUESTS'});

        // console.log('getting fav for', raceSelected.RaceCode)
        // console.log("fbuser",fbuser)
        
        let query = db
            .collection('users')
            .doc(fbuser.uid)
            .collection('fav');
        
        let list = true;
            
        if(RaceCode && typeof RaceCode==='string'){
            query = query.doc(RaceCode);
            list = false;
        }

        if(list){
            return query.get()
                .then((snap) => {
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'});                

                    if(snap.docs.length){
                        snap.docs.forEach(doc => {

                            // console.log('DOC FAVORITES:', doc.data(), doc, doc.id );

                            const RaceCode = doc.id;
                            
                            // const favoriteAthletes = Object.keys( doc.data() );
                            const favoriteAthletes = {...doc.data()};
                            dispatch({ type: 'SET_FAVORITE_ATHLETES', RaceCode, favoriteAthletes });
        
        
                            //this.props.athletesByFilterActions.getAthletesByFilter();
                            // dispatch(getAthletesByFilter())

                        })
                    }


                        

                })
                .catch(handleError);
        } else {
            return query.get()
                .then((doc) => {
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'});                
    
                    if(doc.exists){
                        // console.log('DOC FAVORITES:', doc.data() )
                        const favoriteAthletes = Object.keys( doc.data() );
                        dispatch({ type: 'SET_FAVORITE_ATHLETES', RaceCode, favoriteAthletes });
    
    
                        //this.props.athletesByFilterActions.getAthletesByFilter();
                        dispatch(getAthletesByFilter())
                        
                    }
                })
                .catch(handleError);
        }

    }
}


export const setFav = (o) => {
    const { fbuser, EmailHash, RaceCode, bib, isFavorite} = o;
    console.log('SETFAV', EmailHash);
    if(!fbuser){ return false; }

    return (dispatch, getState) => {

        const {favoriteAthletes} = getState();
    
        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.warn('FIREBASE ERROR:', e)
        }
    
        dispatch({type:'INCREMENT_PENDING_REQUESTS'});

        return db
            .collection('users')
            .doc(fbuser.uid)
            .collection('fav')
            .doc(RaceCode)
            .set({
                [bib]: isFavorite ? EmailHash : FieldValue.delete(),     
                // [bib+'--'+EmailHash]: isFavorite ? true : FieldValue.delete(),     
            }, {merge:true})
            .then(() => {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});

                let nextFavorites = favoriteAthletes[RaceCode] ? {...favoriteAthletes[RaceCode]} : {};

                if(isFavorite){ //add
                    // nextFavorites = [...nextFavorites, bib];
                    nextFavorites[bib] = EmailHash;
                } else { // remove
                    // nextFavorites = nextFavorites.filter(b => b!==bib);
                    delete nextFavorites[bib];
                    // nextFavorites = nextFavorites.filter(b => b!==(bib+'--'+EmailHash));
                }

                dispatch({ type: 'SET_FAVORITE_ATHLETES', RaceCode, favoriteAthletes: nextFavorites})
                
            })
            .catch(handleError);

    }
}
