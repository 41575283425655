// import firebase from 'firebase/app'
import mongoapi from '../apis/mongoapi'

export const getSponsors = (raceCode) => {
    return (dispatch, getState) => {


        const { sponsors } = getState();

        // console.log('[getSponsors] raceCode:', raceCode, typeof raceCode)
    
        if(!raceCode || sponsors[raceCode]) {
            return; // we're already either requesting or got the data
        }

        
        
        dispatch({ type: "SPONSORS/SET_PENDING", slug: raceCode});
        dispatch({type:'INCREMENT_PENDING_REQUESTS'})
        
        mongoapi.run(mongodb => {
            const coll = mongodb.collection("races.race.sponsors");
            let filter = {};
            filter._id = raceCode;
            
            // MAKE THE CALL
            coll.findOne(filter)
            .then(sponsor => {
                // console.log("Sponsors found",sponsors);
                    
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                    
                    dispatch({ type: "SPONSORS/SET_DATA", slug: raceCode, data: sponsor});
                }
                )
                .catch(err => {
                    
                    console.warn('MONGO DB error (when requesting sponsors by raceCode):', err);
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'})
                });

        })
    }
}