import React, { Component } from "react";
import {connect} from 'react-redux'
// import Loader from 'react-loader-spinner';
import './Spinner.scss'
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
    root: {
        height: 2,
        position: 'fixed',
        top: 61,
        right: 0,
        left: 0,
    },
    colorPrimary: {
        backgroundColor: '#008dfb'
    },
    barColorPrimary: {
        backgroundColor: '#b2dcfd'
    }
})

class Spinner extends Component {
    render(){
        const { requestsPending, classes } = this.props;
        if( !requestsPending ) {
            return null;
        }
        return (
            <div className="Spinner">
                <LinearProgress 
                    classes={{
                        root: classes.root,
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                    }}
                    />
            </div>
        )
    }
}




const mapStateToProps = state => ({
    requestsPending: state.requestsPending,
})

export default connect(mapStateToProps)(withStyles(styles)(Spinner));