import initialState from "./initialState";

export default (state = initialState.pathname, action) => {
  switch (action.type) {
      case 'SET_PATHNAME':
          return action.pathname
    
    default:
      return state;
  }
};
