import initialState from "./initialState";

export default (state = initialState.athleteSelected, action) => {
  switch (action.type) {
    case "SET_SELECTED_ATHLETE":
      return action.athlete;
    case "SET_SELECTED_ATHLETE_RANKING_AND_PASSING":
      return action.athleteRankingAndPassing;
    case "UPDATE_SELECTED_ATHLETE":
        return {...state, ...action.athlete};
    default:
      return state;
  }
};
