// import firebase from 'firebase/app';
import mongoapi from '../apis/mongoapi'

// export const getSlug = (raceCode, athleteBib, emailHash) => `${raceCode}--${athleteBib}--${emailHash}`;
export const getSlug = (raceCode, athleteBib) => `${raceCode}--${athleteBib}`;



export const getAthleteMainData = (raceCode, athleteBib) => {
  return (dispatch, getState) => {
    const slug = getSlug(raceCode, athleteBib);

    const { athletesByRacebib } = getState();

    // if(!raceCode || athletesByRacebib[slug]) {
    if(!raceCode || (athletesByRacebib[slug] && athletesByRacebib[slug].main_data)) {
      return; // we're already either requesting or got the data
    }

    // request main athlete data
    dispatch({ type: "ATHLETES_BY_RACEBIB/SET_MAIN_PENDING", slug});
       
    dispatch({type:'INCREMENT_PENDING_REQUESTS'})

    mongoapi.run( mongodb => {

      const coll = mongodb.collection("athletes");
      let filter = {};

      filter.RaceCode = raceCode;
      filter.Bib = athleteBib;

      // MAKE THE CALL
      coll.findOne(filter)
      .then(athlete => {

          dispatch({type:'DECREMENT_PENDING_REQUESTS'})

          // console.log("XXXX athlete found",athlete);

          dispatch({ type: "ATHLETES_BY_RACEBIB/SET_MAIN_DATA", slug, 
            data: athlete,
            status: athlete ? 'received' : 'missing'
          });
          dispatch({type:'SET_AGE_GRADE_FACTOR', ageGradeFactor: athlete ? athlete.MyRace.AgeGradeFactor : 1 });

        }).catch(err => {
          console.warn('MONGO DB error (when requesting athlete by Bib):', err);
          dispatch({type:'DECREMENT_PENDING_REQUESTS'})
        });

    })
  }
}

export const getAthleteRanking = (raceCode, athleteBib) => {
  return (dispatch, getState) => {
    const slug = getSlug(raceCode, athleteBib);
    // console.log("getAthleteRanking, About to get athlete ranking for ",raceCode,athleteBib);
    // console.log("getAthleteRanking, About to get athlete slug ",slug);

    const { athletesByRacebib } = getState();

    // console.log("getAthleteRanking, athletesByRacebib ",athletesByRacebib)

    // console.log("About to get athlete ranking",raceCode, slug);
    // console.log("About to get athletesByRacebib[slug]",athletesByRacebib);
    // console.log("About to get athletesByRacebib[slug]",athletesByRacebib[slug]);


    // MONGODB
    // if(!raceCode || athletesByRacebib[slug]) {
    if(!raceCode || (athletesByRacebib[slug] && athletesByRacebib[slug].ranking_data)) {
      // console.log("XXX Not requesting, seems we already have?")
      return; // we're already either requesting or got the data
    }

    // request main athlete data
    dispatch({ type: "ATHLETES_BY_RACEBIB/SET_RANKING_PENDING", slug});
       
    dispatch({type:'INCREMENT_PENDING_REQUESTS'})

    mongoapi.run(mongodb=>{
      const coll = mongodb.collection("athletes-rankingAndPassing");
      let filter = {};

      filter.RaceCode = raceCode;
      filter.Bib = athleteBib;
      // console.log("XXX Filter",filter);

      // MAKE THE CALL
      coll.findOne(filter)
      .then(athlete => {

          dispatch({type:'DECREMENT_PENDING_REQUESTS'})

          // console.log("XXXX athlete found",athlete);

          dispatch({ type: "ATHLETES_BY_RACEBIB/SET_RANKING_DATA", slug, 
            data: athlete,
            status: athlete ? 'received' : 'missing'
          });

        }).catch(err => {
          console.warn('MONGO DB error (when requesting athletes-rankingAndPassing by Bib):', err);
          dispatch({type:'DECREMENT_PENDING_REQUESTS'})
        });

    })
  }
}

export const getRace = (raceCode) => {
  return (dispatch, getState) => {

    const { raceSelected } = getState();

    if( raceSelected && raceCode === raceSelected.RaceCode ) {
      return;
    }

       
    dispatch({type:'INCREMENT_PENDING_REQUESTS'})

    mongoapi.run(mongodb=>{
      const coll = mongodb.collection("races");
      let filter = {};

      filter.RaceCode = raceCode;

      // MAKE THE CALL
      coll.findOne(filter)
      .then(race => {

          dispatch({type:'DECREMENT_PENDING_REQUESTS'})

          // console.log("XXXX race found",race);

          dispatch({type:'SET_SELECTED_RACE', race});

        }).catch(err => {
          console.warn('MONGO DB error (when requesting race):', err);
          dispatch({type:'DECREMENT_PENDING_REQUESTS'})
        });


    })
  }
}



export const getAthlete = (raceCode, athleteBib) => {
    return (dispatch) => {
      // console.log("raceCode",raceCode,"athleteBib",athleteBib)
      dispatch(getAthleteMainData(raceCode, athleteBib))
      dispatch(getAthleteRanking(raceCode, athleteBib))
      dispatch(getRace(raceCode))

    }
}