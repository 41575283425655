import initialState from "./initialState";

function isAthleteFilterDirty(filters) {
  const propsToCheck = [ 'overallFilter', 'divisionFilter', 'locationFilter', 'splitTypeFilter', 'sorterCol', 'sorterColDir', 'onlyFavorite' ];
  return !!propsToCheck.find( prop => initialState.raceAthleteFilter[prop] !== filters[prop] )
}

export default (state = initialState.raceAthleteFilter, action) => {
  switch (action.type) {
    case "SET_RACE_ATHLETE_PAGE":{
      // console.log('SET_RACE_ATHLETE_PAGE', action.page)
      const nextState = {...state, page: action.page};
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }
    case "SET_RACE_ATHLETE_PERPAGE": {
      const nextState = {...state, perPage: action.perPage};
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }
    case "SET_RACE_ATHLETE_FILTER": {
      const nextState = {...state,
        overallFilter: action.filter, 
        divisionFilter: initialState.raceAthleteFilter.divisionFilter, 
        // locationFilter: initialState.raceAthleteFilter.locationFilter, 
        page: 1 };
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }
    case "SET_DIVISION_FILTER": {
      const nextState = {...state, 
        divisionFilter: action.filter, 
        overallFilter: initialState.raceAthleteFilter.overallFilter, 
        page: 1 
      };
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }
    case "SET_SPLIT_TYPE_FILTER": {
      const nextState = {...state, 
        splitTypeFilter: action.filter, 
        page: 1 
      };
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }
    case "SET_LOCATION_FILTER":{
        const nextState = {...state, 
        locationFilter: action.filter, 
        page: 1 
      };
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }
    case "SET_SORT_FILTER":{
        const nextState = {...state, 
        sorterColDir: typeof action.sorterColDir==='undefined' ? state.sorterColDir : action.sorterColDir,
        sorterCol: typeof action.sorterCol==='undefined' ? state.sorterCol : action.sorterCol,
        page: 1 
      };
      return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    }

    // case "SET_ONLY_FAVORITE_FILTER":{
    //     const nextState = {...state, 
    //     onlyFavorite: action.onlyFavorite,
    //     page: 1 
    //   };
    //   return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
    // }

    case "TOGGLE_ONLY_FAVORITE_FILTER":{
      // console.log('TOGGLE_ONLY_FAVORITE_FILTER ->', !state.onlyFavorite)
      //   const nextState = {...state, 
      //   onlyFavorite: !state.onlyFavorite,
      //   page: 1 
      // };
      // return {...nextState, isDirty: isAthleteFilterDirty(nextState)};
      return {
        ...initialState.raceAthleteFilter,
        onlyFavorite: !state.onlyFavorite,
        isDirty: true,
      }
    }



    case "SET_SELECTED_RACE":
      return initialState.raceAthleteFilter

    // case "SET_ATHLETE_FILTER": {
    //   let newState = state;
    //   for(let prop in action){
    //     if(prop!=='type'){
    //       if(state[prop] !== action[prop]) {
    //         newState = {...newState, [prop]: action[prop] }
    //       }
    //     }
    //   }
    //   return newState;
    // }

    case "CLEAR_RACE_ATHLETE_FILTER":
      return initialState.raceAthleteFilter;
      
      
    default:
      return state;
  }
};
