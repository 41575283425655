import React, { Component } from "react";
import { connect } from 'react-redux'
import classNames from 'classnames'
import imgMile13 from "../../images/map/miles/mile-13.svg";
import imgAid5 from "../../images/map/aid/aid-5.svg";
import imgAidGu7 from "../../images/map/gu/gu-7.svg";
import imgRoute from "../../images/map/route.svg";
import imgSplit from "../../images/map/split.svg";
import imgSplitRoute from "../../images/dot-1.svg";
import './MapControls.scss'

import ReactTooltip from 'react-tooltip'


import imgOpenArrowHover from '../../images/page-next-3.svg';
import imgOpenArrow from '../../images/page-next-1.svg';

import imgUnselected from '../../images/radio-1.svg';
import imgRadioHover from '../../images/radio-2.svg';
import imgSelected from '../../images/radio-3.svg';



const OpenButton = ({onClick, className}) => (
    <>

        <ReactTooltip  />
                
        <div data-tip="Legend" data-effect='solid' data-place='right' data-delay-show='1000' data-class="MyRaceTooltipDarkLeftArrow"
            onClick={onClick} className={classNames("OpenButton","IconSwappable",className)}>
            <div className="icon" style={{ backgroundImage: `url(${imgOpenArrow})`}}/>
            <div className="hover" style={{ backgroundImage: `url(${imgOpenArrowHover})`}}/>
        </div>
    </>
)

class MapControls extends Component {
    state = {
        show: true
    }
    componentDidMount(){
        this.closeSidebarsOnSmallScreen();
    }

    closeSidebarsOnSmallScreen = () => {
        const { settings: { windowW } } = this.props;
        if(windowW < 600) { this.hide(); }
    }

    setPitch = pitch => e => {
        this.props.setPitch(pitch);
    }

    hide = e => this.setState({ show: false })
    show = e => this.setState({ show: true })
    
    render(){
        const { pitch } = this.props;
        const { show } = this.state;



        return (
            <>
            {/* !show && <OpenButton onClick={this.show} /> */}
            <OpenButton onClick={this.show} className={classNames({ hidden: show })} />

            <div className={classNames('MapControls', { hidden: !show })}>
                <div className="Legend">
                    <div className="LegendSeparator" />
                    <h3>Legend</h3>
                    <dl>
                        <dt><img src={imgMile13} alt="Mile Marker" /></dt>
                        <dd>Mile marker</dd>
                    
                        <dt><img src={imgAid5} alt="Aid Station"/></dt>
                        <dd>Aid station</dd>
                    
                        <dt><img src={imgAidGu7} alt="Aid Station and Gu"/></dt>
                        <dd>Aid station with GU</dd>
                    
                        <dt><img src={imgRoute} style={{marginTop: 5}} alt="Route"/></dt>
                        <dd>Route</dd>
                    
                        <dt><img src={imgSplit} style={{marginTop: 5}} alt="Split Route"/></dt>
                        <dd>Interval route</dd>
                    
                        <dt><img src={imgSplitRoute} alt="Checkpoint Marker"/></dt>
                        <dd>Interval marker</dd>
                    </dl>
                </div>
                <div className="Pitch">
                    <h3>Pitch</h3>
                    <ul>
                        <li className="RadioButtonControl">
                            <input type="radio" name="pitch" value="0" id="mappitch0" checked={pitch===0} onChange={this.setPitch(0)} />
                            <label htmlFor="mappitch0">
                                <img src={imgUnselected} className="radiobtn unselected" alt="radio 0 not select" />
                                <img src={imgRadioHover} className="radiobtn unselected_hover" alt="radio 0 hover"/>
                                <img src={imgSelected} className="radiobtn selected" alt="radio 0 selected"/>
                                <span className="radioLabel">0°</span>
                            </label>
                        </li>
                        <li className="RadioButtonControl">
                            <input type="radio" name="pitch" value="45" id="mappitch45" checked={pitch===45} onChange={this.setPitch(45)} />
                            <label htmlFor="mappitch45">
                                <img src={imgUnselected} className="radiobtn unselected" alt="radio 0 not select" />
                                <img src={imgRadioHover} className="radiobtn unselected_hover" alt="radio 0 hover"/>
                                <img src={imgSelected} className="radiobtn selected" alt="radio 0 selected"/>
                                <span className="radioLabel">45</span>
                            </label>
                        </li>
                        <li className="RadioButtonControl">
                            <input type="radio" name="pitch" value="60" id="mappitch60" checked={pitch===60} onChange={this.setPitch(60)} />
                            <label htmlFor="mappitch60">
                                <img src={imgUnselected} className="radiobtn unselected" alt="radio 0 not select" />
                                <img src={imgRadioHover} className="radiobtn unselected_hover" alt="radio 0 hover"/>
                                <img src={imgSelected} className="radiobtn selected" alt="radio 0 selected"/>
                                <span className="radioLabel">60°</span>
                            </label>
                        </li>
                    </ul>
                </div>

                <div className="hideButton" onClick={this.hide}>&laquo; hide</div>
            </div>
            </>
        )
    }
}

const mapState = ({settings}) => ({settings});
export default connect(mapState)(MapControls);