import React, { Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import {roundDistance} from '../../timeUtils'
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import Header from "../../components/Header/Header";
import MainAthleteBlock from '../../components/MainAthleteBlock/MainAthleteBlock';
import IntervalAnalysisBib from '../../components/IntervalAnalysisBib/IntervalAnalysisBib';
import Footer from '../../components/Footer/Footer'
import Headroom from 'react-headroom';

import "./IntervalAnalysis.scss";

import RaceCourse from './RaceCourse'


import ToggleInterval from "../../components/ToggleButton/ToggleInterval";


import * as athletesByRacebibActions from '../../actions/athletesByRacebib'
import * as sponsorsActions from '../../actions/sponsors'
import TooltipText from '../../components/Tooltip/TooltipText'

import maleIcon from '../../images/male.svg'
import femaleIcon from '../../images/female.svg'

import steeltoe from 'steeltoe';
import { getAvatarAndRaces } from '../../actions/users'
import UserAvatar from '../../components/WelcomeBib/UserAvatar'

class IntervalAnalysis extends Component {

  componentDidMount() {
    // window.analytics.page();

    window.scrollTo(0, 0);

    // this.props.setIntervalIndex(0);

    const { settings: { lastSelectedRaceCode, lastSelectedAthleteBib } } = this.props;

    const { raceCode, athleteBib } = this.props.match.params;


    if(!this.props.athlete || this.props.athlete === undefined || !this.props.athlete.Bib){ // is athlete in the redux?
      // console.log("athlete not in redux, let's load, race & bib",this.props.match.params.raceCode,this.props.match.params.athleteBib);
      this.props.athletesByRacebibActions.getAthlete(
        this.props.match.params.raceCode,
        this.props.match.params.athleteBib
      );

    } 
    // console.log("lastSelectedRaceCode",lastSelectedRaceCode)
    // console.log("lastSelectedRaceCodeBib",lastSelectedAthleteBib)
    // if (!lastSelectedRaceCode || !lastSelectedAthleteBib) {

    //   this.props.setIntervalIndex(0);
    //   this.props.setDistanceDisplayOption(1);
    // } else {
    //   if (raceCode !== lastSelectedRaceCode || athleteBib != lastSelectedAthleteBib ) {
    //     this.props.setIntervalIndex(0);
    //     this.props.setDistanceDisplayOption(1);
    //   }
    // }

    this.props.sponsorsActions.getSponsors(this.props.match.params.raceCode);

    this.getAvatar()

  }

  

  componentDidUpdate(prevProps) {
    this.getAvatar();


    
  }

  getAvatar = () => {
    const { raceCode, athleteBib } = this.props.match.params;
    const EmailHash = steeltoe(this.props).get(`athletesByRacebib.${raceCode}--${athleteBib}.main_data.EmailHash`);
    // console.log('getAvatar', EmailHash)
    if(!EmailHash|| this._getAvatarCalled) { return; }
    this._getAvatarCalled = true;
    // console.log('getAvatar DOWNLOAD', EmailHash)
    this.props.usersActions.getAvatarAndRaces({ EmailHash });
  }


  onIntervalSelected = intervalIndex => {


    this.props.setIntervalIndex(intervalIndex);

    // window.analytics.track('Interval Analysis - Interval Selected', {
    //   "Interval": intervalIndex
    // });

    
  }

  onStartClick = e => {
    // console.log('BACK')

    this.props.goToStart();
    // window.analytics.track('Interval Analysis - Go To Start');

    
  }

  onFinishClick = e => {
    // console.log('BACK')
    const intervalsData = this.props.athlete && this.props.athlete['run-event-intervals-data'];
    if(!intervalsData || !intervalsData.length) { return; }
    const max = intervalsData.length - 1;

    
    this.props.goToFinish(max)

    // window.analytics.track('Interval Analysis - Go To Finish');

    
  }

  onPrevClick = e => {
    // console.log('BACK')

    this.props.goToPrev();

    // window.analytics.track('Interval Analysis - Go To Previous');

    
  }
  onNextClick = e => {
    // console.log('NEXT');
    const intervalsData = this.props.athlete && this.props.athlete['run-event-intervals-data'];
    if(!intervalsData || !intervalsData.length) { return; }
    const max = intervalsData.length - 1;

    this.props.goToNext(max);

    // window.analytics.track('Interval Analysis - Go To Next');
    

  }

  toTitleCase = str => {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  render() {
    // const { raceCode, athleteBib } = this.props.match.params;
    // const { athleteBib } = this.props.match.params;
    const { athletesByRacebib, race, intervalAnalysis, settings, setDistanceDisplayOption, requestsPending, sponsors } = this.props;
    // const { settings } = this.props;
    // const { distanceDisplayOption, currentStartIntervalIndex, currentEndIntervalIndex } = this.state;
    const { distanceDisplayOption, currentEndIntervalIndex } = intervalAnalysis;
    

    const slug = this.props.match.params.raceCode + '--' + this.props.match.params.athleteBib;
    const athleteMainData = athletesByRacebib[slug] && athletesByRacebib[slug].main_data;
    const athleteRankingData = athletesByRacebib[slug] && athletesByRacebib[slug].ranking_data;
    // const athleteRanking = athletesByRacebib[slug] && athletesByRacebib[slug].ranking_data;
    // console.log("athlete from redux",athlete)

    if (requestsPending) {
      return null;
    }

    const sponsorsData = sponsors[race.RaceCode] || {};
    const eventSeriesCode = race.EventSeriesCode;


    // let raceDate = '';
    // let raceDiscipline = '';
    // let raceDistance = '';
  
    // let raceEventName = '';
    let raceDate = '';
    let raceDay = '';

    let showLogo = false;
    
    // const raceCode = this.props.match.params.raceCode;
    // const raceLogo = sponsorsData.raceLogo;
    // if (sponsorsData && sponsorsData.raceLogo && sponsorsData.raceLogo.linkURL && sponsorsData.raceLogo.altText) {
    //   showLogo = true;

    // }
  
    
    // const image = showLogo && (
    //   <a href={raceLogo.linkURL} target="_blank" alt={raceLogo.altText} title={raceLogo.altText} rel="noopener noreferrer" className="topSponsorLink tooltip"><span />
    //     {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
    //     <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`${raceLogo.src}` } />
    //     <TooltipText>Event's Home Page</TooltipText>
    //   </a> 

    // )

    const raceImage = race  && race.sponsorImages && race.sponsorImages[0];
    // const raceImage = race.RaceDetails.sponsorImages[0].src;
    
    // console.log("raceImage",raceImage);
    const image = raceImage && (

        <a href={raceImage.linkURL} target="_blank" alt={raceImage.altText} title={raceImage.altText} rel="noopener noreferrer" className="topSponsorLink tooltip"><span />
          {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
          <img className="topSponsorImage" alt={`${raceImage.altText}`} src={`${raceImage.src}` } />
          <TooltipText>Event's Home Page</TooltipText>
        </a> 

    )

    // let athleteDivision = "";
    let athleteBib = "";
    let athleteDivision = null;

    if (athleteMainData && athleteMainData.MyRace) {

      if (athleteMainData.MyRace.DivisionEliteGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionEliteGroup === "EliteM" ? "Elite Men" : "Elite Women";
      } else if (athleteMainData.MyRace.DivisionMastersGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionMastersGroup === "MastersM" ? "Masters Men" : "Masters Women";
      } else if (athleteMainData.MyRace.DivisionAthenaClydesdaleGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionAthenaClydesdaleGroup;
      } else if (athleteMainData.MyRace.DivisionVisuallyImpairedGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionVisuallyImpairedGroup === "VisuallyImpairedM" ? "Visually Impaired Men" : "Visually Impaired Women";
      } else {
        athleteDivision = athleteMainData.MyRace.DivisionAgeGroup.replace('-','–');
      }

      athleteBib = athleteMainData.MyRace.Bib;
    }

    if (race.RaceDateStart) {

      raceDay = moment(race.RaceDateStart).format('ddd') ;
      raceDate = moment(race.RaceDateStart).format('MMM D, YYYY') ;
      // raceDate = '';
      // raceDiscipline = '';
      // raceDistance = '';
    } else {

      raceDate=null;

    }

    const page = this.props.match.path.split('/')[1];

    const avatarIcon = athleteMainData && athleteMainData.Gender==='M' ? maleIcon : femaleIcon;

    // const noOfIntervals = athlete && athlete["event-intervals-data"] ? athlete["event-intervals-data"].length: null;

    let startDistance = "N/A";
    let endDistance = "N/A";
    let prevStartDistance = "N/A";
    let prevEndDistance = "N/A";
    let prevTotalDistance = "N/A";
    let nextStartDistance = "N/A";
    let nextEndDistance = "N/A";
    let nextTotalDistance = "N/A";
    let totalDistance = "N/A";
    let unitsToShow = "N/A"

    const AGon = settings.ageGrade === "age_grade_on";
    const intervalDisplayOption=!distanceDisplayOption ? true : false;
    startDistance = 0;
    const intervalData = (athleteMainData && athleteMainData['run-event-intervals-ag-data']) ? (AGon ? athleteMainData['run-event-intervals-ag-data'] : athleteMainData['run-event-intervals-data']) : null;

    unitsToShow = settings.units === 'metric' ? 'km' : 'mi';
    
    if (intervalData && intervalData[currentEndIntervalIndex]) {
        if (settings.units === 'metric') {
            //Calculate Current Interval Data
            if (intervalDisplayOption) {
                if (currentEndIntervalIndex === 0) {
                    startDistance = 0;
                } else {

                    startDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceKm,2); 
                }
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,2); 
            }  else {  
                startDistance = 0;
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,2);  
            }
            // totalDistance = roundDistance(endDistance - startDistance); 

            // Calculate Previous Interval Data
            if (intervalDisplayOption) {
              if (currentEndIntervalIndex <= 1) {
                  prevStartDistance = 0;
                  if (currentEndIntervalIndex !== 0) {
                    prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceKm,2); 
                  }
              } else {

                  prevStartDistance = roundDistance(intervalData[currentEndIntervalIndex-2].CumulativeDistanceKm,2); 
                  prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceKm,2); 
              }
              // prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2); 
            }  else {  
              if (currentEndIntervalIndex == 0) {
                prevStartDistance = 0;
                prevEndDistance = 0;
              } else {
                prevStartDistance = 0;
                prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceKm,2);  
              }
            }
            // prevTotalDistance = roundDistance(endDistance - startDistance); 

            // Calculate Next Interval Data
            if (intervalDisplayOption) {
              if (currentEndIntervalIndex <= 1 ) {
                  nextStartDistance = 0; 
                  nextEndDistance = roundDistance(intervalData[currentEndIntervalIndex+1].CumulativeDistanceKm,2); 
                } else {
                  
                  nextStartDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,2); 
                  if (currentEndIntervalIndex+1 < intervalData.length){
                    nextEndDistance = roundDistance(intervalData[currentEndIntervalIndex+1].CumulativeDistanceKm,2); 
                  }
              }
              
              // nextTotalDistance = roundDistance(intervalData[currentEndIntervalIndex+2].CumulativeDistanceMi,2); 
            }  else {  
                nextStartDistance = 0;

                if (currentEndIntervalIndex+1 < intervalData.length){

                  nextEndDistance = roundDistance(intervalData[currentEndIntervalIndex+1].CumulativeDistanceKm,2);  
                }
            }
            // nextTotalDistance = roundDistance(endDistance - startDistance,2); 

            
        } else {

            // Calculate Current Interval Data
            if (intervalDisplayOption) {
                if (currentEndIntervalIndex === 0) {
                    startDistance = 0;
                } else {

                    startDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2); 
                }
                
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,2); 
            }  else {  
                startDistance = 0;

                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,2);  
            }
            // totalDistance = roundDistance(endDistance - startDistance,2); 

            // Calculate Previous Interval Data
            if (intervalDisplayOption) {
              if (currentEndIntervalIndex <= 1) {
                  prevStartDistance = 0;
                  if (currentEndIntervalIndex !== 0) {
                    prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2); 
                  }
              } else {

                  prevStartDistance = roundDistance(intervalData[currentEndIntervalIndex-2].CumulativeDistanceMi,2); 
                  prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2); 
              }
              // prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2); 
            }  else {  
              if (currentEndIntervalIndex == 0) {
                prevStartDistance = 0;
                prevEndDistance = 0;
              } else {
                prevStartDistance = 0;
                prevEndDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2);  
              }
            }
            // prevTotalDistance = roundDistance(endDistance - startDistance); 

            // Calculate Next Interval Data
            if (intervalDisplayOption) {
              if (currentEndIntervalIndex <= 1 ) {
                  nextStartDistance = 0; 
                  nextEndDistance = roundDistance(intervalData[currentEndIntervalIndex+1].CumulativeDistanceMi,2); 
                } else {
                  
                  nextStartDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,2); 
                  if (currentEndIntervalIndex+1 < intervalData.length){
                    nextEndDistance = roundDistance(intervalData[currentEndIntervalIndex+1].CumulativeDistanceMi,2); 
                  }
              }
              
              // nextTotalDistance = roundDistance(intervalData[currentEndIntervalIndex+2].CumulativeDistanceMi,2); 
            }  else {  
                nextStartDistance = 0;

                if (currentEndIntervalIndex+1 < intervalData.length){

                  nextEndDistance = roundDistance(intervalData[currentEndIntervalIndex+1].CumulativeDistanceMi,2);  
                }
            }
            // nextTotalDistance = roundDistance(endDistance - startDistance,2); 
            
        }

        
    } 

    
    // console.log("INTERVAL ANALYSIS: Athlete:",athleteMainData);

    return (
      <div className="IntervalAnalysis">
         
        <Headroom>

          <Header title="SEGMENT ANALYSIS" page={page} race={race} athlete={athleteMainData} />   
        </Headroom>
         <div>

          <div className="container">
              {image}    
          </div>
         </div>


          
        <MainAthleteBlock athlete={athleteMainData} prevStartDistance={prevStartDistance} prevEndDistance={prevEndDistance} nextStartDistance={nextStartDistance} nextEndDistance={nextEndDistance} unitsToShow={unitsToShow}>
          
          <div>


          <div className="MainAthleteBlockHeader">
            {/* <div>
              <img src={avatarIcon} alt="user-avatar" className="userAvatar" />
            </div> */}

            <UserAvatar 
                EmailHash={steeltoe(athleteMainData).get('EmailHash')} 
                Gender={steeltoe(athleteMainData).get('Gender')} // 'M', 'F'
              />

            <div className="flexgrow">
              <Link 
                  className="MarathonTitle"
                  to={`/select-athlete/${this.props.race.EventCode}/${this.props.race.RaceCode}`}>
                  {/* <span data-tip="Open" data-delay-show='1000' data-effect="solid" data-place='bottom' data-class="MyRaceTooltip"> */}
                  <span className="tooltip">
                      <TooltipText>Race's <em>Home Page</em></TooltipText>
                    <strong>{this.props.race.EventName}</strong>
                  </span>
                </Link> 

                <span className="MarathonDates">{raceDay}<i className="dot">•</i>{raceDate}</span>
            </div>
          </div>



              <div className="Label">
                <i/>
                  <span className="tooltip">
                    {startDistance} – {endDistance} {unitsToShow}
                    <TooltipText><em>Current Segment</em></TooltipText>
                    {/* {this.props.race.RaceDistance} */}
                  </span>
                <i/>
              </div>

              <div className="EdgesLine">
                <div><strong>#{athleteBib}</strong></div>
                <div><strong>{athleteDivision}</strong></div>
              </div>
            </div>

          <IntervalAnalysisBib currentIntervalIndex={currentEndIntervalIndex} race={race} athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} settings={settings} intervalDisplayOption={intervalDisplayOption} />
        </MainAthleteBlock>

        <ToggleInterval titles={['Segment', 'Cumulative']} value={distanceDisplayOption} onChange={setDistanceDisplayOption} />
      
        

        <div className="containerRaceLayout flexgrow">
          <RaceCourse athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} race={race} distanceDisplayOption={distanceDisplayOption}/>
        </div>

        <Footer />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  race: state.raceSelected,
  settings: state.settings,
  intervalAnalysis: state.intervalAnalysis,
  user: state.user,
  requestsPending: state.requestsPending,
  sponsors: state.sponsors,
})

const mapDispatchToProps = dispatch => ({

  goToPrev: () => dispatch({type:'INTERVAL_ANALYSIS_GO_TO_PREV'}),
  goToNext: (max) => dispatch({type:'INTERVAL_ANALYSIS_GO_TO_NEXT', max}),
  setDistanceDisplayOption: value => dispatch({type:'INTERVAL_ANALYSIS_SET_DISTANCE_DISPLAY_OPTION', value}),
  setIntervalIndex: intervalIndex => dispatch({type:'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX', intervalIndex}),
  goToStart: () => dispatch({type:'INTERVAL_ANALYSIS_GO_TO_START'}),
  goToFinish: (max) => dispatch({type:'INTERVAL_ANALYSIS_GO_TO_FINISH', max}),


  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
  sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
  usersActions: bindActionCreators({getAvatarAndRaces}, dispatch),

  
})

export default connect(mapStateToProps, mapDispatchToProps)(IntervalAnalysis);
