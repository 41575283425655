import React, { Component } from "react";
import ReactDOM from 'react-dom'
import searchIconActive from "../../images/search-1.svg";
// import searchIconInactive from "../../images/search-2.svg";
// import searchIconDisabled from "../../images/search-3.svg";
import SearchContent from "./SearchContent";
import './SearchModal.scss'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import TooltipText from '../../components/Tooltip/TooltipText'
import {RemoveScroll} from 'react-remove-scroll';

class SearchModal extends Component {

    componentDidMount(){
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        this.contentEl = window.document.createElement('div');
        window.document.body.appendChild(this.contentEl);
    }
    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        window.document.body.removeChild(this.contentEl);
    }

    open = () => {
        if( this.props.disabled ) { return; }
        ReactTooltip.hide();
        this.props.openSearchModal();
    }
    
    select = id => e => {
        this.props.onChange(id);
        this.close();
        
    }
    close = () => this.props.closeSearchModal();

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="SearchCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }

    renderModalContent = () => {
        return (
            ReactDOM.createPortal(
                <RemoveScroll>
                    <SearchContent close={this.close} />
                </RemoveScroll>
            , this.contentEl)
        )
    }

    render() {
        const { settings, page } = this.props;
        const { isSearchModalOpen } = settings;

        return (
            <>
            </>
            
            // <>
              
            //     {/* <ReactTooltip  />   */}
            //     <div 
            //         // data-tip="Search" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
            //         className="searchIcon tooltip" 
            //         onClick={this.open} >
            //             <TooltipText>Search</TooltipText>
            //             <div className="background" />

            //             <img src={searchIconActive} alt="Search" />
            //             {/* <img src={searchIconInactive} alt="Search" className="hover" /> */}
            //             {/* <img src={searchIconActive} alt="Search" className="hover" /> */}

            //     </div>
            //     { isSearchModalOpen && this.renderModalContent() }
            //     { isSearchModalOpen && this.renderModal() }
                
            // </>
        );
            
        
    }
}

const mapState = ({settings}) => ({settings});
const mapDispatch = dispatch => ({
    openSearchModal: ()=>dispatch({type:'SET_SEARCH_MODAL_OPEN', open: true}),
    closeSearchModal: ()=>dispatch({type:'SET_SEARCH_MODAL_OPEN', open: false}),
})


export default connect(mapState, mapDispatch)(SearchModal);
