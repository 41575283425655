// import firebase from 'firebase/app'
import mongoapi from '../apis/mongoapi'
import moment from "moment";

export const getAllRaces = (raceCode) => {
    return (dispatch, getState) => {

        const { getAllRaces, settings } = getState();
        console.log('settings:', settings)

        // console.log('111[getAllRaces] raceCode:', raceCode, typeof raceCode)

        if(getAllRaces && getAllRaces.length) {
            return; // we're already either requesting or got the data
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'})

        mongoapi.run( mongodb=>{
            const coll = mongodb.collection("races");
            let filter = {
            };

            console.log('settings.isPrivate && settings.orgId', settings.isPrivate, settings.orgId);
            if(settings.isPrivate && settings.orgId) {
              console.log('filtering private for orgId', settings.orgId);
              filter.$or = [ { AdminStatus: 'Public' },{ AdminStatus: 'Public-Unofficial' }, { 
                $and: [
                  { AdminStatus: 'Private' },
                  { AdminOrgId: settings.orgId }
                ]
              } ];
            } else {
              console.log('only public races');
              // filter.AdminStatus = 'Public';
              filter.$or = [ { AdminStatus: 'Public' },{ AdminStatus: 'Public-Unofficial' } ]
            }




            
            // filter.Active = true;


            const options = { sort: { RaceDateStart: -1 } };
        
            // MAKE THE CALL
            coll.find(filter, options).toArray()
                .then(snap => {
                    let raceList = [];
                    snap.forEach(doc => {
                      // let race = doc.data();
                      // console.log("RACE",doc);
                      // raceList.push(doc.data());
                      raceList.push(doc);
                    });

                    // console.log("RACELIST",raceList);

                    dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                    dispatch({ type: "ALL_RACES/SET_ALL_RACES", allRaces: raceList});

                    dispatch(setEvents(raceList))
                    
                    
                })
                .catch(err => {
                    console.warn('MONGO DB error (when requesting allRaces):', err);
                    dispatch({type:'DECREMENT_PENDING_REQUESTS'})
                });
        })
    }
}




function setEvents(raceList) {
    return dispatch => {

        let events = {};
    
        raceList.forEach( race => { /* raceList are races - race rows in db */
            const { EventCode } = race;
            if(!events[EventCode]) {
                
                events[EventCode] = { // default event object
                    ...race,
                    TotalRaces: 0,
                    TotalParticipants: 0,
                    races: [], // races in this event
                }
            }
       
    
            // console.log("events[EventCode].EventDateStart",events[EventCode].EventDateStart);
            if (events[EventCode].EventDateStart) {
              
              // const tmpStartDate = moment.unix(race.RaceDateStart.seconds);
              const tmpStartDate = moment(race.RaceDateStart);
              // console.log("Event DATE START",race.RaceDateStart);
              // console.log("Event DATE START seconds",race.RaceDateStart.seconds);
              // console.log("Event DATE START tmpStartDate",moment.unix(race.RaceDateStart.seconds));
    
              const curEventStartDate = moment(events[EventCode].EventDateStart);
              const curEventEndDate = moment(events[EventCode].EventDateEnd);
              // const curEventStartDate = moment.unix(events[EventCode].EventDateStart.seconds);
              // const curEventEndDate = moment.unix(events[EventCode].EventDateEnd.seconds);
    
              const diffFromCurrentStartDate = curEventStartDate.diff(tmpStartDate, 'days');
              const diffFromCurrentEndDate = tmpStartDate.diff(curEventEndDate, 'days');
    
              // console.log("diffFromCurrentStartDate",diffFromCurrentStartDate);
              // console.log("diffFromCurrentEndDate",diffFromCurrentEndDate);
              if (diffFromCurrentStartDate > 0) {
                events[EventCode].EventDateStart = race.RaceDateStart;
              }
              if (diffFromCurrentEndDate > 0) {
                events[EventCode].EventDateEnd = race.RaceDateStart;
              }
    
            } else {
              events[EventCode].EventDateStart = race.RaceDateStart;
              events[EventCode].EventDateEnd = race.RaceDateStart;
    
              // console.log("Event DATE START",race.RaceCode, race.RaceDateStart);
              
            }
    
            events[EventCode].TotalRaces += 1;
            events[EventCode].TotalParticipants += (race.Participants ? race.Participants : 0);

            //be sure there is at least race has defaultRaceCode
            if (events[EventCode].TotalRaces === 1) {
               events[EventCode].DefaultRaceCode = race.RaceCode;
            } else {
              if (race.DefaultRaceCodeForEvent) {
                events[EventCode].DefaultRaceCode = race.RaceCode;
              }
            }
            
            events[EventCode].races.push(race)
        });
        
    
        // console.log("events",events);
    
        // this.props.setRaceEvents(events);
        dispatch({ type:'SET_RACE_EVENTS', events})

    }    

  }