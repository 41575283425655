import initialState from "./initialState";
import update, { extend } from 'immutability-helper';

extend('$auto', function(value, object) {
    return object ?
        update(object, value):
        update({}, value);
});
extend('$autoArray', function(value, object) {
    return object ?
        update(object, value):
        update([], value);
});


// const stub = {
//     // totalItems: 0,
//     // totalPages: 24,
//     pages: {
//         /*
//         1: {
//             status: '', // -- 'pending', 'received', 'error'
//             data: [ {Bib:"1"}, {Bib:"2"} ]
//         }
//         */
//     }
// } 





export const SET_STATUS = "ATHLETES_BY_FILTER/SET_STATUS";
export const SET_DATA = "ATHLETES_BY_FILTER/SET_DATA";
export const SET_TOTAL_ITEMS = "ATHLETES_BY_FILTER/SET_TOTAL_ITEMS";



export default (state = initialState.athletesByRacebib, action) => {
  switch (action.type) {

    case SET_STATUS: {
        // expected: action.slug - filterKey
        // expected: action.pagenum
        // expected: action.status

        return update(state, {
            [action.slug]: { $auto: {
                pages: { $auto: {
                    [action.pagenum]: { $auto: {
                        status: { $set: action.data },
                        // data: { $set: action.data }
                    }}
                }}
            }}
        });

        // return update(state, {
        //     [action.slug]: { $auto: {
        //         status: { $set: action.status }
        //     }}
        // })

        // let nextState = state;
        // if(!nextState[action.slug]){
        //     nextState = update(nextState, { [action.slug]: { $set: stub } })
        // }

        // return update(nextState, {
        //     [action.slug]: {
        //         status: {$set: action.status}
        //     }
        // });
    }

    case SET_DATA: {
        // expected: action.slug, action.data
        // action.pagenum


        // let nextState = state;
        // if(!nextState[action.slug]){
        //     nextState = update(nextState, { [action.slug]: { $set: stub } })
        // }

        return update(state, {
            [action.slug]: { $auto: {
                pages: { $auto: {
                    [action.pagenum]: { $auto: {
                        status: { $set: 'received' },
                        data: { $set: action.data }
                    }}
                }}
            }}
        });
        
        
        // let nextState = {...state};
        // nextState[action.slug] = {...nextState[action.slug]}; // clone it
        // nextState[action.slug].main_status = 'received';
        // nextState[action.slug].main_data = action.data;
        // return nextState;
    }

    case SET_TOTAL_ITEMS: {
        return update(state, {
            [action.slug]: { $auto: {
                totalItems: {
                    $set: action.totalItems || 0
                },
                totalPages: {
                    $set: action.totalPages || 0
                }
            }}
        });
    }

    case "TOGGLE_ONLY_FAVORITE_FILTER": {
        // clear all slugs with |fav| in it

        let nextState = {};
        const goodKeys = Object.keys(state).filter( slug => !slug.includes('|fav|'));

        goodKeys.forEach( key => nextState[key] = state[key] );
        
        return nextState;
    }
    


    default:
      return state;
  }
};
