import React, { Component } from "react";
import {connect} from 'react-redux'
// import "./MainAthleteBlockHome.scss";


// import generic_skyline from '../../images/generic@3x.png';
import generic_skyline from '../../images/generic-02.svg';



import {withRouter} from 'react-router-dom';
// import Beacon from '../Beacon/Beacon'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'



class MainAthleteBlockHome extends Component {



  render() {

    const { children} = this.props;


    let bgImg = generic_skyline;

    // console.log("bgImg",bgImg);

    // console.log("isFirst",isFirst);
    // console.log("isSecond",isSecond);
    // console.log("isThird",isThird);
    // console.log("isFourth",isFourth);
    // console.log("isLast",isLast);
   

    return (
      <>
    
      
      <div className="MainAthleteBackground" style={{
        backgroundImage: `url(${bgImg})`
      }} />
      
      <div className="MainAthleteBlock">


      <div className="imgLeft">
        <div className="backgroundImage"></div>
      </div>

      <div className="card">

         
          <div className="cardcontent">
            {children}
          </div>


      </div>

      <div className="imgRight">
        <div className="backgroundImage"></div>
      </div>

      </div>
      

      
      </>
    );
  }
}

// const mapState = ({raceSelected}) => ({raceSelected})

const mapState = state => ({
  settings: state.settings,
  raceSelected: state.raceSelected
})



const mapDispatch = dispatch => ({
  settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
})

export default withRouter(connect(mapState, mapDispatch)(MainAthleteBlockHome));
