import React, { Component } from "react";
import classnames from 'classnames'
import "./ToggleButton.scss"

import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'
import Beacon from '../Beacon/Beacon'


class ToggleInterval extends Component {

    handleClick = value => e => {
        this.props.onChange(value)

        this.props.settingsActions.setBeaconDone("displayOption")

    }
    
    render(){
        const { titles, value } = this.props;

        return (
            <>
                
                <div className="ToggleButton ToggleInterval">
                    
                    <div onClick={ this.handleClick(1) } className={classnames({ on: value===1 })}>{ titles[1] }</div>
                    <div onClick={ this.handleClick(0) } className={classnames({ on: !value })}>{ titles[0] }
                        <Beacon id="displayOption" text={'Click to view analytics per segment'} direction="left" style={{ top:'0px', left:'90px'}} />
                    </div>
                </div>
            </>
            
        )
    }
}

const mapDispatch = dispatch => ({
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
})
export default connect(null, mapDispatch)(ToggleInterval)

// export default ToggleInterval
