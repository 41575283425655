import { Component } from "react";
import { connect } from "react-redux"
import throttle from 'lodash.throttle'

class WindowWidthListener extends Component {
    constructor(props){
        super(props);
        this.onResize = throttle(this.onResize, 100);
    } 

    componentDidMount(){
        this.onResize();
        window.addEventListener('resize', this.onResize);
      }
    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        // console.log('window resized:', window.innerWidth, 'x', window.innerHeight );
        const windowW = window.innerWidth;
        // console.log("window.matchMedia",window.matchMedia)
        // const windowW = window.matchMedia;
        if(windowW!==this.props.settings.windowW) {
            this.props.setWindowW(windowW)
        }
    }
    
    render(){
        return null;
    }
}


const mapState = ({settings}) => ({settings})
const mapDispatch = (dispatch) => ({
  setWindowW: (windowW) => dispatch({type: "SET_SETTING", windowW})
})

export default connect(mapState, mapDispatch)(WindowWidthListener);