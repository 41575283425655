import React, { Component } from "react";
import { bindActionCreators } from "redux";
import MapSidebar from "./MapSidebar";
import Legend from "./MapControls";
import Header from "../../components/Header/Header";
import {connect} from 'react-redux'
import * as athletesByRacebibActions from '../../actions/athletesByRacebib';
// import firebase from 'firebase/app'
import {db} from '../../config/firebase'
import 'mapbox-gl/dist/mapbox-gl.css';
// import mileIconsSprite from '../../images/map/miles/mile-markers-sprite.png'
// import mileIconsSprite from '../../images/map/miles/mile-1.png'
import mapIconsSprite from '../../images/map-sprite.png'
import iconMapping from '../../images/map-sprite.json'
// import tooltipBackground from '../../images/tooltip-bg.svg'
import iconIntervalPoint from "../../images/dot-1.png";
// import iconIntervalPointHov from "../../images/dot-2.png";
import {secondsToTimeStrip} from '../../timeUtils'

// import imgMileSponsor from "../../images/mvm-ma-2018/partners/01-1-item@3x.png";



import DeckGL from '@deck.gl/react';
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl';

import { tooltipStyle } from './style';



class CourseMap extends Component {


  state = {
    hover: {
      x: 0,
      y: 0,
      hoveredObject: null
    },

    showMap: true,
    
    pitch: 0, // 0° 45° 60°
    coursePathpoints: [],

    // style: 'mapbox://styles/mapbox/light-v9',
    // style: 'mapbox://styles/mapbox/light-v9?optimize=true',
    // style: 'mapbox://styles/mapbox/streets-v10',
    // style: 'mapbox://styles/mapbox/streets-v10?optimize=true',
    // style: 'mapbox://styles/amatheou/cjwgxs5sd448x1cjz6yfmtteb?optimize=true',
    // style: 'mapbox://styles/amatheou/cjwgxs5sd448x1cjz6yfmtteb',
    style: 'mapbox://styles/amatheou/cjwhv9xil4z6v1cmphqxaocmw',
    linecolor: [0, 141, 251, 255], 
    allGeoPoints: [],
    // intervalsGeoPoints: [], // [{ startKm: , endKm: , geoPoints: [] }]})
    intervalsGeoPoints: [], // [{ startMi: , endMi: , geoPoints: [] }]})
    mileMarkerGeos: {},
    hydrationStops: [],
    lastPointsOfIntervals: [],
    stickyTooltip: null
  };

  mapInitialProps = {
    zoom: 12.1, // SFMAR
    minZoom: 5,
    maxZoom: 16,
    pitch: 0,
    bearing: 0,
    latitude: 37.790, // SFMAR
    longitude: -122.442, //SFMAR
  }

  componentDidMount() {
    // window.analytics.page();
    
    const { raceCode, athleteBib } = this.props.match.params;

    this.props.setIntervalIndex(0);
    // const { race } = this.props;
    
    // console.log("raceCode",raceCode);
    // console.log("athleteBib",athleteBib);
    this.props.athletesByRacebibActions.getAthlete( raceCode, athleteBib );

    this.requestGeoPoints(raceCode);

    this.setCoordinates();
       
  }


  setCoordinates = () => {
    const { race } = this.props;
    if(!race || !race.RaceCode) return;
    const { settings: { windowW } } = this.props;
    const DefaultMapZoomAdjust = windowW < 600 ? 1.1 : 0;
  
    const {DefaultMapLatitude, DefaultMapLongitude, DefaultMapZoom} = race;
    this.resetMap({ latitude: DefaultMapLatitude , longitude: DefaultMapLongitude, zoom: DefaultMapZoom - DefaultMapZoomAdjust});
  }

  componentDidUpdate(prevProps) {
    const { race } = this.props;
    if(prevProps.race.RaceCode !== race.RaceCode){
      this.setCoordinates() 
    }
  }

  setPitch = pitch => {
    this.resetMap({pitch});
  }
  
  
  resetMap = newState => {
    this.mapInitialProps = {...this.mapInitialProps, ...newState};
    this.setState({showMap:false, ...newState}, ()=>window.setTimeout(()=>this.setState({showMap:true}), 200) );
  }



  requestGeoPoints = raceCode => {
    this.props.incrementPendingRequests();
    db
      .collection('races')
      .doc(raceCode)
      .collection('geoPoints')
      .doc('geoPoints')
      .get()
      .then((snap) =>{
        this.props.decrementPendingRequests();
        const data = snap.data();
        // console.log('GeoPoints data:', data);
        if(data && data.geoPoints && data.geoPoints.routePoints){
          this.processGeoPoints(data.geoPoints);
        }

      })
      .catch(err => {
        this.props.decrementPendingRequests();
        console.warn('ERR (GeoPoints):', err)
      });
  }

  processGeoPoints = (geoPoints) => {   
    const athlete = this.getAthleteMainData();
    if(!athlete || !athlete['event-intervals-data']) { 
      window.setTimeout(()=>this.processGeoPoints(allGeoPoints), 1000);
      return; 
    }
    
    const allGeoPoints  = geoPoints.routePoints;
    // const mileMarkers  = geoPoints.mileMarkers;

    
    // generate mile marker icons
    let mileMarkerGeos = {};
    allGeoPoints.forEach( (point,i) => {
      if(!i) return;
      // let mile = Math.floor(point.distanceTraveledEstimatedM);
      let mile = Math.floor(point.mile);
      // poing.geo

      let pointGeo = point.geo.split(',');
      pointGeo = [ +pointGeo[1], +pointGeo[0] ];

      if(!mileMarkerGeos[mile]){
        mileMarkerGeos[mile] = pointGeo;
      }
    })


    // generate water stop icons
    let hydrationStops = [];
    let hydrationStopsSource = [...geoPoints.hydrationStops];
    allGeoPoints.forEach( (point,i) => {
      if(!i) return;
      // hydrationStops
      // as soon as hydrationStops[0]  >= point.distanceTraveledEstimatedM
      // as soon as it reaches the distance -> hydrationStops.shift();

      if(hydrationStopsSource.length && ( point.distanceTraveledEstimatedM >= hydrationStopsSource[0] )){
        let pointGeo = point.geo.split(',');
        pointGeo = [ +pointGeo[1], +pointGeo[0] ];

        hydrationStops.push({
          distanceTraveledEstimatedM: point.distanceTraveledEstimatedM,
          pointGeo
        })
        hydrationStopsSource.shift();
      }
    });





    // generate intervals
    let indexGeoPoint = 0;
    let intervals = athlete['event-intervals-data'].map( (intr) => {

      // const startKm = intr.CumulativeDistanceKm - intr.IntervalDistanceKm;
      // const endKm = intr.CumulativeDistanceKm;
      const startMi = intr.CumulativeDistanceMi - intr.IntervalDistanceMi;
      const endMi = intr.CumulativeDistanceMi;
      let geoPoints = [];
      

      for(let i = indexGeoPoint; allGeoPoints.length-1; i++){
        let point = allGeoPoints[i];
        if(!point) { break; }
        // if (point.distanceTraveledEstimatedKm >= endKm) { break; }
        if (point.mile >= endMi) { break; }

        point = point.geo.split(',');
        point = [ +point[1], +point[0] ];
        geoPoints.push(point)
        indexGeoPoint = i;
      }

      // return { startKm, endKm, geoPoints }
      return { startMi, endMi, geoPoints }
    });




    
    
    


    // generate points at end of every interval (except the last one)
    let lastPointsOfIntervals = intervals.map( intr=> intr.geoPoints[intr.geoPoints.length-1] );
    // lastPointsOfIntervals.splice(-1,1); // <- remove the last point


    this.setState({ intervalsGeoPoints: intervals, mileMarkerGeos, allGeoPoints, lastPointsOfIntervals, hydrationStops })
  }


  _onHover({ x, y, object }) {
    const label = object ? (object.pickup ? 'Pickup' : 'Dropoff') : null;

    this.setState({ hover: { x, y, hoveredObject: object, label } });
  }

  onStyleChange = style => {
    this.setState({ style });
  };

  _updateLayerSettings(settings) {
    this.setState({ settings });
  }



  _renderTooltip() {
    const {hoveredObject, pointerX, pointerY} = this.state || {};
    // console.log("hoveredObject",hoveredObject);
    // return;
    if(!hoveredObject) { return; }
    const { name, tooltipData: data } = hoveredObject;
    // console.log("data", data);

    if(!name) { return; }

    const isInterval = name.indexOf('interval')===0;
    const isMile = name.indexOf('mile')===0;
    const isHydrationStop = name.indexOf('hydrationStop')===0;


    const width = 160;
    const height = isInterval ? 140 : 80;
    const fontSize = isInterval ? 11 : 14;
    const dlMargin = isInterval ? '0 0 0 10px'  : '5px 0 0 36px';
    
    const styles = {
      container: {
        position: 'absolute', 
        padding: '10px 10px 0 10px',
        width,
        height,
        color: "#000",
        fontSize,
        fontWeight: 700,
        zIndex: 1, 
        pointerEvents: 'none', 
        left: pointerX - (width/2), // + 10, 
        top: pointerY - (height+30),// -10,
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        borderRadius: 3,
        boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
        lineHeight: '15px',
      },
      dl: {
        display: 'grid',
        // margin: 0,
        margin: dlMargin,
        gridTemplateColumns: '1.1fr 1.9fr',
        gridColumnGap: 10,
        fontWeight: 500,

      },
      dt: {
        textAlign: 'right',
        color: '#6b7c88',
        fontWeight:500,
      },
      dd: {
        margin: 0,
        color: '#2c2f3c',
        fontWeight: 400,
      },
      arrow: {
        position: 'absolute',
        display: 'block',
        top: '100%',
        left: '50%',
        border: 'solid transparent',
        borderColor: 'transparent',
        borderTopColor: '#fff',
        borderWidth: 8,
        // content: " ",
        height: 0,
        width: 0,
        pointerEvents: 'none',
        marginLeft: -8,
      },
      img: {
        maxWidth: '93%',
        maxHeight: '50px',
        padding: '4px',
        borderBottom: '1px solid #E9E9E9'
      },
      smallFont: {
        fontSize: '9px'
      },
      blueFont: {
        color: '#008dfb',
        fontWeight: '600px'
      },
      greenFont: {
        color: '#28bb28'
      }
    }

    // const hoverLabel = (name.slice(0, name.length-1) + ' ' + name.slice(-1));

    let tooltipToRender = null;
    if (isInterval) {
      tooltipToRender = 
      <div style={styles.container}>
        {/* <img src="/images/mvm-ma-2018/partners/01-1-item@3x.png" alt="logo" style={styles.img} /> */}
        <dl style={styles.dl}>
          <dt style={styles.dt}>Distance:</dt>
          <dd style={styles.dd}><strong>{data.CumulativeDistanceMi}</strong> <span style={styles.smallFont}>mi</span></dd>
          <dt style={styles.dt}>Interval:</dt>
          <dd style={styles.dd}><strong>{data.IntervalDistanceMi}</strong> <span style={styles.smallFont}>mi</span></dd>
          <dt style={styles.dt}>Pace:</dt>
          <dd style={styles.dd}><span style={styles.blueFont}>{secondsToTimeStrip(data.IntervalPaceEnglishSeconds)}</span> <span style={styles.smallFont}>min/mi</span></dd>
          <dt style={styles.dt}>Elevation:</dt>
          <dd style={styles.dd}><span style={styles.greenFont}>+83</span> <span style={styles.smallFont}>ft</span></dd>
          <dt style={styles.dt}>Time:</dt>
          <dd style={styles.dd}>{secondsToTimeStrip(data.IntervalCumulativeTimeSeconds)}</dd>
        </dl>
        <div style={styles.arrow}></div>
      </div>
    } else if (isMile) {
      const mileNumber = name.substring(4,name.length);
      tooltipToRender = 
      <div style={styles.container}>
        {/* <img src="/images/mvm-ma-2018/partners/01-1-item@3x.png" alt="logo" style={styles.img} /> */}
        <dl style={styles.dl}>
          <dt style={styles.dt}>Mile:</dt>
          <dd style={styles.dd}><strong>{mileNumber}</strong></dd>
        </dl>
        <div style={styles.arrow}></div>
      </div>
    } else if (isHydrationStop) {
      const hydrationStopNumber = name.substring(13,name.length);
      tooltipToRender = 
      <div style={styles.container}>
        {/* <img src="/images/mvm-ma-2018/partners/04-2-item@3x.png" style={styles.img} /> */}
        <dl style={styles.dl}>
          <dt style={styles.dt}>Water/GU:</dt>
          <dd style={styles.dd}><strong>{hydrationStopNumber}</strong></dd>
        </dl>
        <div style={styles.arrow}></div>
      </div>
    }

    return tooltipToRender;
  }


  
  createGeoJsonObj = (pathpoints, color, intervalIndex) => {
    return {
      name: "route-" + intervalIndex,
      type: 'LineString',
      coordinates: pathpoints.map( point => {        
        // expected item format: [100.0, 0.0]
        return point;
      }),
      properties: {
        color
      }
    }
  }
  
  createIntervalIcon = (data) => {

    const { settings: { windowW } } = this.props;
    
    return new IconLayer({
      id: 'interval-points-layer',
      data,
      pickable: true,
      iconAtlas: iconIntervalPoint,
      iconMapping: {
        interval: {x:0, y:0, width:36, height: 38, mask:false}
      },
      getIcon: d => "interval",
      sizeScale: windowW < 600 ? 5.7 : 4.5,
      getSize: d => (windowW < 600 ? 5.7 : 4.5),
      opacity: 1,
      pickable: true,
      // Update app state
      onClick: info => {
        console.log('IconLayer CLICKED:', info);
        if(info && info.object){
          const index = +(info.object.name.replace('interval','')) - 1;
          this.props.setIntervalIndex(index);
          this.setState({
            stickyTooltip: info.object
          })
        }
      },
      onHover: info => this.setState({
        hoveredObject: info.object,
        pointerX: info.x,
        pointerY: info.y
      }),
      getPosition: d => {
        // console.log('[getposition in mileMarker', d)
        return d.coordinates},
      
    })
  }

  createHydrationStopMarkerIcon = (data) => {

    const { settings: { windowW } } = this.props;
 

    return new IconLayer({
      id: 'hydration-markers-layer',
      data,
      pickable: true,
      iconAtlas: mapIconsSprite,
      iconMapping,
      getIcon: d => d.name,
      sizeScale: windowW < 600 ? 7.5 : 6.5,
      getSize: d => (windowW < 600 ? 7.5 : 6.5),
      opacity: 1,
      pickable: true,
      // Update app state
      onHover: info => this.setState({
        hoveredObject: info.object,
        pointerX: info.x,
        pointerY: info.y
      }),
      getPosition: d => {
        // console.log('[getposition in mileMarker', d)
        return d.coordinates},
      
      // getColor: d => [255, 140, 0],
      // onHover: ({object, x, y}) => {
      //   // const tooltip = `${object.name}\n${object.address}`;
      //   const tooltip = `Mile Marker`;
      //   /* Update tooltip
      //      http://deck.gl/#/documentation/developer-guide/adding-interactivity?section=example-display-a-tooltip-for-hovered-object
      //   */
      // }
    })
  }
  
  createMileMarkerIcon = (data) => {

    const { settings: { windowW } } = this.props;
 

    return new IconLayer({
      id: 'mile-markers-layer',
      
      data,
      pickable: true,

      // iconAtlas: mileIconsSprite,
      iconAtlas: mapIconsSprite,
      iconMapping,
      getIcon: d => d.name,
      sizeScale: windowW < 600 ? 6.5 : 5.5,
      getSize: d => (windowW < 600 ? 6.5 : 5.5),
      opacity: 1,
      pickable: true,
      // Update app state
      onHover: info => this.setState({
        hoveredObject: info.object,
        pointerX: info.x,
        pointerY: info.y
      }),
      getPosition: d => {
        // console.log('[getposition in mileMarker', d)
        return d.coordinates},
      
      // getColor: d => [255, 140, 0],
      // onHover: ({object, x, y}) => {
      //   // const tooltip = `${object.name}\n${object.address}`;
      //   const tooltip = `Mile Marker`;
      //   /* Update tooltip
      //      http://deck.gl/#/documentation/developer-guide/adding-interactivity?section=example-display-a-tooltip-for-hovered-object
      //   */
      // }
    })
  }

  getAthleteMainData = () => {
    const slug = this.props.match.params.raceCode + '--' + this.props.match.params.athleteBib;
    const athlete = this.props.athletesByRacebib[slug] && this.props.athletesByRacebib[slug].main_data;
    return athlete;
  }
  getAthleteRankingData = () => {
    const slug = this.props.match.params.raceCode + '--' + this.props.match.params.athleteBib;
    const athlete = this.props.athletesByRacebib[slug] && this.props.athletesByRacebib[slug].ranking_data;
    // console.log("ZZZ, athlete rank", athlete);
    // console.log("ZZZ, athlete ", this.props.athletesByRacebib[slug]);
    return athlete;
  }
  
  render() {
    const {pitch, intervalsGeoPoints, hover, showMap, mileMarkerGeos, allGeoPoints, lastPointsOfIntervals, hydrationStops, requestsPending} = this.state;
    const { race } = this.props;
    // const { athletesByRacebib, race, match } = this.props;
    // const { match, settings } = this.props;
    const { match } = this.props;
    const {raceCode} = match.params;
    // const { windowW } = settings;

    const athleteMainData = this.getAthleteMainData();
    const athleteRankingData = this.getAthleteRankingData();
    
    let layers = [];

    const { intervalAnalysis } = this.props;
    const { currentStartIntervalIndex, currentEndIntervalIndex } = intervalAnalysis;
  
    const { settings: { windowW } } = this.props;

    if (requestsPending){
      return null;
    }
      

    if(intervalsGeoPoints && intervalsGeoPoints.length) {

      intervalsGeoPoints.forEach( (intervalGeoPoints, i) => {
        
        // console.log('intervalGeoPoints', intervalGeoPoints);
        let curColor = [0, 141, 251, 255];
        let lineWidthPx = windowW < 600 ? 5 : 3;
        if (i >= currentStartIntervalIndex && i <= currentEndIntervalIndex){
          curColor = [16, 59, 118, 255];
          lineWidthPx = windowW < 600 ? 7 : 5;
        }
        let col = this.state.linecolor;
        // col[0] += (i*100);
        // col[2] -= (i*100);
        const geojsonobject = this.createGeoJsonObj(intervalGeoPoints.geoPoints, col,i);
        
        // console.log("geojsonobject",geojsonobject)
        const courseGeoJSONLayer = new GeoJsonLayer({
          id: 'geojson' + i,
          data: geojsonobject,
          opacity: 1,
          stroked: false,
          filled: true,
          lineWidthMinPixels: lineWidthPx,
          parameters: {
            depthTest: false
          },
          // getLineColor: d => d.geometry.properties.color,
          getLineColor: curColor,
          pickable: true,
          onClick: info => {
            // console.log('GeoJson Layer CLICKED:', info);
            if(info && info.object){
              // console.log("info line",info);
              const index = +(info.object.geometry.name.replace('route-',''));
              this.props.setIntervalIndex(index);
              // this.setState({
              //   stickyTooltip: info.object
              // })
            }
          },
          onHover: info => this.setState({
            hoveredObject: info.object,
            pointerX: info.x,
            pointerY: info.y
          }),
          updateTriggers: {
            getLineColor: [200, 200, 200],
            getLineWidth: 2018
          },
          transitions: {
            getLineColor: 500,
            getLineWidth: 500
          }
        })
        layers.push(courseGeoJSONLayer);

      })
    }






    let mileMarkerIcons = Object.keys(mileMarkerGeos).map( mile => {
      return {
        name: 'mile'+mile, 
        coordinates: mileMarkerGeos[mile], 
      }
    })
    
    layers.push(this.createMileMarkerIcon(mileMarkerIcons));


    // let hydrationIcons = hydrationStops.map( (hydrationStop, i) => {
    //   // hydrationStop.distanceTraveledEstimatedM
    //   // hydrationStop.pointGeo
    //   return {
    //     name: 'hydrationStop'+(i+1), 
    //     coordinates: hydrationStop.pointGeo, 
    //   }
    // })
    // layers.push(this.createHydrationStopMarkerIcon(hydrationIcons));


    
    
    // let intervalIcons = lastPointsOfIntervals.map( (coordinates, i) => {


    //   return {
    //     name: 'interval'+(i+1), coordinates,
    //     tooltipData: athleteMainData['event-intervals-data'] ? athleteMainData['event-intervals-data'][i] : {name: 'no athlete data yet'}
    //   }
    // });
    
    // layers.push(this.createIntervalIcon(intervalIcons));

    

    return (
      <div className="CourseMap">

      <Header title={`COURSE MAP`} race={race} athlete={athleteMainData} />

        <div style={{
          position: 'absolute',
          // minHeight: 'calc(100vh - 10px)',
          minHeight: 'calc(100vh - 70px)',
          left: 0,
          top: 60,
          minWidth: 'calc(100vw - 0px)',
        }}>

        
            {hover.hoveredObject && (
            <div
                style={{
                ...tooltipStyle,
                transform: `translate(${hover.x}px, ${hover.y}px)`
                }}
            >
                <div>{hover.label}</div>
            </div>
            )}

           
            
            <MapSidebar raceCode={raceCode} athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} allGeoPoints={allGeoPoints} />
            <Legend athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} raceCode={raceCode} sponsors={this.props.sponsors} pitch={pitch} setPitch={this.setPitch} />


            { showMap && <DeckGL 
              layers={layers}
              pitch={pitch}
              initialViewState={this.mapInitialProps}
              // viewState={this.state}
              controller={true}
              >              
                <StaticMap 
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
                    mapStyle={this.state.style} 
                >
                </StaticMap>

             
                { this._renderTooltip() }
            </DeckGL> }
        </div>
            
      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  race: state.raceSelected,
  sponsors: state.sponsors,
  intervalAnalysis: state.intervalAnalysis,
  settings: state.settings,
  requestsPending: state.requestsPending
})

const mapDispatchToProps = dispatch => ({

  setAgeGradeFactor: ageGradeFactor => dispatch({type:'SET_AGE_GRADE_FACTOR', ageGradeFactor}),

  setIntervalIndex: intervalIndex => dispatch({type:'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX', intervalIndex}),
  
  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
  incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
  decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
  
})

export default connect(mapStateToProps, mapDispatchToProps)(CourseMap);


