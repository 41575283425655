import React, { Component } from "react";
import ReactDOM from 'react-dom'
import classNames from 'classnames';
import ToggleDivision from "../../components/ToggleButton/ToggleDivision";
import './DropdownDivision.scss'
import checkIcon from '../../images/check.svg';
import chevronIcon from '../../images/chevron-3.svg';
import TooltipText from '../Tooltip/TooltipText'


const Option = ({id, title, onClick, selected, disabled}) => {
    let style={};
    if(selected){
        style.backgroundImage = `url(${checkIcon})`;
    }

    let props = {};
    // console.log("selected",selected);
    if(!disabled){ props.onClick = onClick; }
    return (
        <li className={classNames({ selected, disabled })} style={style}  {...props}>{title}</li>


    )
}



class DropdownDivision extends Component {

    state = {
        open: false,
        gender: 0, // 0 - male, 1 - female
    }

    componentDidMount(){
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
    }
    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        
    }

    componentDidUpdate(prevProps, prevState){
        // console.log("xxx this.props.value",this.props.value);
        if( this.props.value!=='All-DivisionType1s' && prevState.gender !== this.state.gender){
            const g = this.state.gender ? 'F' : 'M';
            const id =  g + this.props.value.substring(1);
            this.props.onChange(id);
            this.close();
        }
    }

    open = () => {
        if( this.props.disabled ) { return; }
        this.setState({ open: true })
    }
    
    select = id => e => {
        // console.log('DIVISION SELECTED:' , id)
        this.props.onChange(id);
        this.close();
    }
    close = () => this.setState({ open: false })

    onGenderChange = gender => this.setState({ gender })


    render() {
        const { open, gender } = this.state;
        const { options, value, disabled, race } = this.props;
        // console.log('this.props',this.props)
        const selectedOption = options.find( o => o.id === value );

        let disabledOptions = [];
     
        if(race && race.EmptyDivisionFilters){
            disabledOptions = race.EmptyDivisionFilters.split(',');
        }

        // console.log("disabledOptions",disabledOptions);

        const divisionOptions = options.slice(1)
            .filter( o => o.id[0] === (gender?'F':'M') )
            .map(  o => ({
                ...o,
                title: o.title.substring(1)
            }));

        const allDivisionsOption = options[0];

        const tooltipTextVal = disabled ? null :  <TooltipText>Filter by...</TooltipText>;
        
       
 
        // console.log("selectedOption",selectedOption);
        // console.log("disabled",disabled);
        return (
            <div className={classNames("DropdownContainer", {tooltip: !disabled})} >
                {tooltipTextVal}
                {!disabled}
                <div className={classNames("DropdownValue", {disabled})} onClick={this.open}>
                    <em>{selectedOption.title}</em>
                    <span className="chevron"><img src={chevronIcon} alt='dropdown' /></span>
                </div>

                <div 
                    className={classNames('DropdownList', { open })} 
                    // onMouseLeave={this.close}
                    >

                    <ul className="AllDivisionsOption">
                        {
                            <Option
                                selected={ allDivisionsOption.id === selectedOption.id }
                                onClick={this.select(allDivisionsOption.id)} 
                                {...allDivisionsOption} />
                            }
                    </ul>
                    <ToggleDivision titles={[ 'M', 'F']} value={gender} onChange={this.onGenderChange} />
                    <ul className="DivisionOptions">
                        { divisionOptions.map( option  => <Option
                            selected={ option.id === selectedOption.id }
                            key={option.id} 
                            disabled={ disabledOptions.includes(option.id) }
                            onClick={this.select(option.id)} 
                            {...option} />)}
                    </ul>
                </div>
                { open && ReactDOM.createPortal(
                    <div className="DropdownCover" onClick={this.close}></div>
                , this.coverEl)}
            </div>
        );
    }
}

export default DropdownDivision;
