import React, { useState } from 'react';
import imgEmailBlue from '../../images/mail-01.svg';
import imgEmailRed from '../../images/mail-02.svg';
import imgEmailBlack from '../../images/mail-03.svg';
import {connect} from 'react-redux'
import { useForm } from "react-hook-form";
import classNames from 'classnames'
import axios from 'axios';

const SuperAdminPageUtilityEmailHash = ({close, settings}) => {
    const { register, errors, triggerValidation, handleSubmit } = useForm();

    const [generatedEmailHash, setGeneratedEmailHash] = useState(null)

    const onSubmit = data => {
        // console.log('FORM SUBMIT', data);
        const {email} = data;
        
        // setEmail(email);
    

        // this.props.incrementPendingRequests();


    
        axios.get('https://us-central1-my-race-com.cloudfunctions.net/getHashFromEmail', {
            params: { email }
        }).then((response) => {
            // this.props.decrementPendingRequests();
            const EmailHash = response.data.EmailHash;

            // console.log("EmailHash",EmailHash)
            setGeneratedEmailHash(EmailHash);
            
        }, (error) => {
            // this.props.decrementPendingRequests();
            console.log(error);
            });

    }

    return (
        <div className="SuperAdminPageUtilities">

            <div className="UtilityCard">

                {/* <h1>Get EmailHash</h1>
                <div className="Label"><i></i><span>Get EmailHash from Email</span><i></i></div> */}

                <p className="tagline">Gets the Email Hash value for a given email address:</p>
                
            
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={classNames("control","email", {
                        valid: true,
                        invalid: false,
                        })}>
                        <div className="ControlIcon">
                            <div className="IconSwappableValid">
                                <div className="icon" style={{ backgroundImage: `url(${imgEmailBlue})`}}/>
                                <div className="invalid" style={{ backgroundImage: `url(${imgEmailRed})`}}/>
                                <div className="valid" style={{ backgroundImage: `url(${imgEmailBlack})`}}/>
                            </div>
                        </div>

                        <input name="email" type="email" 
                            ref={register({
                                validate: value => (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                            })}
                            autoComplete="off"
                            placeholder="Type in your email address..."
                            />
                    </div>


                    <div className="buttons">
                        <button type="submit">Go</button>
                    </div>

                    <p className="tagline">{generatedEmailHash}</p>
                </form>
            </div>
        </div>

    )

}

const mapStateToProps = ({settings})=>({settings});

const mapDispatchToProps = dispatch => ({
    incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
    decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
})

// const mapDispatchToProps = (dispatch) => ({

// })
  
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminPageUtilityEmailHash);