import React, { Component } from "react";
import classNames from 'classnames'


class PageInputForm extends Component {
    constructor(props){
        super(props);
        this.inputRef = React.createRef();
    }
    componentDidMount(){
        this.inputRef.current.focus();
        this.inputRef.current.select();
    }
    render(){
        const {pageNumInvalid} = this.props;
        return (
            <form
                className={classNames({ pageNumInvalid })}
                onSubmit={this.props.onSubmit}>
                <input 
                    className="numinput"
                    type="text" 
                    onChange={this.props.onChange} 
                    value={this.props.value} 
                    ref={this.inputRef} 
                    onBlur={this.props.onBlur} 
                    />
                <input type="submit" />
            </form>
        )
    }
}

class DotsPageInput extends Component {

    state = {
        isOpen: false,
        pagenum: 0,
        pageNumInvalid: false
    }

    onSubmit = e => {
        e.preventDefault();
        const {isOpen, pagenum} = this.state;

        let page = pagenum;

        console.log('Go to page', page);

        const {totalPages} = this.props;

        if(typeof page === 'string'){
            page = parseInt(page, 10);
        }
        if(page !== page/*NaN*/ || page > totalPages || page <= 0) {
            this.setPageInvalid(true);
            return;
        }
        
        this.setPageInvalid(false);

        this.props.onSubmit(page);
    }

    componentDidMount(){
        this.setState({
            pagenum: this.props.nextPageNum
        })
    }

    setPageInvalid = (pageNumInvalid) => {
        console.log('setPageInvalid', pageNumInvalid)
        this.setState({ pageNumInvalid })
    }


    updnum = e => this.setState({ pagenum: e.target.value})
    open = e => this.setState({ isOpen: true })
    close = e => this.setState({ isOpen: false })
    
    render() {
        const {isOpen, pagenum} = this.state;
        
        return (
            <span 
                onClick={this.open}
                className={classNames("tooltip","DotsPageInput", {
                pagenum: !isOpen,
                ellipses: !isOpen
            })}>
                { !isOpen ? (
                    <span
                        // data-tip="Go to page ..." data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
                        ><span className="tooltiptext dotsPageInputTooltip">Go to page...</span>...</span>
                ) : (
                        <PageInputForm 
                            onSubmit={this.onSubmit}
                            onChange={this.updnum}
                            value={pagenum}
                            onBlur={this.close}
                            pageNumInvalid={this.state.pageNumInvalid}
                            />
                    )
                }
            </span>
        );
    }
}


export default DotsPageInput;
