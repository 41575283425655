import React, { Component } from "react";
import ReactDOM from 'react-dom'
import logo from "../../images/logo.svg"
import icoRunner from "../../images/user-04.svg"
import iconEmail from "../../images/mail-01.svg"
// import iconPhone from "../../images/form-phone.png"
// import iconComment from "../../images/form-comment.png"

// import searchIconActive from "../../images/search-1.svg";
// import searchIconInactive from "../../images/search-2.svg";
// import searchIconDisabled from "../../images/search-3.svg";
// import SearchContent from "./SearchContent";
import './KeepInTouchModalForm.scss'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import classNames from 'classnames'
import mongoapi from '../../apis/mongoapi'
import keepInTouchIcon from "../../images/happy120+.gif";
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

// import imgSkyline from '../../images/sf-skyline-small.jpg'
// import imgSkyline from '../../images/sf-skyline-large@3x_cropped.png';
// import cityImg from '../../images/sf-skyline-finish@3x.png'
// import cityImgMobile from '../../images/sf-skyline-mobile.png'
import imgRunner from '../../images/running-small.gif'
import cityImgMobile from '../../images/generic-popup-1.svg'
import TooltipText from '../../components/Tooltip/TooltipText'
import {RemoveScroll} from 'react-remove-scroll';

class KeepInTouchModalForm extends Component {

    constructor(props){
        super(props);

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        this.contentEl = window.document.createElement('div');
        window.document.body.appendChild(this.contentEl);
    }

    state = {
        email: '',
        name: '', 
        race: '', 
        comment: '',
        errors: {},
        formSubmitted: false,
    }
    

    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        window.document.body.removeChild(this.contentEl);
    }

    open = () => {
        if( this.props.disabled ) { return; }
        ReactTooltip.hide();
        this.props.openModal();
    }
    close = () => {
        this.props.closeModal();
        this.setState({formSubmitted: false})
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="KeepInTouchModalCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }

    updateField = e => this.setState({ [e.target.name]: e.target.value });

    validate = async () => {
        let nextErrors = {...this.state.errors};
        // nextErrors.name = this.state.name ? null : 'Please enter Name';
        nextErrors.email = this.state.email ? null : 'Please enter Email';
        // nextErrors.race = this.state.race ? null : 'Please enter Race name';

        this.setState({errors: nextErrors});

        const formInvalid = Object.keys(nextErrors).filter( key => !!nextErrors[key] ).length > 0;

    
        if(formInvalid){
            throw new Error('Form not valid');
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        

        this.validate()
            .then(()=>{
                // form valid
                // console.log('form validation: VALID');
                this.sendMessage();
            })
            .catch(err =>{
                // form not valid
                // console.log('form validation: INVALID', err)
            });
    }

    sendMessage = () => {

        // mongo call insert
        const keepInTouch = {
            RequestDate : new Date(),
            Email : this.state.email,
            // Name : this.state.name,
            // Race : this.state.race,
            // Comment : this.state.comment
        };

        
        mongoapi.run(mongodb=>{
            mongodb.collection("form-keep-in-touch")
                .insertOne(keepInTouch)
                .then(
                    // this.handleRacesResponse
                    this.thankYou()
                );
        })

    }

    thankYou = () => this.setState({formSubmitted: true})


    renderForm = () => {
        // const { email, name, phone, comment, errors } = this.state;
        const { email, errors } = this.state;

        return (
            <form onSubmit={this.handleSubmit}>

                <div className="title">Like what you see?</div>

                <div className="Label">
                    <i></i>
                    <span>Let's Stay in Touch</span>
                    <i></i>
                </div>
                
                <p className="light">MyRace provides race performance data through a series of beautiful visualizations, charts, tables, animations and stats.</p>
                <p className="light">Sign up to receive news and notifications including any updates to your race analytics!</p>

            
                <label className={classNames({ error: errors['email'] })}>
                    <input type="email" name="email" value={email} onChange={this.updateField} placeholder="Type in your email..." style={{backgroundImage: `url(${iconEmail})`}} />
                </label>
                

                <div className="buttons">
                    <button className="secondary" onClick={this.close}>Cancel</button>
                    <button type="submit">Sign up Now!</button>
                </div>


                
            </form>
        )
    }

    renderThankYou = () => {
        return (
            <div className="ThankYou">
                <div className="title">Thank you!</div>
                <p>We'll keep you updated on all things MyRace!</p>
                <img src={icoRunner} className="ThankYouFinishIcon" />
            </div>
        )
    }


    
    renderModalContent = () => {
        const { formSubmitted } = this.state;
        const { settings } = this.props;

        return (
            ReactDOM.createPortal(
                <RemoveScroll>

                    <div className={classNames("KeepInTouchModalForm", {
                        KeepInTouchModalFormThankYou: formSubmitted
                    })} style={{
                        // backgroundImage: `url(${imgRunner}), url(${cityImgMobile})`,
                    }}>
                        {/* <div className="IconSwappable ClearIcon" onClick={this.close}>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div> */}

                        <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                            <div className="background"/>
                            <TooltipText><em>Close</em></TooltipText>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div>

                        <div className="HeroImage">
                            <img src={cityImgMobile} className="CityBackground" />
                            <img src={imgRunner} className="Runner" />
                        </div>

        
                        {/* <div className="closeKeepInTouchModal" onClick={this.close}>⨯</div> */}
        
                        <div className="ModalContent">

                            { !formSubmitted? this.renderForm() : this.renderThankYou() }
            
                            {/* <div className="Logo">
                                <img src={logo} alt="MyRace logo" />
                            </div> */}
                        </div>
        
                        
        
                        {/* <img src={ imgRunner } className="runnergif"  /> */}
                    </div>
                </RemoveScroll>
            , this.coverEl)
        )
    }

    render(){
        const { settings: {isKeepInTouchModalOpen = false} } = this.props;

        return (
            <>
                {/* <div className="FeatureButton invert" onClick={this.open}>Sign up Now!</div> */}
                <div className="rightbar-container-block search-icon-container">

                    <div className="keepInTouchIcon tooltip">                        
                        {/* <img id="button_stay_in_touch" src={keepInTouchIcon} alt="KeepInTouch" /> */}
                        <a id="button_keep_in_touch" onClick={this.open}><img src={keepInTouchIcon} alt="KeepInTouch" /></a>
                        <TooltipText>Let's Keep in Touch</TooltipText>
                    </div>

                </div>
                { isKeepInTouchModalOpen && this.renderModalContent() }
                { isKeepInTouchModalOpen && this.renderModal() }
            </>
        )
    }
}

const mapState = ({settings}) => ({settings});
const mapDispatch = dispatch => ({
    openModal: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),
    closeModal: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: false}),
})


export default connect(mapState, mapDispatch)(KeepInTouchModalForm);
