import React, { Component } from "react";
import ReactDOM from 'react-dom'
import Modal from 'react-modal';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import ReactTooltip from 'react-tooltip'
import {roundDistance} from '../../timeUtils'
import "./PageInfoModal.scss";
import classNames from 'classnames'

import pageInfoIconHov from "../../images/help-01.svg";
import pageInfoIcon from "../../images/help-03.svg";
import pageModalIcon from "../../images/help.svg";
import pageInfoIconEnabled from "../../images/help-02.svg";
import Beacon from '../Beacon/Beacon'
import TooltipText from '../../components/Tooltip/TooltipText'
import { RemoveScroll } from "react-remove-scroll";

import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

import Label from '../../ui/Label/Label'


Modal.setAppElement('#root');


const EventSearch = () => (
    <div>
        <p><strong>MyRace</strong> has partnered with race organizations to provide athletes with the best race results experience... and more!</p>
        <p>Use this page to select an event.  You will then be directed to the list of supported races in that event.</p>
        <p></p>
        <p><strong>1.</strong> You can click "Partner with Us" from the menu if you are an event organizer interested in partnering with MyRace.</p>
        <p><strong>2.</strong>You can click "Request a Race" from the menu if you are an athlete and do not see your event listed.  We can reach out on your behalf to the event organizer to explore a partnership opportunity.</p>
        
        <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
      
    </div>
)
const AthleteSelect = () => (
    <div>
        <p><strong>MyRace</strong> has partnered with race organizations to provide athletes with the best race results experience... and more!</p>
        <p><strong>1.</strong> This is the <strong>Athlete Results</strong> table. Navigate around using pagination, filtering and sortung to find and view a specific athlete's race results.</p>
        <p><strong>2.</strong> You can even favorite one or more athletes to come back to them later. Note that you must be a user to favorite an athlete.</p>
        <p>
        </p>

        <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
      
      
    </div>
)

const AccountProfile = () => (
    <div>
        <p>This is your Account Profile page where you can update your avatar and your personal information.</p>
        <p>You can also navigate to other account information (i.e. your Favorites).</p>

        <div className="infoBlock">
            <ul>
                {/* <li><a href="http://about.myrace.com/article/16-segment-analysis" target="_blank" alt="learn more" title="Segment Analysis page" rel="noopener noreferrer">Click here to learn more about this page.</a></li> */}
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
      
    </div>
)
const AccountFavorites = () => (
    <div>
        <p>This is your Favorites page where you can see all of the athletes you have favorited and navigate directly to their race results.</p>

        <div className="infoBlock">
            <ul>
                {/* <li><a href="http://about.myrace.com/article/16-segment-analysis" target="_blank" alt="learn more" title="Segment Analysis page" rel="noopener noreferrer">Click here to learn more about this page.</a></li> */}
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
      
    </div>
)
const AccountAdmin = () => (
    <div>
        <p>This is your Account Administration.</p>
        <p>Functionality is not yet ready :)</p>
      
    </div>
)

const Welcome = ({ race, athlete}) => (
    <div>
        <p>This is the <strong>Welcome</strong> page, it is just the first page in the set of athlete race results.</p>
        <p>This page sets the stage by surfacing some basic information such as the athlete's name, bib number, hometown and race division.:</p>  
        
        <div className="forBeacon">
            <Beacon id="WelcomePage" text='' direction="right" style={{ top:'202px', left:'7px'}} />
            <p className="forBeacon">
            
                <strong>1.</strong> Beacons are sprinkled around the various pages to introduce you to key features. Hover over them to get more info... beacons will disappear once the key feature is activated.
            </p>
        </div>
        <hr />
        <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/14-welcome-page" target="_blank" alt="learn more" title="Welcome page" rel="noopener noreferrer">Click here to learn more about this page.</a></li>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>

        
    </div>
)

const AthleteHome = ({ race, athlete}) => (
    <div>
        <p>The <strong>Athlete Home</strong>page lists all of the athlete's races.</p>
        <p>MyRace tries to determine the athlete's races based on the email address used at the time of registration.</p>
        <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
        
    </div>
)
// const Welcome = ({ race, athlete}) => (
//     <div>
//         <p><strong>MyRace</strong> has partnered with the {race.raceName} team to provide a set of race reports.</p>
//         <p>Use this page to select an interval set option (i.e. every mile, every 5 km, etc.) to understand how the pace changed along the course.</p>
//         <p>Click <a href="http://about.myrace.com/article/14-welcome-page" target="_blank" alt="learn more" title="Welcome page" rel="noopener noreferrer">here</a> to learn more.</p>
        
//     </div>
// )


const ResultsSummary = () => (
    <div>
        <p>The <strong>Results Summary</strong> page show's the athlete's overall performance information in the race as well as the per segment summary.  It has two main sections:</p>
        <p><strong>1.</strong> The middle section includes such details as </p>
        <ul>
            <li>Finish Time (Chip or Net Time)</li>
            <li>Average Pace</li>
            <li>Overall, Gender, Division Rank and Percent</li>
        </ul>
        <p><strong>2.</strong> At the bottom, you'll see a chart that provides per segment distance and pace information both in table and elevation chart formats.</p>
        <hr/>
        <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/15-results-summary" target="_blank" alt="learn more" title="Results Summary page" rel="noopener noreferrer">Click here to learn more about this page.</a></li>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
    </div>
)


const IntervalAnalysis = ({ startDistance, endDistance, unitsToShow}) => (
    <div>
        <p><strong>Current Segment:</strong> {startDistance} – {endDistance} {unitsToShow}</p>
        <p>The <strong>Segment Analysis</strong> page show's the athlete's performance information in the race on a per segment basis.  It has two main sections:</p>
        <p><strong>1.</strong> The middle section includes such details as:</p>
        <ul>
            <li>Time in Segment</li>
            <li>Average Pace in selected Segment</li>
            <li>Projected Finish Time based on Pace of selected Segment</li>
            <li>Overall, Gender, Division Rank and Percent per specified Segment</li>
        </ul>
        <p><strong>2.</strong> At the bottom, you'll see a chart with the race's elevation profile and a toggle switch that allows you to choose to view data for a specific distance segment or the cumulative distance.</p>
        <p>The data in the middle section will adjust based on the interval and the segment vs. cumulative toggled selection.</p>
        <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/16-segment-analysis" target="_blank" alt="learn more" title="Segment Analysis page" rel="noopener noreferrer">Click here to learn more about this page.</a></li>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>

    </div>
)




const CourseMap = () => (
    <div>
         <p>The Course Map shows similar information to the Segment Analysis page but renders it on a map with various layers added on top:</p>
         <ul>
            <li>The race course's route</li>
            <li>Aid Stations</li>
            <li>Mile Markers</li>
            <li>Interval Checkpoint Markers</li>
        </ul> 
        <p>The right side panel can be shown to display the athlete's interval info or hidden to show more of the map.</p>
        <p>The left side panel is a legend that can also be shown or hidden.  It also contains the options on how the map can be rendered (i.e. pitch setting).</p>
        <p>Click <strong><a href="http://about.myrace.com/article/17-course-map" target="_blank" alt="learn more" title="Course Map" rel="noopener noreferrer">here</a></strong> to learn more about this page.</p>
        <p>Click <strong><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">here</a></strong> to learn more about how MyRace gets and compiles the data used in these pages.</p>
        
    </div>
)


const PassingStatisticsDetails = () => (
    <div>
         <p>Report details coming soon.</p>    
    </div>
)

const Finish = () => (
    <div>
         <p>The <strong>Finish</strong> page serves as an unofficial race finisher certificate.</p>    
         <p><strong>1.</strong> The Official Finisher Certificate may be available through the event's timing results official partner.</p>  
         <p><strong>2.</strong> This page also has Social Sharing buttons to allow you to share on Facebook, Twitter and Email.</p>  

         <div className="infoBlock">
            <ul>
                <li><a href="http://about.myrace.com/article/26-finisher-certificate" target="_blank" alt="learn more" title="Finish Certificate" rel="noopener noreferrer">Click here to learn more about this page.</a></li>
                <li><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">Click here to learn more about how MyRace gets and compiles the data used in these pages.</a></li>
            </ul>
            <div>
                <img src={pageModalIcon} />
            </div>
        </div>
         
    </div>
)

const OverallRaceResults = () => (
    <div>
         <p>Report details coming soon.</p>    
    </div>
)



class PageInfoModal extends Component {

    constructor(props){
        super(props);
        this.contentEl = window.document.createElement('div');
        window.document.body.appendChild(this.contentEl);
    }

    componentWillUnmount(){
        window.document.body.removeChild(this.contentEl);
    }
    
    openModal = () => {
        ReactTooltip.hide();
        this.props.openModal();

        // const page = this.props.match.path.split('/')[1];

        // window.analytics.track('Page Info', {
        //     "Page": page
        // });

    }
    closeModal = () => {
        this.props.closeModal();
    }
    
    getTitle = ()  => {
        let page = this.props.match.path.split('/')[1];

        if (page==="account"){
            page = this.props.match.path.split('/')[2];
        }

        switch(page ){
            case '':
                return 'Event Search';
            case 'select-athlete':
                return 'Athlete Search';
            case 'athlete':
                return 'Athlete Home Page';
            case 'welcome':
                return 'Welcome Page';
            case 'results-summary':
                return 'Results Summary';
            case 'segment-analysis':
                return 'Segment Analysis';
            case 'passing-statistics-overall':
                return 'Passing Statistics - Overall';
            case 'course-map':
                return 'Course Map';            
            case 'finish':
                return 'Finish';
            case 'profile':
                return 'Account Profile';
            case 'favorites':
                return 'Your Favorites';
            case 'admin':
                return 'Account Admin';
            case 'passing-statistics-details':
                return 'Passing Statistics - Details';
            case 'overall-race-results':
                return 'Overall Race Results';
            default:
                return 'Not Found'
        }
    }
    getContent = (race, athlete)  => {
        let page = this.props.match.path.split('/')[1];
        let startDistance = 0;
        let endDistance = 0;
        let unitsToShow = ""
        if (page==="account"){
            page = this.props.match.path.split('/')[2];
        }
        // console.log("page",page)

        const { athletesByRacebib, settings, intervalAnalysis } = this.props;
        const { distanceDisplayOption, currentEndIntervalIndex } = intervalAnalysis;

        const slug = this.props.match.params.raceCode + '--' + this.props.match.params.athleteBib;
        const athleteMainData = athletesByRacebib[slug] && athletesByRacebib[slug].main_data;

        const AGon = settings.ageGrade === "age_grade_on";
        const intervalDisplayOption=!distanceDisplayOption ? true : false;
        const intervalData = (athleteMainData && athleteMainData['event-intervals-ag-data']) ? (AGon ? athleteMainData['event-intervals-ag-data'] : athleteMainData['event-intervals-data']) : null;

        unitsToShow = settings.units === 'metric' ? 'km' : 'mi';

        if (intervalData && intervalData[currentEndIntervalIndex]) {
            if (settings.units === 'metric') {
                //Calculate Current Interval Data
                if (intervalDisplayOption) {
                    if (currentEndIntervalIndex === 0) {
                        startDistance = 0;
                    } else {
    
                        startDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceKm,2); 
                    }
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,2); 
                }  else {  
                    startDistance = 0;
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,2);  
                }
            
            } else {
    
                // Calculate Current Interval Data
                if (intervalDisplayOption) {
                    if (currentEndIntervalIndex === 0) {
                        startDistance = 0;
                    } else {
    
                        startDistance = roundDistance(intervalData[currentEndIntervalIndex-1].CumulativeDistanceMi,2); 
                    }
                    
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,2); 
                }  else {  
                    startDistance = 0;
    
                    endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,2);  
                }
                
                
            }
    
            
        } 

        // console.log("PAGE",page);
        // console.log("raceName is", race.raceName);
        switch(page ){
            case '':
                return <EventSearch />
            case 'athlete':
                return <AthleteHome />
            case 'select-athlete':
                return <AthleteSelect />
            case 'welcome':
                return <Welcome race={race} athlete={athlete} />
            case 'results-summary':
                return <ResultsSummary />
            case 'segment-analysis':
                return <IntervalAnalysis startDistance={startDistance} endDistance={endDistance} unitsToShow={unitsToShow} />
            case 'course-map':
                return <CourseMap />
            case 'passing-statistics-details':
                return <PassingStatisticsDetails />
            case 'finish':
                return <Finish />
            case 'overall-race-results':
                return <OverallRaceResults />;
            case 'profile':
                return <AccountProfile />;
            case 'favorites':
                return <AccountFavorites />;
            case 'admin':
                return <AccountAdmin />;
            default:
                return <div/>
        }
    }


    renderModalContent = () => {
        const { athlete, race, user, settings } = this.props;
        return (
            <div
                className="PageInfoModalPopup"
                // isOpen={isPageInfoModalOpen}
                // onAfterOpen={this.afterOpenModal}
                // onRequestClose={this.closeModal}
                // contentLabel="Info Modal"
                >

                    <header>                    
                        <div className="IconSwappable ClearIcon tooltip" onClick={this.closeModal}>
                            <div className="background"/>
                            <TooltipText><em>Close</em></TooltipText>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div>
                    </header>

                    <div className="modal_content">


                        <Label>{ this.getTitle() }</Label>
                        
                        
                        
                        {/* <p>This page provides a comprehensive set of times and statistics at a per interval level!</p> */}
                        { this.getContent(race, athlete, user) }
                    </div>

            </div>
        )
    }
    
    renderModal = () => {
        return (
            ReactDOM.createPortal(
                this.renderModalContent()
            , this.contentEl)
        )
    }
    
    render() {
        // const { title } = this.state;
        const { athlete, race, user, settings } = this.props;
        const { isPageInfoModalOpen } = settings;

        // const eventsAuthorized = user.eventsAuthorized === undefined ? [] : user.eventsAuthorized;
        // const eventsAuthorized = [];

        // eventsAuthorized.push('mvm-2016');
        // eventsAuthorized.push('mvm-2018');
        // eventsAuthorized.push('buckeye-2018');
        
        // const isAuthorizedForRace = eventsAuthorized.includes(race.raceCode);

        // console.log("race",race.raceName);
        return (
        <div className="PageInfoModal">
            <ReactTooltip multiline className="MyRaceTooltip"  />
            {/* <div
                className="info_button_modal" onClick={this.openModal}>?
            </div> */}
            {!isPageInfoModalOpen ? 
                <div className="IconSwappable info_button_modal tooltip" onClick={this.openModal}>
                    <div className="icon" style={{ backgroundImage: `url(${pageInfoIcon})`}}/>
                    <div className="hover" style={{ backgroundImage: `url(${pageInfoIconHov})`}}/>
                    
                    <TooltipText><em>Information<br/>about this page</em></TooltipText>
                </div> :
                <div className="IconSwappable info_button_modal" onClick={this.closeModal}>
                    <div className="icon" style={{ backgroundImage: `url(${pageInfoIconEnabled})`}}/>
                    <div className="hover" style={{ backgroundImage: `url(${pageInfoIconEnabled})`}}/>

                </div>
        
            }
            {/* <div data-tip="Help" data-effect='float' data-place='bottom' data-class="MyRaceTooltipLight"
                className="info_button_modal" onClick={this.openModal}>?
            </div> */}
            
            

            { isPageInfoModalOpen && this.renderModal() }

        </div>
        );
    }
}

const mapStateToProps = state => ({
    athlete: state.athleteSelected,
    race: state.raceSelected,
    user: state.user,
    settings: state.settings,
    intervalAnalysis: state.intervalAnalysis,
    athletesByRacebib: state.athletesByRacebib,
})

const mapDispatch = dispatch => ({
    openModal: ()=>dispatch({type:'SET_PAGE_INFO_MODAL_OPEN', open: true}),
    closeModal: ()=>dispatch({type:'SET_PAGE_INFO_MODAL_OPEN', open: false}),
})

export default withRouter(connect(mapStateToProps, mapDispatch)(PageInfoModal));


