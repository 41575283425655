import React, { Component } from "react";
import ReactDOM from 'react-dom'
import './SignUpModal.scss'
// import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as Sentry from '@sentry/browser';
// import UserAvatar from '../../components/WelcomeBib/UserAvatar'

import * as fbuserActions from '../../actions/fbuser'
import { getAvatarAndRaces } from '../../actions/users'
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import genericIcon from '../../images/user-05.svg'

import classNames from 'classnames'
import iconEmail from "../../images/mail-01.svg"
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

import iconEmailBlue from "../../images/mail-01.svg"
import iconEmailRed from "../../images/mail-02.svg"
import iconEmailBlack from "../../images/mail-03.svg"
import iconCalendarBlue from "../../images/date-01.svg"
import iconCalendarRed from "../../images/date-02.svg"
import iconCalendarBlack from "../../images/date-03.svg"
import iconUserBlue from "../../images/user-04.svg"
import iconUserRed from "../../images/user-05.svg"
import iconUserBlack from "../../images/user-06.svg"

import imgSignup from "../../images/signup.svg"
// import imgRunner from "../../images/runner.svg"
// import imgStopWatch from "../../images/stopwatch-02.svg"

import axios from 'axios';
import { withRouter } from 'react-router-dom'

import imgLock from '../../images/lock.svg';
import imgUnlock from '../../images/unlock.svg';
import imgLandscape from '../../images/generic-mobile.svg';
import throttle from 'lodash.throttle'
import {RemoveScroll} from 'react-remove-scroll';
import moment from "moment";
// import firebase from 'firebase/app'
// import {db, auth} from '../../config/firebase'
import firebase, {db, auth} from '../../config/firebase'
// import mongoapi from '../../apis/mongoapi'
import TooltipText from '../../components/Tooltip/TooltipText'
import steeltoe from 'steeltoe'

import imgPreviewReveal from '../../images/preview-2.svg';
import imgPreviewNoReveal from '../../images/preview-1.svg';

import DatePicker from "react-datepicker";
import {CopyToClipboard} from 'react-copy-to-clipboard'
 
import "react-datepicker/dist/react-datepicker.css";

// const textToCopy = `Hello MyRace team,
// I was not able to get past the email validation to claim this athlete’s races. https://myrace.com/athletehome/sdhsd7e433j

// I have tried entering all other email addresses I may have used when registering for races but none seem to be letting me through. 

// Please help. 
// Thanks!`;


class PasswordField extends Component {
    state = {
        reveal: false
    }
    toggle = () => this.setState( s => ({ reveal: !s.reveal }))

    render(){
        const { reveal } = this.state;
        const { title, name, onChange, value, error, disabled }  = this.props;
        return (
            <div className={classNames("control", "PasswordField", {invalid: !!error})}>
                <label> 
                    <span className="label">{ title }</span>
                    { reveal ?
                    <input name={ name } type="text" 
                        onChange={onChange} 
                        disabled={disabled}
                        value={value} 
                        />
                    :
                    <input name={ name } type="password" 
                        onChange={onChange} 
                        disabled={disabled}
                        value={value} 
                        />
                    }



                </label>
                
                <div className="RevealButton" onClick={this.toggle}>
                {reveal ?
                    <img src={imgPreviewNoReveal} />
                    :
                    <img src={imgPreviewReveal} />
                }
                </div>
            </div>
        )
    }
}

class SignUpModal extends Component {
    constructor(props){
        super(props);

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        // this.contentEl = window.document.createElement('div');
        // window.document.body.appendChild(this.contentEl);

        // this.requestValidation = throttle(this.requestValidation, 3000);
    }

    state = {
        email: '',
        errors: {},
        formSubmitted: false,
        isValidEmailHash: false,
        modalActivated: false, // used for animation, adds "activated" class after mount
        lockShakes: false,
        step: 1, // step 2 is the expanded step
        userAnsweredHasEmailAccess: null,
        firstname: '',
        lastname: '',
        birthday: '',
        birthdayToShow: '',
        password: '',
        username: '',
        gender: 'M',
        city: '',
        stateCode: '',
        countryCode: '',
        processing: false,
        existingProfileFound: false,
        uncertainState: false,
        EmailHash: '',
        invalidEmailAttempt: false
    }



    

    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        // window.document.body.removeChild(this.contentEl);
        window.clearTimeout(this._timeoutIdOpen);
        window.clearTimeout(this._timeoutIdClipboard);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.EmailHash) {
            const FirstName = steeltoe(this.props).get(`users.${this.state.EmailHash}.athleteRaceResults.0.FirstName`)
            const LastName = steeltoe(this.props).get(`users.${this.state.EmailHash}.athleteRaceResults.0.LastName`)
            const City = steeltoe(this.props).get(`users.${this.state.EmailHash}.athleteRaceResults.0.MyRace.City`)
            const Gender = steeltoe(this.props).get(`users.${this.state.EmailHash}.athleteRaceResults.0.MyRace.Gender`)
            const CountryCode = steeltoe(this.props).get(`users.${this.state.EmailHash}.athleteRaceResults.0.MyRace.CountryTwoCharCode`)
            const StateCode = steeltoe(this.props).get(`users.${this.state.EmailHash}.athleteRaceResults.0.MyRace.StateCode`)
            if(!prevState.firstname && FirstName){
                this.setState({ 
                    firstname: FirstName, 
                    city: City, 
                    stateCode: StateCode, 
                    countryCode: CountryCode,
                    gender: Gender
                });
            }
            if(!prevState.lastname && LastName){
                this.setState({ 
                    lastname: LastName,
                    city: City, 
                    stateCode: StateCode, 
                    countryCode: CountryCode,
                    gender: Gender
                });


                this.setState(
                    { lastname: LastName}
                
                    // username: tmpUsername
                    , async () =>{
                        let username;

                        // console.log("about to get username",this.state.firstname,this.state.lastname);
                        const getAvailableUsername = firebase.functions().httpsCallable('getAvailableUsername');
                        const usernameResult = await getAvailableUsername( {firstname: this.state.firstname, lastname: this.state.lastname })
                        username = usernameResult.data.username;
                        // console.log("username",username);

                        // try {
                        //     // console.log('try getAvailableUsername')
                        //     username = await this.getAvailableUsername(FirstName.toLowerCase().charAt(0), LastName.toLowerCase())
                        // } catch(err){
                        //     Sentry.captureMessage('getAvailableUsername failed!', err);
                        // }
                        if(username){
                            this.setState({username})
                        }
                    })
            }

        }
    }

    // getAvailableUsername = (firstname, lastname) => {
    //     return new Promise((resolve, reject)=>{
    //         let countAttempts = 0;
            
    //         let username = (firstname+lastname).replace(/[^0-9a-z]/gi, ''); 
    //         username = username.replace(/\s/g, ''); //remove spaces
            
    //         // console.log('getAvailableUsername');

    //         const checkIfUsernameAvailable = async () => {
    //             // console.log('checkIfUsernameAvailable', username)
    //             return new Promise((resol, rejec)=>{

    //                 countAttempts++;
    //                 db.collection('email-hash-with-username').where('username', '==', username).limit(1).get()
    //                     .then(snap => {
    //                         // console.log('SNAPSHOT', snap.size, snap)
    //                         if(snap.size){
    //                             // username unavailable
    //                             resol(false);
    //                         } else {
    //                             // username is free!
    //                             resol(true);
    //                         }
    //                     })
    //                     .catch(err => {
    //                         console.log('FIrestore error:', err)
    //                         Sentry.captureMessage('getAvailableUsername failed firestore call!', err);
    //                         resol(true);
    //                     })
    //             })
    //         }

    //         const keepCheckingUntilAvailable = async () => {
    //             // console.log('keepCheckingUntilAvailable')
    //             if(countAttempts > 5){
    //                 reject('Error getting available username')
    //             }
    //             const isAvailable = await checkIfUsernameAvailable();
    //             if(isAvailable){
    //                 // console.log('Username is available:', username)
    //                 resolve(username)
    //             } else {
    //                 // console.log('Username is NOT available:', username)
    //                 username = firstname+lastname+(`${Date.now()}`.slice(-3));
    //                 keepCheckingUntilAvailable();
    //             }
    //         }
    //         keepCheckingUntilAvailable();

    //     })
    // }

    open = () => {
        if( this.props.disabled ) { return; }
        window.scrollTo(0, 0);
        // ReactTooltip.hide();
        this.props.openModal();

        this._timeoutIdOpen = window.setTimeout(()=>{
            // console.log("this.props.countryCode",this.props.countryCode)

            //    console.log("AGE",this.props.athleteAge);             
            let latestYear = new Date().getFullYear();
            const momentBirthdayGuess = moment(`${latestYear - this.props.athleteAge}-01-01`);
            // const bestGuessFormatted = momentBirthdayGuess.format('YYYY-MM-DD'); 
            // const bestGuessFormatted = momentBirthdayGuess.format('YYYY-MM-DD'); 
            // const tmpUsername = this.props.athleteFirstName.toLowerCase().charAt(0) + this.props.athleteLastName.toLowerCase();
            const formattedBirthday = momentBirthdayGuess.format('MM/DD/YYYY');
            
            this.setState({
                modalActivated: true,
                firstname: this.props.athleteFirstName,
                lastname: this.props.athleteLastName,
                city: this.props.city,
                stateCode: this.props.stateCode,
                countryCode: this.props.countryCode,
                gender: this.props.athleteGender,
                // birthday: bestGuessFormatted,
                // birthday: new Date(momentBirthdayGuess.format()),
                birthdayToShow: formattedBirthday,
                // username: tmpUsername
            }, async () =>{
                let username;
                try {
                    // console.log('try getAvailableUsername')
                    // username = await this.getAvailableUsername(this.props.athleteFirstName.toLowerCase().charAt(0), this.props.athleteLastName.toLowerCase())

                    const getAvailableUsername = firebase.functions().httpsCallable('getAvailableUsername');
                    const usernameResult = await getAvailableUsername(this.state.firstname, this.state.lastname )
                    username = usernameResult.data.username;

                    console.log("username2",username);

                } catch(err){
                    Sentry.captureMessage('getAvailableUsername failed!', err);
                }
                if(username){
                    this.setState({username})
                }
            })
        },70)
    }
    close = () => {
        const emptyState = {
            email: '',
            errors: {},
            formSubmitted: false,
            isValidEmailHash: false,
            modalActivated: false, // used for animation, adds "activated" class after mount
            lockShakes: false,
            step: 1, // step 2 is the expanded step
            userAnsweredHasEmailAccess: null,
        }
        this.setState(emptyState);
        
        this.props.closeModal();
        

    }


    createAccount = () => {
        const { email, password, errors,
            firstname, lastname, birthday,gender, username, city, stateCode, countryCode
        } = this.state;
        const {fbuserActions} = this.props;
        const {athleteHash} = this.props.match.params;

        

        // console.log('Createing user...', {firstname, lastname, birthday})

        const validationFailed = this.validateFields();

        if(validationFailed && Object.keys(errors).length) {
            return;
        }
        // setPasswordValid(!!password);
        // setEmailValid(!!email);
        // if(!password || !email){return}

        auth.createUserWithEmailAndPassword(email, password).then(
            (userCredential)=>
            {
                const { user } = userCredential;
                // console.log('USER CREATED:', user);
                // console.log('SAVE user DOC', user.uid, JSON.stringify(this.state))
                
                console.log('USusername:', username);
                
                const signedUpDate = new Date();
                

                fbuserActions.saveProfileFields({
                    fbuser: user, 
                    data: { username, firstname,gender, lastname, birthday, uid:user.uid, city, stateCode, countryCode, signedUpDate  }
                });


                
                this.props.setUsersProperty({ EmailHash: athleteHash, username });


                this.props.history.push(`/${username}`)

                // this.generateUsername();

                this.close();

        }).catch((error) => {
            console.warn('Error creating user:', error);
            this.setFormErrorMessage({ form: error.message })
        });

    }

    generateUsername = () => {

    }
    
    

    validateFields = () => {
        // const { email, password, username, firstname, lastname } = this.state;
        // if(!email || !password || !username || !firstname || !lastname) {
        //     return true;
        // }
        const fields= ['email', 'password', 'username', 'firstname', 'lastname'];
        let validationFailed = false;
        let errors = {}
        for(let i = 0; i<fields.length; i++){
            const field = fields[i];
            const error = this.getValidationErrors(field, this.state[field])
            errors[field] = error;
            if(error) {
                validationFailed = true;
            }
        }
        this.setFormErrorMessage(errors);
        return validationFailed;
    }
    updateField = field => e => {
        // console.log("field",field)
        // console.log("birthday",e)

        // const newDate = moment(e);

        // new Date(moment(e).format())
        if (field==='birthdayToShow') {
            this.setState({ 
                birthday: new Date(moment(e.target.value).format()),
                // birthday: new Date(moment(e).format()),
                // birthdayToShow: moment(e).format('MM/DD/YYYY')
                birthdayToShow: e.target.value
            })
            return;
        }

        // console.log("new bday",newDate.format('YYYY-MM-DD') )

        let errors = {
            [field]: this.getValidationErrors(field, e.target.value),
            form: null
        };

        this.setFormErrorMessage(errors);
        
        this.setState({ [field]: e.target && typeof e.target.value!=='undefined' ? e.target.value : e })
    }

    getValidationErrors = (field, value) => {
        if(field==='username' || field==='firstname' || field==='lastname'){
            return !value ? `${field} is required` : null;
        }
        if(field==='password' && value.length < 3){
            return 'Password is invalid';
        }

        return null;
    }

    setFormErrorMessage = (err) => { // err:  { field: errorMessage }
        const errors = this.state.errors || {};
        let nextErrors = {...errors, ...err};
        let errs = {};
        Object.keys(nextErrors).forEach(key=>{
            if(nextErrors[key]){
                errs[key] = nextErrors[key]
            }
        });
        this.setState({errors: errs});
    }
    
    
    updateEmailField = e => {

        this.setState({ email: e.target.value });
        // this.requestValidation();
    }


    shakeLock = () => {
        // console.log("SETTING SHAKE TO TRUE");
        this.setState({
            lockShakes: true
        }, () => {
            window.setTimeout(()=>{
                this.setState({lockShakes: false})
            },400)
        })

        this.setState({invalidEmailAttempt: true})
    }



    checkEmailValid = (e) => {
        e.preventDefault();
        // console.log("e",e)

        const emailToCheck = this.state.email;
                
        // console.log("CHECKING IF EMAIL VALID")
        if( !(/.+@.+\..+/g).test(emailToCheck) ) {
            console.log("INVALID FORMAT")
            this.shakeLock();
            return;
        }
        // console.log("ABOUT TO REQUEST VAL AND SHAKE")
        this.findAnyAthleteWithEmail();
        // this.shakeLock();
    }
    
    findAnyAthleteWithEmail = () => {
        const { email } = this.state;
        //const { users } = this.props;
        // const {athleteHash} = this.props.match.params;
        // const athleteHash = '2b10my6m6ff9l4ehmxmnecfcfod5mzxcu8tbaqawtdjpyuk1ezkufaw'
        // this.setState({isValidEmailHash:true})
        // return;

        this.setState({ uncertainState: true });
        

        this.props.incrementPendingRequests();
    
        axios.get('https://us-central1-my-race-com.cloudfunctions.net/findAnyAthleteWithEmailHashFromEmail', {
        // axios.get('https://us-central1-my-race-com.cloudfunctions.net/addEmailHashSubstitution', {
        // axios.get('https://myrace.com/validateEmailAgainstHash', {
            params: {
                email
            }
        }).then( async (response) => {
            this.props.decrementPendingRequests();
            this.setState({ uncertainState: false });
            console.log("RESPONSE",response.data);
            const AtLeastOneAthlete = response.data;

            const isValid = !!AtLeastOneAthlete;
            const {EmailHash} = response.data

            // const isValid = response.data.valid;
            this.setState({
                isValidEmailHash:isValid,
                EmailHash
            })
            
            !isValid && this.shakeLock();


            if(isValid) {
                // console.log('[isValid]');
                this.setState({ uncertainState: true });
                await this.props.usersActions.getAvatarAndRaces({EmailHash});

                this.setState({ uncertainState: false });

                // console.log('EmailHash', EmailHash);



                // console.log('Users:', this.props.users);

                // users.{EmailHash}.status.profile === 'empty'
                // users.{EmailHash}.status.profile === 'received'
                const profileStatus = steeltoe(this.props).get(`users.${EmailHash}.status.profile`);
                // console.log('profileStatus:',profileStatus)
                if(profileStatus==='received') {
                    // ERROR: User already has profile
                    this.setState({ existingProfileFound: true })
                }
                if(profileStatus==='empty') {
                    // proceed
                }

            }
            
        }, (error) => {
            this.props.decrementPendingRequests();
            console.log(error);
          });

    }

    userAnsweredHasEmailAccess = e => {
        this.setState({
            // userAnsweredHasEmailAccess: e.target.value==='yes',
            userAnsweredHasEmailAccess: e.target.value === "yes" ? true : false,
            step: 2,
        })
    }


    onStep1Submitted = (e) => {
        e.preventDefault();
        console.log('Step 1 submit.', e.target)
    }

    goToLogin = e => {
        this.close();
        this.props.setLoginPopupOpen();
    }

    renderStep1 = () => {
        const { avatarIcon, athleteFirstName, athleteLastName } = this.props;
        const athleteName = athleteFirstName + ' ' + athleteLastName;

        const { errors, email, isValidEmailHash, lockShakes, existingProfileFound, uncertainState, invalidEmailAttempt } = this.state;
        // console.log("isValidEmailHash",isValidEmailHash)

        return (
            <form className="Step1" onSubmit={this.onStep1Submitted}>
                <div className="topLabel"><span>Step</span> <span className="dot">•</span> <strong>1</strong> of <strong>2</strong> </div>
                
                {/* <img src={avatarIcon} alt="user-avatar" className="userAvatar" /> */}
                <div className="SignUpImage">
                    {/* import imgRunner from "../../images/runner.svg"
                    import imgStopWatch from "../../images/stopwatch-02.svg" */}
                    <img src={imgSignup} className="runner" />
                    {/* <img src={imgRunner} className="runner" />
                    <img src={imgStopWatch} className="runner" /> */}
                </div>

                <h1>Sign Up</h1>

                <div className="Label"><i/><span>Athlete Sign-up Process</span><i/></div>


                {(()=>{
                    if(uncertainState) return (
                        <p>Please wait a few seconds while we look for you...</p>
                    )
                    if(existingProfileFound) return (
                        <p>Account already exists with this email.</p>
                    )
                    if(isValidEmailHash) return (
                        <p>Do you still have access to this email account?</p>
                    )
                    if (invalidEmailAttempt) return (
                        <>
                        <p>Enter an email address that you've used when registering for any previous race event.</p>
                        <p>No Matches Found.<br/>Try another email address you've used in the past.</p>
                        </>
                    )
                    return (
                        <p>Enter an email address that you've used when registering for any previous race event.</p>
                    )
                })()}
                

                { !existingProfileFound && (
                    <label className={classNames({ error: errors['email'] })}>
                        {/* <div className={classNames("label ", { error: errors['email'] })}> Email Address* </div> */}
                        <div className="control">
                            <input
                                disabled={uncertainState}
                                type="email"
                                className={classNames({emailValid:isValidEmailHash})}
                                name="email"
                                value={email}
                                onChange={this.updateEmailField}
                                placeholder="Type in your email..."
                                style={{backgroundImage:
                                `url(${iconEmailBlue})`}}
                            />
                            <div className={classNames("lockIconContainer", {lockShakes})}>
                                { isValidEmailHash ? 
                                <img src={imgUnlock} alt="Unlocked"/> :
                                <img src={imgLock} alt="Locked" />
                                }
                            </div>
                        </div>
                    </label>
                )}

                
                {(()=>{
                    if(uncertainState){
                        return null;
                    }
                    if(existingProfileFound) {
                        return (
                            <div>
                                <div className="buttons">
                                    <button onClick={this.goToLogin}>Log In Now</button>
                                </div>
                            </div>
                        )
                    }

                    if(isValidEmailHash) {
                        return (
                            <div className="buttons">
                                <div className="button yesno secondary" value="no" onClick={this.userAnsweredHasEmailAccess}>No</div>
                                <button className="yesno" type="submit" value="yes" onClick={this.userAnsweredHasEmailAccess}>Yes</button>
                            </div>
                        )
                    }
                    return (
                        <div className="buttons">
                            <div className="button secondary" value="cancel" onClick={this.close}>Cancel</div>
                            <button value="continue" type="submit" onClick={this.checkEmailValid}>Continue</button>
                        </div>
                    )
                })()}
                
            </form>
        )
    }

    onClipboardCopy = () => {
        this.setState({ clipboardCopied: true });
        this._timeoutIdClipboard = window.setTimeout(()=>{
            this.setState({ clipboardCopied: false });
        }, 5000)
    }


    renderStep2 = () => {
        // const { avatarIcon, athleteName } = this.props;
        const { avatarIcon, athleteFlag, athleteHometown, users } = this.props;

        const { errors, email, isValidEmailHash,userAnsweredHasEmailAccess, gender, firstname, lastname, username, birthday,birthdayToShow, password, processing, clipboardCopied, 
            EmailHash // <- from response
        } = this.state;
        // const {athleteHash} = this.props.match.params;


        // AVATAR
        let avatar = null;
        // const uploadedImage = steeltoe(this.props).get(`users.${athleteHash}.avatarImageUrl`);

        const athleteRaceResults = steeltoe(this.props).get(`users.${EmailHash}.athleteRaceResults`);

        
        switch (gender) {
            case 'M': avatar = maleIcon; break;
            case 'F': avatar = femaleIcon; break;
            default: avatar = genericIcon
        }

        let oldestRaceDate = null;
        let oldestRaceDateVal = '';
        let athleteAge = null;

        athleteRaceResults && athleteRaceResults.forEach(athlete => {
            if (athlete.RaceDetails) {
              // console.log("athlete",athlete) 
              // console.log("date to check",moment(athlete.RaceDetails.RaceDateStart).format("YYYY"))
              if (oldestRaceDate === null) {
                // console.log("race",race);
                oldestRaceDate = moment(athlete.RaceDetails.RaceDateStart);
                // oldestRaceDate = moment.unix(athlete.RaceDate.seconds);
              } else {
                const curRaceDate = moment(athlete.RaceDetails.RaceDateStart);
                // const curRaceDate = moment.unix(athlete.RaceDate.seconds);
      
                if (curRaceDate < oldestRaceDate) {
                  oldestRaceDate = curRaceDate;
                }
              }
      
              if (!athleteAge || athlete.Age > athleteAge) {
                athleteAge = athlete.Age;

              }
            }
            
          })

          
          if (oldestRaceDate !== null) {
              oldestRaceDateVal = (
                  <>
              since{" "}
              <strong>{oldestRaceDate.format('YYYY')}</strong>
              </>
            );
        }

        // const athleteOldestPerformanceYear = oldestRaceDate && oldestRaceDate.format('YYYY');
        

        const athleteName = (firstname) + ' ' + (lastname);

        const textToCopy = `Hello MyRace team,
I was able to pass step one of the verification process using the registration email (${email}) but I no longer have access to that account.

This is in reference to the following athlete hash:
${EmailHash}
        
I can attest that I am the owner of this profile for ${EmailHash}.

Please help... thanks!`;
        
        
        // console.log("ATHLETE LAST NAME",athleteLastName);
        // console.log("ATHLETE LAST NAME STATE",lastname);
        
        return (
            <div className="Step2">
                <div className="UserCard" style={{ backgroundImage: `url(${imgLandscape})`}}>
                    <div className="Label"><i/></div>

                    <div className="UserInfoTable">
                        <div className="left-aligned">
                            { athleteRaceResults && <div> Performances <i className="dot">•</i> <strong>{athleteRaceResults.length}</strong> </div> }
                            <div>{oldestRaceDateVal}</div>
                            {/* <div> since <i className="dot">•</i> <strong>{ athleteOldestPerformanceYear }</strong> </div> */}
                        </div>
                        <div className="right-aligned">
                            <div> Age <i className="dot">•</i> <strong>{ athleteAge }</strong> </div>
                        </div>
                    </div>


                    <span className="Flag">{ athleteFlag }</span>

                    <p className="Location"> { athleteHometown }</p>

                    <h1>{athleteName}</h1>

                    {/* <img src={avatarIcon} alt="user-avatar" className="userAvatar" /> */}
                    <div className="SignUpImage">
                        {/* import imgRunner from "../../images/runner.svg"
                        import imgStopWatch from "../../images/stopwatch-02.svg" */}
                        {/* <img src={imgSignup} className="runner" /> */}

                        <div className={classNames("userAvatarContainer")}>
                            <img src={ avatar } alt="user-avatar" className="userAvatar" style={{cursor:"default"}} />
                        </div>


                        
                        {/* <img src={imgRunner} className="runner" />
                        <img src={imgStopWatch} className="runner" /> */}
                    </div>

                </div>
                <div className={classNames("ClaimProcess", {clipboardCopied: !!clipboardCopied})}>

                    <div className="topLabel step2"><span>Step</span> <span className="dot">•</span> <strong>2</strong> of <strong>2</strong> </div>

                    <div className="Label"><i/><span>Athlete Claim Process</span><i/></div>

                    {/* <p>Enter the email address that was likely used during the registration for this event</p> */}

                    { userAnsweredHasEmailAccess && 
                        <>
                            <label className={classNames({ error: errors['email'] })}>
                                <div className={classNames("label ", { error: errors['email'] })}> Email Address* </div>
                                <div className="control">
                                    <input type="email" className={classNames({isValidEmailHash})} disabled name="email" value={email} onChange={this.updateEmailField} placeholder="Type in your email..." style={{backgroundImage: `url(${iconEmailBlack})`}} />
                                    {/* <div className={classNames("lockIconContainer", {lockShakes})}>
                                        { isValidEmailHash ? 
                                        <img src={imgUnlock} alt="Unlocked"/> :
                                        <img src={imgLock} alt="Locked" />
                                        }
                                    </div> */}
                                </div>
                            </label>

                            {/* <div className={classNames("control", { invalid: !!errors.username })}>
                                <label> 
                                    <span>Choose your username</span>
                                    <input name="username" disabled type="text" 
                                        placeholder={ 'Your Username...'}
                                        value={username} 
                                        />
                                </label>
                            </div> */}

                            <PasswordField title="Password" name="password" 
                                onChange={this.updateField('password')} 
                                error={errors.password}
                                disabled={processing}
                                value={password} />


                            <div className={classNames("control", { invalid: !!this.state.errors.firstname })}>
                                <label> 
                                    <span className="label">First Name</span>
                                    <input name="firstname" type="text" 
                                        placeholder={ 'Your First Name...'}
                                        onChange={this.updateField('firstname')} 
                                        value={firstname} 
                                        style={{backgroundImage: `url(${iconUserBlue})`}}
                                        />
                                </label>
                            </div>
                            

                            <div className={classNames("control", { invalid: !!this.state.errors.lastname })}>
                                <label> 
                                    <span className="label">Last Name</span>
                                    <input name="lastname" type="text" 
                                        placeholder={ 'Your Last Name...'}
                                        onChange={this.updateField('lastname')} 
                                        value={lastname} 
                                        style={{backgroundImage: `url(${iconUserBlue})`}}
                                        />
                                </label>
                            </div>

                            {/* <div className="control">
                                <label> 
                                    <span className="label">Date of Birth (mm/dd/yyyy)</span>
                                    <input name="birthday" type="text" 
                                        placeholder={ 'Your Birthday...'}
                                        onChange={this.updateField('birthdayToShow')} 
                                        value={birthdayToShow} 
                                        style={{backgroundImage: `url(${iconCalendarBlue})`}}
                                        />
                                </label>
                            </div> */}


                            
                            { !!errors.form && <p className="error"> {errors.form} </p>}


                            <div className="buttons">
                                <div className="button secondary" onClick={this.close}>Close</div>
                                <button type="submit" onClick={this.createAccount} disabled={!!Object.keys(errors).length}>Submit</button>
                            </div>
                        </>
                    
                    }

                    { !userAnsweredHasEmailAccess && 

                        <>
                            { clipboardCopied ? 
                            <p className="strong">Paste into your email client and send it to <a href="mailto:registration@myrace.com">registration@myrace.com</a></p>
                            :
                            <p className="strong">Copy the message below and send it to <a href="mailto:registration@myrace.com">registration@myrace.com</a></p>
                            }


                        

                            <CopyToClipboard text={textToCopy} options={{format: 'text/plain'}} onCopy={this.onClipboardCopy}>
                                <div className="CopyText tooltip">
                                    {!clipboardCopied && <TooltipText>Click to <em>copy to <br/>clipboard</em></TooltipText>}

                                    <p>Hello MyRace team,</p>
                                    <p>I was able to pass step one of the verification process using the registration email (<strong>{email}</strong>) but I no longer have access to that account.</p>
                                    <p>This is in reference to the following athlete hash:</p>
                                    <p className="small">{EmailHash}</p>

                                    <p>I can attest that I am the owner of the profile for {athleteName}</p>
                                    <p>Please help... thanks! </p>



                                </div>
                            </CopyToClipboard>

                            <p>
                                <CopyToClipboard text={textToCopy} options={{format: 'text/plain'}} onCopy={this.onClipboardCopy}>
                                    <button className="ClipboardButton">{clipboardCopied ? 'Copied to clipboard' : 'Copy to clipboard'}</button>
                                </CopyToClipboard>
                            </p>

                            <div className="buttons" style={{marginTop: 40}}>
                                <button className={classNames({secondary: !clipboardCopied})} onClick={this.close}>Done</button>
                            </div>
                            
                        </>
                    }

                    

                </div>
            </div>
        )
    }
    
    



    renderModalContent = () => {
        const { formSubmitted, modalActivated, step } = this.state;
        const { settings } = this.props;



        return (
            ReactDOM.createPortal(
                <RemoveScroll>
                <div className={classNames("SignUpModalContent", `step_${step}`, {
                    // KeepInTouchModalFormThankYou: formSubmitted
                    activated: modalActivated
                })}>

                    <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                        <div className="background"/>
                        <TooltipText><em>Close</em></TooltipText>
                        <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                    </div>

                    {this[`renderStep${step}`]()}

    
                    {/* <div className="closeKeepInTouchModal" onClick={this.close}>⨯</div> */}
{/*     
                    <div className="IconSwappable ClearIcon" onClick={this.close}>
                        <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                    </div>
    
                    <div className="Logo">
                        <img src={logo} alt="MyRace logo" />
                    </div>
    
                    { !formSubmitted? this.renderForm() : this.renderThankYou() } */}
                    
    
                    {/* <img src={ imgRunner } className="runnergif"  /> */}
                </div>
                </RemoveScroll>
            , this.coverEl)
        )
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <div className="SignUpModalCover" onClick={this.close}></div>
            , this.coverEl)
        )
    }

    render(){
        const { children, settings: {isSignUpModalOpen = false} } = this.props;

        return (
            <>
                {/* <button className="ThisIsMe tooltip" onClick={this.open}>
                    {children}
                </button> */}
                { isSignUpModalOpen && this.renderModalContent() }
                { isSignUpModalOpen && this.renderModal() }
            </>
        )
    }
}

// const mapState = ({settings}) => ({settings});

const mapState = state => ({
    settings: state.settings,
    users: state.users,
  })


const mapDispatch = dispatch => ({
    incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
    decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
    openModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: true}),
    closeModal: ()=>dispatch({type:'SET_SETTING', isSignUpModalOpen: false}),
    setLoginPopupOpen: () => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: 'login' }),
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    usersActions: bindActionCreators({getAvatarAndRaces}, dispatch),
    setUsersProperty: ({ EmailHash, username, profile, avatarImageUrl }) => dispatch({ type: "SET_USERS_PROPERTY", EmailHash, username, profile, avatarImageUrl  })
})

export default withRouter(connect(mapState, mapDispatch)(SignUpModal));
