import {db,storage} from '../config/firebase'
import mongoapi from '../apis/mongoapi'
import * as Sentry from '@sentry/browser';
import steeltoe from 'steeltoe';


// export const setUsersProperty = ({ EmailHash, ...properties, /*username, profile, avatarImageUrl, races*/ }) => {
export const setUsersProperty = ({ EmailHash, ...properties }) => {
    console.log('setUsersProperty', EmailHash, properties)
    return (dispatch) =>{
        let setProps = { type: "SET_USERS_PROPERTY", EmailHash };
        Object.keys(properties).forEach(p => setProps[p] = properties[p]);
        dispatch(setProps)
        // dispatch({ type: "SET_USERS_PROPERTY", EmailHash, username, profile, avatarImageUrl, races  })
    }
}

// export const setUsersProfileProperty = ({ EmailHash, ...properties }) => {
//     // console.log('setUsersProperty', EmailHash, properties)
//     return (dispatch) =>{
//         //let setProps = { type: "SET_USERS_PROFILE_PROPERTY", EmailHash };
//         //Object.keys(properties).forEach(p => setProps[p] = properties[p]);
//         dispatch({ type: "SET_USERS_PROFILE_PROPERTY", EmailHash, properties });
//         // dispatch({ type: "SET_USERS_PROPERTY", EmailHash, username, profile, avatarImageUrl, races  })
//     }
// }

/** set request-response status */
export const setRequestStatus = ( EmailHash, requestType, status ) => {
    return (dispatch)=> {
        /*
        [EmailHash] : {
            status: {
            username: '', -- 'pending', 'received', 'error'
            }
        }
        */
        if(status==='pending'){
            dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        } else if(status==='received' || status==='error'){
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
        }
        // console.log("action SET_USERS_REQUEST_STATUS")
        dispatch({
            type: 'SET_USERS_REQUEST_STATUS',
            EmailHash, requestType, status
        })
    }
}



export const getUserAndAvatar = ({ EmailHash }) => {
    return async (dispatch, getState) => {
        // console.log('[getUserAndAvatar]', EmailHash)
        await dispatch(getUsername(EmailHash))
        // console.log('after getting username', JSON.stringify(getState().users) );
        await dispatch(getUser({EmailHash}))
        // console.log('after getting user', JSON.stringify(getState().users) );
        await dispatch(getAvtrImageFromApi({EmailHash}))
        // console.log('after getting avatar', JSON.stringify(getState().users) );
    }
}



export const getAvatarAndRaces = ({ EmailHash, username, raceCode, bib }) => {
    return async (dispatch, getState) => {


        
        if(!EmailHash && username ){
            // console.log('>>>> 1 >>>> username:', username);
            await dispatch(getEmailHash(username));
            
            const { users } = getState();
            EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );
            // console.log("EmailHash in users.js",EmailHash);
        }

        // if (raceCode && bib) {
        //     const raceCodeBib = raceCode + '|' + bib;
        //     await dispatch(getEmailHashFromRaceCodeBib(raceCodeBib));
        //     const { users } = getState();
        //     EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].raceCodeBib === raceCodeBib );
        //     console.log("EmailHash from racecode-bib",EmailHash)
        // }
        
        if(EmailHash && !username ){
            // console.log('>>>> 1 >>>> EmailHash:', EmailHash);
            await dispatch(getUsername(EmailHash))
        }
        
        if (!EmailHash) {
            console.log("NO EmailHash in users.js, EXITING!");
            return;
        }
        // console.log('>>>> 2 >>>> EmailHash:', EmailHash);    
        await dispatch(getUser({EmailHash}));
        // console.log('>>>> 3');
        await dispatch(getAvtrImageFromApi({EmailHash}));
        // console.log('>>>> 4');
        await dispatch(getRaceResults(EmailHash));

        await dispatch(getRacesDetails(EmailHash));
        
        await dispatch(getAthleteRankingAndPassing(EmailHash));



    }
}




// window.getEmailHashCounter = 0;

export const getEmailHash = (username) => {
    return async (dispatch, getState) => {
        const {users} = getState();

        const FoundEmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );
        if(FoundEmailHash) {
            // console.log('WE ALREADY HAVE USER EMAILHASH', username, EmailHash)
            return;
        }
        
        
        const EmailHash = `username___${username}`;
        const requestType = 'getEmailHash';

            
        if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
            // console.log('[getEmailHash cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
            return;
        }



        try {



            // dispatch(setRequestStatus(EmailHash, requestType, 'pending'));
            // dispatch({type:'INCREMENT_PENDING_REQUESTS'});
            dispatch(setRequestStatus(EmailHash,requestType, 'pending'));
            // console.log('looking for emailhash')
            const snap = await db.collection('email-hash-with-username').where('username', '==', username).get();
            // console.log('done looking for emailhash')
            dispatch(setRequestStatus(EmailHash,requestType, 'received'));
            // dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            // dispatch(setRequestStatus(EmailHash, requestType, 'received'));
                // console.log('athlete username', snap, snap&&snap.data&&snap.data())
        
            
            if(snap && snap.docs && snap.docs.length){
                
                const EmailHash = snap.docs[0].id;
                // console.log('got emailhash from username', EmailHash)
                dispatch(setUsersProperty({ EmailHash, username }));
                
                
                // athleteHash = snap.docs[0].id
                
                // console.log('username hash', snap.docs[0].id, snap.docs[0].data())
    
                //   this.getProfileInfoByUsername(username);
                //   this.getRacesByAthleteHash(athleteHash);
    
            } else {
                // console.log("OOPS - didn't find athlete by username")
                
            }
            
        } catch (err) {
            dispatch(setRequestStatus(EmailHash,requestType, 'error'));
        }

    }
}

// export const getEmailHashFromRaceCodeBib = (raceCodeBib) => {
//     return async (dispatch, getState) => {
//         const {users} = getState();


//         const FoundEmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].raceCodeBib === raceCodeBib );
//         if(FoundEmailHash) {
//             // console.log('WE ALREADY HAVE USER EMAILHASH', username, EmailHash)
//             return;
//         }
        
        
//         const EmailHash = `raceCodeBib___${raceCodeBib}`;
//         const requestType = 'getEmailHashFromRaceCodeBib';

            
//         if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
//             // console.log('[getEmailHash cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
//             return;
//         }

//         try {



//             // dispatch(setRequestStatus(EmailHash, requestType, 'pending'));
//             // dispatch({type:'INCREMENT_PENDING_REQUESTS'});
//             dispatch(setRequestStatus(EmailHash,requestType, 'pending'));
//             // console.log('looking for emailhash')

//             mongoapi.run(mongodb => {
//                 const coll = mongodb.collection("athletes");
//                 let filter = {};
//                 filter._id = raceCodeBib;
                
//                 // MAKE THE CALL
//                 coll.findOne(filter)
//                     .then(athlete => {
//                             console.log("Athlete found",athlete);
                            
//                             dispatch(setUsersProperty({ EmailHash: athlete.EmailHash }));
//                         }
//                         )
//                         .catch(err => {
                            
//                             console.warn('MONGO DB error (when requesting athlete by raceCodeBib):', err);
//                             dispatch(setRequestStatus(EmailHash,requestType, 'error'));
//                         });
    
//             })

//             dispatch(setRequestStatus(EmailHash,requestType, 'received'));

            
//         } catch (err) {
//             dispatch(setRequestStatus(EmailHash,requestType, 'error'));
//         }

//     }
// }



export const getUsername = (EmailHash) => {
    return async (dispatch, getState) => {
        const requestType = 'username';
        try {
            // console.log('[getUsername]')
            // if(this._userRequested) { return; }
            // const { athlete } = this.props;
            // if(!athlete) { return; }
            // const { EmailHash } = athlete;
            if(!EmailHash) {
                // console.log('[getUsername cancelled] - no EmailHash')
                return; 
            }
            

            
            const {users} = getState();
            
            if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
                // console.log('[getUsername cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
                return;
            }

            
            // this._userRequested = true;
            // console.log('[getUsername] MAKING THE CALL')
    
            dispatch(setRequestStatus(EmailHash,requestType, 'pending'));
            
            const snap = await db.collection('email-hash-with-username').doc(EmailHash).get();
            
            dispatch(setRequestStatus(EmailHash,requestType, 'received'))
    
            if(snap.exists){
                const doc = snap.data();
                // console.log('username response:', doc.username);
    
                // this.getUser(EmailHash, doc.username);
    
                dispatch(setUsersProperty({EmailHash, username: doc.username }));
            }
    
        } catch (err){
            dispatch(setRequestStatus(EmailHash,requestType, 'error'))
            Sentry.captureMessage('[actions:users:getUsername]', err);
        }
    }
}


export const getUser = ({EmailHash, username}) => {
    return async (dispatch, getState) => {
        const {users} = getState();
        // console.log("getUser",EmailHash,username)
        if(EmailHash && !username && users[EmailHash] && users[EmailHash].username) {
            username = users[EmailHash].username;
            // console.log("getUser getting username",EmailHash,username)
        }

        if(username && !EmailHash) {
            // username = users[EmailHash].username;
            EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );
        }
        if(!EmailHash) {
            // console.log('[getUser cancelled] - no EmailHash')
            return;
        }

        const requestType = 'profile' 

        // console.log('[getUser]', EmailHash, username)
        try {

            if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
                // console.log('[getUser profile cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
                return;
            }
            
            if(!username) {
                // console.log('[getUser profile cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
                dispatch(setRequestStatus(EmailHash,requestType, 'empty'));
                return;
            }

            dispatch(setRequestStatus(EmailHash,requestType, 'pending'));
            // console.log("CHECKING FIRESTORE users username=", username)
            const snap = await db.collection('users').where('username', '==', username).get();
            dispatch(setRequestStatus(EmailHash,requestType, 'received'));
    
            if(snap && snap.docs && snap.docs.length){
                
                const claimedUser = snap.docs[0].data();
                const claimedUserUid = snap.docs[0].id;
                
               
                const profile = { 
                    firstname: claimedUser.firstname, 
                    lastname: claimedUser.lastname, 
                    birthday: claimedUser.birthday,
                    city: claimedUser.city,
                    stateCode: claimedUser.stateCode,
                    countryCode: claimedUser.countryCode,
                    avatarId: claimedUser.defaultAvatar,
                    uid: claimedUserUid,
                    accessRoles: claimedUser.accessRoles,
                    accessRaceCodes: claimedUser.accessRaceCodes
                }
    
                // console.log('[getUser] claimedUser',  claimedUser, profile);
    
                dispatch(setUsersProperty({EmailHash, profile}));
    
            } else {
           
                
            }
        } catch (err) {
            // console.log("ERROR:", err);
            dispatch(setRequestStatus(EmailHash,requestType, 'error'));
            Sentry.captureMessage('[actions:users:getUser]', err);
        }
    }

}


export const clearUserStatusProfileFlag = (username) => {

    return async (dispatch, getState) => {


        const requestType = 'profile'
        const {users} = getState();
    
        const EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );

        if (!EmailHash) {
            return;
        }

        dispatch(setRequestStatus(EmailHash,requestType, ''));
        return;
    }


}

export const getAvtrImageFromApi = ({EmailHash, username, uid}) => {
    return async (dispatch, getState) => {

        // console.log('{{{{{getAvtrImageFromApi}}}}}', EmailHash, username, uid)
        
        const requestType = 'avatarImageUrl'
        
        const {users} = getState();
        

        
        
        if(username && !EmailHash) {
            // username = users[EmailHash].username;
            EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );
        }
        
        // console.log('{{{{{getAvtrImageFromApi}}}}} 2', EmailHash, username, uid)
        if(!EmailHash) {
            // console.log('[avatarImageUrl cancelled] - no EmailHash')
            return;
        }
        
        if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
            // console.log('[avatarImageUrl cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
            return;
        }
        // console.log('{{{{{getAvtrImageFromApi}}}}} 5', EmailHash, username, uid)
        
        
        // console.log('{{{{{getAvtrImageFromApi}}}}} 2', EmailHash, username, uid)
        
        // console.log("GETTING AVATAR", EmailHash, steeltoe(users).get(`${EmailHash}.profile`) )
        
        if(EmailHash && !uid && steeltoe(users).get(`${EmailHash}.profile.uid`)){
            uid = steeltoe(users).get(`${EmailHash}.profile.uid`);
        }
        if(!uid) {
            // console.log('[avatarImageUrl cancelled] - no uid')
            return;
        }
        // console.log("actually getting avatar", EmailHash, uid)
        // console.log('{{{{{getAvtrImageFromApi}}}}} 7', EmailHash, username, uid)

        
        
        try {
    
            dispatch(setRequestStatus(EmailHash,requestType, 'pending'));
            const avatarImageUrl = await storage.ref().child(`avatars/${uid}`).getDownloadURL();
            // console.log('getting avatar - avatarImageUrl', avatarImageUrl)
            dispatch(setRequestStatus(EmailHash,requestType, 'received'));

            dispatch(setUsersProperty({EmailHash, avatarImageUrl}));
            
            //   .then( avatarImageUrl => {
            //       // this.setState({imageUrl})
            //     //   this.props.setAvatarImageUrl(imageUrl)
            //     dispatch(setUsersProperty({EmailHash, avatarImageUrl}));
            //   })
            //   .catch( err => {
            //     // this.props.setAvatarImageUrl(null)
            //     // console.log('no avatar image found', err)
            //   })



        } catch (err) {
            // console.warn(err)
            dispatch(setRequestStatus(EmailHash,requestType, 'error'));
            Sentry.captureMessage('[actions:users:getAvtrImageFromApi]', err);
        }
    }
}

  



export const getRaceResults = EmailHash => {
    return async (dispatch, getState) => {
        // console.log('[getAthleteRacesMongo]', athleteHash, athleteHash==='2b10my6m6ff9l4ehmxmnecfcfod5mzxcu8tbaqawtdjpyuk1ezkufaw');

        const { users } = getState();
        
        const requestType = 'athleteRaceResults';

        if(!EmailHash){
            return;
        }
        if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
            // console.log('[getRacesByAthleteHash cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
            return;
        }

        return new Promise((resolve, reject)=>{

            dispatch(setRequestStatus(EmailHash, requestType, 'pending'));
            
            mongoapi.run(mongodb=>{
                
                const coll = mongodb.collection("athletes");
                let filter = { EmailHash };
                // filter.EventCode = eventCode;
                // const mysort = {};
                // mysort.EventListOrderIndex = -1;
                // const options = {  };
        
                // MAKE THE CALL
                // console.log('MAKE THE CALL', EmailHash)
                coll.find(filter).toArray()
                .then((athletes)=>{
                    // console.log('RACES FROM MONGO', athletes, EmailHash)
                    // this.handleAthletesResponse()
                    dispatch(setRequestStatus(EmailHash, requestType, 'received'));

                    dispatch(setUsersProperty({EmailHash, athleteRaceResults: athletes}));

                    resolve();
                })
                .catch((err)=>{
                    dispatch(setRequestStatus(EmailHash, requestType, 'error'));
                    Sentry.captureMessage('[actions:users:getRacesByAthleteHash]', err);
                    reject(err);
                });
            })

        })
    }
}





export const getRacesDetails = EmailHash => {
    return async (dispatch, getState) => {
        // console.log('[getAthleteRacesMongo]', athleteHash, athleteHash==='2b10my6m6ff9l4ehmxmnecfcfod5mzxcu8tbaqawtdjpyuk1ezkufaw');

        const { users } = getState();
        
        const requestType = 'racesDetails';

        if(!EmailHash){
            return;
        }
        if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
            // console.log('[getRacesByAthleteHash cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
            return;
        }


        const athletes = steeltoe(users).get(`${EmailHash}.athleteRaceResults`);
        if(!athletes) { return; }



        return new Promise((resolve, reject)=>{

            
            // console.log('[handleAthletesResponse] DB RESPONSE:', athletes );
            
            // this.setState({
            //     resultsAthlete: athletes,
            //     // resultsQuery: requestQuery
            // });
            
            const raceCodes = athletes.map( item => item.RaceCode);
            // console.log('raceCodes:', raceCodes)
            
            dispatch(setRequestStatus(EmailHash, requestType, 'pending'));
            mongoapi.run(mongodb=>{
                const coll = mongodb.collection("races");
                let filter = {
                    _id : { $in : raceCodes }
                };
        
                let haveDifferentNames = false;
        
                coll.find(filter).toArray()
                .then(
                    (races)=>{
                        // console.log("races found",races)
                        dispatch(setRequestStatus(EmailHash, requestType, 'received'));
                        // this.handleRacesResponse(race)
                        
                        const athletesWithRaces = athletes.map((athlete,index)=>{
                            
                
                            const prevAthleteName = index > 0 ? athletes[index-1].FirstName + athletes[index-1].LastName : null;
                            const currAthleteName = index > 0 ? athletes[index].FirstName + athletes[index].LastName : null;
                
                            if (haveDifferentNames === false && prevAthleteName !== currAthleteName) {
                                haveDifferentNames = true;
                            }

                            //find the race 
                            // let matchingRace = {};
                            console.log("!!Looking for race code",athlete.RaceCode);
                            let foundIndex=-1;
                            races.forEach((race,i) => {
                                // let race = doc.data();
                                // raceList.push(doc.data());
                                // console.log("pushing ranking",ranking,ranking._id,athlete._id);
                                if (race._id === athlete.RaceCode) {
                                    // console.log("ranking._id matched athlete._id",ranking._id,athlete._id)
                                    // matchingRace.push(ranking);
                                    foundIndex = i;
                                    // console.log("!!FOUND IT",foundIndex);
                                }
                            });

    
                            // const rankPassDetails =steeltoe(athleteRankingAndPassing).get(`0.RankingAndPassing`);
                            const raceDetails =steeltoe(races).get(`${foundIndex}`);
                            console.log("qqq-111raceDetails for athlete.RaceCode",athlete.RaceCode, raceDetails)
    
                            // if (!rankPassDetails) {
                            //     Sentry.captureMessage('MISSING ATHLETE RANKING PASSING DATA');
                            // }

                            // YURIY TO HELP MAKE EFFICIENT
                            // races.forEach(race => {
                            //     if (race.RaceCode === athlete.RaceCode) {
                            //         // console.log("MATCHED!");
                            //         matchingRace=race;
                            //     }
                            // });

                            // const matchingRace = races.find(race => race.RaceCode === athlete.RaceCode);


                            // console.log("matchingRaces",matchingRaces)
                            console.log("qqq-222raceDetails1",raceDetails)
                            
                            return {
                                // ...athlete,
                                HaveDifferentNames: haveDifferentNames,
                                RaceDetails: raceDetails
                                // RaceDetails: races[index]
                            }
                        });
            
                        //now sort by Race Date

                        console.log("qqq-333athletesWithRaces",athletesWithRaces);
            
                        races.sort((a,b) => {// number sorter
                            // console.log('SORT NUMBERS',colId,  direction, a[colId], b[colId])
                            if(a.RaceDateStart < b.RaceDateStart) return -1;
                            if(a.RaceDateStart > b.RaceDateStart) return 1;
                            return 0;
                        })
            
                        // this.setState({resultsAthlete: athletesWithRaces});
                        // // console.log("Have Different Names",haveDifferentNames);
                        // this.setState({haveDifferentNames});

                        console.log("setting the athletesWithRaces",athletesWithRaces)
                        // console.log("setting the EmailHash",EmailHash)
                        dispatch(setUsersProperty({EmailHash, athleteRaceResults: athletesWithRaces}));
                        resolve();
                    }
                ).catch((err)=>{
                    dispatch(setRequestStatus(EmailHash, requestType, 'error'));
                    Sentry.captureMessage('[actions:users:getRacesDetails]', err);
                    reject(err);
                })
            })


        });
    }

}







export const getAthleteRankingAndPassing = EmailHash => {
    return async (dispatch, getState) => {
        // console.log('[getAthleteRacesMongo]', athleteHash, athleteHash==='2b10my6m6ff9l4ehmxmnecfcfod5mzxcu8tbaqawtdjpyuk1ezkufaw');

        const { users } = getState();
        
        const requestType = 'rankingAndPassing';

        if(!EmailHash){
            return;
        }
        if(steeltoe(users).get(`${EmailHash}.status.${requestType}`)){
            // console.log('[getRacesByAthleteHash cancelled] - status', steeltoe(users).get(`${EmailHash}.status.${requestType}`))
            return;
        }


        const athletesWithRaces = steeltoe(users).get(`${EmailHash}.athleteRaceResults`);
        if(!athletesWithRaces) { return; }



        return new Promise((resolve, reject)=>{            
            dispatch(setRequestStatus(EmailHash, requestType, 'pending'));
    
    
    

            // NOW WE NEED TO GET THE ATHLETES RANKING AND PASSING DETAILS
                            
            
            mongoapi.run(mongodb=>{
                const athleteCodes = athletesWithRaces.map( item => item._id);
                // console.log('athleteCodes:', athleteCodes)
                const coll = mongodb.collection("athletes-rankingAndPassing");
                let filter = {
                    _id : { $in : athleteCodes }
                };

                coll.find(filter).toArray()
                .then((athleteRankingAndPassing)=>{
                    dispatch(setRequestStatus(EmailHash, requestType, 'received'));

                    const athletesWithRacesAndRankingPassing = athletesWithRaces.map((athlete,index)=>{

                        // let matchingRankingAndPassing = [];
                        let foundIndex=-1;
                        athleteRankingAndPassing.forEach((ranking,i) => {
                            // let race = doc.data();
                            // raceList.push(doc.data());
                            // console.log("pushing ranking",ranking,ranking._id,athlete._id);
                            if (ranking._id === athlete._id) {
                                // console.log("ranking._id matched athlete._id",ranking._id,athlete._id)
                                // matchingRankingAndPassing.push(ranking);
                                foundIndex = i;
                            }
                        });

                        // const rankPassDetails =steeltoe(athleteRankingAndPassing).get(`0.RankingAndPassing`);
                        const rankPassDetails =steeltoe(athleteRankingAndPassing).get(`${foundIndex}.RunRankingAndPassing`);

                        if (!rankPassDetails) {
                            Sentry.captureMessage('MISSING ATHLETE RANKING PASSING DATA');
                        }

                        
                        // console.log("athleteRankingAndPassing[0].RankingAndPassing",athleteRankingAndPassing[0].RankingAndPassing)
                        return {
                            // ...athlete,

                            // RankingAndPassingDetails: athleteRankingAndPassing[0].RankingAndPassing
                            RankingAndPassingDetails: rankPassDetails
                            // RankingAndPassingDetails: athletesWithRacesAndRankingPassing[index].RankingAndPassing
                        }
                    });

                    // console.log("setting the athletesWithRacesAndRankingPassing",athletesWithRacesAndRankingPassing)
                    // this.setState({resultsAthlete: athletesWithRacesAndRankingPassing});
                    dispatch(setUsersProperty({EmailHash, athleteRaceResults: athletesWithRacesAndRankingPassing}));
                    
                
                    resolve()
                })
                .catch((err)=>{
                    dispatch(setRequestStatus(EmailHash, requestType, 'error'));
                    Sentry.captureMessage('[actions:users:getAthleteRankingAndPassing]', err);
                    reject(err);
                });
            });


        })
    }




        
        
}