import React, { Component } from "react";

import {Link, withRouter, Redirect} from 'react-router-dom'
import navNext from '../../images/next-big-03.svg';
import Beacon from '../../components/Beacon/Beacon'
import { setBeaconDone } from '../../actions/settings'

import moment from "moment";
import Header from "../../components/Header/Header";
import MainAthleteBlockHome from "./MainAthleteBlockHome";
import Footer from '../../components/Footer/Footer'
import {connect} from 'react-redux'
import {code2 as countriesByCode2} from 'simple-country-list'
import steeltoe from 'steeltoe';
import imgFavoriteEnabled from '../../images/star-big-04.svg';
import imgFavoriteHover from '../../images/star-big-05.svg';
import imgFavoriteDisabled from '../../images/star-big-06.svg';
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import genericIcon from '../../images/user-05.svg'
import classNames from 'classnames'

import imgMail from '../../images/mail-02.svg'
import imgStopWatch2 from '../../images/stopwatch-02.svg';
import imgStopWatch3 from '../../images/stopwatch-03.svg';
import TooltipText from '../../components/Tooltip/TooltipText'
import AthleteHomeRaces from './AthleteHomeRaces'
import "./AthleteHome.scss";
// import firebase from 'firebase/app';
import Headroom from 'react-headroom';
import ThisIsMeModal from './ThisIsMeModal'
import { bindActionCreators } from "redux";
import * as fbuserActions from '../../actions/fbuser'
import * as favoriteAthletesActions from '../../actions/favoriteAthletes'
import { toast } from 'react-toastify';
import {getAvatarImage} from '../AccountProfile/avatars'

import { getAvatarAndRaces } from '../../actions/users'

class AthleteHomeTimelinePage extends Component {


    state = {
      // races: [],
      // resultsAthlete: [],
      haveDifferentNames: false,
      claimedLastname: '',
      claimedCity: '',
      claimedStateCode: '',
      claimedCountryCode: '',
      // claimedProvinceCode: '',
      claimedBirthday: '',
      // claimedAvatarId: ''
      // athlete: null,
    }
        
    componentDidMount() {

      this.showEmailVerifiedMessage();

      let {athleteHash, username} = this.props.match.params;
      if (!athleteHash && username) {
        this.props.usersActions.getAvatarAndRaces({username});
      } else if (athleteHash && !username) {
        this.props.usersActions.getAvatarAndRaces({EmailHash: athleteHash});
      }

      this.getFavorites();
    }

  
    getFavorites = () => {
        this.props.favoriteAthletesActions.getFav();
    }

    
    componentDidUpdate(prevProps, prevState){
      const { fbuser } = this.props;
      const { from } = this.props.location.state || { from: { pathname: '/' }}
      
      if (prevProps.fbuser != fbuser) {
  
        if (!fbuser) {
          return;
        }
        // console.log("fbuser",fbuser);

        let {athleteHash, username} = this.props.match.params;
        if (!athleteHash && username) {
          this.props.usersActions.getAvatarAndRaces({username});
        } else if (athleteHash && !username) {
          this.props.usersActions.getAvatarAndRaces({EmailHash: athleteHash});
        }
  
        if(from.pathname === "/login/confirmemail") { /* we are coming from ConfirmEmail */ 
            toast.info('Your email has been verified!',{
                className: 'black-background',
                autoClose: 8000
            });
        } else if (fbuser && !fbuser.isAnonymous && !fbuser.emailVerified) {
            toast.info('NOTE: Your email address has not been validated. Check your inbox (and spam folder) for an email from MyRace and click the verify email button',{
                className: 'black-background',
                autoClose: 8000
            });
        }
      }
  }

  showEmailVerifiedMessage = () => {
    const {fbuserProfile} = this.props;
    const { fbuser } = this.props;

    const { from } = this.props.location.state || { from: { pathname: '/' }}
    
    if(!fbuserProfile) { return; }
    
    if(from.pathname === "/login/confirmemail") { /* we are coming from ConfirmEmail */ 
      toast.info('Your email has been verified!',{
        className: 'black-background',
        autoClose: 8000
      });
    } else if (fbuser && !fbuser.isAnonymous && !fbuser.emailVerified) {
      toast.info('NOTE: Your email address has not been validated. Check your inbox (and spam folder) for an email from MyRace and click the verify email button',{
        className: 'black-background',
        autoClose: 8000
      });
    }
  }

  beaconDone = beaconId => e => {
    // console.log("beacondId",beaconId);
    this.props.settingsActions.setBeaconDone(beaconId)
  }

  

  render() {
    const { match, fbuserProfile, fbuser, settings, users} = this.props;

    let {athleteHash, username} = this.props.match.params;
    // const EmailHash = athleteHash;
      

    // const { resultsAthlete, claimedAvatarId, haveDifferentNames, claimedFirstname, claimedLastname, claimedCity, claimedStateCode, claimedCountryCode, claimedBirthday } = this.state;
    const { haveDifferentNames, claimedLastname, claimedCity, claimedStateCode, claimedCountryCode, claimedBirthday } = this.state;

    let performancesURL = `/athlete/${athleteHash}/racelist`;

    if(athleteHash && steeltoe(users).get(`${athleteHash}.username`)) {
      // we are on the route that is based on ahtleteHash
      return <Redirect to={`/${users[athleteHash].username}`} />
    }
    
    if(!athleteHash && username ){
      athleteHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );
      performancesURL = `${username}/racelist`
    }

    let userNotFound = false;
    if (!athleteHash && steeltoe(users).get(`username___${username}.status.getEmailHash`)==='received') {
      userNotFound = true;
    }

    const athleteRaceResults = steeltoe(this.props).get(`users.${athleteHash}.athleteRaceResults`);

    athleteRaceResults && athleteRaceResults.sort((a,b) => {// number sorter
      // console.log('SORT NUMBERS',colId,  direction, a[colId], b[colId])
      if(a.RaceDetails && b.RaceDetails) {
          if(a.RaceDetails.RaceDateStart < b.RaceDetails.RaceDateStart) return -1;
          if(a.RaceDetails.RaceDateStart > b.RaceDetails.RaceDateStart) return 1;
          return 0;
      }
  })

    const athleteProfile = steeltoe(this.props).get(`users.${athleteHash}.profile`);
    
    console.log("athleteRaceResults1232",athleteRaceResults)
    const numRaces = athleteRaceResults && athleteRaceResults.length;

    const userViewsOwnProfile = fbuserProfile && fbuserProfile.username && match.params.username === fbuserProfile.username;
    const userViewsOwnProfileEmailVerified = userViewsOwnProfile && fbuser && fbuser.emailVerified === true;
    const userViewsOwnProfileEmailNotVerified = userViewsOwnProfile && fbuser && fbuser.emailVerified === false;

    const userViewsOtherClaimedProfile = !!match.params.username && !userViewsOwnProfile;

    const publicNotClaimedProfile = !userViewsOwnProfile && !userViewsOtherClaimedProfile;

    
    let hometownCountry = (athleteRaceResults && athleteRaceResults[0] && athleteRaceResults[0].HometownTwoCharCountryCode) ? athleteRaceResults[0].HometownTwoCharCountryCode : null;
    let hometownCountryVal = hometownCountry ? hometownCountry.replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) : null;
    // console.log("XXXathlete hometown here",hometownVal);
    
    let cityStateCountryCode = '';
    let city = '';
    let stateCode='';
    let countryCode = '';
    let provinceCode = (athleteRaceResults && athleteRaceResults[0] && athleteRaceResults[0].HometownProvince) ? athleteRaceResults[0].HometownProvince : '';



    if (athleteRaceResults && athleteRaceResults[0] && athleteRaceResults[0].HometownStateCode) {
        if (athleteRaceResults[0].HometownStateCode.length > 0) {
          cityStateCountryCode = athleteRaceResults[0].HometownCity ? athleteRaceResults[0].HometownCity + ', ' + athleteRaceResults[0].HometownStateCode : athleteRaceResults[0].HometownStateCode;
          city = athleteRaceResults[0].HometownCity ? athleteRaceResults[0].HometownCity : '';
          stateCode = athleteRaceResults[0].HometownStateCode ? athleteRaceResults[0].HometownStateCode : '';
          countryCode = athleteRaceResults[0].HometownTwoCharCountryCode ? athleteRaceResults[0].HometownTwoCharCountryCode : '';
        } else {
          if (athleteRaceResults[0].HometownCountryName && athleteRaceResults[0].HometownCountryName.length > 0) {
            cityStateCountryCode = athleteRaceResults[0].HometownCity + ', ' + athleteRaceResults[0].HometownCountryName;
            city = athleteRaceResults[0].HometownCity ? athleteRaceResults[0].HometownCity : '';
            countryCode = athleteRaceResults[0].HometownTwoCharCountryCode ? athleteRaceResults[0].HometownTwoCharCountryCode : '';
          } else {
            
            cityStateCountryCode = athleteRaceResults[0].HometownCity;
            city = athleteRaceResults[0].HometownCity ? athleteRaceResults[0].HometownCity : '';
          }
          
        }
        if (cityStateCountryCode.length === 0) {
          cityStateCountryCode = athleteRaceResults[0].HometownTwoCharCountryCode;
          countryCode = athleteRaceResults[0].HometownTwoCharCountryCode ? athleteRaceResults[0].HometownTwoCharCountryCode : '';
        }

    }

    
    let oldestRaceDate = null;
    let oldestRaceDateVal = '';
    let isMale = true;
    let athleteAge = null;

    let athleteFirstName = '';
    let athleteLastName = '';
    let athleteName='';

    let athleteGender = 'M';


    athleteRaceResults && athleteRaceResults.forEach(athlete => {
      if (athlete.RaceDetails) {
        // console.log("athlete",athlete) 
        isMale = athlete.Gender === "M" ? true : false;
        athleteGender = athlete.Gender;
        // console.log("date to check",moment(athlete.RaceDetails.RaceDateStart).format("YYYY"))
        if (oldestRaceDate === null) {
          // console.log("race",race);
          oldestRaceDate = moment(athlete.RaceDetails.RaceDateStart);
          // oldestRaceDate = moment.unix(athlete.RaceDate.seconds);
        } else {
          const curRaceDate = moment(athlete.RaceDetails.RaceDateStart);
          // const curRaceDate = moment.unix(athlete.RaceDate.seconds);

          if (curRaceDate < oldestRaceDate) {
            oldestRaceDate = curRaceDate;
          }
        }

        if (!athleteAge || athlete.Age > athleteAge) {
          athleteAge = athlete.Age;
          athleteFirstName = athlete.FirstName;
          athleteLastName = athlete.LastName;
        }
      }
      
    })

    if (athleteProfile && athleteProfile.firstname) {
      athleteFirstName = athleteProfile.firstname;
    }
    if (athleteProfile && athleteProfile.lastname) {
      athleteLastName = athleteProfile.lastname;
    }

    
    athleteName=athleteFirstName + ' ' + athleteLastName;
    
    if (claimedCity.length > 0 || claimedStateCode.length > 0 || claimedCountryCode.length > 0 || provinceCode.length > 0) {
      city = claimedCity;
      stateCode = claimedStateCode;
      countryCode = claimedCountryCode;
      

      hometownCountry = countryCode;
      hometownCountryVal = countryCode.replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));

      // console.log("city,stateCode, countrCode",city,stateCode,countryCode)
      if (claimedStateCode && claimedStateCode.length > 0) {
        cityStateCountryCode = claimedCity.length > 0 ? claimedCity + ', ' + claimedStateCode : claimedStateCode;

      } else {

        if (claimedCountryCode.length > 0) {
          cityStateCountryCode = claimedCity.length > 0 ? claimedCity + ', ' + claimedCountryCode : claimedCountryCode;
        } else {
          cityStateCountryCode = claimedCity.length > 0 ? claimedCity : '';
        }
        
      }
      
      if (provinceCode && provinceCode.length >0) {
        if (claimedCountryCode.length > 0) {
          cityStateCountryCode = provinceCode.length > 0 ? provinceCode + ', ' + claimedCountryCode : claimedCountryCode;
        } else {
          cityStateCountryCode = provinceCode.length > 0 ? provinceCode : '';
        }
        
      }

    }

    if (athleteProfile && athleteProfile.birthday) {
      athleteAge = Math.abs(moment(athleteProfile.birthday).diff(moment(), 'years'));
    }


    // console.log("oldestRaceDate",oldestRaceDate);
    if (oldestRaceDate !== null) {
      // oldestRaceDateVal = oldestRaceDate.format('LL');
      oldestRaceDateVal = (
        <>
        since{" "}
        <strong>{oldestRaceDate.format('YYYY')}</strong>
        </>
      );
    }

    const page = this.props.match.path.split('/')[1];


    // AVATAR
    let avatar = null;
    const uploadedImage = steeltoe(this.props).get(`users.${athleteHash}.avatarImageUrl`);
    
    avatar = getAvatarImage({
      avatarName: steeltoe(this.props).get(`users.${athleteHash}.profile.avatarId`),
      uploadedImage
    })

    if(!avatar){
      const Gender = steeltoe(athleteRaceResults).get('0.Gender');
      switch(Gender){
          case 'M': avatar = maleIcon; break;
          case 'F': avatar = femaleIcon; break;
          default: avatar = genericIcon
      }
    }


    let avatarNameToShow = steeltoe(this.props).get(`users.${athleteHash}.profile.avatarId`);
    const isDefaultImageSelected = avatarNameToShow==='imgM7'||avatarNameToShow==='imgF7';

    

    return (
      <div className="AthleteHome">
        <Headroom>
          <Header title={`ATHLETE HOME`} page={page} isMale={isMale} />
        </Headroom>

        { (!userNotFound && numRaces) ? 
        <>
        <MainAthleteBlockHome>


            <div className="WelcomeBib">

              <div className="WelcomeBibHeader">
                <div className="WelcomeBibLocation">
                  <span className="WelcomeBibFlag">{hometownCountryVal}</span>
                  <span className="WelcomeBibHometown tooltip" >
                     <TooltipText><em>Athlete's Hometown</em></TooltipText>
                    {cityStateCountryCode}
                  </span>
                </div>
                <div className="tooltip">
                {!fbuserProfile && <TooltipText><em>Add to Favorites</em>Requires Login</TooltipText>}
                {fbuserProfile && <TooltipText>Favorites Coming <em>January '20</em></TooltipText>}
                  <span className="favoriteSpan">
                    <div className="background"/>

                    <img src={imgFavoriteDisabled} />
                  </span>
                </div>
              </div>

              <div className="Label">
                <i/>
              </div>

              <div className="EdgesLine extrapad">
                <div className="left-aligned">
                  <div> Performances <i className="dot">•</i> <strong>{numRaces}</strong> </div>
                  <div>{oldestRaceDateVal}</div>
                </div>
                <div className="right-aligned">
                  <div>Age <i className="dot">•</i> <strong>{athleteAge}</strong></div>
                </div>
              </div>


              {/* <img src={avatar} alt="user-avatar" className="userAvatar2" /> */}


              { avatar && (
                    <div className={classNames("userAvatarContainer", {
                        uploadedImage: uploadedImage&&isDefaultImageSelected
                    })}>
                        <img src={ avatar } alt="user-avatar" className="userAvatar" />
                    </div>
                )}
  
              <h1>{athleteName}</h1>

              <div>

                {
                  userViewsOwnProfileEmailVerified && (
                    <div>
                      <p> My Performances </p>

                      <img src={imgStopWatch2} width={40}/>
                      {/* imgStopWatch2 */}

                    </div>
                  )
                
                }
                {
                  userViewsOwnProfileEmailNotVerified && (
                    <div>
                      <p className="smaller"> Your email address has not been verified yet.<br/>Please check your inbox and verify.</p>

                      <img className="smaller" src={imgMail} width={40}/>
                      {/* imgStopWatch2 */}

                    </div>
                  )
                
                }
                {
                  userViewsOtherClaimedProfile && (
                    <div>
                      <p> Performances Home </p>

                      <img src={imgStopWatch3} width={40}/>
                    </div>
                  )
                }


                {


                  
                  publicNotClaimedProfile && (
                    <>
                    <ThisIsMeModal
                      avatarIcon={avatar}
                      athleteFirstName={ athleteFirstName }
                      athleteLastName={ athleteLastName }
                      city = { city }
                      stateCode = { stateCode }
                      countryCode = { countryCode }
                      provinceCode = { provinceCode }
                      athleteAge={ athleteAge }
                      athletePerformances = {numRaces}
                      athleteOldestPerformanceYear = { oldestRaceDate && oldestRaceDate.format('YYYY') }
                      athleteFlag = { hometownCountryVal }
                      athleteGender = {athleteGender}
                      athleteHometown = { cityStateCountryCode }

                    >
                      This is me!
                      <TooltipText><em>Make it yours!</em> Requires login</TooltipText>
                    </ThisIsMeModal>

                    <div className="MakeItYours">
                      Click to make it yours
                    </div>
                    </>
                  )
                }
              </div>

              

            </div>  

            

            <Link 
            // data-tip="Go to Results Summary" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDark" 
            // to={`/results-summary/${RaceCode}/athletes/${Bib}`} 
            to={performancesURL}
            className="nextPage tooltip" 
            onClick={this.beaconDone("athleteTimelineArrow")}
            >
              <Beacon id="athleteTimelineArrow" text='' direction="up" style={{ top:'55px', left:'21px'}} />
      
            
              <span className="background" />
              <img src ={navNext } />
              <TooltipText>Go to <em>Performances List</em></TooltipText>
          </Link>

        </MainAthleteBlockHome>
        <div className="container flexgrow">
          <AthleteHomeRaces athletes={athleteRaceResults} haveDifferentNames={haveDifferentNames} />
        </div>

        </>
      :
      (
        <div className="container flexgrow">
          {/* <h1>User not found</h1>
          <h3>(or no races for this user)</h3> */}
        </div>
      )
      }                

        
        <Footer />

      </div>
    );
  }
}



const mapStateToProps = state => ({
  // race: state.raceSelected,
  // user: state.user,
  fbuser: state.fbuser,
  fbuserProfile: state.fbuserProfile,
  settings: state.settings,
  users: state.users,
})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
  // setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
  fbuserActions: bindActionCreators(fbuserActions, dispatch),
  usersActions: bindActionCreators({getAvatarAndRaces}, dispatch),
  settingsActions: bindActionCreators({setBeaconDone}, dispatch), 
  favoriteAthletesActions: bindActionCreators(favoriteAthletesActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AthleteHomeTimelinePage));
