import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom';
// import moment from "moment";
// import ToggleUnits from "../ToggleUnits/ToggleUnits";
// import ToggleAgeGrade from "../ToggleAgeGrade/ToggleAgeGrade";
// import logoimg from "../../images/logo-05.svg";
import {connect} from 'react-redux'
import "./ReportHeader.scss";
// import PageInfoModal from "../PageInfoModal/PageInfoModal";
// import SearchModal from '../SearchModal/SearchModal';
import ReactTooltip from 'react-tooltip'
// import LoginPopup from "../../components/Login/LoginPopup";
import TooltipText from '../Tooltip/TooltipText'

class ReportHeader extends Component {




  renderTopImage = (img) => {
    const {race} = this.props;
    const image = <img 
      // className="topSponsorImage" alt={`${img.altText}`} src={`/images/${race.EventSeriesCode}/partners/${img.src}` } />
      className="topSponsorImage" alt={`${img.altText}`} src={`${img.src}` } />
    if(img.linkURL) {
      return (
        // <a href={img.linkURL} target="_blank" alt={img.altText} title={img.altText} rel="noopener noreferrer" className="topSponsorLink"><span />{image}</a>
        <a href={`/select-athlete/${race.EventCode}/${race.RaceCode}/All-Genders`} alt={img.altText} title={img.altText} rel="noopener noreferrer" className="topSponsorLink"><span />{image}</a>
      )
    }
    
    return image;
  }
  
  render() {



    const {
      // toggleUnits,
      // toggleAgeGrade,
      athleteMainData,
      race,
      showTitle,
      // hideSearch,
      requestsPending,
      // showHelp,
      showLogo,
      raceLogo,
    } = this.props;

    
 
    // const eventsAuthorized = user.eventsAuthorized === undefined ? [] : user.eventsAuthorized;
    // const eventsAuthorized = [];

    // eventsAuthorized.push('mvm-2018');
    // eventsAuthorized.push('mvm-2016');
    // eventsAuthorized.push('buckeye-2018');
    // eventsAuthorized.push('tsfm-2018');
    // eventsAuthorized.push('tsfm-2017');
    // eventsAuthorized.push('tsfm-2016');
    // eventsAuthorized.push('tsfm-2015');
    
    // const isAuthorizedForRace = user.isAdmin || eventsAuthorized.includes(race.EventCode);
    // const isAuthorizedForRace = race.EventCode === undefined || eventsAuthorized.includes(race.EventCode);
    // console.log("race.EventCode",race.EventCode);
    // console.log("isAuthorizedForRace",isAuthorizedForRace);

    if (requestsPending) {
      return null;
    }


    // let hideUnits = false;

    let header;


    if (!athleteMainData || athleteMainData.Bib === null || race.RaceName === undefined) {
      header = <div className="athlete-info"> No Athlete Selected</div>
      // hideUnits = true;
    } else {
      const countryFlag = athleteMainData.MyRace.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));
      

      let cityState = '';
      if (athleteMainData && athleteMainData.MyRace) {
          cityState = athleteMainData.MyRace.City ? athleteMainData.MyRace.City + ', ' + athleteMainData.MyRace.StateName : athleteMainData.MyRace.StateName;
          if (cityState.length === 0) {
              cityState = athleteMainData.MyRace.CountryName;
          }
      }
      header = (
        <div className="athlete-info">
          <ReactTooltip  /> 
          <span data-tip={cityState} data-effect='solid' data-place='left' data-class="MyRaceTooltipLight"
                className="flag">{countryFlag}</span>


          <span data-tip="Athlete's Page" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltip"
            className="athleteName">
            <Link to={`/athlete/${athleteMainData.NameGenderSlug}/${athleteMainData.MyRace.HometownSlug}`}>{athleteMainData.MyRace.FirstName + ' ' + athleteMainData.MyRace.LastName}</Link> 
          </span>

          <span className="bib">{`${'#' + athleteMainData.Bib}`}</span>
          <i>•</i>
          <span className="divisionType">{race.RaceDivisionType === 1 ?  athleteMainData.MyRace.DivisionType1.replace(" ", "") : athleteMainData.MyRace.DivisionType2.replace(" ", "")} </span>
        </div>
        )

    }

    // console.log("raceLogo header",raceLogo)


    return (
      <div className="header">
        <div className="logo-container">

          <div className="logo-img-container">
            {showLogo === false ? 
            null
            
            :
            // <Link to="/">
            //   <img src={logoimg} alt="MyRace" />
              
            // </Link>

            raceLogo && this.renderTopImage(raceLogo)
            }
          </div>
        </div>
        {
          showTitle ? 
          <>
            <div className="athlete-info-container">
              
                {header}

            </div>
            {/* <div className="rightbar-container">
              <div className="rightbar-container-block search-icon-container">
                {hideSearch ? null : <SearchModal />}
              </div>
              <div className="rightbar-container-block">
                <div className="rightbar-container-row rightbar-container-row-1">
                  {hideUnits ? null : <ToggleUnits toggleUnits={toggleUnits} />} 
                  <PageInfoModal />
                </div>
                <div className="rightbar-container-row rightbar-container-row-2">
                  <ToggleAgeGrade toggleAgeGrade={toggleAgeGrade} />
                </div>
              </div>

              <div className="rightbar-container-block profile-icon-container">
                <LoginPopup />
              </div>

              
            </div> */}
          </>
        : 
        <div>

          {/* <SearchModal /> */}
          {/* <div className="rightbar-container">
            <div className="rightbar-container-block search-icon-container">
              {hideSearch ? null : <SearchModal />}
            </div>
            <div className="rightbar-container-block">
              <div className="rightbar-container-row rightbar-container-row-1">
                {hideUnits ? null : <ToggleUnits toggleUnits={toggleUnits} />} 
                <PageInfoModal />
                
              </div>

            </div>
            <div className="rightbar-container-block profile-icon-container">
              <LoginPopup />
            </div>
          </div> */}
          
        </div>
      }
      </div>
    );
  }
}


const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  race: state.raceSelected,
  settings: state.settings,
  requestsPending: state.requestsPending,
  user: state.user,
})



export default withRouter(connect(mapStateToProps)(ReportHeader));

