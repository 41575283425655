import React, {Component} from 'react'
import './MapSidebar.scss'
import classNames from 'classnames';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import SearchModal from '../../components/SearchModal/SearchModal';
import ToggleUnits from '../../components/ToggleUnits/ToggleUnits';
import PageInfoModal from '../../components/PageInfoModal/PageInfoModal';
import ToggleAgeGrade from '../../components/ToggleAgeGrade/ToggleAgeGrade';
import ToggleInterval from "../../components/ToggleButton/ToggleInterval";
import IntervalAnalysisBib from '../../components/IntervalAnalysisBib/IntervalAnalysisBib';
import D3ElevationMap from '../../components/D3ElevationMap/D3ElevationMap'
import * as sponsorsActions from '../../actions/sponsors'
import {Link} from 'react-router-dom';
import moment from "moment";

import nextEnabled from '../../images/next-2.svg';
import nextDisabled from '../../images/next-1.svg';
import prevEnabled from '../../images/back-2.svg';
import prevDisabled from '../../images/back-1.svg';
import {roundDistance} from '../../timeUtils'



class SponsorsCarousel extends Component {
    state = {
        currIndex: 0
    }
    static getDerivedStateFromProps(props, state) {

        const { images } = props;
        if(!images) {
            return null;
        }
        let orderedImages = images.filter( img => typeof img.mapIntervalOrderIndex === 'number' )
        orderedImages.sort( (a,b)=> a.mapIntervalOrderIndex-b.mapIntervalOrderIndex );
        // console.log('orderedImages', orderedImages)

        return {
            images: orderedImages
        }

    }
    componentDidMount(){
        this.startAutoloop();
    }
    
    componentWillUnmount(){
        this.stopAutoloop();
    }

    handleEnter = () => this.stopAutoloop();
    handleLeave = () => this.startAutoloop();
    
    
    startAutoloop = () => {
        if(this.interval) return null;
        this.interval = window.setInterval(this.next, 5000);
    }
    
    stopAutoloop = () => {
        if(!this.interval) return null;
        window.clearInterval(this.interval);
        this.interval=null;
    }

    next = () => {
        const { currIndex, images } = this.state;
        if(!images || !images.length) { return }
        // console.log('NEXT', currIndex, images.length)
        this.setState({
            currIndex: (currIndex+1) % images.length
        })
    }
    renderImage = img => {
        const { eventSeriesCode } = this.props;
        const { athleteMainData } = this.props;
        let linkURL = img.linkURL;
        if (img.appendBib) {
            linkURL += athleteMainData.MyRace.Bib;
        }
        let image = (
            // <img src={`/images/${eventSeriesCode}/partners/${img.src}`} alt={img.altText} title={img.altText} />
            <img src={`${img.src}`} alt={img.altText} title={img.altText} />
        );
        if(img.linkURL){
            return <a href={linkURL} target="_blank" rel="noopener noreferrer">{image}</a>
        }
        return image;
    }
    render(){
        const { currIndex, images } = this.state;

        // typeof mapIntervalOrderIndex === 'number'
        
        
        // console.log('[SponsorsCarousel render]', currIndex, images)

        if(!images || !images[currIndex]) {
            return null;
        }
        
        return (
            <div className="SponsorsCarousel" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
                {this.renderImage(images[currIndex])}
            </div>
        )
    }
}




const renderRaceLogo = (img, eventSeriesCode) => {
    // console.log("XXXLOGO",img,eventSeriesCode);
    if(!img){
        return null;
    }
    const image = <img className="raceLogoImage" src={`/images/${eventSeriesCode}/partners/${img.src}`} alt={img.altText} />
    if(img.linkURL) {
        return (
            <a href={img.linkURL} target="_blank" alt={img.altText} rel="noopener noreferrer" title={img.altText} className="raceLogoContainer">{image}</a>
        )
    } else {
        return <span className="raceLogoContainer">{image}</span>

    }

}


class MapSidebarContent extends Component {
    componentDidMount(){
        const { raceCode } = this.props;
        this.props.sponsorsActions.getSponsors(raceCode);
    }

    gotoPrev = () => this.props.gotoPrev();
    gotoNext = () => {
        const { athleteMainData, intervalAnalysis } = this.props;
        // console.log("athlete from sidebar",athleteMainData);
        const intervals = !!athleteMainData && athleteMainData["event-intervals-data"];
        const lastInterval = intervals ? intervals.length-1 : 0;

        // console.log("lastInterval from sidebar",lastInterval);
        
        if(intervalAnalysis.currentEndIntervalIndex === lastInterval){
            return;
        }

        const max = intervals ? intervals.length - 1 : 0;
        this.props.gotoNext(max);
    }


    isIntervalSelected = (index) => {
        const { intervalAnalysis } = this.props;
        // ReactTooltip.hide();
        return index >= intervalAnalysis.currentStartIntervalIndex && index <= intervalAnalysis.currentEndIntervalIndex;
    }


    render(){
    const { raceCode, athleteMainData, athleteRankingData, settings, closeSidebar, race, sponsors, intervalAnalysis, setDistanceDisplayOption, allGeoPoints } = this.props;

    const { distanceDisplayOption, currentStartIntervalIndex, currentEndIntervalIndex } = intervalAnalysis;


    const { raceLogo } = sponsors[raceCode] || {};

    const eventSeriesCode = race.EventSeriesCode;

    const AGon = settings.ageGrade === "age_grade_on";
    const intervals = (athleteMainData && athleteMainData['event-intervals-ag-data']) ? (AGon ? athleteMainData['event-intervals-ag-data'] : athleteMainData['event-intervals-data']) : null;

    const lastInterval = intervals ? intervals.length-1 : 0;

    const sponsorsData = sponsors[raceCode] || {};


    let raceElevations = {
        points: [],
    }
    let elevationPoints = allGeoPoints && allGeoPoints.length ? 
        allGeoPoints.map( point => {
            let el = {
                // accumulatedAscentFt: 0,
                // accumulatedAscentM: 0,
                // accumulatedDescentFt: 0,
                // accumulatedDescentM: 0,
                distanceKm: point.distanceTraveledEstimatedKm,
                distanceMi: point.distanceTraveledEstimatedM,  // 0,
                // distanceRelative: 0,
                elevationFt: point.elevationFt,
                elevationM: point.elevationM,
                // elevationRelative: 6.92,
            }

            Object.keys(el).forEach( key => {
                let val = el[key];
                // eslint-disable-next-line
                if(val!==val || !val){ el[key] = 0; }
            } )
            
            return el;
        }) : [];
    raceElevations.points = elevationPoints;


    // console.log('elevationPoints', elevationPoints)
    // console.log('allGeoPoints', allGeoPoints)
    // console.log('race.elevations', race.elevations)
    // console.log('raceElevations', raceElevations)

    

    const intervalData = (athleteMainData && athleteMainData['event-intervals-ag-data']) ? (AGon ? athleteMainData['event-intervals-ag-data'] : athleteMainData['event-intervals-data']) : null;
    
    let endDistance = "N/A";
    let startDistance = "N/A";
    if (intervalData) {
        if (settings.units === 'metric') {
                
            if (distanceDisplayOption) {
                startDistance = currentStartIntervalIndex === 0 ? 0 : roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceKm,3); 
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,3); 
            }  else {  
                startDistance = currentStartIntervalIndex === 0 ? 0 : roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceKm,3);  
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceKm,3);  
            }
        } else {
            if (distanceDisplayOption) {
                startDistance = currentStartIntervalIndex === 0 ? 0 : roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceMi,3); 
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,3); 
            }  else {  
                startDistance = currentStartIntervalIndex === 0 ? 0 : roundDistance(intervalData[currentStartIntervalIndex-1].CumulativeDistanceMi,3);  
                endDistance = roundDistance(intervalData[currentEndIntervalIndex].CumulativeDistanceMi,3);  
            }
            
        }
    }
    

    let cityState = '';

    if(!athleteMainData || !athleteMainData.MyRace) { return null; }
    
    if (athleteMainData && athleteMainData.MyRace) {
        cityState = athleteMainData.MyRace.City ? athleteMainData.MyRace.City + ', ' + athleteMainData.MyRace.StateName : athleteMainData.MyRace.StateName;
        if (cityState.length === 0) {
            cityState = athleteMainData.MyRace.CountryName;
        }
    }

    // console.log("distanceDisplayOption",distanceDisplayOption);

    return (
        <div className="MapSidebarContent">
            <div className="HideButton" onClick={closeSidebar}>hide &raquo;</div>
            
            { renderRaceLogo(raceLogo,eventSeriesCode) }


            <div className="RaceDistance">
                <i/>
                { (!!race && !!race.RaceDistance) && <span>{race.RaceDistance}</span> }
                <i/>
            </div>

            <div className="EdgesLine">
                <div>#{athleteMainData.MyRace.Bib}</div>
                <div>{athleteMainData.MyRace.DivisionType1.replace('-','–')}</div>
            </div>
            

            <div className="centered">
                <div className="RaceName">
                    {race && race.RaceName}
                </div>
                

                <div className="RaceDate">
                    {(race && race.RaceDateStart) ? moment(race.RaceDateStart).format('MMM D, YYYY') : null}
                    {/* {(race && race.RaceDateStart) ? moment.unix(race.RaceDateStart.seconds).format('MMM D, YYYY') : null} */}
                </div>

                <p className="cityStateCountry">
                    <span data-tip={cityState} 
                        data-effect='solid' data-place='right' data-class="MyRaceTooltipLight">
                        {(athleteMainData && athleteMainData.MyRace) && 
                        athleteMainData.MyRace.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) }
                    </span>
                </p>
                
                <div className="AthleteName">
                    {(athleteMainData && athleteMainData.FirstName && athleteMainData.LastName) ? 
                        <Link to={`/athlete/${athleteMainData.NameGenderSlug}/${athleteMainData.MyRace.HometownSlug}`}><span><strong>{athleteMainData.FirstName + ' ' + athleteMainData.LastName}</strong></span></Link> :
                        ''}
                </div>

                

                <ToggleInterval titles={['Interval', 'Cumulative']} value={distanceDisplayOption} onChange={setDistanceDisplayOption} />


                <IntervalAnalysisBib currentIntervalIndex={currentEndIntervalIndex} race={race} athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} settings={settings} intervalDisplayOption={!distanceDisplayOption ? true : false} noFooter />
            </div>{/* centered */}


            <ToggleUnits />

            <ToggleAgeGrade toggleAgeGrade={AGon} />

            <D3ElevationMap isHiddenNavigation={true}   
                // showTooltipLabels = {true}
                units={settings.units}  
                intervalsData={athleteMainData ? athleteMainData["event-intervals-data"] : []} 
                width={330} 
                height={40}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }} 
                elevationData={race ? race.elevations : []} 
                // elevationData={raceElevations} 
                 />

            <div className="IntervalsContainer">
                {intervals.map( (intr,i)=><div key={i}
                    className={classNames("Interval", {
                        selected: this.isIntervalSelected(i)
                    })}
                    style={{ flexGrow: intr.FlexRatio }}></div>)}
            </div>
            
            
            <div className="IntervalControls">
                <div className="label">{startDistance} {settings.units === "metric" ? "km" : "mi"}</div>

                <div className={classNames("IntervalControlBtn prev",{
                        disabled: distanceDisplayOption ? intervalAnalysis.currentEndIntervalIndex === 0 : intervalAnalysis.currentStartIntervalIndex === 0
                    })} onClick={this.gotoPrev}>
                    <div className="BtnImage active"><img src={prevEnabled} alt='Previous Interval'/></div>
                    <div className="BtnImage disabled"><img src={prevDisabled} alt='Previous Interval'/></div>
                </div>

                <div className="IntervalIndex">
                    {distanceDisplayOption === 0? 
                    `${currentStartIntervalIndex+1} of ${intervals.length}` : 
                    `1 – ${intervalAnalysis.currentEndIntervalIndex+1}`}
                    
                </div>

                <div className={classNames("IntervalControlBtn next",{
                    disabled: intervalAnalysis.currentEndIntervalIndex === lastInterval
                })} onClick={this.gotoNext}>
                    <div className="BtnImage active"><img src={nextEnabled} alt='Next Interval'/></div>
                    <div className="BtnImage disabled"><img src={nextDisabled} alt='Next Interval'/></div>
                </div>                

                <div className="label">{endDistance} {settings.units === "metric" ? "km" : "mi"}</div>
            </div>{/*IntervalControls*/}

            <SponsorsCarousel athleteMainData={athleteMainData} eventSeriesCode={eventSeriesCode} raceCode={raceCode} {...sponsorsData} />
            







        </div>
    )
    }
}

const mapStateToProps = state => ({
    athletesByRacebib: state.athletesByRacebib,
    race: state.raceSelected,
    settings: state.settings,
    sponsors: state.sponsors,
    intervalAnalysis: state.intervalAnalysis,
    user: state.user
  })

const mapDispatchToProps = dispatch => ({
    setDistanceDisplayOption: value => dispatch({type:'INTERVAL_ANALYSIS_SET_DISTANCE_DISPLAY_OPTION', value}),
    
    setIntervalIndex: intervalIndex => dispatch({type:'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX', intervalIndex}),
    gotoPrev: () => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_PREV' }),
    gotoNext: (max) => dispatch({ type: 'INTERVAL_ANALYSIS_GO_TO_NEXT', max }),

    closeSidebar: ()=>dispatch({type:'SET_SETTING', isMapSidebarOpen: false}),
    sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(MapSidebarContent);