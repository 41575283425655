import React, { Component } from 'react'
import Modal from 'react-modal';
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import { setModalDone } from '../../actions/settings'

class PageIntroModal extends Component {

    state = {
        isOpen: false,
        // opacity: 0
    }

    componentDidMount() {
        if(window.localStorage.getItem(`modal_${this.props.id}`)){
            // console.log('DONE', this.props.id)
            // dispatch redux to remove this modal --- this.props.setModalDone(this.props.id)
            // this.props.setModalDone(this.props.id);
        } else {
            this.timeout = window.setTimeout(()=>{
                this.setState({isOpen: true}, ()=>{
                    // this.timeout2 = window.setTimeout(()=>{
                    //     this.setState({opacity: 1})
                    // },100)
                });
            },3000)
        }
    }

    componentWillUnmount(){
        if(this.timeout){ window.clearTimeout(this.timeout); }
        // if(this.timeout2){ window.clearTimeout(this.timeout2); }
    }

    openModal = () => {
        // ReactTooltip.hide();
      
        this.props.openModal();

        const page = this.props.match.path.split('/')[1];

    }
    closeModal = () => {
        // console.log('closeModal', this.props.id)
        this.setState({ isOpen: false });
        this.modalDone(this.props.id)();

        // this.props.closeModal();
    }

    modalDone = modalId => e => {
        // console.log('mdoalDone', modalId)
        this.props.settingsActions.setModalDone(modalId)
    }

    render(){
        const { isOpen, opacity } = this.state;
        const { id, text, title, style, settings } = this.props;
        const { modalsDone } = settings;

        // if(modalsDone[id]) {
        //     return null;
        // }
        
        return (
             <Modal
                isOpen={isOpen}
                onRequestClose={this.closeModal}
                contentLabel="Page Intro Modal"
                closeTimeoutMS={400}
                // style={{
                //     content: {
                //         transition: 'opacity 0.5s',
                //         opacity,
                //     }
                // }}
                >

                <div className="modal_content">
                    <button onClick={this.closeModal} className="close">close</button>

                    <h3> { title} </h3>
                    
                    {/* <p>This page provides a comprehensive set of times and statistics at a per interval level!</p> */}
                    { text }
                </div>
            </Modal>
        )
    }
}


const mapState = ({settings}) => ({ settings });
const mapDispatch = (dispatch) => ({
    // setModalDone: (modalId) => dispatch( { type: 'SET_MODAL_DONE', modalId }), // this.props.setModalDone(modalId)
    settingsActions: bindActionCreators({setModalDone}, dispatch), // this.props.settingsActions.setModalDone(modalId)
})
export default connect(mapState, mapDispatch)(PageIntroModal);
