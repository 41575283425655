import React, { Component } from 'react'
import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './ProfileImage.scss'
import classNames from 'classnames'
// import firebase from 'firebase/app'
import {storage, TaskEvent} from '../../../config/firebase'
import {connect} from 'react-redux'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import imgProfile from '../../../images/user-5.svg'
import imgProfileLight from '../../../images/user-4.svg'
import imgDelete from '../../../images/close-03.svg'
import imgDeleteHover from '../../../images/close-04.svg'
// import imgDelete from '../../../images/close-1.svg'
// import imgDeleteHover from '../../../images/close-2.svg'
import { setUsersProperty } from '../../../actions/users'
import { bindActionCreators } from "redux";
import steeltoe from 'steeltoe'


const messages = {
    change_avatar: 'Change my avatar...',
    clear_avatar: 'Clear my avatar...',
}

const initialState = {
    //imageUrl: null, // URL on Firebase -> settings.userAvatarImageUrl
    file: null, // file selected by the user
    fileUrl: null, // file selected by the user, as url, good for <img /> or Cropper
    cropResultBlob: null, // cropped image blob, good for firebase
    cropResultUrl: null, // cropped image as url, good for <img />
    uploadProgress: 0,
    // avatarMessage: messages.change_avatar,
    avatarDeleteButtonHovered: false,
    uploadedAvatarConfirmed: false,
};

class ProfileImage extends Component {

    constructor(props){
        super(props);
        this.state = {
            ...initialState
        }
        this.fileInput = React.createRef();
        this.cropImage = throttle(this.cropImage, 500);
        this.uploadImage = debounce(this.uploadImage, 2000);
    }

    componentDidUpdate(prevProps, prevState){

        const showCropper = this.state.fileUrl && !this.state.uploadedAvatarConfirmed;
        const prevShowCropper = prevState.fileUrl && !prevState.uploadedAvatarConfirmed;

        // console.log('showCropper', showCropper, prevShowCropper)

        if(showCropper !== prevShowCropper){
            this.props.setCropperMode(showCropper);
        }



    }


    deleteAvatar = () => {
        const {fbuser} = this.props;
        if(!fbuser) { return; }
        // this.setState({imageUrl: null});
        // this.props.setAvatarImageUrl(null);
        this.setUsersProperty({avatarImageUrl:null});
        this.handleAvatarDeleteButtonHover('leave')();
        // firebase.storage().ref().child(`avatars/${fbuser.uid}`).delete()
        storage.ref().child(`avatars/${fbuser.uid}`).delete()
            .then(()=>{
                this.setState({ ...initialState });
            })
            .catch(err=>{ console.warn('error deleting avatar', err)})
    }

    setUsersProperty = (properties) => {
        console.log('[setUsersProperty InRedux]', properties)
        const {fbuserProfile, users} = this.props;
        const EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === fbuserProfile.username );
        // setting `users.${EmailHash}.avatarImageUrl`
        this.props.usersActions.setUsersProperty({ EmailHash, ...properties });
    }
    
    // setUsersProfileProperty = (properties) => {
    //     console.log('[setUsersProfileProperty InRedux]', properties)
    //     const {fbuserProfile, users} = this.props;
    //     const EmailHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === fbuserProfile.username );
    //     // setting `users.${EmailHash}.profile.avatarId`
    //     this.props.usersActions.setUsersProfileProperty({ EmailHash, ...properties });
    // }
    
    
    uploadImage = async () => {
        const { cropResultBlob, uploadProgress} = this.state;
        const {fbuser, users} = this.props;
        console.log('[uploadImage]', cropResultBlob);

        if(uploadProgress > 0) {
            window.setTimeout( this.uploadImage, 3000 );
            return;
        }
        
        // let uploadTask = firebase.storage().ref().child(`avatars/${fbuser.uid}`).put(cropResultBlob);
        let uploadTask = storage.ref().child(`avatars/${fbuser.uid}`).put(cropResultBlob);

        // uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED, s => { /* upload in progress */
        uploadTask.on( TaskEvent.STATE_CHANGED, s => { /* upload in progress */
            let uploadProgress = ( s.bytesTransferred / s.totalBytes ) * 100;
            console.log(`Upload progress: ${uploadProgress}%`);
            this.setState({uploadProgress});

        }, error => { /* upload failed */
            console.log(`Upload failed!`, error)
            this.setState({ uploadProgress: 0 })

        }, async () => { /* upload complete */
            console.log(`Upload complete!`)
            this.setState({ uploadProgress: 100 }, 
                ()=>this.setState({ uploadProgress: 0 })
            );
            
            const imageUrl = await uploadTask.snapshot.ref.getDownloadURL();
            console.log('uploaded image url:', imageUrl);

            // preload image before using the remote file url
            await (new Promise(r=>{ let i = new Image(); i.onload = r; i.src = imageUrl }));

            // this.setState({imageUrl});
            // this.props.setAvatarImageUrl(imageUrl);
            this.setUsersProperty({avatarImageUrl:imageUrl});

            this.setState({...initialState});



            /// TODO get the gender from `users.{EmailHash}.athleteRaceResults.0.Gender`

            const gender = steeltoe(users).get(`{EmailHash}.athleteRaceResults.0.Gender`);
            
            
            this.props.onClick(gender==='M'?'imgM7':'imgF7')();
            // this.setUsersProfileProperty({avatarId: 'imgM7'});
            // this.props.setFbUserProfileProperty({defaultAvatar: 'imgM7'});
        })
    }


    onDrop = droppedFiles => {
        console.log('ondrop:',  droppedFiles)
        if(!droppedFiles || !droppedFiles[0]) { return; }
        const file = droppedFiles[0];
        this.setState({
            file, 
            fileUrl: URL.createObjectURL(file)
        })
    }
    
    // onFileSelect = () => {
    //     console.log('[onFileSelect]')
    //     const file = this.fileInput.current.files[0];
    //     this.setState({
    //         file,
    //         fileUrl: URL.createObjectURL(file)
    //     })
    // }

    cropImage = () => {
        const croppedCanvas = this.refs.cropper.getCroppedCanvas({fillColor:"#fff"});
        if (typeof croppedCanvas === 'undefined') { return; }
        croppedCanvas.toBlob(cropResultBlob => {
            console.log('[cropImage]' ,cropResultBlob);
            this.setState({
                cropResultBlob,
                cropResultUrl: URL.createObjectURL(cropResultBlob)
            }, ()=>{console.log('done cropping')})
        }, 'image/jpeg');
    }

    closeCropper = () => this.setState({ fileUrl: null })

    cancelUpload = () => {
        this.setState({ 
            ...initialState,
            uploadedAvatarConfirmed: false
        });
    }
    applyUpload = () => {
        this.setState(s =>({ 
            ...initialState,
            cropResultBlob: s.cropResultBlob,
            cropResultUrl: s.cropResultUrl,
            uploadedAvatarConfirmed: true
        }), this.uploadImage);
    }

    handleAvatarDeleteButtonHover = act => e => this.setState({
        // avatarMessage: act==='enter' ? messages.clear_avatar : messages.change_avatar
        avatarDeleteButtonHovered: act==='enter'
    })

    renderDefaultAvatar = () => {
        const {fbuser, defaultGenderAvatar, uploadedImage} = this.props;
        const {avatarDeleteButtonHovered} = this.state;

        let src = uploadedImage || defaultGenderAvatar.img;

        if(fbuser.photoURL) { // google avatar photo
            src= fbuser.photoURL;
        }

        if(avatarDeleteButtonHovered){
            src = defaultGenderAvatar.img;
        }

        return (
            <img src={src} className="AvatarImage" alt="user phot" />
        )


        // return (
        //     <>
        //         <div className="iconDefault" style={{ backgroundImage: `url(${imgProfileLight})`}}/>
        //         <div className="iconHover" style={{ backgroundImage: `url(${imgProfile})`}}/>
        //     </>
        // )
    }

    render() {
        const { fileUrl, cropResultUrl, uploadedAvatarConfirmed } = this.state;
        // const {settings: {userAvatarImageUrl}, fbuser} = this.props;
        const {
            // settings: {userAvatarImageUrl},  - not used anymore
            defaultGenderAvatar, onAvatarPreview, 
            onClick, // - on avatar click, saves the selection
        uploadedImage // previously uploaded avatar image
    } = this.props;

        // const defaultAvatar = userAvatarImageUrl || defaultGenderAvatar;

        const showCropper = fileUrl && !uploadedAvatarConfirmed;
        

        
        return (
            <div className="ProfileImage">
                {
                    // !fileUrl && 
                    (
                        <div className="DropzoneWrapper">

                            {/* <div className="AvatarMessage">{avatarMessage}</div> */}
                        
                            { !!uploadedImage && !showCropper && <div className="DeleteAvatarButton" onClick={this.deleteAvatar}
                                onMouseEnter={this.handleAvatarDeleteButtonHover('enter')}
                                onMouseLeave={this.handleAvatarDeleteButtonHover('leave')}
                            >
                                <div className="IconSwappable">
                                    <div className="icon" style={{ backgroundImage: `url(${imgDelete})`}}/>
                                    <div className="hover" style={{ backgroundImage: `url(${imgDeleteHover})`}}/>
                                </div>
                            </div> }

                            {!showCropper && <div className="AvatarIcon"
                                    onMouseEnter={onAvatarPreview(defaultGenderAvatar.name)}
                                    onMouseLeave={onAvatarPreview(null)}
                                    onClick={onClick(defaultGenderAvatar.name)}
                                >
                                {cropResultUrl ?
                                    <img src={cropResultUrl} className="AvatarImage" alt="user-pic" />
                                    :
                                    this.renderDefaultAvatar()
                                }
                            </div>}

                            {!showCropper && <Dropzone onDrop={this.onDrop} multiple={false}>
                                {({getRootProps, getInputProps, isDragActive}) => {
                                    return (
                                        <div
                                        {...getRootProps()}
                                            className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                                        >
                                        <input {...getInputProps()} />

                                        <span className="FileSelectButton">Add My Picture</span>
                                        

                                        {/*
                                            isDragActive ?
                                            <p>Drop files here...</p> :
                                            <p>Try dropping some files here, or click to select files to upload.</p>
                                        */}
                                        </div>
                                    )
                                }}
                            </Dropzone>}

            
                            {
                                showCropper && !uploadedAvatarConfirmed && (
                                    <div className="CropperWrapper">
                                        
                                        <div className="DeleteAvatarButton" onClick={this.closeCropper}>
                                            <div className="IconSwappable">
                                                <div className="icon" style={{ backgroundImage: `url(${imgDelete})`}}/>
                                                <div className="hover" style={{ backgroundImage: `url(${imgDeleteHover})`}}/>
                                            </div>
                                        </div>

                                        <Cropper
                                            style={{ height: 200, width: '100%' }}
                                            ref="cropper"
                                            aspectRatio={1} 
                                            src={fileUrl}
                                            // src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGTVf63Vm3XgOncMVSOy0-jSxdMT8KVJIc8WiWaevuWiPGe0Pm"}
                                            viewMode={0}
                                            dragMode="move"
                                            guides={false}
                                            scalable={true}
                                            cropBoxMovable={true}
                                            cropBoxResizable={true}
                                            crop={this.cropImage}
                                        />

                                        <div className="buttons">
                                            <button className="secondary" onClick={this.cancelUpload}>Cancel</button>
                                            <button className="primary" onClick={this.applyUpload}>Apply</button>
                                        </div>

                                        
                                    </div>
                                )
                            }


                            
                        </div>
                    )                            
                }
                
                {/* <h2>Crop Result {
                    uploadProgress > 0 && <span>- Uploading: {uploadProgress}%</span>
                }</h2> */}
                {/*
                    // !!cropResultUrl && (
                        <>
                            <img style={{width: '500px'}} alt="" src={cropResultUrl} />
                            <label htmlFor="thumbnail">
                                <input onChange={this.onFileSelect} type="file"
                                        ref={this.fileInput}
                                        id="thumbnail"
                                        name="thumbnail"
                                        accept="image/png, image/jpeg"
                                        />
                            </label>
                        </>
                    // )
                */}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    // setAvatarImageUrl: userAvatarImageUrl => dispatch({type:'SET_SETTING', userAvatarImageUrl}),
    // setFbUserProfileProperty: payload => dispatch({type:'SET_PROFILE_DOC_PROPERTY', payload}),
    usersActions: bindActionCreators({setUsersProperty}, dispatch),
})
const mapState = ({fbuser, fbuserProfile, users})=>({fbuser, fbuserProfile, users})

export default connect(mapState, mapDispatchToProps)(ProfileImage)