import React, { Component } from "react";
import "./WelcomeBib.scss";
// import timePng from '../../images/clock-1.svg'
import timePng from '../../images/swatch-04.svg'
import {connect} from 'react-redux'
import {Link, withRouter} from 'react-router-dom';
import {secondsToTimeStrip} from '../../timeUtils'
// import navNext from '../../images/next-big-03.svg';
// import Beacon from '../Beacon/Beacon'
import { bindActionCreators } from "redux";
import classNames from 'classnames'
import { setBeaconDone } from '../../actions/settings'
import TooltipText from '../../components/Tooltip/TooltipText'
// import { motion } from "framer-motion"
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import {db,storage} from '../../config/firebase'
import * as Sentry from '@sentry/browser';
import steeltoe from 'steeltoe';
import {getAvatarImage} from '../../pages/AccountProfile/avatars'
import { getAvatarAndRaces } from '../../actions/users'
import UserAvatar from './UserAvatar'


class WelcomeBib extends Component {

    componentDidMount(){
        // this.getUsername();
        this.props.usersActions.getAvatarAndRaces({
            EmailHash: this.props.athlete && this.props.athlete.EmailHash
        })

    }
    componentDidUpdate(){
        // this.getUsername();
        // this.props.usersActions.getUserAndAvatar({
        //     EmailHash: this.props.athlete && this.props.athlete.EmailHash
        // })
    }
      


    beaconDone = beaconId => e => {
        this.props.settingsActions.setBeaconDone(beaconId)
    }
    

    render() {
        const { athlete, race } = this.props;
        let athleteTimes = null;
        
        switch(race.RaceDiscipline) {
            case 'Run':
                // athleteTimes = athlete.times;
                athleteTimes = athlete.runtimes.RunTime;
                break;
            case 'Triathlon':
                athleteTimes = athlete.finaltimes.FinishTime;
                break;
            default:
                // no-op
                break;

        } // switch

        // console.log("race",race)
        

        if(!athlete) {
            return <div>... Loading ...</div>
        }


        const { MyRace, RaceCode, Bib, EmailHash, FirstName, LastName, Gender } = athlete;


        const gender = athlete.Gender;
        
        // console.log("athleteRaceResults",athleteRaceResults)
        // console.log("Gender",gender)
        let content = '';
        const { settings } = this.props;
        const on = settings.AgeGrade === "age_grade_on";

        if (athleteTimes) {

            switch(race.RaceDiscipline) {
                case 'Run':
                    // console.log("athleteTimes",athleteTimes)
                    // console.log("athlete.times.OfficialTime",athlete.times.OfficialTime);
                    if (on) {
                        // content = secondsToTimeStrip(athleteTimes.OfficialTime.TimeSeconds_AG);
                        content = secondsToTimeStrip(athleteTimes.TimeSeconds_AG);
                    } else {
                        // content = secondsToTimeStrip(athleteTimes.OfficialTime.TimeSeconds);
                        content = secondsToTimeStrip(athleteTimes.TimeSeconds);
                    }
                    break;
                case 'Triathlon':
                    content = secondsToTimeStrip(athleteTimes.TimeSeconds);
                    break;
                default:
                    // no-op
                    break;
    
            } // switch


        } else {
            content = '0:00:00';
        }

        let nameLink = null;

        //if (this.props.athlete) {
        if (EmailHash && EmailHash.length > 0) {
            nameLink =  <Link to={`/athlete/${EmailHash}`} className="athleteName tooltip">
            {FirstName + ' ' + LastName}
                <TooltipText>Athlete's Home Page</TooltipText>
            </Link>;
        } else {
            nameLink =  <span className="athleteName">{FirstName + ' ' + LastName}</span>;
        }
        //}

        // const nameLink = (athlete && athlete.FirstName && athlete.LastName) ? 
        //     <span className="athleteName">{athlete.FirstName + ' ' + athlete.LastName}</span>
        //      :
        //     '';

        // const nameLink = (athlete && athlete.FirstName && athlete.LastName) ? 
        //     <Link to={`/athlete/${this.props.athlete.EmailHash}`} className="athleteName">{athlete.FirstName + ' ' + athlete.LastName}</Link> :
        //     '';
        
        let cityState = '';
        if (MyRace) {
            // cityState = MyRace.City ? MyRace.City + ', ' + MyRace.StateName : MyRace.StateName;
            // if (cityState.length === 0) {
            //     cityState = MyRace.CountryName;
            // }

       
            if (MyRace.StateCode) {
                cityState = MyRace.City + (MyRace.City ? ', ' : '') + MyRace.StateCode;
            }  
            else if (MyRace.City  && MyRace.CountryThreeCharCode) {
                cityState = MyRace.City + ', ' + MyRace.CountryName;
            }
            else if (MyRace.City  && MyRace.CountryTwoCharCode) {
                cityState = MyRace.City + ', ' + MyRace.CountryName;
            }

            if (cityState.length === 0) {
                cityState = MyRace.CountryName;
            }
        }
        // console.log("MyRace",MyRace)
        
        
        
        const flag = MyRace && MyRace.CountryTwoCharCode && MyRace.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));
        

       

        return (
            

            // <motion.div 
            <div 
                className="WelcomeBib"
                // initial={{
                //     opacity: 0
                // }}
                // animate={{ opacity: 1 }}
                // transition={{ duration: 2 }}
                >
              
                {/* <p className="cityStateCountry"> </p> */}

                {/* <img src={avatar} alt="user-avatar" className="userAvatar" /> */}

                {/* avatar && (
                    <div className={classNames("userAvatarContainer", {
                        uploadedImage: uploadedImage&&isDefaultImageSelected
                    })}>
                        <img src={ avatar } alt="user-avatar" className="userAvatar" />
                    </div>
                )*/}

                <UserAvatar 
                    EmailHash={EmailHash} 
                    Gender={gender} // 'M', 'F'
                />
  


                <p className="cityStateCountryLabel">
                    <span className="countryFlag">{flag}</span>
                    {cityState}
                </p>


                {/* <h1><span data-tip="Athlete's Page" data-effect='solid' data-delay-show='1000'  data-place='bottom' data-class="MyRaceTooltip"> */}
                <h1><span>
                        {/* {(athlete && athlete.FirstName && athlete.LastName) ? athlete.FirstName + ' ' + athlete.LastName : ''} */}
                        {nameLink}
                    </span>
                </h1>
                
                {/* <h1>{nameLink}</h1> */}
                {/* <p className="bib">{(athlete && athlete.Bib) ? `# ${athlete.Bib}` : ''}</p> */}
                <span className="timeContainer">{athleteTimes ? 
                    <span className="time" style={{backgroundImage:`url(${timePng})`}}>{content}</span> : ''
                    }
                    {/* <Link data-tip="Go to Results Summary" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDark" 
                        to={`/results-summary/${RaceCode}/athletes/${Bib}`} className="nextPage" onClick={this.beaconDone("pageNavigationWelcomeArrow")}>
                        <Beacon id="pageNavigationWelcomeArrow" text='Proceed to Results Summary' direction="left" style={{ top:'12px', left:'50px'}} />
                        <span className="background" />
                        <img src ={navNext } />
                    </Link> */}
                </span>

            </div>
            // </motion.div>
        );
    }

}

const mapStateToProps = state => ({
    settings: state.settings,
    users: state.users
})

const mapDispatch = dispatch => ({
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
    usersActions: bindActionCreators({getAvatarAndRaces}, dispatch), // this.props.usersActions.getUserAndAvatar(EmailHash)
    setUsersProperty: ({ EmailHash, username, profile, avatarImageUrl }) => dispatch({ type: "SET_USERS_PROPERTY", EmailHash, username, profile, avatarImageUrl  })
})

export default withRouter(connect(mapStateToProps,mapDispatch)(WelcomeBib));


