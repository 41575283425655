import steeltoe from 'steeltoe';
import initialState from "./initialState";

    /*
      [EmailHash] : {
        username: '',
        profile: {}
      }
    */

export default (state = initialState.users, action) => {
  switch (action.type) {
    case "SET_USERS_PROPERTY": {
        // expect: action.EmailHash, action.username
        let userProperty = {};
        // const allowedProperties = ['username', 'profile', 'avatarImageUrl', 'races'];
        const allowedProperties = Object.keys(action).filter(key => key!=='type'&&key!=='EmailHash');
        allowedProperties.forEach(prop=> {
            // if(action[prop]) {
                userProperty[prop] = action[prop];
            // }
        });
        
        let nextState = {...state};

        
        nextState[action.EmailHash] = nextState[action.EmailHash] ?
            {...nextState[action.EmailHash], ...userProperty } :
            userProperty;
        
        
        
        
        if(steeltoe(state).get(`${action.EmailHash}.athleteRaceResults.length`) && action.athleteRaceResults && action.athleteRaceResults.length) {
          nextState[action.EmailHash].athleteRaceResults = state[action.EmailHash].athleteRaceResults.map((race,i) => ({
              ...race,
              ...action.athleteRaceResults[i]
            })
          );
        }



        return nextState;
    }

    // case "SET_USERS_PROFILE_PROPERTY": {
    //   // action.EmailHash
    //   // action.properties -> merge into -> state.{EmailHash}.profile
      
    //   if(steeltoe(state).get(`${action.EmailHash}.profile`)){
    //     const nextState = {...state};
    //     nextState[action.EmailHash] = {...nextState[action.EmailHash]};
    //     nextState[action.EmailHash].profile = {...nextState[action.EmailHash].profile, ...action.properties}
    //     return nextState;
    //   }

    // }

    case "SET_USERS_REQUEST_STATUS": {
      // console.log("reducer SET_USERS_REQUEST_STATUS")
      /*
        [EmailHash] : {
          status: {
            username: '', -- 'pending', 'received', 'error'
          }
        }
      */
      const { EmailHash, requestType, status } = action;
      let nextState = {...state};
      // if(!nextState[EmailHash]) nextState[EmailHash] = {};
      // if(!nextState[EmailHash].status) nextState[EmailHash].status = {};
      // nextState[EmailHash].status[requestType] = status;

      steeltoe(nextState).set(`${EmailHash}.status.${requestType}`, status);
      // console.log(JSON.stringify(nextState));
      
      return nextState;
    }

    default:
      return state;
  }
};
