import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore';
import 'firebase/storage';
import "firebase/performance";
import "firebase/functions";


const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCg9_wUJ-Fwa-FHx8R6fqR6p8zh9IDrGDk",
  authDomain: "my-race-com-portal.firebaseapp.com",
  databaseURL: "https://my-race-com-portal.firebaseio.com",
  projectId: "my-race-com-portal",
  storageBucket: "my-race-com-portal.appspot.com",
  messagingSenderId: "33298422550",
  appId: "1:33298422550:web:77b57c954c8e7ae7360e37",
  measurementId: "G-Y48Q0M0T4Y"
}, 'portal');

// const firestore = firebase.firestore();
window.firebase = firebase;

// const perf = firebase.performance();

// firestore.settings({ timestampsInSnapshots: true });

firebaseApp.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });


export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const FieldValue = firebase.firestore.FieldValue;
export const TaskEvent = firebase.storage.TaskEvent;
// const perf = firebase.performance();

export default firebase;