import React, {Component} from 'react'
import {countries, code2 as countriesByCode2} from 'simple-country-list'
import classNames from 'classnames'
import checkIcon from '../../images/check.svg';
import './CountryDropdown.scss'

class CountryDropdown extends Component {
    state = {
        selected: '', // code2
        open: false,
    }

    componentDidMount() {
        // console.log('this.props.countryCode', this.props.countryCode)
        this.setState({ selected: this.props.countryCode })
    }
    componentDidUpdate(prevProps){
        if(!prevProps.countryCode && this.props.countryCode) {
            this.setState({ selected: this.props.countryCode })
        }
    }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })
    toggle = () => this.setState(s => ({ open: !s.open }))

    setSelected = code2 => e => this.setState({ selected: code2, open: false }, this.props.onChange(code2))


    
    render(){
        const { open, selected } = this.state;

        return (
            <div className={classNames('CountryDropdown', { 
                invalid: !selected,
                open })}
            >
                <div className="DropdownValue" onClick={this.toggle}>
                    {!!selected ? (
                        <>
                        <span>{countriesByCode2[selected][0].name}</span>
                        <span className="flag">{countriesByCode2[selected][0].emoji}</span>
                        </>
                    ): 'Select Country'}
                </div>
                <div className={classNames("DropdownList")}>
                    <div className="DropdownListInner">
                        { countries.map( country => {
                            return (
                                <div key={country.alpha2} className={classNames("DropdownOption",{
                                    selected: country.alpha2 === selected
                                })} onClick={this.setSelected(country.alpha2)}>
                                    <div className="flag">{country.emoji}</div>
                                    <div className="title">{country.name}</div>
                                    <div className="checkbox">{country.alpha2 ===selected && <img src={checkIcon} />}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default CountryDropdown