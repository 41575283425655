import React, { Component } from 'react'
import moment from "moment";
import './AthleteHomeTimeline.scss'
import classNames from 'classnames'
import TooltipText from '../../components/Tooltip/TooltipText';
import steeltoe from 'steeltoe'


// MEMOIZE
let prevHashedIds = '';
let cachedRaces = null;
let cachedYears = [];

const calcRaces = (props) => {
    
    // let's first remove any athleteRaces that have RaceDetails as undefined (probably becuase it references a private race)
    
    console.log("111 before athleteRaces in Timeline",props.athleteRaces)
    const athleteRacesFiltered = props.athleteRaces.filter(race => (race.RaceDetails !== undefined && (race.RaceDetails.AdminStatus == 'Public' || race.RaceDetails.AdminStatus == 'Public-Unofficial'))); 
    console.log("111 after athleteRaces in Timeline",athleteRacesFiltered)

    // if(!steeltoe(props).get('athleteRaces.0.RaceDetails.RaceDateStart')) {
    if(!steeltoe(athleteRacesFiltered).get('0.RaceDetails.RaceDateStart')) {
        console.log('[calcRaces] cancelled', props)
        return { athleteRaces: null, years: null };
    }
  

    
    const hashedIds = athleteRacesFiltered.map(r=>r._id).join('|');
    
    if(hashedIds===prevHashedIds) {
        // console.log('REUSING CACHED RACES');
        return { athleteRaces: cachedRaces, years: cachedYears };
    }
    prevHashedIds = hashedIds;
    // console.log('CALCULATE RACES')

    let latestYear = new Date().getFullYear();
    let earliestYear = latestYear;

    
    let athleteRaces = athleteRacesFiltered.map( athleteRace => {
        console.log("athleteRace",athleteRace)
        // const RaceDateObj = new Date(athleteRace.RaceDetails.RaceDateStart);
        const RaceDateMoment = moment(athleteRace.RaceDetails.RaceDateStart);
        // const RaceYear = RaceDateObj.getFullYear();
        const RaceYear = RaceDateMoment.format('YYYY');

        if(RaceYear < earliestYear) earliestYear = RaceYear;
        
        return { ...athleteRace, RaceDateMoment }
    });

    const momentTimelineStart = moment(`${earliestYear}-01-01`);
    const momentTimelineEnd = moment(`${latestYear}-12-31`);
    // window.momentTimelineStart = momentTimelineStart;

    let timelineDuration = moment.duration(momentTimelineEnd.diff(momentTimelineStart));
    let timelineDays = timelineDuration.asDays();
    // console.log('timelineDays:', timelineDays)

    let prevTimelinePercent = -1;
    athleteRaces = athleteRaces.map( (athleteRace, index) => {
        let duration = moment.duration(athleteRace.RaceDateMoment.diff(momentTimelineStart));
        let days = duration.asDays(); // days since the beginning of the timeline
        let timelinePercent = (days / timelineDays)*100;
        if (prevTimelinePercent === timelinePercent) {
            timelinePercent += .3;
        }

        prevTimelinePercent = timelinePercent;


        // const perc = days / timelineDays;
        // console.log('perc', days, '/', timelineDays, '=', perc*100)
        return {
            ...athleteRace,
            timelineDays: days,
            timelinePercent
          
        }
    })

    let years = [];
    for(let y = earliestYear; y <=latestYear; y++){
        years.push(y);
    }

    cachedRaces = athleteRaces;
    cachedYears = years;

    return { athleteRaces, years };
}


class AthleteHomeTimeline extends Component {

    selectRace = objectID => e => {
        this.props.onRaceSelected(objectID)
    }
    // goToRace = e => {
    //     // this.props.onRaceSelected(objectID)

    //     const {selectedRaceIndex, races} = this.props;
    //     const race = races[selectedRaceIndex];
    //     const uri = `/welcome/${race.RaceCode}/athletes/${race.Bib}`
    //     this.props.history.push(uri);
    // }

    // componentDidMount() {
    //     this.autoSelectLastRace()
    // }
    
    // autoSelectLastRace = () => {
    //     const { selectedRaceIndex, races, onRaceSelected } = this.props;
    //     if(!races[selectedRaceIndex] && races && races.length){
    //         // const lastRace = races[races.length-1];
    //         onRaceSelected(races.length-1)
    //     }
    // }
    
    render(){

        // console.log('AthleteHomeTimeline Render')

        const {selectedRaceIndex} = this.props;

        
        // console.log("selectedRaceIndex", selectedRaceIndex);
        const { athleteRaces, years } = calcRaces(this.props)
        console.log("Racees in timeline", athleteRaces);

        

        return (
            <div className="AthleteHomeTimeline">
                <div className="TheTimeline">
                    <div className="Races">{athleteRaces && athleteRaces.map((race,i) => {
                        const active = selectedRaceIndex === i;
                        return (
                            <div
                                // key={race.objectID} 
                                key={race._id} 
                                className={classNames("Race","tooltip", { active })}
                                onClick={active? this.props.goToSelectedRace : this.selectRace(i)}
                                style={{
                                    left: `${race.timelinePercent}%`
                                }}
                            >
                                <div className="TheDot" />
                                <div className="background" />
                                <TooltipText>{
                                    active?
                                    <span>Go to Race</span>
                                    : 
                                    race.RaceDateMoment.format('MMM DD, YYYY')
                                }</TooltipText>
                                <div className="RaceLabel">{
                                    race.RaceDateMoment.format('MMM DD, YYYY')}
                                </div>
                            </div>
                        )
                    })}</div>

                    <div className="Years">
                        {years && years.map(year => {
                            return (
                                <div className="Year" key={year}>
                                    <span className="yearname">{year}</span>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        )
    }
}

export default AthleteHomeTimeline