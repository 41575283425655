import "whatwg-fetch";
import '@babel/polyfill';

import React from "react";
import ReactDOM from "react-dom";
// import LogRocket from 'logrocket';
import {BrowserRouter} from 'react-router-dom';
import "./index.css";
import App from "./App";



// import registerServiceWorker from "./registerServiceWorker";
import {  unregister } from "./registerServiceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { Provider } from "react-redux";

import firebaseLogger from './re-middleware/firebaseLogger'

import reducers from "./reducers";


if (process.env.NODE_ENV === 'production') {
  console.log = function(){};
  console.warn = function(){};
  console.error = function(){};
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  // composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware()))
  composeEnhancers(applyMiddleware(thunk, firebaseLogger))
);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
// registerServiceWorker();
unregister();
