import React, { Component } from "react";
import ReactDOM from 'react-dom'
import classNames from 'classnames';
import ToggleGeotype from "../../components/ToggleButton/ToggleGeotype";
import './DropdownHometown.scss'
import checkIcon from '../../images/check.svg';
import chevronIcon from '../../images/chevron-3.svg';
import algolia from '../../apis/algolia'
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';
import TooltipText from '../Tooltip/TooltipText'
// import TooltipText from '../Tooltip/TooltipText'
// import ReactTooltip from 'react-tooltip'

const Option = ({objectID, title, tooltipTitle, onClick, selected}) => {
    let style={};

    if(selected){
        style.backgroundImage = `url(${checkIcon})`;
    }

    let tooltipProps = {};
    // if(tooltipTitle){
    //     tooltipProps["data-tip"] = tooltipTitle;
    //     tooltipProps["data-effect"] = 'float' ;
    //     tooltipProps["data-delay-show"] = '500' ;
    //     tooltipProps["data-place"] = 'bottom' ;
    //     tooltipProps["data-class"] = "MyRaceTooltipDark";
    // }
    if(tooltipTitle){
        tooltipProps["className"] = "tooltip";
    }
    
    return (
        <>
        {/* {tooltipTitle ? <ReactTooltip /> : null} */}
        <li className={classNames({ selected })} style={style} onClick={onClick} {...tooltipProps}>
            {/* <TooltipText>{tooltipTitle}</TooltipText> */}
            <span className="tooltiptext">{tooltipTitle}</span>
                {title} 
        </li>
        </>
    )
}



class DropdownHometown extends Component {

    state = {
        open: false,
        gender: 0, // 0 - male, 1 - female
        // geotype: 'City', // Country, City, State
        geotype: 'State', // Country, City, State
        // options: [
        //     {
        //         objectID: 'all',
        //         geotype: '',
        //         title: 'All Locations'
        //     }
        // ],
        selectedOptionId: 'all',
        // filterCountry: '',
        // filterCity: '',
        // filterState: '',
        listCountry: null,
        listCity: null,
        listState: null,
    }

    indexNames = {
        Country: 'racecode-hometown-countries',
        City: 'racecode-hometown-cities',
        State: 'racecode-hometown-states'
    }
    
    componentDidMount(){

        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);

        // console.log('[DropdownHometown] mounted value:', this.props.value);

        if(this.props.value && this.props.value !== 'All-Locations'){
            const [city, state, country] = this.props.value.split('*');
            if(country && !state){
                this.setState({
                    geotype: 'Country'
                }, this.getDefaults);
            } else {
                this.getDefaults();
            }
        } else {
           this.getDefaults();
        }


    }


    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
    }

    componentDidUpdate(prevProps){
        if(prevProps.value !== this.props.value && this.props.value==='All-Locations'){
            // console.log('[DropdownHometown] changed', prevProps.value, '->', this.props.value);
            this.setState({
                // filterCountry: "",
                // filterCity: "",
                // filterState: "",
                selectedOptionId: 'all',
            })
        }

        if(this.props.disabled && !prevProps.disabled) {
            console.log(('clear state'));
            this.clearSearch()
            // this.setState({
            //     filterCountry: "",
            //     filterCity: "",
            //     filterState: "",
            //     selectedOptionId: 'all',
            // })
        }
        
    }

    getDefaults = () => {
        const {race} = this.props;

        // const filters = 'RaceCode:'+race.RaceCode;
        if(!race || !race.Location){
            return window.setTimeout(this.getDefaults, 1000);
        }
       
        this.search();

    }

    open = () => {
        if( this.props.disabled ) { return; }
        this.setState({ open: true })
    }
    
    select = (geotype, obj) => e => {
        console.log('LOCATION SELECTED:', geotype , obj)
        this.props.onChange(geotype, obj);
        this.close();

        let val = '';
        if(geotype==='Country') val = obj.CountryName;
        else if(geotype==='City') val = obj.City;
        else if(geotype==='State') val = obj.StateName;
        this.setState({
            ['filter'+geotype]: val,
            selectedOptionId: geotype ? obj.objectID : 'all',
        })
    }
    close = () => this.setState({ open: false })

    // onGenderChange = gender => this.setState({ gender })
    onGeotypeChange = geotype => {
        this.setState({ geotype }, () => {
            if(!this.state[`list${geotype}`]){
                this.search();
            }
        })
    }

    clearSearch = () => {
        this.setState({ ['filter'+this.state.geotype]: '' }, this.search)
    }
    
    updateFilterText = geotype => e => this.setState({ ['filter'+geotype]: e.target.value }, this.search)

    search = () => {
        const {race} = this.props;
        if(!race){ return; }
        const { geotype } = this.state;
        const query = this.state[`filter${geotype}`];

        const filters = 'RaceCode:'+race.RaceCode;

        const index = algolia.initIndex(this.indexNames[geotype]);

        index.search({query, hitsPerPage: 500, filters}, (err, content) => {
            // console.log('content', content);
            this.setState({ [`list${geotype}`]: content }, this.setDefaults)
        } );
    }

    setDefaults = () => {
        if(!this._defaults_are_set){
            // console.log('[DropdownHometown] SETTING DEFAULTS:', this.props.value);

            if(this.props.value && this.props.value !== 'All-Locations'){
                const [city, state, country] = this.props.value.split('*');
                // const value = this.props.split('*');
                const {geotype} = this.state;
                if(state || country){
                    const results = this.state['list'+geotype];
                    const lowercasedGeotype = geotype.toLowerCase();

                    // country obj.CountryThreeCharCode
                    // state obj.StateCode

                    let objKey, value;
                    if(geotype==='State') {
                        objKey = 'StateCode';
                        value = state;
                    }
                    else if(geotype==='Country') {
                        objKey = 'CountryThreeCharCode';
                        value = country;
                    }

                    // console.log('[DropdownHometown] SETTING DEFAULTS step 2:', geotype, value);
                    
                    const obj = results && results.hits && results.hits.find( o => o[objKey] === value);
                    if(obj && obj.objectID){
                        this.setState({
                            selectedOptionId: obj.objectID
                        })
                    }
                }

            }
            
            
            this._defaults_are_set = true;
        }
    }


    getTitle(geotype, obj){
        // console.log("obj",obj);
        const countryFlag =  obj && obj.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));
        if(!geotype || !obj) return 'All Locations';
        if(geotype==='Country') return `${countryFlag} ${obj.CountryName}`;
        if(geotype==='City') {
            if (!!obj.StateCode) {
                return `${countryFlag} ${obj.City} • ${obj.StateCode}`;
            } else {
                return `${countryFlag} ${obj.City} • N/A`;
            }
        }
        if(geotype==='State') return `${countryFlag} ${obj.StateName}`;
        return 'All Locations';
    }
    getTooltipTitle(geotype, obj){
        // console.log("obj tooltip",obj);
        if(!geotype || !obj) return 'All Locations';
        if(geotype==='Country') {
            // console.log("obj.CountryName",obj.CountryName)
            const newCountry = obj.CountryName && obj.CountryName.length > 20 ? obj.CountryName : "";
            // console.log("newCountry",newCountry)
            return newCountry;
        }
        if(geotype==='City') {
            

            // console.log("geotype",geotype);
            // console.log("obj.City",obj.City);
            // console.log("obj.StateCode",obj.StateCode);
            const city = obj.City;
            const state = !!obj.StateCode ? obj.StateCode : 'N/A';
            // console.log("state",state);

            const cityState = city + ' • ' + state;
            // console.log("cityState",cityState,cityState.length);

            const newCity = cityState.length > 20 ? cityState : "";
            return `${newCity}`;
    
        }
        if(geotype==='State') {
            return null;
          
            return obj.StateName;
        }
        return 'All Locations';
    }

    getShortTitle(geotype, obj){
        // console.log("obj",obj);
        // if(!obj) return null;
        const countryFlag =  obj && obj.CountryTwoCharCode && obj.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));
        if(!geotype || !obj) return 'All Locations';
        if(geotype==='Country') {
            // console.log("obj.CountryName",obj.CountryName)
            const result = obj.CountryName && obj.CountryName.length > 20 ? (obj.CountryName.substring(0,10) + ' ... ' + obj.CountryName.substring(obj.CountryName.length-8,obj.CountryName.length) ) : obj.CountryName;
            return `${countryFlag} ${result}`;
        }
        // if(geotype==='City') {
            

        //     // console.log("geotype",geotype);
        //     // console.log("obj.City",obj.City);
        //     // console.log("obj.StateCode",obj.StateCode);
        //     const city = obj.City;
        //     const state = !!obj.StateCode ? obj.StateCode : 'N/A';
        //     // console.log("state",state);

        //     const cityState = city + ' • ' + state;
        //     // console.log("cityState",cityState,cityState.length);

        //     const newCity = cityState.length > 20 ? (city.substring(0,10) + ' ... ' + city.substring(city.length-8,city.length) ) : city;
        //     return `${countryFlag} ${newCity} • ${state}`;
    
        // }
        if(geotype==='State') return `${countryFlag} ${obj.StateName}`;
        return 'All Locations';
    }

    
    

    render() {
        const { open, geotype, selectedOptionId } = this.state;
        const { disabled, value } = this.props;
        // console.log('this.props',this.props)
        // const selectedOption = options.find( o => o.objectID === selectedOptionId );

        const filterText = this.state[`filter${geotype}`];
        const results = this.state[`list${geotype}`];
        const list = results ? results.hits : [];

        let title = 'All Locations';
        // let tooltipTitle = '';
        // console.log("geotype",geotype);
        if(geotype){
            const results = this.state['list'+geotype];
            const obj = results && results.hits && results.hits.find( o => o.objectID === selectedOptionId);
            // title = this.getTitle(geotype, obj);
            title = this.getShortTitle(geotype, obj);
            // tooltipTitle = this.getTooltipTitle(geotype, obj);
        }

       
        const tooltipTextVal = disabled ? null :  <TooltipText>Filter by...</TooltipText>;

        // if(value && typeof value==='string'){
        //     title = value;
        // }

        return (
            // <div className="DropdownContainer DropdownHometown tooltip">
            <div className={classNames("DropdownContainer","DropdownHometown", {tooltip: !disabled  })}>
                {tooltipTextVal}
                <div className={classNames("DropdownValue", {disabled })} onClick={this.open}>
                    
                    <em>{title}</em>
                    <span className="chevron"><img src={chevronIcon} alt='dropdown' /></span>
                </div>

                <div 
                    className={classNames('DropdownList', { open })} 
                    // onMouseLeave={this.close}
                    >
                    <ul className="AllLocationsOption">
                        {
                            <Option
                                selected={ !geotype || !selectedOptionId || selectedOptionId==='all'}
                                onClick={this.select()} 
                                title="All Locations"
                                />
                        }
                    </ul>

                    {/* <ToggleGeotype titles={[ 'Country', 'City', 'State' ]} value={geotype} onChange={this.onGeotypeChange} /> */}
                    <ToggleGeotype titles={[ 'Country', 'State' ]} value={geotype} onChange={this.onGeotypeChange} />

                    <div className="FilterInput">
                        <input placeholder="Filter..." value={filterText || ''} onChange={this.updateFilterText(geotype)} />
                    
                        {/* { !!filterText && <div data-tip="Clear" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDark" */}
                        { !!filterText && <div 
                            className="IconSwappable ClearIcon" onClick={this.clearSearch}>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div> }
                    </div>
                    

                    <ul className="LocationOptions">
                        { list && list.map( option  => {
                            let title = this.getShortTitle(geotype, option);
                            let tooltipTitle = this.getTooltipTitle(geotype, option);

                            return (<Option
                                selected={ option.objectID === selectedOptionId }
                                key={option.objectID} 
                                onClick={this.select(geotype, option)} 
                                title={title}
                                tooltipTitle={tooltipTitle}
                                {...option} />)
                        })}
                    </ul>
                </div>
                { open && ReactDOM.createPortal(
                    <div className="DropdownCover" onClick={this.close}></div>
                , this.coverEl)}
            </div>
        );
    }
}

export default DropdownHometown;
