import React, {Component} from 'react'
import classNames from 'classnames'
import {
    isIPad13,
    isMobile
  } from "react-device-detect";

class TooltipText extends Component {
    handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
    }
    render(){
        const pageNumber = this.props.pageNumber;
        // console.log("pageNumber",pageNumber);
        return (
            (isMobile || isIPad13) ? 
                    null :
                    <span  className={classNames("tooltiptext",{welcome: pageNumber===4})} onClick={this.handleClick}>{this.props.children}</span>
        )
    }
}

export default TooltipText