import React, { Component, useState } from "react";
import * as Sentry from '@sentry/browser';
import SuperAdminOptions from "./SuperAdminOptions";
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'

import moment from "moment";
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
// import landscape from "/images/city-generic/generic-02.svg";
import "./SuperAdminPage.scss";
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'
import {formatNumber} from '../../timeUtils'
import { useForm } from "react-hook-form";

import imgEmailBlue from '../../images/mail-01.svg';
import imgEmailRed from '../../images/mail-02.svg';
import imgEmailBlack from '../../images/mail-03.svg';
import axios from 'axios';

import * as allRacesActions from '../../actions/allRaces'


import openBlue from '../../images/open-01.svg';
import openGray from '../../images/open-02.svg';

import Headroom from 'react-headroom';
import SuperAdminPageUtilityEmailHash from "./SuperAdminPageUtilityEmailHash";
import SuperAdminPageUtilityAddEmailHashSubstitution from "./SuperAdminPageUtilityAddEmailHashSubstitution";



class SuperAdminPage extends Component {

  state = {
    // currentInterval: 0
    superAdminPageOption: 'summary',
  }

  

  updateReportType = superAdminPageOption => this.setState({superAdminPageOption})


  
  componentDidMount() {
  

  }



  render() {

    const { requestsPending, fbuserProfile, titles, value } = this.props;
    const { superAdminPageOption } = this.state;




    if (requestsPending) {
      return null;
    }

    let hasAccess = false;

    if (fbuserProfile && fbuserProfile.accessRoles && fbuserProfile.accessRoles.includes("SuperAdmin")) {
        hasAccess = true;
    } 

    // console.log("this.props.match",this.props.match);
    // const page = this.props.match.path.split('/')[1];
    
    const page = "Super Admin";
    return (
      <div className="SuperAdminPage">
        <Headroom>
          <Header title={`SUPER ADMIN`} page={page}/>
        </Headroom>

        {/* <Header page={page}/> */}

        <div className="MainContent">
            <div className="container">

              <p1>&nbsp;</p1>

              {!hasAccess && (
                <p>You do not have access to the page or event.</p>
              )}

              {hasAccess && (
                <>
                  <SuperAdminOptions value={superAdminPageOption} onChange={this.updateReportType} />

                  {superAdminPageOption === 'utilities' && (
   
                    <>
                      <SuperAdminPageUtilityEmailHash />
                      <SuperAdminPageUtilityAddEmailHashSubstitution />
                    </>
                  )}
                </>
              )}

              

                
            </div>
          
        </div>

        <div className="SuperAdminPageFooterBackground" style={{
          backgroundImage: `url(/images/city-generic/generic.svg)`
        }}>
          
        </div>

        <Footer />

      </div>
    );
  }
}



const mapStateToProps = ({raceEvents, raceEventsFilter, requestsPending, fbuserProfile}) => ({raceEvents, raceEventsFilter, requestsPending, fbuserProfile})


// const mapState = ({fbuser, fbuserProfile})=>({fbuser, fbuserProfile})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
//   setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
//   incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
//   decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
//   sponsorsActions: bindActionCreators(sponsorsActions, dispatch),

    allRacesActions: bindActionCreators(allRacesActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminPage);





