import React, { Component } from "react";
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import {Link} from 'react-router-dom';
// import ToggleUnits from "../../components/ToggleUnits/ToggleUnits";
// import PageInfoModal from "../../components/PageInfoModal/PageInfoModal";
import Header from "../../components/Header/Header";
import FinishContent from "./FinishContent";
import Footer from '../../components/Footer/Footer'
// import SearchModal from '../../components/SearchModal/SearchModal';
import ReactTooltip from 'react-tooltip'
import Headroom from 'react-headroom';
// import FinishSidebar from './FinishSidebar'
// import classNames from 'classnames'

// import LoginPopup from "../../components/Login/LoginPopup";


// import imgFb from '../../images/fb.svg';
// import imgTw from '../../images/tw.svg';
// import imgEm from '../../images/em.svg';
import { FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon, TwitterShareButton, TwitterIcon } from 'react-share';

import Beacon from '../../components/Beacon/Beacon'
import { setBeaconDone } from '../../actions/settings'

import * as athletesByRacebibActions from '../../actions/athletesByRacebib';
import * as sponsorsActions from '../../actions/sponsors';

import "./Finish.scss";



class Finish extends Component {




  componentDidMount() {

    // window.analytics.page();

    window.scrollTo(0, 0);
    
    this.props.athletesByRacebibActions.getAthlete(
      this.props.match.params.raceCode,
      this.props.match.params.athleteBib
    );

    window.document.body.classList.add('finish');

    this.loadTabImages();

  }


  loadTabImages = () => {
    const { raceCode } = this.props.match.params;

    // console.log("raceCode fin",raceCode);

    this.props.sponsorsActions.getSponsors(raceCode);
  }
  
  componentWillUnmount(){
    window.document.body.classList.remove('finish');
  }

  beaconDone = beaconId => e => {
    // console.log('beaconDone onclick', beaconId);
    this.props.settingsActions.setBeaconDone(beaconId)
  }

  onDownloadPDFClick = (e) => {
    this.beaconDone('downloadPDF')();
    // fake spinner
    this.props.incrementPendingRequests();
    window.setTimeout(()=>{
      this.props.decrementPendingRequests();
    },1000)
  }
  


  render() {

    const { athletesByRacebib, race, match, requestsPending, sponsors, settings } = this.props;
    const { raceCode, athleteBib } = match.params;



    if (requestsPending) {
      return null;
    }

    const sponsorsData = sponsors[raceCode] || {};


    const slug = raceCode + '--' + athleteBib;
    // console.log("slug",slug)
    // console.log("athletesByRacebib",athletesByRacebib)
    const athlete = athletesByRacebib[slug] && athletesByRacebib[slug].main_data;

    // console.log("sponsor",sponsorsData);
    if (!athlete) {
      return null;
    }
    // console.log("2")



    const shareUrl = 'https://myrace.com' + match.url;
    const welcomeUrl = `https://myrace.com/welcome/${race.RaceCode}/athletes/${athlete.MyRace.Bib}`; 

    
    // console.log("Athlete Fin cert",athlete);

    // const imageFb = (
    //   <img src={imgFb} alt="Facebook" title="Facebook" />
    // );
    // const imageTw = (
    //   <img src={imgTw} alt="Twitter" title="Twitter" />
    // );
    // const imageEm = (
    //   <img src={imgEm} alt="Email" title="Email" />
    // );

    const isSidebarOpen = settings.isSidebarOpen;

    const subject = `${race.RaceName} Race Results!`
    const bodyEmail = `${athlete.MyRace.FirstName} ${athlete.MyRace.LastName} (#${athlete.MyRace.Bib})
${athlete.OfficialTime}

Check out this finisher results page:
${shareUrl}


Check out the complete race results:
${welcomeUrl}

@MyRaceHQ
`;

  const bodyTwitter = `${athlete.MyRace.FirstName} ${athlete.MyRace.LastName} (#${athlete.MyRace.Bib})
${athlete.OfficialTime}


Check out this @MyRaceHQ finisher results page:
`;

const page = this.props.match.path.split('/')[1];

    return (
      <div className="Finish">

        <ReactTooltip  /> 
        
        <Headroom>
          <Header title={`FINISH CERTIFICATE`} page={page} race={race} athlete={athlete} />
        </Headroom>

        
        <FinishContent isSidebarOpen={isSidebarOpen} athlete={athlete} race={race} settings={settings} {...sponsorsData} />

        <div className="FinishFooter">
          {/* <div></div> */}
           
          {/* <div data-tip="MyRace • Race Results" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipNoArrow"
            className="MainUrl">
            <Link to="/">WWW.MYRACE.COM</Link>
          </div> */}

          <div data-tip="Download as PDF" data-effect='float' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipNoArrow"
            className="DownloadUrl">
           
            <a onClick={this.onDownloadPDFClick} href={`https://us-central1-my-race-com.cloudfunctions.net/createcertpdf?racecode=${raceCode}&bib=${athlete.MyRace.Bib}`}>Download as PDF</a>
          </div>
        </div>

        <div className="socialSharing container flexgrow">
        

          <div className="socialButtonContainer" onClick={this.beaconDone('socialSharing')}>
            <Beacon id="socialSharing" text={"Share on Social"} direction="right" style={{ top:'13px', left:'-40px'}} />
            <FacebookShareButton
              url={shareUrl}
              quote={bodyEmail}
              className="socialButton Demo__some-network__share-button">
              <FacebookIcon
                size={40}
                round />
            </FacebookShareButton>
          </div>

          <div className="socialButtonContainer" onClick={this.beaconDone('socialSharing')}>
            <TwitterShareButton
              url={shareUrl}
              title={bodyTwitter}
              className="socialButton Demo__some-network__share-button">
              <TwitterIcon
                size={40}
                round />
            </TwitterShareButton>
          </div>

          <div className="socialButtonContainer" onClick={this.beaconDone('socialSharing')}>
            <EmailShareButton
              url={shareUrl}
              subject={subject}
              body={bodyEmail}
              className="socialButton Demo__some-network__share-button">
              <EmailIcon
                size={40}
                round />
            </EmailShareButton>
          </div>

        </div>
        

        <Footer />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  race: state.raceSelected,
  sponsors: state.sponsors,
  settings: state.settings,
  requestsPending: state.requestsPending
})

const mapDispatchToProps = dispatch => ({

  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
  sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
  settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
  incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
  decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
})



export default connect(mapStateToProps, mapDispatchToProps)(Finish);
