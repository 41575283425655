import React, { useState } from 'react';
import imgEmailBlue from '../../images/mail-01.svg';
import imgEmailRed from '../../images/mail-02.svg';
import imgEmailBlack from '../../images/mail-03.svg';
import {connect} from 'react-redux'
import { useForm } from "react-hook-form";
import classNames from 'classnames'
import axios from 'axios';

const SuperAdminPageUtilityAddEmailHashSubstitution = () => {
    const { register, errors, triggerValidation, handleSubmit } = useForm();

    const [emailforHash, setEmailForHash] = useState(null);

    const [successText, setSuccessText] = useState(null)

    const addEmailHashSubstitute = (emailHashSourcePattern,emailHashNew) => {
        // const { email } = this.state;
        //const { users } = this.props;
        // const {athleteHash} = this.props.match.params;
        // const athleteHash = '2b10my6m6ff9l4ehmxmnecfcfod5mzxcu8tbaqawtdjpyuk1ezkufaw'
        // this.setState({isValidEmailHash:true})
        // return;

        // this.setState({ uncertainState: true });
        

        // this.props.incrementPendingRequests();


        axios.get('https://us-central1-my-race-com.cloudfunctions.net/addEmailHashSubstitution', {
            params: {
                emailhashsourcepattern: emailHashSourcePattern,
                emailhashnew: emailHashNew
                // emailhashsourcepattern: '2b10my6m6ff9l4ehmxmnecfcfo6gm6hbopiki0ngx6uomav96qcovwie|*',
                // emailhashnew: '2b10my6m6ff9l4ehmxmnecfcfo6senxgapg4ygqkmuxmft0s2yfetb1ec'
            }
        }).then( async (response) => {
            // this.props.decrementPendingRequests();
            // this.setState({ uncertainState: false });
            // console.log("RESPONSE",response.data);
            const responseNew = response.data;

            if (responseNew && responseNew.success) {
                setSuccessText("Success");
            } else {
                setSuccessText("Error");
            }



            // const isValid = !!AtLeastOneAthlete;
            // const {EmailHash} = response.data

            // const isValid = response.data.valid;
            // this.setState({
            //     isValidEmailHash:isValid,
            //     EmailHash
            // })
            
            // !isValid && this.shakeLock();


            
            
        }, (error) => {
            // this.props.decrementPendingRequests();
            console.log(error);
          });

    }

    const onSubmit = data => {
        const {emailHashSourcePattern, emailHashNew} = data;
        
        addEmailHashSubstitute(emailHashSourcePattern,emailHashNew);


    }

    return (
        <div className="SuperAdminPageUtilities">

            <div className="UtilityCard">

                {/* <h1>Get EmailHash</h1>
                <div className="Label"><i></i><span>Get EmailHash from Email</span><i></i></div> */}

                <p className="tagline">Gets the Email Hash value for a given email address:</p>
                
            
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={classNames("control","email", {
                        valid: true,
                        invalid: false,
                        })}>
                        {/* <div className="ControlIcon">
                            <div className="IconSwappableValid">
                                <div className="icon" style={{ backgroundImage: `url(${imgEmailBlue})`}}/>
                                <div className="invalid" style={{ backgroundImage: `url(${imgEmailRed})`}}/>
                                <div className="valid" style={{ backgroundImage: `url(${imgEmailBlack})`}}/>
                            </div>
                        </div> */}

                        <input name="emailHashSourcePattern" type="text" 
                            ref={register({})}
                            autoComplete="off"
                            placeholder="Type in Email Hash Pattern..."
                            />

                        <input name="emailHashNew" type="text" 
                            ref={register({})}
                            autoComplete="off"
                            placeholder="Type in your Email Hash New..."
                            />
                    </div>


                    <div className="buttons">
                        <button type="submit">Go</button>
                    </div>

                    <p className="tagline">{successText}</p>
                </form>
            </div>
        </div>

    )

}

const mapStateToProps = ({settings})=>({settings});

const mapDispatchToProps = dispatch => ({
    incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
    decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
})

// const mapDispatchToProps = (dispatch) => ({

// })
  
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminPageUtilityAddEmailHashSubstitution);