import React, { Component } from "react";
import Modal from 'react-modal';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
// import ReactTooltip from 'react-tooltip'
import "./InfoModal.scss";

import { bindActionCreators } from "redux";
import { initBeacon } from '../../actions/fbuser'


import infoIconHov from "../../images/info-01.svg";
import infoIcon from "../../images/info-03.svg";
import TooltipText from '../../components/Tooltip/TooltipText'

// import Beacon from '../Beacon/Beacon'

Modal.setAppElement('#root');



class InfoModal extends Component {
    
    
    openModal = () => {
        // ReactTooltip.hide();
        this.props.openModal(this.props.id);

        // this.props.fbuserActions.initBeacon();
        // window.Beacon("close");

        // window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');

        // window.Beacon("article", "15-results-summary", { type: "sidebar" });



    }
    closeModal = () => {
        this.props.closeModal(this.props.id);
    }
    
   
    render() {
        // const { title } = this.state;
        const { settings, id } = this.props;
        // const { isElementInfoModalOpen } = settings;

        // const {isElementInfoModalOpen} = settings;

        const isInfoModalOpen = !!settings[`isInfoModalOpen_${id}`];

        // console.log("race",race.raceName);
        return (
        <div className="InfoModal">
            <div className="IconSwappable info_button_modal tooltip" onClick={this.openModal}>
                {/* // className="IconSwappable info_button_modal" onClick={this.openModal}> */}
                <TooltipText>Help</TooltipText>
                <div className="icon" style={{ backgroundImage: `url(${infoIcon})`}}/>
                <div className="hover" style={{ backgroundImage: `url(${infoIconHov})`}}/>
            </div>
            
             <Modal
                isOpen={isInfoModalOpen}

                // onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                contentLabel="Info Modal"
                >

                <div className="modal_content">
                    <button onClick={this.closeModal} className="close">close</button>

                    <h3><div className="info_button_modal">?
                        </div>{ this.props.title || 'Read this!' }
                    </h3>

                    { this.props.children }
                </div>
            </Modal>

        </div>
        );
    }
}

const mapStateToProps = state => ({
    athlete: state.athleteSelected,
    race: state.raceSelected,
    user: state.user,
    settings: state.settings,
})

const mapDispatch = dispatch => ({
    openModal: (id)=>dispatch({type:'SET_INFO_MODAL_OPEN', id, open: true}),
    closeModal: (id)=>dispatch({type:'SET_INFO_MODAL_OPEN', id, open: false}),
    fbuserActions: bindActionCreators({initBeacon}, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatch)(InfoModal));


