import React, {Component } from 'react'
import './Beacon.scss'
import {connect} from 'react-redux'
import classNames from 'classnames'


class Beacon extends Component {
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         done: !!localStorage.getItem(`beacon_${this.props.id}`)
    //     }
    // }
    componentDidMount() {
        if(window.localStorage.getItem(`beacon_${this.props.id}`)){
            // console.log('DONE', this.props.id)
            // dispatch redux to remove this beacon --- this.props.setBeaconDone(this.props.id)
            this.props.setBeaconDone(this.props.id);
        }
    }
    
    render(){
        const { id, text, style, settings, direction="right" } = this.props;
        const { beaconsDone } = settings;



        if(beaconsDone[id]) {
            return null;
        }
        return (
            <div className={classNames("BeaconMR")}
                onClick={this.done}
                ref="reffoo" 
                // data-tip={text} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
                style={style}>
                    <div className={classNames("pointer", direction )}/>
                
                {
                    text.length > 0 ?  
                        <div className="BeaconTooltip">
                       
                            <div className={classNames("BeaconText", direction )} dangerouslySetInnerHTML={{__html:text}}/> 
                        </div>
                    : null
                }

                
            </div>
        )
    }
}

const mapState = ({settings}) => ({ settings });
const mapDispatch = (dispatch) => ({
    setBeaconDone: (beaconId) => dispatch( { type: 'SET_BEACON_DONE', beaconId })
})
export default connect(mapState, mapDispatch)(Beacon);