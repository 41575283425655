import React, { Component } from 'react'
import {findDOMNode} from 'react-dom'
import { withRouter, Link } from 'react-router-dom'
import back from "../../images/back-5.svg"
import backDisabled from "../../images/back-6.svg"
import next from "../../images/next-5.svg"
import nextDisabled from "../../images/next-6.svg"
import ReactTooltip from 'react-tooltip'
import Beacon from '../Beacon/Beacon'
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'

class PagesNavigation extends Component {

    componentDidMount(){

        // this.startListeners();
        

    }

    componentWillUnmount(){
        // this.stopListeners();

    }

    startListeners = () => {
        window.document.addEventListener('keyup', this.onKeyup)
    }
    stopListeners = () => {
        window.document.removeEventListener('keyup', this.onKeyup)
    }

    onKeyup = (e) => {
            // console.log('KEYUP', e.key, e);
            const { key } = e;
            if(key==='ArrowLeft'){
                const link = this.getNextPrevLink('prev');
            if(!link) return;
                this.props.history.push(link);
        }
        if(key==='ArrowRight'){
            const link = this.getNextPrevLink('next');

            if(!link) return;
            this.props.history.push(link);
        }
    }

    getNextPrevLink = (nextOrPrev) => {
        const {pages, activePageIndex, settings: { lastSelectedAthleteBib: Bib, lastSelectedRaceCode: RaceCode, lastSelectedEventCode: EventCode }} = this.props;

        if(!Bib || !RaceCode || !EventCode) { // Bib, RaceCode, EventCode
            return null;
        }

        //console.log('PAGE URL', this.props.location.href)
        // const pageIndex = pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));

        // console.log("pageIndex",pageIndex);
        if(activePageIndex === -1) { 
            return null;
        }

        const pageNumber = activePageIndex ? activePageIndex + 1 : 1;



        let nextLinkUrl = ''; let prevLinkUrl = '';
        let isFirst = true;
        let isLast = true;
    

        if(pages[activePageIndex+1]) {
            nextLinkUrl = pages[activePageIndex+1].url ;
            isLast = false;
            // nextImg = next;
        }

        // if(typeof nextLinkUrl==='function') { nextLinkUrl = nextLinkUrl({Bib, RaceCode, EventCode}); }
        
        // if(pages[pageIndex-1]){
        //     prevLinkUrl = pages[pageIndex-1].url;
        //     isFirst = false
        //     // prevImg = back;
        // }
        // if(typeof prevLinkUrl==='function') { prevLinkUrl = prevLinkUrl({Bib, RaceCode, EventCode}); }

    
        if(nextOrPrev==='prev'){
            if(pages[activePageIndex-1]){
                prevLinkUrl = pages[activePageIndex-1].url;
                isFirst = false
            // prevImg = back;
            }
            if(typeof prevLinkUrl==='function') { 
                return prevLinkUrl({Bib, RaceCode, EventCode}); 
            }
               
        }
        if(nextOrPrev==='next'){
            if(activePageIndex >= pages.length - 1){
                return null;
              }
          return nextLinkUrl({Bib, RaceCode, EventCode});
        }
    
    }


    

    
    beaconDone = beaconId => e => {
        // console.log('beaconDone onclick', beaconId);
        if (beaconId === 1) {
            this.props.settingsActions.setBeaconDone("pageNavigation")
        }
        else if (beaconId === 2) {
            this.props.settingsActions.setBeaconDone("pageNavigation2")
        }
        else if (beaconId === 3) {
            this.props.settingsActions.setBeaconDone("pageNavigation3")
        }
        else if (beaconId === 4) {
            this.props.settingsActions.setBeaconDone("pageNavigation4")
        }
    }
    
    render(){
        const { pages, activePageIndex} = this.props;

        const { settings: { lastSelectedAthleteBib: Bib, lastSelectedRaceCode: RaceCode, lastSelectedEventCode: EventCode }} = this.props;

        if(!Bib || !RaceCode || !EventCode) { // Bib, RaceCode, EventCode
            return null;
        }

        //console.log('PAGE URL', this.props.location.href)
        // const pageIndex = pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));

        // console.log("activePageIndex",activePageIndex);
        if(activePageIndex === -1) { 
            return null;
        }

        const pageNumber = activePageIndex ? activePageIndex + 1 : 1;





        let nextLinkUrl = ''; let prevLinkUrl = '';
        let isFirst = true;
        let isSecond = false;
        let isThird = false;
        let isFourth = false;
        let isLast = true;
  
    

        if(pages[activePageIndex+1]) {
            nextLinkUrl = pages[activePageIndex+1].url ;
            isLast = false;
            // nextImg = next;
        }

        if(typeof nextLinkUrl==='function') { nextLinkUrl = nextLinkUrl({Bib, RaceCode, EventCode}); }
        
        if(pages[activePageIndex-1]){
            prevLinkUrl = pages[activePageIndex-1].url;
            isFirst = false
            // prevImg = back;
        }
        if(pageNumber === 2) {
            isSecond = true;
        }
        if(pageNumber === 3) {
            isThird = true;
        }
        if(pageNumber === 4) {
            isFourth = true;
        }
        if(typeof prevLinkUrl==='function') { prevLinkUrl = prevLinkUrl({Bib, RaceCode, EventCode}); }
        console.log("isFirst",isFirst, isSecond)
    
        return (
            <div className="PagesNavigation rightbar-container-block">
                <ReactTooltip multiline />
                {
                    

                    isFirst ?
                    
                    <img src={backDisabled} />
                    :
   
                    <Link to={prevLinkUrl}>
                        <span className="background" />
                        <img src={back} />
                    </Link>
                   
               

                }
                <strong>{pageNumber}</strong><span>of</span><strong>{pages.length}</strong>
                {
                    isLast ?
                    <img src={nextDisabled} />
                    :
                    

                    <Link 
                        ref="refbar"
                        // data-tip="Next Page" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                        to={nextLinkUrl}
                        // onClick={this.beaconDone('pageNavigation')}
                        onClick={this.beaconDone(pageNumber)}
                        >
                        <span className="background" />
                        <img src={next} />
                    </Link>
                    

                }
                {
                    isFirst ? 
                    <>
                    <Beacon id="pageNavigation" text='Proceed to Results Summary' direction="left" style={{ top:'21px', left:'105px'}} />
                    </>
                    // <span ref="reffoo" data-tip={hotspotText} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" className="beacon"></span>
                    :
                    null
                }
                {
                    isSecond ? 
                    <>
                    <Beacon id="pageNavigation2" text='Proceed to Segment Analysis' direction="left" style={{ top:'21px', left:'117px'}} />
                    </>
                    // <span ref="reffoo" data-tip={hotspotText} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" className="beacon"></span>
                    :
                    null
                }
                {
                    isThird ? 
                    <>
                    <Beacon id="pageNavigation3" text='Proceed to Next Page' direction="left" style={{ top:'21px', left:'118px'}} />
                    </>
                    // <span ref="reffoo" data-tip={hotspotText} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" className="beacon"></span>
                    :
                    null
                }
                {
                    isFourth ? 
                    <>
                    <Beacon id="pageNavigation4" text='Proceed to Next Page' direction="left" style={{ top:'21px', left:'120px'}} />
                    </>
                    // <span ref="reffoo" data-tip={hotspotText} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" className="beacon"></span>
                    :
                    null
                }
                

            </div>

        )
    }
}


const mapState = state => ({
    settings: state.settings,
})
const mapDispatch = dispatch => ({
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
})
export default withRouter(connect(mapState, mapDispatch)(PagesNavigation))