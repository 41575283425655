import { createSelector } from 'reselect'



export const getFilterKey = ({
    RaceCode,perPage,overallFilter, divisionFilter, locationFilter, splitTypeFilter, sorterCol, sorterColDir, onlyFavorite, fbuser
}) => {

    if(onlyFavorite && fbuser) {
        return `${RaceCode}|fav|${fbuser.uid}|col:${sorterCol}|dir:${sorterColDir}`
    }
    
    return `${RaceCode}|perPage:${perPage}|overall:${overallFilter}|division:${divisionFilter}|location:${locationFilter}|splitType:${splitTypeFilter}|col:${sorterCol}|dir:${sorterColDir}`;
}

//sorterColDir, sorterCol



const getAthletesByFilter = (state) => {

    if(!state.raceSelected) return {};
    
    const {RaceCode} = state.raceSelected;
        
    const { perPage, overallFilter, divisionFilter, locationFilter, splitTypeFilter, page, sorterCol, sorterColDir, onlyFavorite } = state.raceAthleteFilter;
    
    const FILTERKEY = getFilterKey({ RaceCode, perPage, overallFilter, divisionFilter, locationFilter, splitTypeFilter, sorterCol, sorterColDir, onlyFavorite, 
        fbuser: state.fbuser
    });

    return state.athletesByFilter[FILTERKEY] || {};
}

export const getAthletesByFilterState = createSelector([getAthletesByFilter], props=>props)