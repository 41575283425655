import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import ReactTooltip from 'react-tooltip'
import "./ToggleAgeGrade.scss";
import imgUnchecked from '../../images/check-1.svg';
import imgCheckHover from '../../images/check-2.svg';
import imgChecked from '../../images/check-3.svg';
import Beacon from '../Beacon/Beacon'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'


class ToggleAgeGrade extends Component {



  switch = () => {
    
    const { ageGrade } = this.props.settings; 
    // window.analytics.track('Age Grade Set', {
    //   "Age Grade": ageGrade === "age_grade_on" ?  "age_grade_on" : "age_grade_off"
    // });

    // console.log("ageGrade is ",ageGrade)

    this.props.setSwitch(
      this.props.settings.ageGrade === "age_grade_off" ? "age_grade_on" : "age_grade_off"
    );

    // Tooltip.open=false;
    ReactTooltip.hide();

    this.props.settingsActions.setBeaconDone("ageGrade")

  };

  // switchOn = () => {
  //   this.props.setSwitch("age_grade_on");
  // };

  // switchOff = () => {
  //   this.props.setSwitch("age_grade_off");
  // };

  
  render() {
    const { settings } = this.props;
    const on = settings.ageGrade === "age_grade_on";
    const factor = settings.ageGradeFactor;
    // console.log("FACTOR",factor);
    const ageGradeCheckbox = (
      <>

        {/* <div className="CheckboxControl" 
          data-tip={on ? 'Disable' : 'Enable'} 
          data-effect='solid' 
          data-delay-show='1000' 
          data-place='bottom' 
          data-class="MyRaceTooltip"
          > */}
        <div className="CheckboxControl" >
          {/* <Beacon id="ageGrade" text="AG Toggle" style={{ top:'10px', left:'-10px'}} /> */}
          <Beacon id="ageGrade" text="Age Grade" direction="right" style={{ top:'0px', left:'-30px'}} />
          <input disabled={!settings.ageGradeFactor === 1} onChange={this.switch} type="checkbox" name="checkbox_ag" id="checkbox_ag1" checked={on} />
          <label htmlFor="checkbox_ag1">
            <img src={imgUnchecked} className="checkbtn unselected" alt="age grade off" />
            <img src={imgCheckHover} className="checkbtn unselected_hover" alt="age grade hover"/>
            <img src={imgChecked} className="checkbtn selected" alt="age grade on"/>
            <span className="label_name">Age Grade</span> <span className="dot">•</span> <span className="factor numericFont">{factor}</span>
            </label>
        </div>
      </>

      )

    // console.log("Is On",on);
    // console.log("settings.ageGradeFactor",settings.ageGradeFactor)
    // console.log("factor",factor)
    return (
      <div className={classnames("toggle-age-grade-container", { on }) }>
        
          
       {/* { settings.ageGradeFactor === 1 ? null : ageGradeCheckbox} */}
       { ageGradeCheckbox}
      </div>
    );
  }
}

const mapStateToProps = s => ({ settings }) => ({ settings }); // this.props.settings

const mapDispatchToProps = dispatch => ({
  setSwitch: ageGrade => dispatch({ type: "SET_AGE_GRADE", ageGrade }), // this.props.setSwitch()
  settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleAgeGrade);
