import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {connect} from 'react-redux'
import * as athletesByRacebibActions from '../../actions/athletesByRacebib'

import { Bar } from '@nivo/bar'

// import overallCumulativeRankData from "./overall-cumul-rank.json";
// import overallRankDiffData from "./overall-rank-diff.json";
// import genderCumululativeRankData from "./gender-cumul-rank.json";


// https://nivo.rocks/storybook/?selectedKind=Bar&selectedStory=diverging%20stacked&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybooks%2Fstorybook-addon-knobs

class PassingStatisticsDetails extends Component {


  componentDidMount() {
    // window.analytics.page();
    
    this.props.athletesByRacebibActions.getAthlete(
      this.props.match.params.raceCode,
      this.props.match.params.athleteBib
    );

  }
  


  render() {
    const { athletesByRacebib, race, settings, requestsPending } = this.props;

    // console.log("passData",passData);
    // console.log("athleteRankingAndPassing",athleteRankingAndPassing);

    if (requestsPending) {
      return null;
    }


    const slug = this.props.match.params.raceCode + '--' + this.props.match.params.athleteBib;
    const athlete = athletesByRacebib[slug] && athletesByRacebib[slug].main_data;
    const athleteRankData = athletesByRacebib[slug] && athletesByRacebib[slug].ranking_data;

    console.log("athlete  Data",athlete);
    console.log("athlete Rank Data",athleteRankData);
    // console.log(process.env.REACT_APP_MAPBOX_API_KEY);


    return (
      <div>
        <div>

        <Bar
          width={900}
          height={500} 
          animate={true}

          margin={{
            top: 60,
            right: 80,
            bottom: 60,
            left: 80
          }}
          data={[
            {
              "pos": 20,
              "neg": 0,
              "interval": "0-5km"
            },
            {
              "pos": 10,
              "neg": 0,
              "interval": "5-10km"
            },
            {
              "pos": 0,
              "neg": -5,
              "interval": "10-15km"
            },
            {
              "pos": 0,
              "neg": -30,
              "interval": "15-20km"
            },
            {
              "pos": 0,
              "neg": -8,
              "interval": "20-25km"
            },
            {
              "pos": 5,
              "neg": 0,
              "interval": "25-30km"
            },
            {
              "pos": 2,
              "neg": 0,
              "interval": "30-35km"
            },
            {
              "pos": 0,
              "neg": 0,
              "interval": "35-40km"
            },
            {
              "pos": 0,
              "neg": -10,
              "interval": "40-Finish"
            },
            
          ]}
          indexBy="interval"
          keys={[
            'pos',
            'neg',
          ]}
          padding={0.4}
          labelTextColor="inherit:darker(1.2)"
          labelSkipWidth={16}
          labelSkipHeight={16}
          minValue={-40}
          maxValue={40}
          enableGridX
          enableGridY={false}
          label={"TEST"}
          axisTop={{tickSize: 0,tickPadding: 12}}
          axisBottom={{
            legend: 'USERS',
            legendPosition: 'middle',
            legendOffset: 50,
            tickSize: 0,
            tickPadding: 12
          }}
          axisLeft={null}
        //   axisRight={{format: format}}
          markers={[{
              axis: 'y',
              value: 0,
              lineStyle: {strokeOpacity: 0},
            },{
              axis: 'y',
              value: 0,
              lineStyle: {stroke: '#f47560',strokeWidth: 1},
            }]}
          colors={[
            '#26BB27',
            '#FF785E',
          ]}
        //   labelFormat={labelFormat}
        />

        
          {/* <Bar
            width={700}
            height={280}
            margin={{
              top: 60,
              right: 80,
              bottom: 60,
              left: 80
            }}
            // data={overallCumulativeRankData}
            data={overallRankDiffData}
            indexBy="interval"
            keys={[
              'Rank'
            ]}
            padding={0.2}
            // labelTextColor="inherit:darker(1.4)"
            labelTextColor="#FFF"
            labelSkipWidth={16}
            labelSkipHeight={16}
            colors="category10"
            // Property to use to determine node color. If a function is provided, it will receive current node data and must return a color.
            // colorBy={(id) => id[`RankColor`]}
            // color="#008dfb"
          />

          <Bar
            width={700}
            height={280}
            margin={{
              top: 60,
              right: 80,
              bottom: 60,
              left: 80
            }}
            data={genderCumululativeRankData}
            indexBy="interval"
            keys={[
              'Rank'
            ]}
            padding={0.2}
            // labelTextColor="inherit:darker(1.4)"
            labelTextColor="#FFF"
            labelSkipWidth={16}
            labelSkipHeight={16}
            colors="category10"
            colorBy="id"
            // color="#008dfb"
          /> */}
        </div>
      
      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  athleteRankingAndPassing: state.athleteRankingAndPassing,
  race: state.raceSelected,
  requestsPending: state.requestsPending
})

const mapDispatchToProps = dispatch => ({
  setAgeGradeFactor: ageGradeFactor => dispatch({type:'SET_AGE_GRADE_FACTOR', ageGradeFactor}),

  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PassingStatisticsDetails);
