import React, { Component } from "react";
import {Link, withRouter} from 'react-router-dom';
import logoimg from "../../images/logo-05.svg";
import {connect} from 'react-redux'
import SearchModal from '../SearchModal/SearchModal';
import "./ReportHeaderSameHometownAthletes.scss";


class ReportHeaderSameHometownAthletes extends Component {


  render() {
    const { header } = this.props;
  
    return (
      <div className="header ReportHeaderSameHometownAthletes">
        <div className="logo-container">

          <div className="logo-img-container">
            <Link to="/">
              <img src={logoimg} alt="MyRace" />
            </Link>
          </div>
        </div>

          <>
            <div className="athlete-info-container">
              
                {header}     
              
            </div>
            
            <div>
                <SearchModal />
                {/* <img src={searchIcon} className="searchIcon"/> */}
            </div>
          </>
        
      </div>
    );
  }
}

export default withRouter(connect()(ReportHeaderSameHometownAthletes));
