import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import { connect } from 'react-redux'




import airshipBlimpPNG from '../../images/craft-07@3x.png'
import craft1 from '../../images/craft-01.svg'
import craft2 from '../../images/craft-02.svg'
import craft3 from '../../images/craft-03.svg'
import craft4 from '../../images/craft-04.svg'
import craft5 from '../../images/craft-05.svg'
import craft6 from '../../images/craft-06.svg'
import craft7 from '../../images/craft-07.svg'
import craft8 from '../../images/craft-08.svg'
import craft9 from '../../images/craft-09.svg'
import craft10 from '../../images/craft-10.svg'
import craft11 from '../../images/craft-11.svg'
import harnessSVG from '../../images/harness.svg'
import imgRunBetter from '../../images/adsImages/RunBetter.png'
import imgGarmin from '../../images/adsImages/garmin.png'
import imgGu from '../../images/adsImages/gu.png'
import imgRevuLogo from '../../images/adsImages/revolugo.png'
import imgCapitalOne from '../../images/adsImages/2_0001sCapital-One.png'
import styled from 'styled-components'
import classnames from 'classnames'



const useAirship = (airshipType) => {

    
    const adImgAd = imgRunBetter; 
    // const imgAds = imgGarmin; 
    // const imgAds = imgGu; 
    // const imgAds = imgRevuLogo; 
    
    let adTop = 0;
    let adImgAirshipBody = null;
    let adSpeed = 10;
    let adVertical = false
    
    switch(airshipType) {
        case 1: //small helicopter
            adTop=5;
            adImgAirshipBody=craft1;
            adSpeed = 400;
            break;
        case 2: //drone
            adTop=5;
            adImgAirshipBody=craft2;
            adSpeed = 400;
            adVertical = true;
            break;
        case 3: //big helicopter
            adTop=18;
            adImgAirshipBody=craft3;
            adSpeed = 400;
            break;
        case 4: //red airplane
            adTop=10;
            adImgAirshipBody=craft4;
            adSpeed = 40;
            break;
        case 5: //jet fighter
            adTop=2;
            adImgAirshipBody=craft5;
            adSpeed = 20;
            break;
        case 6: //green airplane
            adTop=16;
            adImgAirshipBody=craft6;
            adSpeed = 20;
            break;
        case 7: //blimp
            adTop=5;
            adImgAirshipBody=craft7;
            adSpeed = 100;
            break;
        case 8: //submarine
            adTop=-14;
            adImgAirshipBody=craft8;
            adSpeed = 400;
            break;
        case 9: //hot air balloon
            adTop=-14;
            adImgAirshipBody=craft9;
            adSpeed = 400;
            adVertical = true;
            break;
        case 10: //ufo
            adTop=-5;
            adImgAirshipBody=craft10;
            adSpeed = 50;
            adVertical = true;
            break;
        case 11: //rocket
            adTop=0;
            adImgAirshipBody=craft11;
            adSpeed = 10;
            break;
        default:
            adTop=5;
            adImgAirshipBody=craft7;
            adSpeed = 30;
            break;
    
    }

    return {adTop, adImgAirshipBody, adSpeed, adVertical, adImgAd};
}

const AirshipAnimated = styled.div`
    position: absolute;
    display: flex;
    top: 120px;
    right: -300px;
    z-index: 9999;
    transform: scale(1) translateX(0px);
    transition: transform ${props => props.adSpeed}s;
    
    &.move {
        transform: scale(1) translateX(-2500px);
    }
`;



const AirshipBody = styled(({className, adImgAirshipBody, top})=>(
    <div className={className}>
        <img src={adImgAirshipBody} />
    </div>
))`
    position: relative;
    top: ${p=>p.top}px;
    width: 153px;
    display:flex;
    align-items: center;
    img {
        width: 100%;
    }
`;



const AirshipHarness = styled(({className})=>(
    <div className={className}>
        <img src={harnessSVG} />
    </div>
))`
    position: relative;
    display:flex;
    align-items: center;
`;





const BannerStick = styled.div`
    position: relative;
    width: 6px;
    background-color: #bb2764;
    border-radius: 3px;
`;


const Marquee = styled(({className}) => {
    return (
        <div className={className}>
            Run better using real course data.
        </div>
    )
})`
    padding: 10px;
    font-size: 12px;
`;

const AnimatedCard = styled(({className, adImgAd}) => {
    return (
        <div className={className}
            onClick={()=>window.open('https://runbetterapp.com/')}
        >
            <div className="CardContent">
                <div className="Logo">
                    <img src={adImgAd} />
                </div>
                <Marquee />                
            </div>
            <div className="CardCut">
            </div>
        </div>
    )
})`
    position:relative;
    font-size: 3em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px rgba(50,50,0,0.2));
    
    .CardContent {
        background-color: #8888ce;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }
    
    .CardCut{
        background-color: #8888ce;
        background-color: #fff;
        clip-path: polygon(100% 0,45% 48%,100% 100%,0 100%,0 0);
        padding: 10px 30px 10px 20px;
    }
    .Logo {
        padding: 0 10px;
        max-width: 200px;
        margin-bottom: -24px;
        img {
            top: -9px;
            position: relative;
            max-width: 100%;
        }
    }
`;




const Airship = ({showAds, airshipType}) => {
    const [move, setMove]=useState(false);

    const {adTop, adImgAirshipBody, adSpeed, adVertical, adImgAd} = useAirship(airshipType);


    if (!showAds) return null;
    // console.log("showAds",showAds)
    // console.log("adTop",adTop)
    // console.log("adImgAirshipBody",adImgAirshipBody)
    // console.log("adSpeed",adSpeed)
    // console.log("adImgAd",adImgAd)

    useEffect(()=>{
        window.setTimeout(()=>{
            setMove(true)
        },300);
    }, [])
    

    return ( 
        <AirshipAnimated
            adSpeed={adSpeed}
            className={classnames({
            move
        })}>
            <AirshipBody adImgAirshipBody={adImgAirshipBody} adTop={adTop} />
            <AirshipHarness />
            <BannerStick />
            <AnimatedCard adImgAd={adImgAd} />
        </AirshipAnimated>
    )

}

const mapState = state => ({
    settings: state.settings,
    users: state.users,
  })

// export default Airship;
export default connect(mapState, null)(Airship);
