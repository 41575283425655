import React, { useState, Component } from 'react'
import './Header.scss'
import Sidebar from '../Sidebar/Sidebar'
import PageInfoModal from "../PageInfoModal/PageInfoModal";
import SearchModal from '../SearchModal/SearchModal';
import { withRouter, Link } from 'react-router-dom'

import classNames from 'classnames'
import LoginPopup from "../../components/Login/LoginPopup";
import PagesNavigation from './PagesNavigation'
import {connect} from 'react-redux'
import KeepInTouchModalForm from './KeepInTouchModalForm';
import HeaderSearchBar from './HeaderSearchBar'
import cloneDeep from 'clone-deep'
import TooltipText from '../../components/Tooltip/TooltipText'
import { getAvatarAndRaces } from '../../actions/users'
import { bindActionCreators } from "redux";

class Header extends Component {

    state = {
        StateEmailHash: '',
        StateUsername: ''
    }

    pages = [
        // {
        //     // http://localhost:3000/select-athlete/tsfm-2018/tsfm-ma-2018/All-Genders?stayintouch=true
        //     // url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders?stayintouch=true&bib=${Bib}`,
        //     url: ({Bib, RaceCode, EventCode}) => `/`,
        //     handle: "home",
        //     title: "Home"
        // },
        {
            // http://localhost:3000/select-athlete/tsfm-2018/tsfm-ma-2018/All-Genders?stayintouch=true
            // url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders?stayintouch=true&bib=${Bib}`,
            // url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders?stayintouch=true`,
            url: ({Bib, RaceCode, EventCode}) => `/select-athlete/${EventCode}/${RaceCode}/All-Genders`,
            handle: "select-athlete",
            title: "Event & Athletes"
        },
        {
            // http://localhost:3000/welcome/tsfm-ma-2018/athletes/1
            url: ({Bib, RaceCode, EventCode}) => `/welcome/${RaceCode}/athletes/${Bib}`,
            handle: "welcome",
            title: "Welcome"
        },
        {
            // http://localhost:3000/results-summary/tsfm-ma-2018/athletes/1
            url: ({Bib, RaceCode, EventCode}) => `/results-summary/${RaceCode}/athletes/${Bib}`,
            handle: "results-summary",
            title: "Results Summary"
        },
        {
            url: ({Bib, RaceCode, EventCode}) => `/segment-analysis/${RaceCode}/athletes/${Bib}`,
            handle: "segment-analysis",
            title: "Segment Analysis"
        },
        // {
        //     url: ({Bib, RaceCode, EventCode}) => `/course-map/${RaceCode}/athletes/${Bib}`,
        //     handle: "course-map",
        // },
        {
            url: ({Bib, RaceCode, EventCode}) => `/finish/${RaceCode}/athletes/${Bib}`,
            handle: "finish",
            title: "Finish Certificate"
        },
    ];
    
    componentDidMount(){

        // console.log('HEADER MOUNTED', this.props.athlete ? this.props.athlete.Bib : '-')
        this.keepLastAthlete();

        let {athleteHash, username} = this.props.match.params;
        if (!athleteHash && username) {
          this.props.usersActions.getAvatarAndRaces({username});
        } else if (athleteHash && !username) {
          this.props.usersActions.getAvatarAndRaces({EmailHash: athleteHash});
        }


        if (!athleteHash && username) {
            this.setState({
                StateUsername: username,
            });
        } else if (athleteHash && !username) {
            this.setState({
                StateEmailHash: athleteHash,
            });
        }

    

       
    }
    
    componentDidUpdate(prevProps,prevState){
        this.keepLastAthlete();


        let {athleteHash, username} = this.props.match.params;

        if ((username && prevState.StateUsername != username) || (athleteHash && prevState.StateEmailHash != athleteHash)){
            // console.log("prevState.StateUsername",prevState.StateUsername)
            // console.log("username",username)
            // console.log("prevState.StateEmailHash",prevState.StateEmailHash)
            // console.log("athleteHash",athleteHash)
            if (!athleteHash && username) {
                this.setState({
                    StateUsername: username,
                });
            } else if (athleteHash && !username) {
                this.setState({
                    StateEmailHash: athleteHash,
                });
            }
        }
    }

    keepLastAthlete = () => {
        // console.log('[HEADER]', 
        //     'Athlete:', this.props.athlete ? this.props.athlete.Bib : '-',
        //     'RaceCode:', this.props.race? this.props.race.RaceCode : '-',
        //     'EventCode:', this.props.race? this.props.race.EventCode : '-'
        // )

        const settingsObj = {};

        const activePageIndex = this.pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));

        // console.log("this.props.location.pathname",this.props.location.pathname)

        const isClaimedAthleteHome = this.props.location.pathname.length > 1 && (this.props.location.pathname.match(/\//g)).length === 1;

        // console.log("isclaimedathletehome",isClaimedAthleteHome,activePageIndex);
        // console.log("numslashes",this.props.location.pathname.match(/\//g).length);
        const isAthleteRaceList = activePageIndex === -1 && (this.props.location.pathname.includes(`/racelist`) );

        


        // console.log("isAthleteHomePage",isAthleteHome);

        let isAthleteHome = false;

        if (!isAthleteRaceList)
        {
            isAthleteHome = activePageIndex === -1 && (this.props.location.pathname.includes(`/athlete/`) || isAthleteRaceList || isClaimedAthleteHome );
        }
   
        if(activePageIndex === -1 && !isAthleteHome) {
            // Let's clear the current athlete & race if we are on Home Page
            settingsObj.lastSelectedAthlete = null;
            settingsObj.lastSelectedAthleteBib = null;
            settingsObj.lastSelectedAthleteGender = null;
            settingsObj.lastSelectedAthleteHometownSlug = null;
            settingsObj.lastSelectedAthleteNameGenderSlug = null;
            settingsObj.lastSelectedAthleteIndexFinish = null;
            settingsObj.lastSelectedRaceCode = null;
            settingsObj.lastSelectedEventCode = null;

            this.props.setIntervalIndex(0);
            this.props.setDistanceDisplayOption(1);

        }
        else {
            if(this.props.athlete && (!this.props.settings.lastSelectedAthlete || this.props.settings.lastSelectedAthlete.Bib !== this.props.athlete.Bib)) {
                // settingsObj.lastSelectedAthlete = cloneDeep(this.props.athlete);
                const { Bib, Gender, HometownSlug, NameGenderSlug, IndexFinish, EmailHash } = this.props.athlete;
                settingsObj.lastSelectedAthlete = {
                    Bib, Gender,HometownSlug, NameGenderSlug, IndexFinish, EmailHash
                }
                settingsObj.lastSelectedAthleteBib = this.props.athlete.Bib;
                settingsObj.lastSelectedAthleteGender = this.props.athlete.Gender;
                settingsObj.lastSelectedAthleteHometownSlug = this.props.athlete.MyRace.HometownSlug;
                settingsObj.lastSelectedAthleteNameGenderSlug = this.props.athlete.MyRace.NameGenderSlug;
                settingsObj.lastSelectedAthleteIndexFinish = this.props.athlete.IndexFinish;

                this.props.setIntervalIndex(0);
                this.props.setDistanceDisplayOption(1);

            }
            if(this.props.race) {
                settingsObj.lastSelectedRaceCode = this.props.race.RaceCode;
                settingsObj.lastSelectedEventCode = this.props.race.EventCode;
            }
        }

        this.props.setSetting(settingsObj);

    }


    render(){
        
        const { page, fbuserProfile, title, isMale, raceSelected, athlete, settings: { windowW, 
            lastSelectedAthlete, 
            lastSelectedAthleteBib: Bib, 
            lastSelectedAthleteGender: Gender, 
            lastSelectedRaceCode: RaceCode, 
            lastSelectedEventCode: EventCode
         } } = this.props;



        
        
        //  console.log("this.props.location.pathname",this.props.location.pathname)
        //  console.log("isAthleteRaceList",isAthleteRaceList)

        const isDesktop = windowW > 1100;

        const bibForRandom = "6581";


        const linkForRandom = raceSelected ? '/welcome/' + raceSelected.RaceCode + '/athletes/' + bibForRandom : null;
        const emojiGender = (Gender === "M") ? '🏃'  : '🏃‍♀️';

        let nameLink = null;

        if (lastSelectedAthlete && lastSelectedAthlete.EmailHash && lastSelectedAthlete.EmailHash.length > 0) {
            nameLink =  <Link to={`/athlete/${lastSelectedAthlete.EmailHash}`} className="athleteEmojiLink tooltip">
                <TooltipText>Athlete's Home Page</TooltipText>
                {emojiGender}
            </Link>;
        } else {
            nameLink =  <span className="athleteEmojiLink">{emojiGender}</span>;
        }

        // console.log("athlete.Gender",Gender);

        // console.log("athlete",athlete)
        

        const activePageIndex = this.pages.findIndex( o => this.props.location.pathname.includes(`/${o.handle}/`));

        const isClaimedAthleteHome = this.props.location.pathname.length > 1 && (this.props.location.pathname.match(/\//g)).length === 1;
        // console.log("isclaimedathletehome",isClaimedAthleteHome,activePageIndex);
        // console.log("numslashes",this.props.location.pathname.match(/\//g).length);

        

        let isAthleteHome = false;
        const isAthleteRaceList = (this.props.location.pathname.includes(`/racelist`) );

        if (!isAthleteRaceList)
        {
            isAthleteHome = activePageIndex === -1 && (this.props.location.pathname.includes(`/athlete/`) || isAthleteRaceList || isClaimedAthleteHome );
        }

        const isAccountProfile = activePageIndex === -1 && (this.props.location.pathname.includes(`/account/profile`) );
        const isAdminPage = activePageIndex === -1 && (this.props.location.pathname.includes(`/admin/`) );
        const isAccountFavorites = activePageIndex === -1 && (this.props.location.pathname.includes(`/account/favorites`) );
        
        

        
        let showAthleteSearch = false;


        if (page==="" || page==="select-athlete" || page==="athlete" || page===":username" || page === "account") {
            showAthleteSearch = true;
        }


        let pathforusername = '';
        if (this.state.StateEmailHash) {
            pathforusername=`athlete/${this.state.StateEmailHash}`;
        }
        else if (this.state.StateUsername) {
            pathforusername = `${this.state.StateUsername}`;
        } else {
            pathforusername=``;
        }


        const EventAthleteLink = (EventCode && RaceCode && !isAthleteHome) ? 
            <Link key={this.pages[0].handle} 
                to={this.pages[0].url({Bib, RaceCode, EventCode})}
                className={classNames("HeaderTab", { active: activePageIndex===0 })}>{this.pages[0].title}</Link>
            : null;

        // console.log("EventAthleteLink",EventAthleteLink)
        // console.log("isDesktop",isDesktop,windowW)
        // console.log("fbuserProfile",fbuserProfile)
        // console.log("pathforusername",pathforusername)
        // console.log("activePageIndex",activePageIndex)

        return (
            <div className="Header">
                
                <div className="container">

                    <Sidebar page={page} race={raceSelected} athlete={athlete} />

                    {/* { activePageIndex === -1 &&  <HeaderSearchBar /> } */}
          

                    

                    { activePageIndex === 0 && isDesktop && !Bib && 
                        <div className="HeaderTabs">
                            {/* <Link key={this.pages[0].handle} 
                                    to={this.pages[0].url({Bib, RaceCode, EventCode})}
                                    className={classNames("HeaderTab", { active: activePageIndex===0 })}>{this.pages[0].title}</Link> */}
                            <Link key={this.pages[0].handle} 
                                    to={this.pages[0].url({Bib, RaceCode, EventCode})}
                                    className={classNames("HeaderTab", { active: activePageIndex===0 })}>{this.pages[0].title}</Link>

                                    <span className="tabSeparator" />
                             {linkForRandom ? 
                                <React.Fragment key="lets-race">
                                    
                                    <span role="img" className="athletesNoneSelected">
                                    🏃‍♀️🏃
                                    </span>
                                
                                    <Link 
                                            data-tip="Go to random athlete" data-effect='solid' data-delay-show='500' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                            key='lets-race' 
                                            to={linkForRandom}
                                            className={classNames("HeaderTab")}>Let’s Race</Link>
                                </React.Fragment>
                             : null }
                        </div>
                    }

                    { activePageIndex !== -1 && Bib && isDesktop && <div className="HeaderTabs">
                        {this.pages.map((page,i) => {
              
                            return (
                                page.title==="Welcome" ? (
                                    <React.Fragment key="lets-race">
                                        <span className="tabSeparator" />
                                        <span className="athletes">
                                            {nameLink}
                                        </span>

                                        <Link key={page.handle} 
                                            to={page.url({Bib, RaceCode, EventCode})}
                                            className={classNames("HeaderTab", { active: activePageIndex===i })}>{page.title}</Link>
                                    </React.Fragment>

                                ) : (
                                    <Link key={page.handle} 
                                        to={page.url({Bib, RaceCode, EventCode})}
                                        className={classNames("HeaderTab", { active: activePageIndex===i })}>{page.title}</Link>
                                )
                            )
                        })}
                        
                    </div>}

                    { activePageIndex === -1 && (isAthleteHome || isAthleteRaceList) && 

                        <div className="HeaderTabs">
                            {/* <Link key={this.pages[0].handle} 
                                    to={this.pages[0].url({Bib, RaceCode, EventCode})}
                                    className={classNames("HeaderTab", { active: activePageIndex===0 })}>{this.pages[0].title}</Link> */}

                            {EventAthleteLink}
                                    


                            <span className="tabSeparator" />
                            <span className="athletes">
                                {isMale ? '🏃'  : '🏃‍♀️'}
                                
                            </span>
                                
                            
                            <Link 
                                    key='athlete-home' 
                                    // to={linkForRandom}
                                    to={`/${pathforusername}`}
                                    // onClick={ (event) => event.preventDefault() }
                                    className={classNames("HeaderTab",{active: isAthleteHome === true })}>Athlete Home</Link>
                            <Link 
                                    key='athlete-home-race-list' 
                                    to={`/${pathforusername}/racelist`}
                                    // onClick={ (event) => event.preventDefault() }
                                    className={classNames("HeaderTab", { active: isAthleteRaceList===true })}>Performance List</Link>

                        </div>

                    } 

                    { (activePageIndex === -1 && isAdminPage) &&
                        <div className="HeaderTitle">
                            { title }
                        </div>
                    }

                    { activePageIndex === -1 && (isAccountProfile || isAccountFavorites) && 

                    <div className="HeaderTabs">
                        {/* <Link key={this.pages[0].handle} 
                                to={this.pages[0].url({Bib, RaceCode, EventCode})}
                                className={classNames("HeaderTab", { active: activePageIndex===0 })}>{this.pages[0].title}</Link> */}

                        {EventAthleteLink}
                                


                        <span className="tabSeparator" />

                        {fbuserProfile && fbuserProfile.username && (<Link to={`/${fbuserProfile.username}`} className="athleteEmojiLink ">
                            <span className="athletes tooltip">
                                <TooltipText>Athlete's Home Page</TooltipText>
                                {fbuserProfile && fbuserProfile.gender && fbuserProfile.gender === "M"  ? '🏃'  : '🏃‍♀️'}
                            </span>
                           
                        </Link>)}
{/* 
                        <span className="athletes">
                            {isMale ? '🏃'  : '🏃‍♀️'}
                        </span> */}
                            
                        
                        <Link 
                                // data-tip="Go to random athlete" data-effect='solid' data-delay-show='500' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                key='account-profile' 
                                // to={linkForRandom}
                                to="/account/profile"
                                // onClick={ (event) => event.preventDefault() }
                                className={classNames("HeaderTab",{ active :  isAccountProfile} )}>Account Profile</Link>

                        <Link 
                                // data-tip="Go to random athlete" data-effect='solid' data-delay-show='500' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                key='account-favorites' 
                                // to={linkForRandom}
                                to="/account/favorites"
                                // onClick={ (event) => event.preventDefault() }
                                className={classNames("HeaderTab", { active: isAccountFavorites} )}>
                                    Favorites
                                </Link>

                    </div>

                    } 

                    { !isDesktop && activePageIndex !=-1 && <div className="HeaderTitle">
                        { title }
                    </div> }

                    {/* <div className="HeaderTitle">
                        { title }
                    </div> */}

            
                    <div className="rightbar-container">
                      
                        {/* { !isDesktop && <PagesNavigation race={raceSelected} athlete={athlete} pages={this.pages} activePageIndex={activePageIndex} /> } */}
                        {/* <PagesNavigation race={raceSelected} athlete={athlete} pages={this.pages} activePageIndex={activePageIndex} />  */}

                       
                        <KeepInTouchModalForm />
                        {showAthleteSearch ? 
                            <div className="rightbar-container-block search-icon-container">
                                <SearchModal />
                            </div> 
                        : null} 
                        <div className="rightbar-container-block">
                            <div className="rightbar-container-row rightbar-container-row-1">
                 
                            <PageInfoModal />

                            </div>

                        </div>
                        <div className="rightbar-container-block profile-icon-container">
                            <LoginPopup />
                        </div>
                    </div>
                    

                        
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // console.log('raceAthleteFilter:', JSON.stringify(state.raceAthleteFilter));

    // const { totalPages, totalItems } = getAthletesByFilterState(state);
    
    return {
        raceSelected: state.raceSelected,
        settings: state.settings,
        fbuserProfile: state.fbuserProfile

    }
}


const mapDispatch = dispatch => ({
    setSetting: (settingsObj) => dispatch({type:'SET_SETTING', ...settingsObj}),
    usersActions: bindActionCreators({getAvatarAndRaces}, dispatch), // this.props.usersActions.getUserAndAvatar(EmailHash),
    setIntervalIndex: intervalIndex => dispatch({type:'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX', intervalIndex}),
    setDistanceDisplayOption: value => dispatch({type:'INTERVAL_ANALYSIS_SET_DISTANCE_DISPLAY_OPTION', value}),
})

export default withRouter(connect(mapStateToProps, mapDispatch)(Header));