import React, {Component} from 'react'
import searchIcon from '../../images/search-4.svg'
import './HeaderSearchBar.scss'
import classNames from 'classnames'
import { withRouter, Link } from 'react-router-dom'

class HeaderSearchBar extends Component {

    state = {
        text: '',
        searchFilter: 'event'
    }
    
    onChange = e => {
        
        this.setState({ text: e.target.value });
        // this.search();
    }

    // setSearchFilter = searchFilter => e => this.setState({ searchFilter })
    setSearchFilter =  newSearchFilter => e => {
        this.setState({ searchFilter: newSearchFilter })

        if (newSearchFilter === "event") {
            this.props.history.push('/');
        } else {
            this.props.history.push('/' + newSearchFilter + 's');
        }
    }

    componentDidMount(){
        // const searchFilterPlural = this.props.match.path.split('/')[1];
        // console.log("searchFilterPlural",searchFilterPlural)
        // if (searchFilterPlural === "events") {
        //     this.setSearchFilter('event');
        // }
        // if (searchFilterPlural === "races") {
        //     this.setSearchFilter('race');
        // }
        // if (searchFilterPlural === "athletes") {
        //     this.setSearchFilter('athlete');
        // }
       
    }
    
    render(){
        const { text, searchFilter } = this.state;
        console.log("searchFilter",searchFilter)
        
        return (
            <div className="HeaderSearchBar" style={{
                    backgroundImage: `url(${searchIcon})`
                }}>

                    <input type="text"
                        ref={this.inputEl}
                        onChange={this.onChange}
                        className="SearchInput"
                        placeholder={`Find ${searchFilter === 'race' ? 'a' : 'an'} ${searchFilter}`}
                        value={text} 
                    />

                <div className="SearchFilterOptions">
                    <span 
                        className={classNames("SearchFilterOption", { selected: searchFilter==='event' })}
                        onClick={this.setSearchFilter('event')}
                        >Event</span>
                    <span className="dot">•</span>
                    <span 
                        className={classNames("SearchFilterOption", { selected: searchFilter==='race' })}
                        onClick={this.setSearchFilter('race')}
                        >Race</span>
                    <span className="dot">•</span>
                    <span 
                        className={classNames("SearchFilterOption", { selected: searchFilter==='athlete' })}
                        onClick={this.setSearchFilter('athlete')}
                        >Athlete</span>
                </div>
            </div>
        )
    }
}

export default withRouter(HeaderSearchBar);