import React,{Component} from 'react'
import { connect } from 'react-redux';
import classNames from 'classnames';
import imgArrowInactive from '../../images/sort-01.svg';
import imgArrowInactiveHover from '../../images/sort-01-hover.svg';
import imgArrowActiveDown from '../../images/sort-02.svg';
import imgArrowActiveUp from '../../images/sort-03.svg';
import TooltipText from '../Tooltip/TooltipText'

class SorterButton extends Component {

    handleSortClick = e => {
        const { col, setColDir, raceAthleteFilter: { sorterCol, sorterColDir } } = this.props;

        if(col!==sorterCol){
            setColDir(col, 'down');
        } else {
            setColDir(col, sorterColDir==='up' ? 'down' : 'up' );
        }
    }
    
    render(){
        const { col, raceAthleteFilter } = this.props;
        // redux: settings.sorterCol
        // redux: settings.sorterColDir
        
        const active = col === raceAthleteFilter.sorterCol;
        const inactive = !active;
        const up = raceAthleteFilter.sorterColDir==='up';

        
        return (
            <div className={classNames("SorterButton", { active, inactive, up })} onClick={this.handleSortClick}>
            {/* <div className={classNames("SorterButton","tooltip", { active, inactive, up })} onClick={this.handleSortClick}> */}
                {/* <TooltipText>Sort</TooltipText> */}
                <div className="SorterButtonDir">
                    <img src={imgArrowInactive} className="sorter_img_inactive" />
                    <img src={imgArrowInactiveHover} className="sorter_img_inactive_hover" />
                    <img src={imgArrowActiveDown} className="sorter_img_active_down" />
                    <img src={imgArrowActiveUp} className="sorter_img_active_up" />
                </div>
            </div>
        )
    }
}
const mapState = ({raceAthleteFilter}) => ({raceAthleteFilter});
const mapDispatch = dispatch => ({
    //setCol: (col)=>dispatch({type:'SET_SETTING', sorterCol: col }),
    //setColDir: (dir)=>dispatch({type:'SET_SETTING', sorterColDir: dir }),
    setColDir: (sorterCol, sorterColDir)=>dispatch({type:'SET_SORT_FILTER', sorterCol, sorterColDir }),
})

export default connect(mapState, mapDispatch)(SorterButton)