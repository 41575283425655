import {
    Stitch,
    AnonymousCredential,
    RemoteMongoClient
  } from "mongodb-stitch-browser-sdk";

class MongoApi {
    constructor(){

        this.scheduledOnAuth = [];

        // Initialize the App Client
        this.client = Stitch.initializeDefaultAppClient("myrace-lmlrv");

        this.client.auth.loginWithCredential(new AnonymousCredential())
              .then(this.onAuthenticated)
              .catch(console.error);

        // Get a MongoDB Service Client
        // This is used for logging in and communicating with Stitch
        this.mongodb = this.client.getServiceClient(
            RemoteMongoClient.factory,
            "mongodb-atlas-myrace"
        );
        // Get a reference to the database
        this.db = this.mongodb.db("myrace");
    }

    onAuthenticated = () => {
        this._isAuthenticated = true;
        this.scheduledOnAuth.forEach(fn => fn(this.db, this.client))
    }

    run = fn => {
        if(this._isAuthenticated){
            fn(this.db, this.client)
        } else {
            this.scheduledOnAuth.push(fn)
        }
    }
}


export default new MongoApi()