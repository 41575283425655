import React from "react";

// import imgFavHover from '../../images/star-1.svg'
import imgFav from '../../images/star-2.svg'
import imgFavDisabled from '../../images/star-4.svg'
import imgFavFavorited from '../../images/star-3.svg'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as fbuserActions from '../../actions/fbuser'
import classNames from 'classnames'
import TooltipText from '../Tooltip/TooltipText'


const FilterFavoritesButton = ({ fbuser, setLoginPopupOpen, setCreateAccountPopupOpen, toggleOnlyFavorites, raceAthleteFilter: {onlyFavorite} }) => {

    let props = {};
    if(fbuser && !fbuser.isAnonymous) {
        props.onClick = toggleOnlyFavorites;
    }

    let imgFavToShow = imgFav;
    if(onlyFavorite) imgFavToShow = imgFavFavorited;
    if(!fbuser) imgFavToShow = imgFavDisabled;


    return (
        <div className={classNames("tooltip","FilterFavoritesButton", {
            loggedOut: !fbuser,
            onlyFavorite
            })} 
            style={{ backgroundImage: `url(${imgFavToShow})`}} {...props}
        >   
            <TooltipText>Favorites Filter</TooltipText>
            {!fbuser && 
                <>
                    <div className="LoginLink" onClick={setLoginPopupOpen}>Login</div> 
                    <i className="dot">•</i>
                    <div className="CreateAccountLink" onClick={setCreateAccountPopupOpen}>Create an account</div> 
                </>}
        </div>
    )
}

const mapState = ({fbuser,fbuserProfile,requestsPending, raceAthleteFilter})=>({fbuser,fbuserProfile,requestsPending, raceAthleteFilter});
const mapDispatch = dispatch => ({
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    setLoginPopupOpen: () => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: 'login' }),
    setCreateAccountPopupOpen: () => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: 'signup' }),
    toggleOnlyFavorites: () => dispatch({ type: 'TOGGLE_ONLY_FAVORITE_FILTER' }),
})

export default connect(mapState, mapDispatch)(FilterFavoritesButton);