import React, { Component } from "react";
import {connect} from 'react-redux'

import { bindActionCreators } from "redux";
import * as athletesByRacebibActions from '../../actions/athletesByRacebib'

import "./OverallRaceResults.css";



class OverallRaceResults extends Component {


  componentDidMount() {

    // window.analytics.page();
    
    // if(!this.props.athlete || this.props.athlete === undefined || !this.props.athlete.Bib){ // is athlete in the redux?
      this.props.athletesByRacebibActions.getAthlete(
        this.props.match.params.raceCode,
        this.props.match.params.athleteBib
      );
    // }
  }
  


  render() {
    // const { raceCode, athleteBib } = this.props.match.params;
    // const { athleteBib } = this.props.match.params;
    // const { athlete, race } = this.props;
    const {requestsPending} = this.props;

    if (requestsPending) {
      return null;
    }


    return (
      <div>


      
      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  race: state.raceSelected,
  requestsPending: state.requestsPending
})

const mapDispatchToProps = dispatch => ({
  setAgeGradeFactor: ageGradeFactor => dispatch({type:'SET_AGE_GRADE_FACTOR', ageGradeFactor}),

  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(OverallRaceResults);
