import React, { useState } from 'react';
import imgEmailBlue from '../../images/mail-01.svg';
import imgEmailRed from '../../images/mail-02.svg';
import imgEmailBlack from '../../images/mail-03.svg';
// import firebase from 'firebase/app'
import {auth} from '../../config/firebase'
import imgArrow from '../../images/next-3.svg';
// import classNames from 'classnames'
// import EmailPassFields from './EmailPassFields'
import {connect} from 'react-redux'
import { useForm, Controller } from "react-hook-form";
import classNames from 'classnames'
import axios from 'axios';

import {PasswordField} from './EmailPassFields'


const ResetPassword = ({close, setLoginPopupOpen, settings, setEmail}) => {
    const { register, errors, triggerValidation, handleSubmit, control, watch } = useForm();

    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    // const [passwordValid, setPasswordValid] = useState(true);
    // const [emailValid, setEmailValid] = useState(true);

    const [formErrorMessage, setFormErrorMessage] = useState(null);
    const [firebaseErrorMessage, setFirebaseErrorMessage] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const password = watch('password');


    const onSubmit = data => {
        console.log('FORM SUBMIT', data);
        const actionCode = settings.actionCode;
        const {password} = data;

        auth.confirmPasswordReset(actionCode,password).then(function() {
            console.log('successful password change');
            // Update successful.
            close();
          }).catch(function(error) {
              console.log('FIREBASE ERROR:', error);
              setFirebaseErrorMessage(error.message);
            // An error happened.
          });

    }

    return (
        <div>
            <h1>Reset Password</h1>
            <div className="Label"><i></i><span>New Password</span><i></i></div>

            <p className="tagline">Enter your new password below</p>
            
            
            {/* <LoginSocial login /> */}
            <form onSubmit={handleSubmit(onSubmit)}>

            

                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: true,
                        validate: {
                            len: value => value.length > 5
                        }
                    } }
                    as={
                        <PasswordField 
                            formErrorMessage={!password || errors.password ? 'Type in your password...' : ''}
                            formSubmitted={!!errors.password || !password}
                            // onChange={e => {
                            //     // setPassword(e.target.value);
                            //     // setFormErrorMessage('');
                            // }} 
                            // onFocus={e => setPasswordValid(true)}
                            // value={password}
                            // placeholder={ passwordValid ? "password" : 'Type in your password...'}
                            placeholder={'Type in your password...'}
                            // formErrorMessage={formErrorMessage}
                            // formSubmitted={formSubmitted}
                        />
                    }
                />

                { firebaseErrorMessage && <p>{firebaseErrorMessage}</p>}



                {/* <div className="rememberpass">Don't remember your password?</div> */}
                
                {/* { !!formErrorMessage && <p className="FormErrorMessage">{formErrorMessage}</p>} */}


                <div className="buttons">
                    {/* <input type="submit" value="Sign In" style={{ backgroundImage: `url(${imgArrow})` }}/> */}
                    <button type="button" className="secondary" onClick={close}>Cancel</button>
                    <button type="submit">Reset</button>
                </div>

            </form>
        </div>

    )

}

const mapStateToProps = ({settings})=>({settings});
const mapDispatchToProps = (dispatch) => ({
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);