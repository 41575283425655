import initialState from "./initialState";
import update from 'immutability-helper';

const stub = {
    main_status: '', // main athlete data
    main_data: null,
    ranking_status: '', // ranking athlete data
    ranking_data: null,
}

export default (state = initialState.athletesByRacebib, action) => {
  switch (action.type) {

    case "ATHLETES_BY_RACEBIB/SET_MAIN_PENDING": {
        // expected: action.slug


        let nextState = state;
        if(!nextState[action.slug]){
            nextState = update(nextState, { [action.slug]: { $set: stub } })
        }

        return update(nextState, {
            [action.slug]: {
                main_status: {$set: 'pending'}
            }
        });


        
        // let nextState = {...state};
        // if(!nextState[action.slug]) { nextState[action.slug] = stub; }
        // nextState[action.slug] = {...nextState[action.slug]}; // clone it
        // nextState[action.slug].main_status = 'pending';
        // return nextState;
    }
    case "ATHLETES_BY_RACEBIB/SET_MAIN_DATA": {
        // expected: action.slug, action.data,
        // expected: action.status == 'received', 'missing'
        let nextState = {...state};
        nextState[action.slug] = {...nextState[action.slug]}; // clone it
        nextState[action.slug].main_status = action.status || 'received';
        nextState[action.slug].main_data = action.data;
        return nextState;
    }

    case "ATHLETES_BY_RACEBIB/SET_MAIN_STATUS": {
        // expected: action.slug, action.status
        let nextState = {...state};
        if(!nextState[action.slug]) { nextState[action.slug] = stub; }
        nextState[action.slug] = {...nextState[action.slug], main_status: action.status}; // clone it
        return nextState;
    }



    case "ATHLETES_BY_RACEBIB/SET_RANKING_PENDING": {
        // expected: action.slug
        let nextState = {...state};
        if(!nextState[action.slug]) { nextState[action.slug] = stub; }
        nextState[action.slug] = {...nextState[action.slug]}; // clone it
        nextState[action.slug].ranking_status = 'pending';
        return nextState;
    }

    case "ATHLETES_BY_RACEBIB/SET_RANKING_DATA": {
        // expected: action.slug, action.data
        // expected: action.status == 'received', 'missing'
        let nextState = {...state};
        nextState[action.slug] = {...nextState[action.slug]}; // clone it
        nextState[action.slug].ranking_status = action.status || 'received';
        nextState[action.slug].ranking_data = action.data;
        return nextState;
    }

    default:
      return state;
  }
};
