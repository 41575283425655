import initialState from "./initialState";

export default (state = initialState.settings, action) => {
  switch (action.type) {
    case "SET_UNITS":
      return { ...state, units: action.units };
    case "SET_SIDEBAR_OPEN":
      return { ...state, isSidebarOpen: action.open };
    case "SET_SEARCH_MODAL_OPEN":
      return { ...state, isSearchModalOpen: action.open };
    case "SET_PAGE_INFO_MODAL_OPEN":
      return { ...state, isPageInfoModalOpen: action.open };
    case "SET_INFO_MODAL_OPEN":
      return { ...state, [`isInfoModalOpen_${action.id}`]: action.open };
    case "SET_AGE_GRADE":
      return { ...state, ageGrade: action.ageGrade };
    case "SET_AGE_GRADE_FACTOR":
      return { ...state, ageGradeFactor: action.ageGradeFactor };

    case "SET_SETTING": {
      let newState = state;
      // let newState = { ...state };
      for(let prop in action){
        if(prop!=='type'){
          if(state[prop] !== action[prop]) {
            newState = {...newState, [prop]: action[prop] }
          }
          //newState[prop] = action[prop];
        }
      }
      return newState;
    }

    case "TOGGLE_SETTING": {
      let newState = { ...state };
      for(let prop in action){
        if(prop!=='type'){
          newState[prop] = !state[prop];
        }
      }
      return newState;
    }

    case "SET_BEACON_DONE": {
      let newState = { ...state };
      // action.beaconId
      newState.beaconsDone = {...newState.beaconsDone, [action.beaconId]: true };
      return newState;
    }
    
      
    default:
      return state;
  }
};
