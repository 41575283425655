import React, {useState} from 'react'
import imgEmailBlue from '../../images/mail-01.svg';
import imgEmailRed from '../../images/mail-02.svg';
import imgEmailBlack from '../../images/mail-03.svg';
import imgLock from '../../images/lock-1.svg';
import imgLockHover from '../../images/lock-2.svg';
// import imgArrow from '../../images/next-3.svg';
import imgPreviewRevealBlue from '../../images/hide-01.svg';
import imgPreviewRevealRed from '../../images/hide-02.svg';
import imgPreviewRevealBlack from '../../images/hide-03.svg';

import imgPreviewNoRevealBlue from '../../images/show-01.svg';
import imgPreviewNoRevealRed from '../../images/show-02.svg';
import imgPreviewNoRevealBlack from '../../images/show-03.svg';

import classNames from 'classnames'




export const PasswordField = ({name, onChange, onFocus, disabled, value, placeholder, formErrorMessage, formSubmitted,
    //error, 
    title }) => {
    const [reveal, setReveal] = useState(false);
    const [blurred, setBlurred] = useState(false);
    const [focused, setFocused] = useState(false);
    // const { title, name, onChange, value, error, disabled }  = this.props;

    const valid = !!value && !formErrorMessage && !focused;
    const invalid = (!value || !!formErrorMessage) && (blurred||formSubmitted) && !focused;
    
    const onInputFocus = e => { 
        setFocused(true); 
        typeof onFocus==='function' && onFocus(e); 
    }

    const inputProps = { name, onChange, disabled, value, placeholder, onFocus: onInputFocus,
        onBlur: ()=>{setBlurred(true);setFocused(false)}
    };
    
    return (
        <div className={classNames("control", "PasswordField", { valid, invalid })}>
            <label> 
                <span>{ title }</span>
                { reveal ?
                <input type="text" {...inputProps} />
                :
                <input type="password" {...inputProps} />
                }
            </label>
            <div className="RevealButton" onClick={()=>setReveal(!reveal)}>

                <div className="IconSwappableReveal">
                    <div className="icon">{reveal ? <img src={imgPreviewNoRevealBlue} /> : <img src={imgPreviewRevealBlue} />}</div>
                    <div className="invalid">{reveal ? <img src={imgPreviewNoRevealRed} /> : <img src={imgPreviewRevealRed} />}</div>
                    <div className="valid">{reveal?<img src={imgPreviewNoRevealBlack} /> : <img src={imgPreviewRevealBlack} />}</div>
                </div>

                    
                {/*(()=>{
                    if(valid) return reveal ? <img src={imgPreviewNoRevealBlack} /> : <img src={imgPreviewRevealBlack} />
                    if(invalid) return reveal ? <img src={imgPreviewNoRevealRed} /> : <img src={imgPreviewRevealRed} />
                    return reveal ? <img src={imgPreviewNoRevealBlue} /> : <img src={imgPreviewRevealBlue} />
                })()*/}
            </div>
        </div>
    )
}





const EmailPassFields = props => {
    const {email, setEmail, password, setPassword, formErrorMessage, setFormErrorMessage, formSubmitted,
        passwordValid, setPasswordValid, emailValid, setEmailValid} = props;

    const invalid = !emailValid || !!formErrorMessage;
    const valid = !!email && !!emailValid && !formErrorMessage;
        
    return (
        <>
            <div className={classNames("control","email", { valid, invalid })}>
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${imgEmailBlue})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${imgEmailRed})`}}/>
                        <div className="valid" style={{ backgroundImage: `url(${imgEmailBlack})`}}/>
                    </div>
                </div>
                {/* <input name="email" type="text"  */}
                <input name="email" type="email" 
                    autoComplete="off"
                    placeholder={ emailValid ? "Type in your email address..." : 'Type in your email address...'}
                    onChange={e => {setEmail(e.target.value);
                        setFormErrorMessage('');
                    }} 
                    onFocus={e => setEmailValid(true)}
                    onBlur={e => {
                        // setEmailValid(!!e.target.value && (/.+@.+\..+/).test(e.target.value));
                        setEmailValid(!!e.target.value && (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(e.target.value));
                    }}
                    value={email} 
                    />
            </div>

            <PasswordField 
                onChange={e => {setPassword(e.target.value);
                    setFormErrorMessage('');
                }} 
                onFocus={e => setPasswordValid(true)}
                value={password}
                placeholder={ passwordValid ? "password" : 'Type in your password...'}
                formErrorMessage={formErrorMessage}
                formSubmitted={formSubmitted}
            />

            {/* <div className={classNames("control","password","PasswordField", { invalid: !passwordValid })}>
                <div className="ControlIcon">
                    <div className="IconSwappableValid">
                        <div className="icon" style={{ backgroundImage: `url(${imgLock})`}}/>
                        <div className="invalid" style={{ backgroundImage: `url(${imgLockHover})`}}/>
                    </div>
                </div>
                <input name="password" type="password" 
                    // placeholder={ passwordValid ? "password" : 'Create password...'}
                    // onChange={e => setPassword(e.target.value)} 
                    // onFocus={e => setPasswordValid(true)}
                    //onBlur={e => { setPasswordValid(!!e.target.value) }}
                    value={password} 
                    />

                <div className="RevealButton" onClick={this.toggle}>
                    {reveal ?
                        <img src={imgPreviewNoReveal} />
                        :
                        <img src={imgPreviewReveal} />
                    }
                </div>
            </div> */}
        </>
    )
}

export default EmailPassFields