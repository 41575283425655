import initialState from "./initialState";

export default (state = initialState.athletesBySlug, action) => {
  switch (action.type) {
    case "ATHLETES_BY_SLUG/SET_PENDING": {
        // expected: action.slug
        let nextState = {...state};
        if(!nextState[action.slug]) {
            nextState[action.slug] = { status: 'pending', data: null };
        }
        return nextState;
    }
    case "ATHLETES_BY_SLUG/SET_DATA": {
        // expected: action.slug, action.data
        let nextState = {...state};
        nextState[action.slug] = { status: 'received', data: action.data }
        return nextState;
    }
    default:
      return state;
  }
};
