import React from "react";
import Pagination from './Pagination'
import {formatNumber} from '../../timeUtils'
import AthleteListFilters from "../../components/AthleteListFilters/AthleteListFilters";
import Dropdown from "../Dropdown/Dropdown";
import FilterFavoritesButton from './FilterFavoritesButton'

const PaginationLine = ({ isOfficialData, currentPageLabel, totalItems, ofString, page, perPage, setPage, setPerPage }) => (
    <div className="PaginationLine">
        <div className="totalPages">
            { typeof totalItems==='number' ?
                <>
                    <strong>
                        {currentPageLabel}
                    </strong>  {typeof totalItems==='number' ? ofString : null}  <strong>
                        {typeof totalItems==='number' ? formatNumber(totalItems) : ''}
                    </strong>

                    <i className="dot">•</i>

                    <div className="tooltip">
                        <span className="tooltiptext">Show by...</span>

                        <Dropdown
                            options={[
                                { id: 25, title: '25' },
                                { id: 50, title: '50' },
                                { id: 100, title: '100' },
                                { id: 500, title: '250' },
                            ]} value={perPage} onChange={setPerPage}/>
                    </div>

                    <FilterFavoritesButton />

                    {!isOfficialData && 
                        <div>
                            Unofficial
                        </div>
                    }

                    
                </> :
                <span>Loading ...</span>
            }            
        </div>

        <AthleteListFilters />
    
        <Pagination page={page} onChange={setPage} />
        
    </div> 
)

export default PaginationLine