// https://connect.garmin.com/oauthConfirm?oauth_token="+ oAuthToken; where oAuthToken = response['token'].replaceAll('"','');
import React, { Component, useState, useEffect } from 'react'
import mongoapi from '../../apis/mongoapi'
import { auth } from '../../config/firebase';
// import {     Stitch,
//     AnonymousCredential,
//     RemoteMongoClient } from 'mongodb-stitch-browser-sdk';


// garminConnectURL: https://webhooks.mongodb-stitch.com/api/client/v2.0/app/myrace-lmlrv/service/myrace-mongo-api/incoming_webhook/garminConnect

// return either {garminData object} || {status: 'new_user', token: "OAUTH Token"}

const GarminConnect = () => {
    const [ garminResponse, setGarminResponse] = useState(null);
    const [ popupCount, setPopupCount] = useState(0);
    useEffect(()=>{
        // Trigger Garmin Connect with firebase uid

        //https://webhooks.mongodb-stitch.com/api/client/v2.0/app/myrace-lmlrv/service/myrace-mongo-api/incoming_webhook/garminConnect

        /*
        curl \
-H "Content-Type: application/json" \
-d '{"foo":"bar"}' \
https://webhooks.mongodb-stitch.com/api/client/v2.0/app/myrace-lmlrv/service/myrace-mongo-api/incoming_webhook/garminConnect
*/
        
        if(auth.currentUser && auth.currentUser.uid) {
            const { uid } = auth.currentUser;
            // const uid = 444;

            (async ()=>{
                const data = await stitchCall(uid);
                if(data){
                    setGarminResponse(data);
                }
            })();
            
        }
        
        
        
//
        // mongoapi.run((mongodb, client) => {

        //     //const client = Stitch.defaultAppClient;
        //     client.callFunction("garminConnect", 
        //         ["hXwUSwCZinPBH0ZVyjhLNs9GevM2"]
        //     ).then(result => {
        //         console.log('[garminConnect] result:', result) // Output: 7
        //     });
        // })

        // const client = Stitch.initializeDefaultAppClient("myrace-lmlrv");

        // client.auth.loginWithCredential(new AnonymousCredential())
        //       .then(() => {
        //             //const client = Stitch.defaultAppClient;
        //             client.callFunction("garminConnect").then(result => {
        //                 console.log('[garminConnect] result:', result) // Output: 7
        //             });

        //       })
        //       .catch(console.error);
        
    },[auth.currentUser]);

    useEffect(()=>{
        // window.open('https://connect.garmin.com/oauthConfirm?oauth_token=0b0bc466-4d6a-4cf4-9197-e3e77a3e11b8', 'Garmin Connect', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=400, height=690, top=0, left=60' )
        if(
            (!popupCount && garminResponse && !garminResponse.authorised && (garminResponse.token || garminResponse.oAuthReqToken))
        ) {
            setPopupCount(c=>c+1);
            const token = garminResponse.token || garminResponse.oAuthReqToken;

            const popup = window.open(`https://connect.garmin.com/oauthConfirm?oauth_token=${token}`, 'Garmin Connect', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=400, height=690, top=0, left=60' );
            
            const timer = setInterval(()=>{
                console.log('popup closed:', popup.closed?'Yes':'No')
                if(popup.closed){
                    clearInterval(timer);
                    (async ()=>{
                        const data = await stitchCall(auth.currentUser.uid);
                        if(data){
                            setGarminResponse(data);
                        }
                    })();
                }
            }, 1000);
        }

    },[garminResponse])


    /**

     "https://connect.garmin.com/oauthConfirm?oauth_token="+ oAuthToken; 

        where oAuthToken = response['token'].replaceAll('"',''); 
     * 
     */

    
    return (
        <div>
            <h1>Garmin Connect (under dev)</h1>

            { garminResponse ? (
                <div>
                    <p>Authorized: { garminResponse.authorised ? 'Yes': 'No' }</p>
                    <p>Token: { garminResponse.token }</p>
                    <p>oAuthToken: { garminResponse.oAuthToken }</p>
                    <p>oAuthReqToken: { garminResponse.oAuthReqToken }</p>
                </div>
            ) :null}
        </div>
    )
}




async function stitchCall(uid){
    const garminUrl = `https://webhooks.mongodb-stitch.com/api/client/v2.0/app/myrace-lmlrv/service/myrace-mongo-api/incoming_webhook/garminConnect?uid=${uid}`;

    let resp, data;
    try {
        resp = await fetch(garminUrl, {
            method: 'post',
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch(err){
        console.log('fetch error:', err);
        return;
    }
    try {
        data = await resp.json();

    } catch(err){
        console.log('data error:', err);
        return;
    }
    
    console.log('Garmin response:', data);

    return data;
    
}


export default GarminConnect