import React, {Component} from 'react'
import {connect} from 'react-redux'
import {auth} from '../../../config/firebase'
import {Redirect} from 'react-router-dom'

class ConfirmEmail extends Component {
    state = {
        done: false,
        error: null
    }
    componentDidMount(){
        this.finishConfirmingEmail();
    }
    getParameterByName = name =>{
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&]"+name+"=([^&#]*)";
        var regex = new RegExp( regexS );
        var results = regex.exec( window.location.href );
        if( results == null )
          return "";
        else
          return decodeURIComponent(results[1].replace(/\+/g, " "));
      }
    
    finishConfirmingEmail = () => {

        // Get the action to complete.
        var mode = this.getParameterByName('mode');
        // Get the one-time code from the query parameter.
        var actionCode = this.getParameterByName('oobCode');
        // (Optional) Get the continue URL from the query parameter if available.
        var continueUrl = this.getParameterByName('continueUrl');
        // (Optional) Get the language code if available.
        var lang = this.getParameterByName('lang') || 'en';
        
        // console.log('auth',auth);
        // console.log('mode',mode);
        // console.log('continueUrl',continueUrl);
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        
        this.props.setFbUser( auth.currentUser );
        // console.log("applied user")
        // console.log("current user",auth.currentUser);
        // console.log('actionCode',actionCode);

        if (mode==="resetPassword") {
                this.props.setActionCode(actionCode);
                this.props.setLoginPopupOpen('resetpassword');
                this.setState({ done: true });
            
        } else {

            auth.applyActionCode(actionCode).then((resp) =>{
                // Email address has been verified.
            
                // TODO: Display a confirmation message to the user.
                // You could also provide the user with a link back to the app.
            
                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
                console.log('email verified!');
    
                // this.props.setFbUser( auth.currentUser );
    
                // if (mode==="resetPassword") {
                //     console.log("SHOW PASSWORD RESET FORM, DO WE WANT TO setFbUser? How this is done");
                //     this.props.setLoginPopupOpen('resetpassword');
                //     // this.props.setFbUser( auth.currentUser );
                // }
     
    
    
                this.setState({ done: true });
              }).catch((error) => {
                // Code is invalid or expired. Ask the user to verify their email address
                // again.

                console.log('error verifying email:', error);
                this.setState({ error: error.message})
              });
        }

    }
    render(){
        const { done, error } = this.state;
        const { fbuser } = this.props;
        if(error){
            return (
                <div>
                    <p>There was an error trying to confirm email:</p>
                    <p>{error}</p>
                </div>
            )
        }

        if(!done){
            return (
                <div>processing...</div>
            )
        }

        return <Redirect to={{
            pathname: fbuser ? "/account/profile" : '/',
            state: { from: this.props.location }
        }} />
    }
}

const mapState = ({fbuser})=>({fbuser})

const mapDispatchToProps = (dispatch) => ({
    setFbUser: user => dispatch({ type: 'SET_FB_USER', user }),
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
    setActionCode: code => dispatch({ type: 'SET_SETTING', actionCode: code }),
})

export default connect(mapState, mapDispatchToProps)(ConfirmEmail);
  