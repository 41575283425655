import initialState from "./initialState";

export default (state = initialState.raceSelected, action) => {
  switch (action.type) {
    case "SET_SELECTED_RACE_ONLY":
    case "SET_SELECTED_RACE":
      // console.trace()
      return action.race;
    case "UPDATE_SELECTED_RACE":
        return {...state, ...action.race};
    default:
      return state;
  }
};
