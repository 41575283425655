import React, { Component } from "react";
import ReactDOM from 'react-dom'
import logo from "../../images/logo.svg"
import icoFinish from "../../images/finish.svg"

import iconUser from "../../images/user-04.svg"
import iconEmail from "../../images/mail-01.svg"
import iconPhone from "../../images/phone-01.svg"
import iconComment from "../../images/comment-01.svg"

import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

import cityImgMobile from '../../images/generic-popup-1.svg'
import imgRunner from '../../images/running-small.gif'

import TooltipText from '../../components/Tooltip/TooltipText'

// import searchIconActive from "../../images/search-1.svg";
// import searchIconInactive from "../../images/search-2.svg";
// import searchIconDisabled from "../../images/search-3.svg";
// import SearchContent from "./SearchContent";
import './PartnerModalForm.scss'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import classNames from 'classnames'
import mongoapi from '../../apis/mongoapi'
import {RemoveScroll} from 'react-remove-scroll';

class PartnerModalForm extends Component {

    state = {
        email: '',
        name: '', 
        phone: '', 
        comment: '',
        errors: {},
        formSubmitted: false,
    }
    

    componentDidMount(){
        // console.log('[PartnerModalForm] mount');
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
    }
    componentWillUnmount(){
        // console.log('[PartnerModalForm] unmount');
        window.document.body.removeChild(this.coverEl);
    }

    open = () => {
        if( this.props.disabled ) { return; }
        ReactTooltip.hide();
        this.props.openModal();
    }
    close = () => {
        this.props.closeModal();
        this.setState({formSubmitted: false})
    }

    renderModal = () => {
        return (
            ReactDOM.createPortal(
                <RemoveScroll>
                    <div className="PartnerModalCover" onClick={this.close}></div>
                </RemoveScroll>
            , this.coverEl)
        )
    }

    updateField = e => this.setState({ [e.target.name]: e.target.value });

    validate = async () => {
        let nextErrors = {...this.state.errors};
        nextErrors.name = this.state.name ? null : 'Please enter Name';
        nextErrors.email = this.state.email ? null : 'Please enter Email';
        nextErrors.phone = this.state.phone ? null : 'Please enter Phone';

        this.setState({errors: nextErrors});

        const formInvalid = Object.keys(nextErrors).filter( key => !!nextErrors[key] ).length > 0;
        if(formInvalid){
            throw new Error('Form not valid');
        }
    }

    handleSubmit = e => {
        e.preventDefault();


        this.validate()
            .then(()=>{
                // form valid
                // console.log('form validation: VALID');
                this.sendMessage();
            })
            .catch(err =>{
                // form not valid
                // console.log('form validation: INVALID')
            });
    }

    sendMessage = () => {

        mongoapi.run(mongodb=>{

            // mongo call insert
            const partnerRequest = {
                RequestDate : new Date(),
                Email : this.state.email,
                Name : this.state.name,
                Phone : this.state.phone,
                Comment : this.state.comment
            };

            
            mongodb.collection("form-partner-requests")
                .insertOne(partnerRequest)
                .then(
                    // this.handleRacesResponse
                    this.thankYou()
                );


            this.thankYou();
        });
    }

    thankYou = () => this.setState({formSubmitted: true})


    renderForm = () => {
        const { email, name, phone, comment, errors } = this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                
                <div className="Label">
                    <i></i>
                    <span>Partner with Us</span>
                    <i></i>
                </div>
                
                <p>Extend your athletes’ post-race experience with the world’s best post-race performance analytics!</p>

            
                <label className={classNames({ error: errors['email'] })}> <div className={classNames("label ", { error: errors['email'] })}> Email Address* </div>
                    <input type="email" name="email" value={email} onChange={this.updateField} placeholder="Type in your email..." style={{backgroundImage: `url(${iconEmail})`}} />
                </label>
                
                <label className={classNames({ error: errors['name'] })}> <div className={classNames("label ", { error: errors['name'] })}> Name* </div>
                    <input type="text" name="name" value={name} onChange={this.updateField} placeholder="Type in your name..." style={{backgroundImage: `url(${iconUser})`}} />
                </label>
                
                <label className={classNames({ error: errors['phone'] })}> <div className={classNames("label ", { error: errors['phone'] })}> Phone Number* </div>
                    <input type="text" name="phone" value={phone} onChange={this.updateField}  placeholder="Enter your phone number..." style={{backgroundImage: `url(${iconPhone})`}} />
                </label>
                
                <label className={classNames({ error: errors['comment'] })}> <div className={classNames("label ", { error: errors['comment'] })}> Comment </div>
                    <input type="text" name="comment" value={comment} onChange={this.updateField}  placeholder="Best time to call, etc..."  style={{backgroundImage: `url(${iconComment})`}}/>
                </label>

                <div className="buttons">
                    <button className="secondary" onClick={this.close}>Cancel</button>
                    <button type="submit">Partner with us</button>
                </div>
                
            </form>
        )
    }

    renderThankYou = () => {
        return (
            <div className="ThankYou">
                <div className="title">Thank you!</div>
                <p>A MyRace representative will be in contact with you shortly.</p>
                <img alt="Thank You" src={icoFinish} className="ThankYouFinishIcon" />
            </div>
        )
    }


    
    renderModalContent = () => {
        const { formSubmitted } = this.state;

        return (
            <div className="PartnerModalForm">
                <div className="LeftPane">

                        <div className="Logo">
                            <img src={logo} alt="MyRace logo" />
                        </div>

                        <div className="title">Are you a race event organizer?</div>

                        <div className="HeroImage">
                            <img src={cityImgMobile} className="CityBackground" />
                            <img src={imgRunner} className="Runner" />
                        </div>

                </div>
                <div className="MainPane">

                    {/* <div className="closePartnerModal" onClick={this.close}>⨯</div> */}
                    <div className="MainModalContent">
                        { !formSubmitted? this.renderForm() : this.renderThankYou() }
                    </div>

                    {/* <div className="IconSwappable ClearIcon" onClick={this.close}>
                        <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                    </div> */}

                    <div className="IconSwappable ClearIcon tooltip" onClick={this.close}>
                        <div className="background"/>
                        <TooltipText><em>Close</em></TooltipText>
                        <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        const { settings: {isPartnerModalOpen = false} } = this.props;

        return (
            <>
                { isPartnerModalOpen && this.renderModalContent() }
                { isPartnerModalOpen && this.renderModal() }
            </>
        )
    }
}


const mapState = ({settings}) => ({settings});
const mapDispatch = dispatch => ({
    openModal: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),
    closeModal: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: false}),
})


export default connect(mapState, mapDispatch)(PartnerModalForm);
