import React, { Component } from "react";
import ReactDOM from 'react-dom'
import classNames from 'classnames';
import './Dropdown.scss'
import checkIcon from '../../images/check.svg';
import chevronIcon from '../../images/chevron-3.svg';
import TooltipText from '../Tooltip/TooltipText'

import rightChevronBlue from '../../images/next-01.svg';
import rightChevronBlack from '../../images/next-02.svg';


const Option = ({id, title, onClick, selected, goTo}) => {
    let style={};
    if(selected){
        style.backgroundImage = `url(${checkIcon})`;
    }
    return (
        <li className={classNames({ selected })} style={style} onClick={onClick}>
            {title}
            {typeof goTo==='function' && (
                <span className="GoTo IconSwappable" onClick={e=>{
                    e.stopPropagation();
                    goTo(id);
                    }}>
                    <div className="icon" style={{ backgroundImage: `url(${rightChevronBlack})`}}/>
                    <div className="hover" style={{ backgroundImage: `url(${rightChevronBlue})`}}/>
                </span>
            )}
        </li>
    )
}


class Dropdown extends Component {

    state = {
        open: false
    }

    componentDidMount(){
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
    }
    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        
    }

    open = e => {
        e.stopPropagation();
        if( this.props.disabled ) { return; }
        this.setState({ open: true })
    }
    
    select = id => e => {
        e.stopPropagation();
        this.props.onChange(id);
        this.close();
    }
    close = (e) => {
        e && e.stopPropagation();
        this.setState({ open: false })
    }
    

    render() {
        const { open } = this.state;
        const { options, value, disabled, isForGenderFilter, goTo } = this.props;
        const selectedOption = options.find( o => o.id === value );

        const tooltipTextVal = (disabled || !isForGenderFilter) ? null :  <TooltipText>Filter by...</TooltipText>;

        if(!selectedOption){
            //console.warn('NO SELECTED OPTION FOR DROPDOWN:', options, value)
            return null;
        }

        return (
            
            <div className={classNames("DropdownContainer", {tooltip: !disabled && isForGenderFilter})} >
                {tooltipTextVal}
                <div className={classNames("DropdownValue", {disabled})} onClick={this.open}>
                    <em>{selectedOption.title}</em>
                    <span className="chevron"><img src={chevronIcon} alt='dropdown' /></span>
                </div>
                <ul className={classNames('DropdownList', { open })} 
                // onMouseLeave={this.close}
                >
                    { options.map( option  => <Option
                        selected={ option.id === selectedOption.id }
                        key={option.id} 
                        onClick={this.select(option.id)} 
                        goTo={goTo}
                        {...option} />)}
                </ul>
                { open && ReactDOM.createPortal(
                    <div className="DropdownCover" onClick={this.close}></div>
                , this.coverEl)}
            </div>
        );
    }
}

export default Dropdown;
