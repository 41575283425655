import React, { Component } from "react";
import { connect } from "react-redux"
import Interval from './Interval'
// import D3ElevationProfile from '../../components/D3ElevationProfile/D3ElevationProfile'
import D3ElevationMap from '../../components/D3ElevationMap/D3ElevationMap'

import classNames from 'classnames'
// import throttle from 'lodash.throttle'
import {secondsToTimeStrip, roundDistance,secondsToPossibleLongMinutesSeconds} from '../../timeUtils'
import Beacon from '../../components/Beacon/Beacon'



class RaceCourse extends Component {
    // constructor(props){
    //     super(props);
    //     this.onResize = throttle(this.onResize, 100);
    //   }
    state = {
        // windowW: 0,
        rowHighlightIndex: -1, //   < 0 - deselected
    }    

    // componentDidMount(){
    //     this.onResize();
    //     window.addEventListener('resize', this.onResize);
    //   }
    // componentWillUnmount(){
    // window.removeEventListener('resize', this.onResize);
    // }

    // onResize = () => {
    
    //     // console.log('window resized:', window.innerWidth, 'x', window.innerHeight );
    //     this.setState({
    //         windowW: window.innerWidth
    //     })
    // }

    getDiffTime(interval, units) {
        // console.log("interval.DiffPaceFromLastIntervalEnglishSeconds",interval.DiffPaceFromLastIntervalEnglishSeconds);
        
        if (isNaN(interval.DiffPaceFromLastIntervalEnglishSeconds)) {
            return ' ';
        } 
        const timeSeconds = units === 'english' ? interval.DiffPaceFromLastIntervalEnglishSeconds : interval.DiffPaceFromLastIntervalMetricSeconds;
        // console.log("timeSeconds",timeSeconds);

        // return  secondsToLongMinutesSeconds(timeSeconds);
        return  secondsToPossibleLongMinutesSeconds(timeSeconds);
    }

    setRowHightlightIndex = index => e => {
        this.setState({ rowHighlightIndex: index })
    }

    unsetRowHightlightIndex = e => this.setState({ rowHighlightIndex: -1 })



    render(){
        // const { athleteMainData, athleteRankingData, race, settings, toggleGraphTable } = this.props;
        const { athleteMainData, race, settings, toggleGraphTable } = this.props;
        const { rowHighlightIndex } = this.state;
        
        const {units, ageGrade, windowW} = settings;
        
        // const margin = { top: 0, bottom: 0, left: 0, right: 0 };
        const AGon = ageGrade === "age_grade_on";
        // const intervals = !!athleteMainData && (AGon ? athleteMainData["event-intervals-ag-data"] : athleteMainData["event-intervals-data"]);
        const intervals = !!athleteMainData && (AGon ? athleteMainData["run-event-intervals-ag-data"] : athleteMainData["run-event-intervals-data"]);
        // const intervals = !!athlete && athlete["interval-5km-data"];
        // const intervalPace = secondsToTimeStrip(units === 'metric' ? interval.IntervalPaceMetricSeconds : interval.IntervalPaceEnglishSeconds);

        // console.log("about to render intervals",intervals);

        let raceChartWidth = 1240; //1100
        if( windowW > 1000 && windowW < (1200+60)  ){
            // console.log("CHANGING raceChartWidth!")
            raceChartWidth = windowW-60;
        } else if ( windowW <= 1000) {
            raceChartWidth = 940;
        }
        
        return (
            <div className="RaceCourseContainerOuter">
            <div className="RaceCourseContainer">
            <div className="RaceCourse">
                <div className="start">
                  <div className="vertical-text">START</div>
                  <div className="half-marathon-bar"></div>
                </div>

                { toggleGraphTable ? ( 
                <div className="RaceCourseIntervals">
                    <Beacon id="resultsSummaryRaceCourse" text={"Hover over each of the segments below"} direction="down" style={{ top:'-30px', left:'35px'}} />

                    <D3ElevationMap isHiddenNavigation={true}   
                        // showTooltipLabels = {true}
                        units={settings.units}  
                        intervalsData={athleteMainData ? athleteMainData["run-event-intervals-data"] : []} 
                        width={raceChartWidth}  
                        height={143}
                        margin={{ top: 0, bottom: 0, left: 0, right: 0 }} 
                        elevationData={race ? race.elevations : []} 
                        // elevationData={raceElevations} 
                        />
                    <section className="IntervalLabel IntervalLabelZero">{intervals && `0`}</section>
            
                    {intervals && intervals.map((interval, i) => (
                        <Interval
                        key={i}
                        index={i}
                        interval={interval}
                        race ={race}
                        setSelectedInterval={this.props.setSelectedInterval}
                        units={units} 
                        last={i+1===intervals.length}
                    />
                    ))}
                </div>
                ): (
                    <div className="TableContainer">
                        <div className="TableHeader">
                            <p className={classNames({
                                highlight: rowHighlightIndex===0
                            })}>Distance, {units === "metric" ? "km" : "mi" }</p>
                            <p className={classNames({
                                highlight: rowHighlightIndex===1
                            })}><strong>Interval</strong>, {units === "metric" ? "km" : "mi" }</p>
                            <p className={classNames({
                                highlight: rowHighlightIndex===2
                            })}><strong>Pace</strong>, {units === "metric" ? "m/km" : "m/mi" }</p>
                            <p className={classNames({
                                highlight: rowHighlightIndex===3
                            })}>&nbsp;</p>
                            <p className={classNames({
                                highlight: rowHighlightIndex===4
                            })}>Time</p>
                        </div>
                        {intervals && intervals.map((interval, i) => (
                            <div key={i} className="IntervalData">
                                <p 
                                    onMouseOver={this.setRowHightlightIndex(0)}
                                    onMouseOut={this.unsetRowHightlightIndex} 
                                    className={classNames("numericFont","splits", {
                                        highlight: rowHighlightIndex===0
                                    })}>{units === 'metric' ? roundDistance(interval.CumulativeDistanceKm,3) : roundDistance(interval.CumulativeDistanceMi,3)}</p>
                                <p 
                                    onMouseOver={this.setRowHightlightIndex(1)}
                                    onMouseOut={this.unsetRowHightlightIndex} 
                                    className={classNames("numericFont","distance", {
                                        highlight: rowHighlightIndex===1
                                    })}>{units === 'metric' ? roundDistance(interval.IntervalDistanceKm,3) : roundDistance(interval.IntervalDistanceMi,3)}</p>
                                <p 
                                    onMouseOver={this.setRowHightlightIndex(2)}
                                    onMouseOut={this.unsetRowHightlightIndex} 
                                    className={classNames("numericFont","pace", {
                                        highlight: rowHighlightIndex===2
                                    })}>{secondsToTimeStrip(units === 'metric' ? interval.IntervalPaceMetricSeconds : interval.IntervalPaceEnglishSeconds)}</p>
                                <p 
                                    onMouseOver={this.setRowHightlightIndex(3)}
                                    onMouseOut={this.unsetRowHightlightIndex} 
                                    className={classNames("numericFont","diff", {
                                        plus: interval.DiffPaceFromLastIntervalEnglishSeconds > 0,
                                        minus: interval.DiffPaceFromLastIntervalEnglishSeconds <= 0,
                                        highlight: rowHighlightIndex===3
                                })}>{this.getDiffTime(interval, units)}&nbsp;</p>
                                <p 
                                    onMouseOver={this.setRowHightlightIndex(4)}
                                    onMouseOut={this.unsetRowHightlightIndex} 
                                    className={classNames("numericFont","time", {
                                        highlight: rowHighlightIndex===4
    })}>{secondsToTimeStrip(interval.IntervalCumulativeTimeSeconds)}{interval.IsEstimated ? '*' : ''}</p>
                            </div>
                        ))}
                    </div>
                )}


                <div className="finish">
                  <div className="vertical-text">FINISH</div>
                </div>
              </div>
              </div>
              </div>
        )
    }
}

const mapState = ({settings}) => ({settings})


export default connect(mapState)(RaceCourse);