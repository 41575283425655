export const setBeaconDone = beaconId => {
    // console.log('set beacon done localstorage', `beacon_${beaconId}` )
    window.localStorage.setItem(`beacon_${beaconId}`, 'y');
    return {
        type: 'SET_BEACON_DONE',
        beaconId
    }
}
export const setModalDone = modalId => {
    // console.log('set modal done localstorage', `modal_${modalId}` )
    window.localStorage.setItem(`modal_${modalId}`, 'y');
    return {
        type: 'SET_MODAL_DONE',
        modalId
    }
}
