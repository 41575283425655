import React, { Component } from "react";
import "./SuperAdminOptions.scss"
import classnames from "classnames";
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
// import TooltipText from '../Tooltip/TooltipText'

class SuperAdminOptions extends Component {


  componentDidMount(){

  }

  handleClick = value => e => {
    // console.log("handleClick",value)
    this.props.onChange(value)
    
  }


  render() {
    const { handleClick, value} = this.props;
    // console.log("xxx races",races);

    // let races = raceSelected && raceEvents[raceSelected.EventCode] && raceEvents[raceSelected.EventCode].races;
    // if(!races) return null;

    // let options = ['users','events']
    
    // if(!options) return null;
    
    
    return (
      <div className="RaceListFilters">
        
        <div className="line"/>

        <div className={classnames("button",{
                selected: (value === 'summary')
              })} onClick={this.handleClick('summary')}>
          Summary
        </div>
        <div className={classnames("button",{
                selected: (value === 'utilities')
              })} onClick={this.handleClick('utilities')}>
          Utilities
        </div>
        <div className={classnames("button",{
                selected: (value === 'eventsAndAthletes')
              })} onClick={this.handleClick('eventsAndAthletes')}>
          Events and Athletes
        </div>
        <div className={classnames("button",{
                selected: (value === 'athleteUsers')
              })} onClick={this.handleClick('athleteUsers')}>
          Athlete Users
        </div>
        <div className={classnames("button",{
                selected: (value === 'partnerUsers')
              })} onClick={this.handleClick('partnerUsers')}>
          Partner Users
        </div>
        {/* <ReactTooltip /> */}
        {/* { races && races.slice(0).reverse().map( race => ( */}
        {/* { options && options.map( option => (
          <div
            // data-tip="Select Race" data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow" 
            key={option} onClick={this.openRace(option)} 
            className={classnames("button", 
              {
                selected: (raceSelected && race.RaceCode === raceSelected.RaceCode)
              },
              {
                tooltip: (!raceSelected || race.RaceCode !== raceSelected.RaceCode)
              })}>
            {race.RaceLabelForFilter}
          </div>
        ))} */}

        <div className="line"/>
      </div>
    );
  }
}



const mapStateToProps = ({ raceSelected, raceEvents, requestsPending }) => ({ raceSelected, raceEvents, requestsPending })

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperAdminOptions));

