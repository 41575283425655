import React, { Component } from "react";
import {Link,withRouter} from 'react-router-dom';
import { bindActionCreators } from "redux";
import moment from "moment";
import Header from "../../components/Header/Header";
import MainAthleteBlock from "../../components/MainAthleteBlock/MainAthleteBlock";
import ResultsSummaryBib from "../../components/ResultsSummaryBib/ResultsSummaryBib";
import {connect} from 'react-redux'
import ToggleGraphTable from '../../components/ToggleButton/ToggleGraphTable'
import Footer from '../../components/Footer/Footer'
import classNames from 'classnames'
// import ReactTooltip from 'react-tooltip'
import RaceCourse from './RaceCourse'
import './ResultsSummary.scss'

import * as athletesByRacebibActions from '../../actions/athletesByRacebib'
import * as sponsorsActions from '../../actions/sponsors'
import TooltipText from '../../components/Tooltip/TooltipText'
import Headroom from 'react-headroom';
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import steeltoe from 'steeltoe';
import { getAvatarAndRaces } from '../../actions/users'
import UserAvatar from '../../components/WelcomeBib/UserAvatar'


class ResultsSummary extends Component {

  state = {
    // currentInterval: 0
    toggleGraphTable: 0
  }

  componentDidMount() {
    // console.log('MOUNTED')
    

    // window.analytics.page();

    // if(!this.props.athlete.Bib){ // is athlete in the redux?
    // if(this.props.athletesByRacebib === undefined || !this.props.athletesByRacebib || !this.props.athlete.Bib){ // is athlete in the redux?
      
    window.scrollTo(0, 0);
    
    this.props.athletesByRacebibActions.getAthlete(
        this.props.match.params.raceCode,
        this.props.match.params.athleteBib
      );
      

      // this.props.usersActions.getAvatarAndRaces({
      //     raceCode: this.props.match.params.raceCode,
      //     bib: this.props.match.params.athleteBib
      // })
    // }

    this.props.sponsorsActions.getSponsors(this.props.match.params.raceCode);

    this.getAvatar()
    
    
  }
  componentDidUpdate() {
    this.getAvatar();
  }

  // athletesByRacebib.tsfm-fivekm-2019--30187.main_data.EmailHash

  getAvatar = () => {
    const { raceCode, athleteBib } = this.props.match.params;
    const EmailHash = steeltoe(this.props).get(`athletesByRacebib.${raceCode}--${athleteBib}.main_data.EmailHash`);
    // console.log('getAvatar', EmailHash)
    if(!EmailHash|| this._getAvatarCalled) { return; }
    this._getAvatarCalled = true;
    // console.log('getAvatar DOWNLOAD', EmailHash)
    this.props.usersActions.getAvatarAndRaces({ EmailHash });
  }
  
  



  

  toTitleCase = str => {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }


  updateToggleGraphTable = toggleGraphTable => this.setState({toggleGraphTable})

  

  render() {

    // const { athletesByRacebib, race, settings, user, requestsPending } = this.props;
    const { athletesByRacebib, race, settings, requestsPending, sponsors, match} = this.props;

    
    // console.log("settings",settings);
    if (requestsPending) {
      return null;
    }

    const { toggleGraphTable } = this.state;
    const raceCode = match.params.raceCode;

    // if(!race) {
    //   return (
    //     <div>No race found</div>
    //   )
    // }

    const sponsorsData = sponsors[raceCode] || {};
    const eventSeriesCode = race && race.EventSeriesCode;

    const slug = match.params.raceCode + '--' + match.params.athleteBib;
    const athleteMainData = athletesByRacebib[slug] && athletesByRacebib[slug].main_data;
    const athleteRankingData = athletesByRacebib[slug] && athletesByRacebib[slug].ranking_data;

    // console.log("athleteMainData",athleteMainData)
    // console.log("athleteRankingData",athleteRankingData)

    const mainStatusMissing = steeltoe(athletesByRacebib).get(`${slug}.main_status`)==='missing';
    if(mainStatusMissing) {
      // return (
      //   <div>
      //     Athlete not found
      //   </div>
      // )
    }

    const rankingStatusMissing = steeltoe(athletesByRacebib).get(`${slug}.ranking_status`)==='missing';
    if(mainStatusMissing) {
      // return (
      //   <div>
      //     Data not found
      //   </div>
      // )
    }





    let raceDistance = '';

    let raceEventName = '';
    let raceDate = '';
    let raceDay = '';

    // let showLogo = false;
    
    
    // const raceLogo = sponsorsData.raceLogo;
    // if (sponsorsData && sponsorsData.raceLogo && sponsorsData.raceLogo.linkURL && sponsorsData.raceLogo.altText) {
    //   showLogo = true;

    // }

    // const image = showLogo && !mainStatusMissing && !rankingStatusMissing && (
    //   <a href={raceLogo.linkURL} target="_blank" alt={raceLogo.altText} title={raceLogo.altText} rel="noopener noreferrer" className="topSponsorLink tooltip"><span />
    //     {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
    //     <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`${raceLogo.src}` } />
    //     <TooltipText>Event's Home Page</TooltipText>  
    //   </a> 
      
    //   )

      const raceImage = race  && race.sponsorImages && race.sponsorImages[0];
      // const raceImage = race.RaceDetails.sponsorImages[0].src;
      
      // console.log("raceImage",raceImage);
      const image = raceImage && (
  
          <a href={raceImage.linkURL} target="_blank" alt={raceImage.altText} title={raceImage.altText} rel="noopener noreferrer" className="topSponsorLink tooltip"><span />
            {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
            <img className="topSponsorImage" alt={`${raceImage.altText}`} src={`${raceImage.src}` } />
            <TooltipText>Event's Home Page</TooltipText>
          </a> 
  
      )


    // let athleteDivision = "";
    let athleteBib = "";
    let athleteDivision = null;

    if (athleteMainData && athleteMainData.MyRace) {
      // console.log("athleteMainData",athleteMainData.MyRace );

      if (athleteMainData.MyRace.DivisionEliteGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionEliteGroup === "EliteM" ? "Elite Men" : "Elite Women";
      } else if (athleteMainData.MyRace.DivisionMastersGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionMastersGroup === "MastersM" ? "Masters Men" : "Masters Women";
      } else if (athleteMainData.MyRace.DivisionAthenaClydesdaleGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionAthenaClydesdaleGroup;
      } else if (athleteMainData.MyRace.DivisionVisuallyImpairedGroup) {
        athleteDivision = athleteMainData.MyRace.DivisionVisuallyImpairedGroup === "VisuallyImpairedM" ? "Visually Impaired Men" : "Visually Impaired Women";
      } else {
        if (athleteMainData.MyRace.DivisionAgeGroup) {

          athleteDivision = athleteMainData.MyRace.DivisionAgeGroup.replace('-','–');
        } else {
          
          athleteDivision = athleteMainData.MyRace.DivisionType1.replace('-','–');
        }
      }

      athleteBib = athleteMainData.MyRace.Bib;
      // if (athleteMainData.MyRace.DivisionType1){

      //   athleteDivision = athleteMainData.MyRace.DivisionType1.replace('-','–');
      //   athleteBib = athleteMainData.MyRace.Bib;
      // }
    }

    if (race && race.RaceDateStart) {
      // console.log("RACE",race);
      // raceDate = moment.unix(race.RaceDateStart.seconds).format('LL') ;
      // raceDiscipline = race.RaceDiscipline;
      raceDistance = this.toTitleCase(race.RaceDistance);

      raceEventName = race.EventName
      // raceDate = moment.unix(race.RaceDateStart.seconds).format('LL') ;
      raceDay = moment(race.RaceDateStart).format('ddd') ;
      raceDate = moment(race.RaceDateStart).format('MMM D, YYYY') ;
      // raceDate = '';
      // raceDiscipline = '';
      // raceDistance = '';
    } else {
      // raceDate = null;
      // raceDiscipline = null;
      raceDistance = null;

      raceEventName = null;
      raceDate=null;
      raceDay=null;

    }

    const avatarIcon = athleteMainData && athleteMainData.Gender==='M' ? maleIcon : femaleIcon;

            
    


    // const noOfIntervals = athlete && athlete["event-intervals-data"] ? athlete["event-intervals-data"].length: null;

    const page = match.path.split('/')[1];

    
    return (
      <div className="ResultsSummary">
        <Headroom>
          <Header title="RESULTS SUMMARY" page={page} race={race} athlete={athleteMainData} />
        </Headroom>
 

        <div>

          <div className="container">
          {image}
          </div>
        </div>

          
          {/* <ReactTooltip  />   */}

        
          { !mainStatusMissing && !rankingStatusMissing && (
          <MainAthleteBlock athlete={athleteMainData}>
          

          <div>


            <div className="MainAthleteBlockHeader">
          

              <UserAvatar 
                EmailHash={steeltoe(athleteMainData).get('EmailHash')} 
                Gender={steeltoe(athleteMainData).get('Gender')} // 'M', 'F'
              />


              
              <div className="flexgrow">
                <Link 
                  className="MarathonTitle"
                    to={`/select-athlete/${this.props.race.EventCode}/${this.props.race.RaceCode}`}>
                      
                    {/* <span data-tip="Open" data-delay-show='1000' data-effect="solid" data-place='bottom' data-class="MyRaceTooltip"> */}
                    <span className="tooltip">
                      <TooltipText>Race's <em>Home Page</em></TooltipText>
                      <strong>{this.props.race.EventName}</strong>
                    </span>
                </Link> 

                <span className="MarathonDates">{raceDay}<i className="dot">•</i>{raceDate}</span>
              </div>
            </div>


            

              <div className="Label">
                <i/>
                  <span>
                    {this.props.race.RaceDistance}
                  </span>
                <i/>
              </div>

              <div className="EdgesLine">
                <div><strong>#{athleteBib}</strong></div>
                <div><strong>{athleteDivision}</strong></div>
              </div>
            </div>

            <ResultsSummaryBib race={race} athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} settings={settings} />
          </MainAthleteBlock>
          )}


        { !mainStatusMissing && !rankingStatusMissing ? (
        <>
          <ToggleGraphTable  value={toggleGraphTable} onChange={this.updateToggleGraphTable} titles={[ 'Table', 'Elevation']}/>


           <div className="containerRaceLayout flexgrow">
            <RaceCourse athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} race={race} toggleGraphTable={toggleGraphTable} />
           </div>

           </> ) : (
             <div className="container flexgrow">Data not found</div>
           )}

           <Footer />
        

      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  race: state.raceSelected,
  settings: state.settings,
  user: state.user,
  requestsPending: state.requestsPending,
  sponsors: state.sponsors,
})

const mapDispatchToProps = dispatch => ({
  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
  sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
  usersActions: bindActionCreators({getAvatarAndRaces}, dispatch), // this.props.usersActions.getUserAndAvatar(EmailHash)
    
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultsSummary));
