import React, { Component } from "react";
import './AthleteListTable.scss'
// import Pagination from '../Pagination/Pagination';
import PaginationLine from '../Pagination/PaginationLine';
import {connect} from 'react-redux'
import Row from './AthleteListRow'
import throttle from 'lodash.throttle'
import Beacon from '../Beacon/Beacon'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'
import * as athletesByFilterActions from '../../actions/athletesByFilter'
import * as favoriteAthletesActions from '../../actions/favoriteAthletes'
import {formatNumber} from '../../timeUtils'
import steeltoe from 'steeltoe'
import AthleteDivisionCells from './AthleteDivisionCells';
import ReactTooltip from 'react-tooltip'
// import classNames from 'classnames'

import { getAthletesByFilterState } from '../../re-selectors/athletesByFilterSelectors'
import SorterButton from "./SorterButton";


class AthleteListTable extends Component {
    // constructor(props){
    //     super(props);
    //     // this.tableHeaderEl = React.createRef();
    //     // this.tableBodyEl = React.createRef();
    //     // this.onResize = throttle( this.onResize, 330 )
    // }

    // state = {
    //     tableBodyHeight: 550
    // }

    
    componentDidMount(){

        this.props.athletesByFilterActions.getAthletesByFilter();
        
        // this.getData();
        this.props.setSelectedAthlete(null);
        // if(raceSelected && raceSelected.RaceCode){
        //     this.requestPaginationData();
        // }
        // this.startListeningResize();
        // window.setTimeout(this.onResize, 50);
    }
    componentWillUnmount(){
        // this.stopListeningResize();
        if(this.setPageTimeout) window.clearTimeout(this.setPageTimeout);
    }
    // startListeningResize = () => {
    //     window.addEventListener('resize', this.onResize);
    // }
    // stopListeningResize = () => {
    //     window.removeEventListener('resize', this.onResize);
    // }

    // onResize = () => {
    //     // const el = document.querySelector('.table_header'); 
    //     // const el = this.tableHeaderEl.current; 
    //     // if(!el) {return;}
    //     // var tableBodyH = window.innerHeight - (el.offsetTop+15+el.offsetHeight+65); 
    //     // c.style.maxHeight = tableBodyH + 'px';
    //     // const tableBodyHeight = window.innerHeight - (el.offsetTop+15+el.offsetHeight+65+60); 


    //     // const tableBodyHeight = window.innerHeight - 270 /*headerHeight*/ - 190 /*footerHeight*/; 
    //     // this.setState({ tableBodyHeight })


    //     // console.log("tableBodyHeight",tableBodyHeight)
    // }

    componentDidUpdate(prevProps, prevState){

        // console.log('[AthleteListTable] did update')

        this.props.athletesByFilterActions.getAthletesByFilter();


        const RaceCode = steeltoe(this.props).get('raceSelected.RaceCode', '');
        if( RaceCode && steeltoe(prevProps).get('raceSelected.RaceCode', '') !== RaceCode ){
            // console.log('RACECODE CHANGED:',steeltoe(prevProps).get('raceSelected.RaceCode', '') , '->', RaceCode);
            this.getFavorites(RaceCode);
        }
    }


    getFavorites = (RaceCode) => {
        this.props.favoriteAthletesActions.getFav(RaceCode);
    }
    

    


    setPage = page => {
        // if(this.state.requestsInProcess) { return; }
        if(this.props.requestsPending){
            this.setPageTimeout = window.setTimeout(()=>this.setPage(page), 100);
            return;
        }
        // console.log('[setPage_]', page);
        // this.setState({ page });
        this.props.setPage(page);
    }

    setPerPage = perPage => {
        this.props.setPerPage(perPage);
    }

    // onMouseLeaveTable = e => {
    //     this.props.setMouseoveredCol(null);
    // }

    

    

    beaconDone = beaconId => e => {
        // console.log('beaconDone onclick', beaconId,e);
        this.props.settingsActions.setBeaconDone(beaconId)
        // this.props.settingsActions.setBeaconDone("athleteList")
    }
    // beaconDone = beaconId => e => {
    //     console.log('beaconDone onclick', beaconId,e);
    //     // this.props.settingsActions.setBeaconDone(beaconId)
    //     this.props.settingsActions.setBeaconDone("athleteList")
    // }


    renderDesktopList = ({totalItems, athletes, showHalfSplits,useEliteMastersVIPlacing, showAgeGrade, raceSelected, RaceCode, page, perPage}) => {
        // const {mouseoveredCol} = this.props.settings;

        const raceAthleteCategories = raceSelected.RaceAthleteCategories && raceSelected.RaceAthleteCategories.split(',');

   
        return (
            <>
                {/* <table className="table_header" ref={this.tableHeaderEl}>
                    
                </table> */}

                <div className="table_body_wrapper" style={{ 
                    maxHeight: 550,
                    // maxHeight: this.state.tableBodyHeight
                    }}>
                    <ReactTooltip  />
                    <table className="table_body" >
                        <thead>
                            <tr>
                                <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '3%'}}>Overall<br/>Place<SorterButton col="IndexFinish"/></th>
                                <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '13%'}}>Name<SorterButton col="Name"/></th>
                                <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '13%'}}>Location<SorterButton col="HometownSlug"/></th>
                                <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '4%'}}>Bib<SorterButton col="Bib"/></th>
                                <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '5%'}}>Official<br/>Time<SorterButton col="PlaceOverall"/></th>
                                <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '4%'}}>Pace,<br/>m/mi<SorterButton col="FinishEnglishPace"/></th>
                                 {/* 42 */}
                                {showHalfSplits ? 
                                    <>
                                        <th 
                                            data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                            style={{ width: '5%'}}>1st<br/>Half<SorterButton col="FirstHalfTimeSeconds"/></th>
                                        <th 
                                            data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                            style={{ width: '5%'}}>2nd<br/>Half<SorterButton col="SecondHalfTimeSeconds"/></th>
                                        <th 
                                            data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                            style={{ width: '4%'}}>Diff<SorterButton col="SplitsAchievedDifferenceSeconds"/></th>
                                    </>
                                    :
                                    null
                                }
                            
                                {
                                    <AthleteDivisionCells raceAthleteCategories={raceAthleteCategories} isHeader={true} />
                                }
                                {showAgeGrade ? <th 
                                    data-tip="Sort" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                                    style={{ width: '6%'}}>Age<br/>Grade<SorterButton col="AgeGrade"/></th> : null}
                                {/* {useEliteMastersVIPlacing ? 
                                    <>

                                        <th style={{ width: '3%'}}>Age</th>
                                        <th style={{ width: '5%'}}>Elite<br/>Group</th>
                                        <th style={{ width: '5%'}}>Elite<br/>Group<br/>Place</th>
                                        <th style={{ width: '6%'}}>Age<br/>Group</th>
                                        <th style={{ width: '5%'}}>Age<br/>Group<br/>Place</th>
                                        <th style={{ width: '5%'}}>Masters<br/>Group</th>
                                        <th style={{ width: '5%'}}>Masters<br/>Group<br/>Place</th>
                                        <th style={{ width: '5%'}}>VI<br/>Group</th>
                                        <th style={{ width: '5%'}}>VI<br/>Group<br/>Place</th>
                                        {showAgeGrade ? <th style={{ width: '3%'}}>Age<br/>Grade</th> : null}
                                    </> : 
                                    <>

                                        <th style={{ width: '6%'}}>Div</th>
                                        <th style={{ width: '5%'}}>Div<br/>Place</th>
                                        <th style={{ width: '6%'}}>Gender<br/>Place</th>
                                        {showAgeGrade ? <th style={{ width: '13%'}}>Age<br/>Grade</th> : null}
                                    </>
                                } */}
     
                            </tr>
                        </thead>
                        <tbody>
                            {athletes.map( (athlete, i) => (
                                <Row 
                                    race={raceSelected} 
                                    raceCode={RaceCode} 
                                    showAgeGrade={showAgeGrade}
                                    key={athlete.Bib} 
                                    row={athlete} 
                                    index={(page-1)*perPage + i} 
                                    onClick={this.beaconDone("athleteList")}
                                    />
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    renderMobileList = ({totalItems, athletes, showHalfSplits, raceSelected, RaceCode, page, perPage}) => {
        return (
            <div>
            {athletes.map( (athlete, i) => (
                <Row 
                    isMobile
                    race={raceSelected} 
                    raceCode={RaceCode} 
                    key={athlete.Bib} 
                    row={athlete} 
                    index={(page-1)*perPage + i} 
                    onClick={this.beaconDone("athleteList")}
                    />
            ))}
            </div>
        )
    }

    
    renderList = (params) => {
        // console.log("params.totalItems",params.totalItems)
        // console.log("params.athletes",params.athletes)
        if(params.totalItems === -1 || !params.athletes) {
            return null;
        }
        const { settings: {windowW} } = this.props;
        return windowW > 800 ? this.renderDesktopList(params) : this.renderMobileList(params);
    }

    render() {
        // const { requestsInProcess } = this.state;
        const { raceAthleteFilter, raceSelected, athleteFilterResults, filteredAthletes, settings: {windowW} } = this.props;

       
        // const { page, perPage } = raceAthleteFilter;
        const { perPage, page } = raceAthleteFilter;
        // const { /*pages,*/ totalItems } = athleteSearchResults;
        // const totalItemsCalculated = totalItems > 0 ? totalItems : perPage * (athleteSearchResults.lastIndexes.length-1) + " (so far)";
        
        const showHalfSplits = raceSelected && raceSelected.ShowHalfSplits;
        const useEliteMastersVIPlacing = raceSelected && raceSelected.UseEliteMastersVIPlacing;

        let showAgeGrade = true;
        if (raceSelected && raceSelected.showAgeGrade === false) {
            showAgeGrade = false;
        }
        // console.log("ShowAgeGrade",showAgeGrade);
        let RaceCode = raceSelected && raceSelected.RaceCode;
        let isOfficialData = raceSelected && raceSelected.AdminStatus && (raceSelected.AdminStatus === "Public");


        const { totalItems, totalPages } = filteredAthletes;

        // const key = this.getKey();
        // const keyWithPage = this.getKeyWithPage();

        const athletes = steeltoe(filteredAthletes).get(`pages.${page}.data`, []);

        // let athletes;
        // let resultsObj;
        // if(athleteFilterResults[key]){
        //     resultsObj = athleteFilterResults[key];
        //     // athletes = resultsObj.pages[page];
        // }
        
        const athletesOnThisPage = athletes && athletes.length ? athletes.length : 0;
        // console.log("athletesOnThisPage",athletesOnThisPage)


        

        let currentPageLabel = '';
        // console.log("totalItems", totalItems);
        if (totalItems === -1) {
            currentPageLabel = 'No Athletes Found';
        } else {
            currentPageLabel = `${formatNumber((page-1)*perPage + 1)}–${formatNumber((page-1)*perPage + athletesOnThisPage)}`;
        }
        // console.log("currentPageLabel",currentPageLabel);
        
        const ofString = ` of `;

        // console.log("totalItems",totalItems)
        // console.log("raceSelected.HasPhotoPartner?",raceSelected && raceSelected.HasPhotoPartner)
 


        if(!RaceCode || steeltoe(filteredAthletes).get(`pages.${page}.status`) !== 'received'){
            return <TableWrapperAndPagination isOfficialData={isOfficialData} currentPageLabel={currentPageLabel} totalItems={totalItems} ofString={ofString} page={page} perPage={perPage} setPage={this.setPage} setPerPage={this.setPerPage}>
                <div>... loading ...</div>
            </TableWrapperAndPagination>;
        }
        
        
        return (
            <TableWrapperAndPagination isOfficialData={isOfficialData} windowW={windowW} currentPageLabel={currentPageLabel} totalItems={totalItems} ofString={ofString} page={page} perPage={perPage} setPage={this.setPage} setPerPage={this.setPerPage}>

                {/* <Beacon id="athleteList" text={"Select an athlete"} direction="down" style={{ top:'67px', left:'120px'}} /> */}

                
                

                {this.renderList({totalItems, athletes, showHalfSplits,useEliteMastersVIPlacing, showAgeGrade, raceSelected, RaceCode, page, perPage})}



            </TableWrapperAndPagination>
        );
    }
}


const TableWrapperAndPagination = (props) => {

    
    return (
        <div className="AthleteListTable">

            <PaginationLine {...props} />

            {props.children}

            { props.windowW < 800 ? <PaginationLine {...props} /> : null }
            
                
        </div>
    )
}





const mapStateToProps = state => {
    // console.log('raceAthleteFilter:', JSON.stringify(state.raceAthleteFilter));

    // const { totalPages, totalItems } = getAthletesByFilterState(state);
    
    return {
        requestsPending: state.requestsPending,
        raceSelected: state.raceSelected,
        athleteSearchResults: state.athleteSearchResults,
        
        raceAthleteFilter: state.raceAthleteFilter,
        athleteFilterResults: state.athleteFilterResults,
        settings: state.settings,
        // athletesByFilter: state.athletesByFilter,
        filteredAthletes: getAthletesByFilterState(state), // <- athletesByFilter[FILTERKEY]

        // raceAthleteFilter: state.filter
    }
}

const mapDispatchToProps = dispatch => ({
    setSelectedAthlete: athlete => dispatch({type:'SET_SELECTED_ATHLETE', athlete}),
    // resetRaceAthleteFilter: filter => dispatch({type:'SET_RACE_ATHLETE_FILTER', filter: 'All-Genders' } ),
    setPage: page => dispatch({type:'SET_RACE_ATHLETE_PAGE', page}),

    setPerPage: perPage => dispatch({type:'SET_RACE_ATHLETE_PERPAGE', perPage}),

    
    addPageFilterSearchResults: (dataKey, payload) => dispatch({type:'ATHLETE_FILTER_RESULTS/ADD_PAGE_SEARCH_RESULTS', dataKey, payload}),
  
    setFilterTotalPages: (dataKey, totalPages, totalItems) => dispatch({ type:'ATHLETE_FILTER_RESULTS/SET_TOTAL_PAGES', dataKey, totalPages, totalItems }),


    incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
    decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),

    // setMouseoveredCol: (colnum)=>dispatch({type:'SET_SETTING', mouseoveredCol: colnum }),
    
    favoriteAthletesActions: bindActionCreators(favoriteAthletesActions, dispatch),

    athletesByFilterActions: bindActionCreators(athletesByFilterActions, dispatch),
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
  
})



export default connect(mapStateToProps, mapDispatchToProps)(AthleteListTable);
  
