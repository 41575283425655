import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';


function SecuredSuperAdminRoute(props) {
  // const {component: Component, path, fbuser, fbuserProfile, requestsPending, exact} = props;
  const {component: Component, path, fbuser, fbuserProfile } = props;
  // console.log("fbUserProfile",fbuserProfile);

  // if(!fbuser) {
  //     return null;
  // }

  return (
    <Route exact path={path} render={() => {

        // // if user, but !user.emailVerified AND user.created > 4 days
        // // then redirect to /account/verifyEmail - page where they only see the verify button
        // if (fbuser && !fbuser.emailVerified) {
        //   const userCreated = +fbuser.createdAt;
        //   const now = (new Date()).getDate();

        //   const secPassedAfterCreated = (userCreated - now)/1000;
        //   const daysPassedAfterCreated = secPassedAfterCreated / 60 / 60 / 24;
        //   if(daysPassedAfterCreated > 4){
        //     return (
        //       <Redirect
        //         to={{
        //           pathname: "/verifyEmail",
        //           state: { from: props.location }
        //         }}
        //         />
        //       )
        //   }
        // }
        
        // if (!fbuser && !fbuserProfile.) {
        if (!fbuser || ( fbuserProfile && !fbuserProfile.isSuperAdmin)) {
          return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
            />
          )
        }
        
        return <Component {...props} />
    }} />
  );
}

const mapState = ({fbuser,fbuserProfile,requestsPending})=>({fbuser,fbuserProfile,requestsPending});

export default connect(mapState)(SecuredSuperAdminRoute);