import React, { Component } from 'react'
import AthleteHomeTimeline from './AthleteHomeTimeline'
import AthleteHomeRaceCard from './AthleteHomeRaceCard'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'


class AthleteHomeRaces extends Component {
    // state = {
    //     // selectedRace: null, // objectID of the selected race
    //     selectedRaceIndex: null, // index of the selected race
    // }
    next = () => {}
    prev = () => {}

    selectRace = selectedRaceIndex => {
        const { athletes: races, settings: { athleteHomeSelectedRace }, match: { params: {athleteHash}} } = this.props;

        
        let nextState = {};
        if(athleteHomeSelectedRace && typeof athleteHomeSelectedRace === 'object'){
            nextState = {...athleteHomeSelectedRace};
        }

        nextState[athleteHash] = selectedRaceIndex;

        // this.setState({ selectedRaceIndex })
        // this.props.setAthleteHomeSelectedRace(nextState);

        const lastSelectedRaceCode = races[selectedRaceIndex].RaceCode;
        const lastSelectedRaceCodeBib = races[selectedRaceIndex].Bib;

        // console.log('lastSelectedRaceCode:', lastSelectedRaceCode)

        this.props.setSetting({
            athleteHomeSelectedRace: nextState,
            lastSelectedRaceCode,
            lastSelectedRaceCodeBib,
        })
    }

    goToSelectedRace = () => {
        const { athletes: races  } = this.props;
        const racesFiltered = races && races.filter(race => (race.RaceDetails !== undefined && (race.RaceDetails.AdminStatus == 'Public' || race.RaceDetails.AdminStatus == 'Public-Unofficial'))); 
    
        //  /welcome/RACE-CODE/athletes/BIB
        const selectedRaceIndex = this.getSelectedRaceIndex();
        const race = racesFiltered[selectedRaceIndex];
        const uri = `/welcome/${race.RaceCode}/athletes/${race.Bib}`
        this.props.history.push(uri);
    }

    getSelectedRaceIndex = () => {
        const { athletes: races, settings: { athleteHomeSelectedRace, lastSelectedRaceCode, lastSelectedRaceCodeBib },  match: { params: {athleteHash}} } = this.props;


        const athleteRacesFiltered = races.filter(race => (race.RaceDetails !== undefined && (race.RaceDetails.AdminStatus == 'Public' || race.RaceDetails.AdminStatus == 'Public-Unofficial'))); 
    
        let theRaceIndex = -1;
        // console.log("lastSelectedRaceCode",lastSelectedRaceCode)
        if(athleteRacesFiltered && !!athleteRacesFiltered.length && lastSelectedRaceCode && lastSelectedRaceCodeBib){
            let theRaceIndex = athleteRacesFiltered.findIndex(r => (r.RaceCode === lastSelectedRaceCode && r.Bib === lastSelectedRaceCodeBib));
            return theRaceIndex;
        } else if (athleteRacesFiltered && !!athleteRacesFiltered.length && lastSelectedRaceCode){
            theRaceIndex = athleteRacesFiltered.findIndex(r => r.RaceCode === lastSelectedRaceCode);
            if (theRaceIndex === -1) {
                return 0;
            } 
            return theRaceIndex;
        } 
        

        return athleteHomeSelectedRace 
            && typeof athleteHomeSelectedRace[athleteHash]==='number' ? 
                athleteHomeSelectedRace[athleteHash] // <- pre-set index
                : (athleteRacesFiltered && !!athleteRacesFiltered.length ? athleteRacesFiltered.length-1 : 0);
    }

    render(){
        // const { selectedRaceIndex } = this.state;

        const { athletes: athleteRaces, haveDifferentNames } = this.props;

        
        // const {races} = this.props;
        // const races = mockRaces;
        // console.log("mockRaces",mockRaces);
        // console.log("athlete-races",athletes);
        // races && console.log("has races object");
        // races && console.log("value of length races object",!!races.length);
        console.log("athleteRaces938",athleteRaces);

        const selectedRaceIndex = this.getSelectedRaceIndex();

        // console.log("selectedsRaceIndex",selectedRaceIndex);
        return (
            <div className="AthleteHomeRaces">
                { athleteRaces && !!athleteRaces.length && <AthleteHomeTimeline 
                    athleteRaces={athleteRaces} 
                    onRaceSelected={this.selectRace}
                    selectedRaceIndex={selectedRaceIndex}
                    goToSelectedRace={this.goToSelectedRace}
                    /> }
                <AthleteHomeRaceCard
                    athleteRaces={athleteRaces} 
                    showName={haveDifferentNames}
                    onRaceSelected={this.selectRace}
                    selectedRaceIndex={selectedRaceIndex}
                    goToSelectedRace={this.goToSelectedRace}
                />
            </div>
        )
    }
}

const mapState = ({settings}) => ({settings})

const mapDispatch = dispatch => ({
    // setAthleteHomeSelectedRace: (athleteHomeSelectedRace) => dispatch({type:'SET_SETTING', athleteHomeSelectedRace}),
    setSetting: (upd) => dispatch({type:'SET_SETTING', ...upd }),
})

export default withRouter(connect(mapState, mapDispatch)(AthleteHomeRaces));