import React from "react";
import {connect} from 'react-redux'
import steeltoe from 'steeltoe';
import {getAvatarImage} from '../../pages/AccountProfile/avatars'
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import genericIcon from '../../images/user-05.svg'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import TooltipText from '../Tooltip/TooltipText'

const UserAvatar = ({
    EmailHash, 
    Gender, // 'F', 'M'
    users}) => {
    let avatar = null;

    let uploadedImage;
    if (EmailHash.length > 0) {
        uploadedImage = steeltoe(users).get(`${EmailHash}.avatarImageUrl`);
        avatar = getAvatarImage({
            avatarName: steeltoe(users).get(`${EmailHash}.profile.avatarId`),
            uploadedImage
        })
    }
    
    
    // console.log("avatar",avatar)
    if(!avatar){
        switch(Gender){
            case 'M': avatar = maleIcon; break;
            case 'F': avatar = femaleIcon; break;
            default: avatar = genericIcon
        }
    }
    // if (!avatar) {
    //     avatar = Gender==='M' ? maleIcon : femaleIcon;
    // }

    // let avatarNameToShow = steeltoe(users).get(`${EmailHash}.profile.avatarId`);
    // const isDefaultImageSelected = avatarNameToShow==='imgM7'||avatarNameToShow==='imgF7';

    let avatarNameToShow = '';
    if (EmailHash.length > 0) {
        avatarNameToShow = steeltoe(users).get(`${EmailHash}.profile.avatarId`);
    } else {
        if (Gender === 'M') {
            avatarNameToShow = 'imgM7';
        } else {
            avatarNameToShow = 'imgF7';

        }
    }
    const isDefaultImageSelected = avatarNameToShow==='imgM7'||avatarNameToShow==='imgF7';


    return avatar ? (
        <Link to={`/athlete/${EmailHash}`} className={classNames("userAvatarContainer tooltip", {
            uploadedImage: uploadedImage&&isDefaultImageSelected
        })}>
            <TooltipText>Go to <em>Athlete's Home</em></TooltipText>
            <img src={ avatar } alt="user-avatar" className="userAvatar" />
        </Link>
    ) :null;
}




const mapStateToProps = ({ users }) => ({ users })

export default connect(mapStateToProps)(UserAvatar);
