import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import steeltoe from 'steeltoe'
// import moment from "moment";
import './AthleteHomeRaceCard.scss'
import classNames from 'classnames'
import TooltipText from '../../components/Tooltip/TooltipText'
// import navNext from '../../images/next-big-03.svg';
import navNext from '../../images/next-06.svg';

import openGray from '../../images/open-02.svg';
import openBlue from '../../images/open-01.svg';
import FavIconLink from "../../components/AthleteListTable/FavIconLink";


class AthleteHomeRaceCard extends Component {

    componentDidMount(){
        this.startListeners();
    }

    componentWillUnmount(){
        this.stopListeners();
        if(this.timeoutId) window.clearTimeout(this.timeoutId);
    }

    startListeners = () => {
        window.document.addEventListener('keyup', this.onKeyup)
    }
    stopListeners = () => {
        window.document.removeEventListener('keyup', this.onKeyup)
    }

    goTo = direction => e => {
        const {selectedRaceIndex, athleteRaces, onRaceSelected} = this.props;
        // const raceIndex = athleteRaces.findIndex(r => r.objectID === selectedRace);
        if(direction==='prev' && selectedRaceIndex>0){
            // onRaceSelected(athleteRaces[raceIndex-1].objectID);
            // console.log("current index",selectedRaceIndex);
            // console.log("about to be index",selectedRaceIndex-1);
            onRaceSelected(selectedRaceIndex-1);
        } else if(direction==='next' && selectedRaceIndex<athleteRaces.length-1){
            onRaceSelected(selectedRaceIndex+1);
        }
    }


    onKeyup = (e) => {
        // console.log('KEYUP', e.key, e);
        const { key } = e;
        const {selectedRaceIndex, athleteRaces, onRaceSelected} = this.props;

        if(key==='ArrowLeft' && selectedRaceIndex>0){
            // onRaceSelected(races[raceIndex-1].objectID);
            // console.log("current index",selectedRaceIndex);
            // console.log("about to be index",selectedRaceIndex-1);
            onRaceSelected(selectedRaceIndex-1);
        } else if(key==='ArrowRight' && selectedRaceIndex<athleteRaces.length-1){
            onRaceSelected(selectedRaceIndex+1);
        }
        
      }

    render(){
        const {selectedRaceIndex, athleteRaces, showName} = this.props;
        // const race = races.find(r => r.objectID === selectedRace);

        const athleteRacesFiltered = athleteRaces.filter(race => (race.RaceDetails !== undefined && (race.RaceDetails.AdminStatus == 'Public' || race.RaceDetails.AdminStatus == 'Public-Unofficial'))); 
    
        
        console.log("RACECARD races",athleteRacesFiltered, selectedRaceIndex)
        // if(!athleteRaces || !athleteRaces[selectedRaceIndex] || !athleteRaces[selectedRaceIndex].RaceDetails || !athleteRaces[selectedRaceIndex].RunRankingAndPassing) {
            if(!athleteRacesFiltered || !athleteRacesFiltered[selectedRaceIndex] || !athleteRacesFiltered[selectedRaceIndex].RaceDetails || !athleteRacesFiltered[selectedRaceIndex].RunRankingAndPassing) {


            return null;
        }
        
        const athleteRace = athleteRacesFiltered[selectedRaceIndex];


        // const logoSrc = `/images/tsfm/partners/sf-logo-lockup-tm@3x.png`;
        // const raceImageSrc = athleteRace && athleteRace.RaceDetails && athleteRace.RaceDetails athleteRace.RaceDetails.sponsorImages[0].src;
        const raceImageSrc = steeltoe(athleteRace).get(`RaceDetails.sponsorImages[0].src`);

        // const logoSrc=  raceImageSrc.includes('https') ? raceImageSrc : `/images/${athleteRace.RaceDetails.EventSeriesCode}/partners/${athleteRace.RaceDetails.sponsorImages[0].src}`;
        const logoSrc=  raceImageSrc && raceImageSrc.includes('https') ? raceImageSrc : null;
     
        // console.log("raceImageSrc",raceImageSrc);
        // console.log("logoSrc",logoSrc);
        // console.log("race",race);
    

        let nextLinkUrl = ''; let prevLinkUrl = '';
        let prevPageTooltipText = 'Previous Race';
        let nextPageTooltipText = 'Next Race';

        const prevButtonDisabled =selectedRaceIndex<1;
        const nextButtonDisabled =selectedRaceIndex>=athleteRacesFiltered.length-1;

        const eventIntervalData = athleteRacesFiltered[selectedRaceIndex].RunRankingAndPassing["run-event-intervals-data"];
        // console.log("eventIntervalData",eventIntervalData);
        // console.log("eventIntervalData[eventIntervalData.length]",eventIntervalData[eventIntervalData.length-1]);

        const flag = athleteRacesFiltered[selectedRaceIndex].RaceDetails.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));

        
        return (
            <div className="AthleteHomeRaceCard">

                <div className="PrevButton">
                    <div
                        className={classNames("prevPage", {
                            tooltip:  !prevButtonDisabled,
                            disabled:  prevButtonDisabled
                        }) }
                        role="button"
                        onClick={this.goTo('prev')}
                        >
                        <span className="background" />
                        {/* { !prevButtonDisabled && <img src={navNext } /> } */}
                        <img src={navNext} />

                        { !prevButtonDisabled && <TooltipText>Go to <em>{prevPageTooltipText}</em></TooltipText>}
                    </div>
                </div>

                <div className="EventCard tooltip" onClick={this.props.goToSelectedRace}>
                    <div className="OpenRaceIcon">
                        <div className="IconSwappable">
                            <div className="icon" style={{ backgroundImage: `url(${openGray})` }} />
                            <div className="hover" style={{ backgroundImage: `url(${openBlue})` }} />
                        </div>
                    </div>
                    
                    <TooltipText><em>More details</em></TooltipText>

                    <FavIconLink row={athleteRacesFiltered[selectedRaceIndex]}/>

                    { logoSrc &&  <div className="EventImage">
                        <img src={logoSrc} alt="event logo"/>
                    </div>
                    }

                    <div className="EventTitle">{athleteRace.RaceDetails.RaceName}</div>

                    { showName && 
                        <div className="EventLocation">
                            {athleteRace.FirstName} {athleteRace.LastName}
                        </div>
                    } 

                    <div className="EventLocation">
                        <span className="Flag">{flag}</span>
                    {athleteRacesFiltered[selectedRaceIndex].RaceDetails.Location}</div>

                    <dl className="EventInfo">
                        <dt>Finish Time</dt>
                        <dd>{athleteRace.OfficialTime}</dd>
                        <dt>Place</dt>
                        {/* <dd>523</dd> */}
                        <dd>{eventIntervalData[eventIntervalData.length-1].All.IntervalCumulativeRank}</dd>
                        <dt>Bib</dt>
                        <dd>{athleteRace.Bib}</dd>
                        <dt>Participants</dt>
                        <dd>{athleteRace.RaceParticipants}</dd>
                    </dl>

                </div>
                <div className="NextButton">
                    <div
                        className={classNames("nextPage", {
                            tooltip: !nextButtonDisabled,
                            disabled: nextButtonDisabled
                        }) }
                        role="button"
                        onClick={this.goTo('next')}
                        >
                        <span className="background" />
                        {/* { !nextButtonDisabled && <img src={navNext } /> } */}
                        <img src={navNext } /> 
      
                    { !nextButtonDisabled && <TooltipText>Go to <em>{nextPageTooltipText}</em></TooltipText> }
                    </div>
                </div>
                
            </div>
        )
    }
}

export default withRouter(AthleteHomeRaceCard)