import React, { Component } from "react";
import { connect } from 'react-redux'
import PartnerModalForm from "../HomeBib/PartnerModalForm";
import RequestRaceModalForm from "../HomeBib/RequestRaceModalForm";
import logo from "../../images/logo.svg"
import './Footer.scss'

class Footer extends Component {
    openPartnerWithUs = e => {
        // console.log("HERE");
        e.preventDefault();
  
        this.props.openPartnerModal();
    }
    openRequestRace = e => {
        // console.log("HERE");
        e.preventDefault();
  
        this.props.openRequestModal();
    }

    render() {
        return (
            <>
                <div className="Footer">
                    <div className="container">

                        <div className="Footer-columns">
                            <div>
                                <a href="/" className="Footer-logo"><img src={logo} alt="MyRace logo" /></a>
                                <p className="info">Your definitive source for race performance analysis</p>
                            </div>
                            <div>
                                <h6>About</h6>
                                <p><a target="_blank" href="https://about.myrace.com/article/4-what-is-myrace">What is MyRace?</a></p>
                                <p><a target="_blank" href="https://about.myrace.com/category/8-faq">FAQ</a></p>
                                <p><a target="_blank" href="https://about.myrace.com/article/38-myrace-product-updates">Product Updates</a></p>
                            </div>
                            <div>
                                <h6>Platform</h6>
                                <p><a target="_blank" href="https://about.myrace.com/collection/1-myrace-athletes">For Athletes</a></p>
                                <p><a target="_blank" href="https://about.myrace.com/collection/5-myrace-partners">For Partners</a></p>
                            </div>
                            <div className="Footer-contact">
                                <h6>Contact</h6>
                                <p><a target="_blank" href="https://about.myrace.com/article/25-contact-us">Contact Us</a></p>
                                <p><a href="#" onClick={this.openPartnerWithUs}>Partner with Us</a></p>
                                <p><a href="#" onClick={this.openRequestRace}>Request a Race</a></p>
                                <p><a target="_blank" href="https://about.myrace.com/article/44-what-is-myrace-ads">For Advertisers</a></p>
                            </div>
                            <div>
                                <h6>Follow us</h6>
                                <p><a target="_blank" href="https://www.linkedin.com/company/myrace">LinkedIn</a></p>
                                <p><a target="_blank" href="https://www.facebook.com/MyRaceHQ">Facebook</a></p>
                                <p><a target="_blank" href="https://twitter.com/MyRaceHQ">Twitter</a></p>
                            </div>

                        </div>


                        <div className="Footer-copy-line">
                            <div className="Footer-copy-container">
                                <span className="Footer-copyright">Copyright &copy; MyRace Inc. All rights reserved.</span>
                                <span className="Footer-privacy">
                                    <a target="_blank" href="https://about.myrace.com/article/30-privacy-policy">Privacy Policy</a>
                                </span>
                            </div>
                            <div>
                                🇺🇸 Mountain View, California
                            </div>
                        </div>
                        
                        
                    </div>

                </div>
                {/* <PartnerModalForm />
                <RequestRaceModalForm /> */}
            </>
        )
    }
}


const mapState = ({settings}) => ({settings});
const mapDispatch = dispatch => ({
    openPartnerModal: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),
    openRequestModal: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),
})


export default connect(mapState, mapDispatch)(Footer);