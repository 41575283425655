import React, { Component } from "react";
import classnames from 'classnames'
import "./ToggleButton.scss"

class ToggleGeotype extends Component {

    handleClick = value => e => {
        this.props.onChange(value)

        // window.analytics.track('Toggle Hometown Search City - State - Country', {
        //     "Selected": value // geotype
        //   });
    }
    
    render(){
        const { titles, value } = this.props;

        return (
            <>
                <div className="ToggleButton">
                    {
                        titles.map( (title, i) => (
                            <div key={i} data-tip={ title } data-delay-show='500' onClick={ this.handleClick(title) } className={classnames({ on: value === title })}>{ title }</div>
                        ))
                    }
                </div>
            </>
            
        )
    }
}

export default ToggleGeotype
