import React, { Component, useState, useEffect } from 'react'
import {Link, withRouter} from 'react-router-dom'
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import Headroom from 'react-headroom';
import TooltipText from '../../components/Tooltip/TooltipText'
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as athletesByRacebibActions from '../../actions/athletesByRacebib';
import * as fbuserActions from '../../actions/fbuser'
import * as favoriteAthletesActions from '../../actions/favoriteAthletes'
import { getAvatarAndRaces } from '../../actions/users'
import {getAvatarImage} from '../AccountProfile/avatars'
import genericIcon from '../../images/user-05.svg'
import './AccountFavorites.scss'
import steeltoe from 'steeltoe';
import {secondsToTimeStrip,secondsToTime,secondsToTimeStripWithSign, roundDistance, formatNumber} from '../../timeUtils'
import moment from "moment";
import classNames from 'classnames'
import FavIconLink from "../../components/AthleteListTable/FavIconLink";

import openGray from '../../images/open-02.svg';
import openBlue from '../../images/open-01.svg';



class AccountFavorites extends Component {

    componentDidMount(){

        this.getFavorites();
        
    }
    
    getFavorites = () => {
        this.props.favoriteAthletesActions.getFav();
    }

    _requestedEmailHashes = []
    
    componentDidUpdate(){

        this.getFavoriteEmailHashes().forEach(({EmailHash}) => {
            // console.log("raceBibEmailHash",raceBibEmailHash)
            if(this._requestedEmailHashes.includes( EmailHash )){ return; }
            this._requestedEmailHashes.push( EmailHash );

            // const [raceCode, athleteBib, emailHash] = raceBibEmailHash.split('--');
            // const [raceCode, athleteBib] = EmailHash.split('--');

            // console.log("raceCode",raceCode,"athleteBib",athleteBib, "emalHash",emailHash)
            
            // this.props.athletesByRacebibActions.getAthlete( raceCode, athleteBib );
            this.props.usersActions.getAvatarAndRaces({EmailHash});
        })
    }


    


    getFavoriteEmailHashes = () => {
        const { favoriteAthletes } = this.props;
        const {getSlug} = athletesByRacebibActions;

        let favoriteRaceBibs = [
            /*
            {
                EmailHash: 
                RaceCode:
                Bib:
            }
            */
        ];

        console.log("favoriteAthletes",favoriteAthletes)

        if(favoriteAthletes) {
            const raceCodes = Object.keys(favoriteAthletes);
            raceCodes.forEach(raceCode => {
                const favDoc = favoriteAthletes[raceCode];
                if(favDoc){
                    
                    const favBibs = Object.keys(favDoc);
                    favBibs.forEach(favBib => {
                        const EmailHash = favDoc[favBib];

                        
                        
                        
                        const fav = {RaceCode:raceCode, Bib:favBib,EmailHash};
                        // console.log("FAVBIB" , fav );
                        favoriteRaceBibs.push(fav);
                        // this.props.usersActions.getAvatarAndRaces({EmailHash});

                    })


                    // athleteBibsEmailHash.forEach(athleteBibEmailHash => {
                    //     const bibToUse = athleteBibEmailHash;
                    //     const emailHashToUse = doc.value;
                    //     favoriteRaceBibs.push( getSlug(raceCode,bibToUse) );
                    // })
                }
            })
        }
        return favoriteRaceBibs;
    }

    goToSelectedRace = ({ card }) => {

        const uri = `/welcome/${card.RaceCode}/athletes/${card.Bib}`
        this.props.history.push(uri);
    }
    
    
    
    render(){

        const { fbuserProfile, favoriteAthletes, athletesByRacebib, settings, users} = this.props;
        
        const page = this.props.match.path.split('/')[1];
        const isMale = (fbuserProfile && fbuserProfile.gender && fbuserProfile.gender === "F") ? false : true ;

        
        const favs = this.getFavoriteEmailHashes(); // RaceCode, Bib, EmailHash
        console.log("favs",favs)


        return (
            <div className="AccountFavoritesPage">
                <Headroom>
                    <Header title={`ACCOUNT FAVORITES`} page={page} isMale={isMale} />
                </Headroom>

                <div className="container flexgrow">

                    <div className="AccountFavoritesPageHeader">
                        <div></div>
                        <div>
                            <span>Favorites <i className="dot">•</i> 
                                <strong className="itemsnum">{favs && favs.length}</strong>
                            </span>
                            {/* <span><a href="#">Favorite All</a></span> */}
                        </div>
                    </div>
                    <div className="AccountFavoritesPageCards">
                        

                        { favs.map(fav => {

                            const races = steeltoe(users).get(`${fav.EmailHash}.athleteRaceResults`);
                            // console.log("FULL",steeltoe(athletesByRacebib).get(`${raceBib}`))
                            console.log("RACES",races);
                            if(!races || !races.length){
                                return null;
                            }

                            const raceResult = races.find(race => race.RaceCode===fav.RaceCode && race.Bib === fav.Bib );



                            return (
                                <Card 
                                    key={fav.RaceCode + fav.Bib} 
                                    card={raceResult} 
                                    // cardTemp={raceBibData} 
                                    settings={settings}
                                    users={users}
                                    goToSelectedRace={this.goToSelectedRace}
                                    />
                            )
                            

                            // return (
                            //     <p>{raceBibData.FirstName} {raceBibData.LastName}</p>
                            // )

                        }) }

                    </div>


                    

                </div>


                <Footer />
                
            </div>
        )
    }
}




const Card = ({card, favorite, users, settings, goToSelectedRace }) => {

    // console.log("card",cardTemp);
    // console.log("users",users);
    // const racesPerEmailHash = steeltoe(users).get(`${cardTemp.EmailHash}.athleteRaceResults`);

    // if (!racesPerEmailHash) {
    //     return (null); //waiting for races to load
    // }
    // console.log("racesPerEmailHash",racesPerEmailHash);
    // let card = null;
    // racesPerEmailHash && racesPerEmailHash.forEach(racePerEmailHash => {
    //     if (cardTemp.RaceCode === racePerEmailHash.RaceCode && cardTemp.Bib === racePerEmailHash.Bib) {
    //         card = racePerEmailHash;
    //     }
    // })


    // console.log("card",card);
    // return;

    const raceAthleteCategories = card.RaceDetails && card.RaceDetails.RaceAthleteCategories.split(',');
    // const [category, setCategory] = useState('MA');
    // const [category, setCategory] = useState(raceAthleteCategories && raceAthleteCategories[0]);

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

    const raceDate = card.RaceDetails && moment(card.RaceDetails.RaceDateStart).format('MMM D, YYYY');

    // console.log("CARD",card)

    // ************
    let rankingAndPassingData = null;
    const AGon = settings.ageGrade === "age_grade_on";
    const intervalData = (card['event-intervals-ag-data']) ? (AGon ? card['event-intervals-ag-data'] : card['event-intervals-data']) : null;
    
    if (AGon) {
        if (card.RankingAndPassing && card.RankingAndPassingDetails['event-intervals-ag-data']) {
            rankingAndPassingData = card.RankingAndPassingDetails['event-intervals-ag-data'];
        } 

    } else {
        if (card.RankingAndPassingDetails && card.RankingAndPassingDetails['event-intervals-data']) {
            rankingAndPassingData = card.RankingAndPassingDetails['event-intervals-data'];
        } 
    }

    let overallRank="N/A";
    let avgPace = "N/A";
    let divisionGroup = "Unknown";
    let genderGroup = "Unknown";
    let startDistance = "N/A";
    let endDistance = "N/A";
    let totalDistance = "N/A";

    let finishTime = "N/A";
    let firstHalfTime = "N/A";
    let secondHalfTime = "N/A";
    let halfDiffTime = "N/A";
    let positiveSplit = true;
    let overallTotalAthletes = "N/A";
    let overallPercent = "N/A";

    let overallGenderRank = "N/A";
    let overallGenderTotalAthletes = "N/A";
    let overallGenderPercent = "N/A";

    let overallDivision1Rank = "N/A";
    let overallDivision1TotalAthletes = "N/A";
    let overallDivision1Percent = "N/A";

    let overallDivisionAgeGroupRank = "N/A";
    let overallDivisionAgeGroupTotalAthletes = "N/A";
    let overallDivisionAgeGroupPercent = "N/A";

    let overallDivisionEliteRank = "N/A";
    let overallDivisionEliteTotalAthletes = "N/A";
    let overallDivisionElitePercent = "N/A";

    let overallDivisionMastersRank = "N/A";
    let overallDivisionMastersTotalAthletes = "N/A";
    let overallDivisionMastersPercent = "N/A";

    let overallDivisionVisuallyImpairedRank = "N/A";
    let overallDivisionVisuallyImpairedTotalAthletes = "N/A";
    let overallDivisionVisuallyImpairedPercent = "N/A";

    const currentIntervalIndex = intervalData && intervalData.length - 1;
    const showHalfSplits = card.RaceDetails && card.RaceDetails.ShowHalfSplits;

    // console.log("showHalfSplits",showHalfSplits)

    let showAgeGrade = true;

    if (card.RaceDetails && card.RaceDetails.showAgeGrade === false){
        showAgeGrade = false;
    }

    const ageGrade = `${(card.MyRace && card.MyRace.AgeGrade*100).toFixed(0)}%`

    

    if (card && card.times) {
        finishTime = AGon ? secondsToTimeStrip(card.times.OfficialTime.TimeSeconds_AG) : secondsToTimeStrip(card.times.OfficialTime.TimeSeconds);
        firstHalfTime = AGon ? secondsToTimeStrip(card.times.Half.TimeSeconds_AG) : secondsToTimeStrip(card.times.Half.TimeSeconds);
        secondHalfTime = AGon ? secondsToTimeStrip(card.times.OfficialTime.TimeSeconds_AG - card.times.Half.TimeSeconds_AG) : secondsToTimeStrip(card.times.OfficialTime.TimeSeconds - card.times.Half.TimeSeconds);
        let firstHalfTimeSeconds = card.times.Half.TimeSeconds;
        let secondHalfTimeSeconds = card.times.OfficialTime.TimeSeconds - card.times.Half.TimeSeconds;
        let firstHalfTimeSecondsAG = card.times.Half.TimeSeconds_AG;
        let secondHalfTimeSecondsAG = card.times.OfficialTime.TimeSeconds_AG - card.times.Half.TimeSeconds_AG;
        let posSplits = "+";
        // let diffSeconds = Math.abs(secondHalfTimeSeconds - firstHalfTimeSeconds);
        // let diffSecondsAG = Math.abs(secondHalfTimeSecondsAG - firstHalfTimeSecondsAG);
        let diffSeconds = secondHalfTimeSeconds - firstHalfTimeSeconds;
        let diffSecondsAG = secondHalfTimeSecondsAG - firstHalfTimeSecondsAG;

        // if (firstHalfTimeSeconds > secondHalfTimeSeconds){
        //     posSplits = "-";
        // }
        
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);
        halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);
        // console.log("halfDiffTime",halfDiffTime)

        // console.log("XXX halfDiffTime",halfDiffTime);
        positiveSplit = diffSeconds > 0 ? false : true; 

        // const intervalCumulTime = secondsToTimeStrip(IntervalCumulativeTimeSeconds);
        // console.log("XXXX",athleteMainData.times);
    } 

      
    if (intervalData && intervalData[currentIntervalIndex]) {
        if (settings.units === 'metric') {
            
 
            avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].CumulativePaceMetricSeconds); 


        } else {
  
            avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].CumulativePaceEnglishSeconds); 

            
        }

    } 
    

    // console.log("About to card",card)
    // console.log("About to",currentIntervalIndex)
    // console.log("About to rankingAndPassingData[currentIntervalIndex]",rankingAndPassingData && rankingAndPassingData[currentIntervalIndex])
    if (rankingAndPassingData && rankingAndPassingData[currentIntervalIndex]) {

      
        overallRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex].All.IntervalCumulativeRank;
        overallGenderTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.All.IntervalCumulativeRank`);
        overallGenderTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.All.TotalAthletes`);
        overallPercent = roundDistance(overallRank / overallTotalAthletes * 100,3);
    
        overallGenderRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.Gender}.IntervalCumulativeRank`);
        
        
        overallGenderTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.Gender}.TotalAthletes`);
        overallGenderPercent = roundDistance(overallGenderRank / overallGenderTotalAthletes * 100,3);
    
        // overallDivision1Rank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].IntervalCumulativeRank;
        // overallDivision1TotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].TotalAthletes;
        // overallDivision1Percent = roundDistance(overallDivision1Rank / overallDivision1TotalAthletes * 100,3);
        
        if (card.MyRace.DivisionAgeGroup) {
            overallDivisionAgeGroupRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionAgeGroup}.IntervalCumulativeRank`);
            overallDivisionAgeGroupTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionAgeGroup}.TotalAthletes`);
            overallDivisionAgeGroupPercent = roundDistance(overallDivisionAgeGroupRank / overallDivisionAgeGroupTotalAthletes * 100,3);
        }

        if (card.MyRace.DivisionEliteGroup) {
            // overallDivisionEliteRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][card.MyRace.DivisionEliteGroup].IntervalCumulativeRank;
            
            overallDivisionEliteRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionEliteGroup}.IntervalCumulativeRank`);
            overallDivisionEliteTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionEliteGroup}.TotalAthletes`);
            overallDivisionElitePercent = roundDistance(overallDivisionEliteRank / overallDivisionEliteTotalAthletes * 100,3);
        }

        if (card.MyRace.DivisionMastersGroup) {
            overallDivisionMastersRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionMastersGroup}.IntervalCumulativeRank`);
            overallDivisionMastersTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionMastersGroup}.TotalAthletes`);
            overallDivisionMastersPercent = roundDistance(overallDivisionMastersRank / overallDivisionMastersTotalAthletes * 100,3);
        }

        if (card.MyRace.DivisionVisuallyImpairedGroup) {
            overallDivisionVisuallyImpairedRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionVisuallyImpairedGroup}.IntervalCumulativeRank`);
            overallDivisionVisuallyImpairedTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionVisuallyImpairedGroup}.TotalAthletes`);
            overallDivisionVisuallyImpairedPercent = roundDistance(overallDivisionVisuallyImpairedRank / overallDivisionVisuallyImpairedTotalAthletes * 100,3);
        }

    }

    // console.log("overallGenderRank",overallGenderRank)

    if (!!card.MyRace) {
        if (card.MyRace.Gender === "F") {
            genderGroup = "Females";
        } else if (card.MyRace.Gender === "M") {
            genderGroup = "Males";
        } else {
            genderGroup = "Unknown";
        }
    }

    if (card.times) {
        finishTime = AGon ? secondsToTimeStrip(card.times.OfficialTime.TimeSeconds_AG) : secondsToTimeStrip(card.times.OfficialTime.TimeSeconds);
        firstHalfTime = AGon ? secondsToTimeStrip(card.times.Half.TimeSeconds_AG) : secondsToTimeStrip(card.times.Half.TimeSeconds);
        secondHalfTime = AGon ? secondsToTimeStrip(card.times.OfficialTime.TimeSeconds_AG - card.times.Half.TimeSeconds_AG) : secondsToTimeStrip(card.times.OfficialTime.TimeSeconds - card.times.Half.TimeSeconds);
        let firstHalfTimeSeconds = card.times.Half.TimeSeconds;
        let secondHalfTimeSeconds = card.times.OfficialTime.TimeSeconds - card.times.Half.TimeSeconds;
        let firstHalfTimeSecondsAG = card.times.Half.TimeSeconds_AG;
        let secondHalfTimeSecondsAG = card.times.OfficialTime.TimeSeconds_AG - card.times.Half.TimeSeconds_AG;
        let posSplits = "+";
        // let diffSeconds = Math.abs(secondHalfTimeSeconds - firstHalfTimeSeconds);
        // let diffSecondsAG = Math.abs(secondHalfTimeSecondsAG - firstHalfTimeSecondsAG);
        let diffSeconds = secondHalfTimeSeconds - firstHalfTimeSeconds;
        let diffSecondsAG = secondHalfTimeSecondsAG - firstHalfTimeSecondsAG;

        // if (firstHalfTimeSeconds > secondHalfTimeSeconds){
        //     posSplits = "-";
        // }
        
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);
        halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);

        // console.log("XXX halfDiffTime",halfDiffTime);
        positiveSplit = diffSeconds > 0 ? false : true; 

        // const intervalCumulTime = secondsToTimeStrip(IntervalCumulativeTimeSeconds);
        // console.log("XXXX",athleteMainData.times);
    } 

    // ************

    let divList = [];
    let divListNames = [];
    let defaultSelectedOption = null;
    let selectedCategory = null;
    let selectedOptionPlace = null;

    
    if (raceAthleteCategories){

        if (raceAthleteCategories.includes("EL") && card.MyRace.DivisionEliteGroup) {
            divList.push("EL");
            divListNames.push("Elite");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "EL";
        }
        if (raceAthleteCategories.includes("MA") && card.MyRace.DivisionMastersGroup) {
            divList.push("MA");
            divListNames.push("Masters");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "MA";
        }
        if (raceAthleteCategories.includes("WE") && card.MyRace.DivisionAthenaClydesdaleGroup) {
            divList.push("WE");
            divListNames.push("Weight");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "WE";
        }
        if (raceAthleteCategories.includes("VI") && card.MyRace.DivisionVisuallyImpairedGroup) {
            divList.push("VI");
            divListNames.push("Visually Impaired");
            
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "VI";
        }
        if (raceAthleteCategories.includes("AG") && card.MyRace.DivisionAgeGroup) {
            divList.push("AG");
            divListNames.push("Age Group");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "AG";
        }
    
        defaultSelectedOption = divList[selectedCategoryIndex];
    
        if (defaultSelectedOption == "EL") {
            selectedCategory = "ELITE • " + card.MyRace.Gender;
            selectedOptionPlace = overallDivisionEliteRank;
        } else if (defaultSelectedOption == "AG") {
            selectedCategory = card.MyRace.DivisionAgeGroup.replace('-','–');
            selectedOptionPlace = overallDivisionAgeGroupRank;
        } else if (defaultSelectedOption == "MA") {
            selectedCategory = "MAS • " + card.MyRace.Gender;
            selectedOptionPlace = overallDivisionMastersRank;
        } else if (defaultSelectedOption == "WE") {
            selectedCategory = card.MyRace.Gender === "F" ? "ATH" : "CL";
            selectedOptionPlace = overallGenderRank;
        } else if (defaultSelectedOption == "VI") {
            selectedCategory = "VI • " + card.MyRace.Gender;
            selectedOptionPlace = overallDivisionVisuallyImpairedRank;
        } 
    }

    // ***************************************
    let cityStateCountryCode = '';
    
    if (card.HometownStateCode) {
        if (card.HometownStateCode.length > 0) {
            cityStateCountryCode = card.HometownCity ? card.HometownCity + ', ' + card.HometownStateCode : card.HometownStateCode;
        } else {
            if (card.HometownCountryName && card.HometownCountryName.length > 0) {
                cityStateCountryCode = card.HometownCity + ', ' + card.HometownCountryName;
            } else {
                
                cityStateCountryCode = card.HometownCity;
            }
            
        }
        if (cityStateCountryCode.length === 0) {
            cityStateCountryCode = card.HometownTwoCharCountryCode;
        }
    }
    
    let hometownCountry = (card.HometownTwoCharCountryCode) ? card.HometownTwoCharCountryCode : null;
    let hometownCountryVal = hometownCountry ? hometownCountry.replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) : null;


    // AVATAR
    let avatar = null;
    console.log("EMAIL HASH",card.EmailHash)
    // console.log("USERS",users)
    // console.log("AVATARID",steeltoe(users).get(`${card.EmailHash}.profile.avatarId`))
    const uploadedImage = steeltoe(users).get(`${card.EmailHash}.avatarImageUrl`);
    
    avatar = getAvatarImage({
        avatarName: steeltoe(users).get(`${card.EmailHash}.profile.avatarId`),
        uploadedImage
    })

    const Gender = card.Gender;
    let isMale = false;
    if(!avatar){
        switch(Gender){
            case 'M': 
            avatar = maleIcon; 
            isMale = true;
            break;
            case 'F': 
            avatar = femaleIcon; 
            isMale = false;
            break;
            default: 
            avatar = genericIcon
            isMale = false;
        }
    }

    // ***************************************

    return (
        <div className={classNames("Card",{
                favorite: !!favorite
            })}
            onClick={e=>goToSelectedRace({card})}
        >


            <div className="OpenRaceIcon tooltip">
                <TooltipText><em>More details</em></TooltipText>
                <div className="IconSwappable">
                    <div className="icon" style={{ backgroundImage: `url(${openGray})` }} />
                    <div className="hover" style={{ backgroundImage: `url(${openBlue})` }} />
                </div>
            </div>

           
            {/* <div className="FavButton" onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); handleFavorite({card, favorite: !favorite}) }} >
                <img className="icon" src={favGray} />
                <img className="hover" src={favBlue} />
            </div> */}

            <FavIconLink row={card}/>

            <div className="CardHeader">
                <div className="avatar">
                    { avatar && <img src={avatar} />}
                    
                </div>
                <div className="name"> 
                    <div> {card.MyRace.FirstName} </div>
                    <div> {card.MyRace.LastName} </div>
                </div>
                <div className="bib-info">
                    <div className="bib"> #{card.MyRace.Bib} </div>
                    {/* <div className="bib"> &nbsp; </div> */}
                    <div className="gender"> {card.MyRace.Gender === "M" ? "Male" : "Female"} <i className="dot">•</i> {card.MyRace.Age} </div>
                </div>
                <div className="flag">{hometownCountryVal}</div>
                    <div className="hometown">{cityStateCountryCode}</div>
                    <div className="time">{finishTime}</div>
                </div>

            {/* <div className="Label"> <i></i> <span>#{card.MyRace.Bib}</span> <i></i> </div> */}
            <div className="Label"> <i></i> <span>{card.RaceDetails && card.RaceDetails.RaceDistance}</span> <i></i> </div>

            <dl className="infotable">
                <dt>Category</dt>
                <dd>{selectedCategory}</dd>
                <dt>Pace</dt>
                <dd className="right-aligned">{avgPace}</dd>
                <dt>Cat. Place</dt>
                <dd>{formatNumber(selectedOptionPlace)}</dd>
                {showHalfSplits ? 
                    <>
                        <dt>1st Half</dt>
                        <dd className="right-aligned">{firstHalfTime}</dd>
                    </> :

                    <>
                        <dt></dt>
                        <dd className="right-aligned">&nbsp;</dd>
                    </>
                }
                <dt>{genderGroup}</dt>
                <dd>{formatNumber(overallGenderRank)}</dd>
                {showHalfSplits ? 
                    <>
                        <dt>2nd Half</dt>
                        <dd className="right-aligned">{secondHalfTime}</dd>
                    </> :
                    <>
                        <dt></dt>
                        <dd className="right-aligned">&nbsp;</dd>
                    </>
                }
                {showAgeGrade ? 
                    <>
                        <dt>Age Grade</dt>
                        <dd>{ageGrade}</dd>
                    </> :

                    <>
                        <dt></dt>
                        <dd></dd>

                    </>
                }
                {showHalfSplits ? 
                    <>
                        <dt>Difference</dt>
                        <dd className={classNames("splitsResult right-aligned",{
                            plus: positiveSplit,
                            minus: !positiveSplit
                        })}>
                            {halfDiffTime}
                        </dd>
                    </> :
                    <>
                        <dt></dt>
                        <dd className="right-aligned">&nbsp;</dd>
                    </>
                }
            </dl>

            <div className="categories">
                {/* {['MA','VI','AG'].map(btn => <button className={classNames({ */}
                {divList.map((btn,i) => 
                    <button key={i} 
                        // className={classNames({active: btn===category})}
                        // onClick={e => setCategory(btn)}
                        // className={classNames(selectedCategoryIndex===i ? '': 'tooltip',"athleteCategory",{ selected: selectedCategoryIndex===i })} 
                        className={classNames({ active: selectedCategoryIndex===i })} 
                        onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCategoryIndex(i) }}>
                        {divListNames[i]}
                    </button>)}

                {/* <span key={i}
                    // data-tip={div} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                    className={classNames(selectedCategoryIndex===i ? '': 'tooltip',"athleteCategory",{ selected: selectedCategoryIndex===i })} 
                    onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCategoryIndex(i) }}>
                    {selectedCategoryIndex===i ? '': <span className={classNames("tooltiptext", divList[i]) }>{divListNames[i]}</span>}
                    {div}
                </span> */}
            </div>

            <div className="Label"><i></i></div>

            <div>
            <h3>{card.RaceDetails && card.RaceDetails.RaceName}</h3>
                <div className="infoline">
                    <div>{card.RaceDetails && card.RaceDetails.Location} <i className="dot">•</i> {raceDate}</div>
                    <div>{formatNumber(card.RaceParticipants)}</div>
                </div>
            </div>
            
        </div>
    )
}





const mapState = ({fbuser, users, fbuserProfile, settings, favoriteAthletes, athletesByRacebib})=>({fbuser, users, fbuserProfile, settings, favoriteAthletes, athletesByRacebib})
const mapDispatch = dispatch => ({
    fbuserActions: bindActionCreators(fbuserActions, dispatch),
    favoriteAthletesActions: bindActionCreators(favoriteAthletesActions, dispatch),
    athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
    usersActions: bindActionCreators({getAvatarAndRaces}, dispatch),
})
export default withRouter(connect(mapState, mapDispatch)(AccountFavorites))