import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'
import classNames from "classnames";
import ReactTooltip from 'react-tooltip'
import {secondsToTimeStrip, roundDistance} from '../../timeUtils'
// import clockimg from "../../../images/clock.svg";

class Interval extends Component {

    handleClick = e => {
        this.props.setIntervalIndex(this.props.index)
        this.props.settingsActions.setBeaconDone("segmentNavigation")
        this.props.settingsActions.setBeaconDone("intervalSummaryRaceCourse");
    }
    
    isSelected = () => {
        const { index, intervalAnalysis } = this.props;
        ReactTooltip.hide();
        return index >= intervalAnalysis.currentStartIntervalIndex && index <= intervalAnalysis.currentEndIntervalIndex;
    }

    render() {
        const { interval, index, intervalAnalysis, units, last } = this.props;
        
        const { FlexRatio } = interval;





        return (
        // <div data-tip="Select" data-effect='solid' data-place='bottom' data-delay-show='1000' data-class="MyRaceTooltip"
        <div className={classNames("Interval", {
            selected: this.isSelected(),
            FirstInterval: index === intervalAnalysis.currentStartIntervalIndex,
            LastInterval: index === intervalAnalysis.currentEndIntervalIndex,
            last,
        })} onClick={this.handleClick} style={{
            flexGrow: (FlexRatio * 100)
        }} 
        
        >
        
            <section className="IntervalLabel Out Top">{ secondsToTimeStrip(interval.IntervalCumulativeTimeSeconds)}</section>
            <section className="IntervalLabel In Top">{secondsToTimeStrip(interval.IntervalTimeSeconds)}</section>
            <section className="IntervalLabel In Bottom">{roundDistance(units === "metric" ? interval.IntervalDistanceKm : interval.IntervalDistanceMi,2)}</section>
            <section className="IntervalLabel Out Bottom">{roundDistance(units === "metric" ? interval.CumulativeDistanceKm : interval.CumulativeDistanceMi,2)}</section>

            { index === intervalAnalysis.currentStartIntervalIndex && <div className="HighlightFirstSelected" />}

            { index === intervalAnalysis.currentEndIntervalIndex && <div className="HighlightLastSelected" />}

            
        </div>
        );
    }
}

const mapStateToProps = state => ({
    intervalAnalysis: state.intervalAnalysis,
  })
  
  const mapDispatchToProps = dispatch => ({
    setIntervalIndex: intervalIndex => dispatch({type:'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX', intervalIndex}),
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Interval);
  
