
import imgF1 from '../../images/f-1-01.svg';
import imgF2 from '../../images/f-1-02.svg';
import imgF3 from '../../images/f-1-03.svg';
import imgF4 from '../../images/f-1-04.svg';
import imgF5 from '../../images/f-1-05.svg';
import imgF6 from '../../images/f-1-06.svg';
import imgF7 from '../../images/f-1-07.svg';

import imgM1 from '../../images/m-1-01.svg';
import imgM2 from '../../images/m-1-02.svg';
import imgM3 from '../../images/m-1-03.svg';
import imgM4 from '../../images/m-1-04.svg';
import imgM5 from '../../images/m-1-05.svg';
import imgM6 from '../../images/m-1-06.svg';
import imgM7 from '../../images/m-1-07.svg';

import imgL1 from '../../images/item-1-01.svg';
import imgL2 from '../../images/item-1-02.svg';
import imgL3 from '../../images/item-1-03.svg';
import imgL4 from '../../images/item-1-04.svg';
import imgL5 from '../../images/item-1-05.svg';
import imgL6 from '../../images/item-1-06.svg';
import imgL7 from '../../images/item-1-07.svg';
import imgL8 from '../../images/item-1-08.svg';
import imgL9 from '../../images/item-1-09.svg';
import imgL10 from '../../images/item-1-10.svg';
import imgL11 from '../../images/item-1-11.svg';
import imgL12 from '../../images/item-1-12.svg';
import steeltoe from 'steeltoe'


const avatars = [
    { img: imgF1, name: 'imgF1', type: 'F' },
    { img: imgF2, name: 'imgF2', type: 'F' },
    { img: imgF3, name: 'imgF3', type: 'F' },
    { img: imgF4, name: 'imgF4', type: 'F' },
    { img: imgF5, name: 'imgF5', type: 'F' },
    { img: imgF6, name: 'imgF6', type: 'F' },
    { img: imgF7, name: 'imgF7', type: 'F' },
    { img: imgM1, name: 'imgM1', type: 'M' },
    { img: imgM2, name: 'imgM2', type: 'M' },
    { img: imgM3, name: 'imgM3', type: 'M' },
    { img: imgM4, name: 'imgM4', type: 'M' },
    { img: imgM5, name: 'imgM5', type: 'M' },
    { img: imgM6, name: 'imgM6', type: 'M' },
    { img: imgM7, name: 'imgM7', type: 'M' },
    { img: imgL1, name: 'imgL1', type: 'landscape' },
    { img: imgL2, name: 'imgL2', type: 'landscape' },
    { img: imgL3, name: 'imgL3', type: 'landscape' },
    { img: imgL4, name: 'imgL4', type: 'landscape' },
    { img: imgL5, name: 'imgL5', type: 'landscape' },
    { img: imgL6, name: 'imgL6', type: 'landscape' },
    { img: imgL7, name: 'imgL7', type: 'landscape' },
    { img: imgL8, name: 'imgL8', type: 'landscape' },
    { img: imgL9, name: 'imgL9', type: 'landscape' },
    { img: imgL10, name: 'imgL10', type: 'landscape' },
    { img: imgL11, name: 'imgL11', type: 'landscape' },
    { img: imgL12, name: 'imgL12', type: 'landscape' },
];


// When we need to show avatar image to the same person who is logged in.
export const getOwnAvatarImage = ({
    fbuserProfile, /*
        fbuserProfile.defaultAvatar - globally selected avatar image in firestore, eg. 'imgM7' - current user
    */
   avatarPreviewName, // <- locally selected avatar image name, eg. 'imgM7' - used for hover, preferred. - current user editing profile
   avatarName, // <- globally selected avatar image name, eg. 'imgM7'. - current user editing profile
   uploadedImage, // <- uploaded avatar image url
}) => {

    let avatarNameToShow = avatarPreviewName || avatarName || steeltoe(fbuserProfile).get('defaultAvatar');
    
    if((avatarNameToShow==='imgM7' || avatarNameToShow==='imgF7') && uploadedImage) {
        return uploadedImage;
    }

    // previewing avatar
    const avatar = avatars.find(a=>a.name===avatarNameToShow);
    if(avatar) return avatar.img;
}


// Get avatar image by name, for someone else
export const getAvatarImage = ({
    avatarName, // <- globally selected avatar image name, eg. 'imgM7' - this is avatar that is potentially for another user, coming from `users.{EmailHash}.profile.avatarId`
    uploadedImage, // <- uploaded avatar image url
}) => {

    if((avatarName==='imgM7' || avatarName==='imgF7' || avatarName === undefined) && uploadedImage) {
        return uploadedImage;
    }
    
    const avatar = avatars.find(a=>a.name===avatarName);
    if(avatar) return avatar.img;
}

export default avatars;