import initialState from "./initialState";

export default (state = initialState.fbuser, action) => {
  switch (action.type) {

    case 'SET_FB_USER':
        return action.user;
        
    default:
      return state;
  }
};
