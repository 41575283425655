import React, { Component } from "react";
import classnames from 'classnames'
import "./ToggleButton.scss"

class ToggleLoginSignup extends Component {

    handleClick = value => e => {
        this.props.onChange(value)

        // window.analytics.track('Toggle Interval - Cumulative', {
        //     "Selected": value === 0 ? "Interval" : "Cumulative"
        //   });
    }
    
    render(){
        const { titles, value } = this.props;

        return (
            <>
                <div className="ToggleButton ToggleLoginSignup">
                    <div onClick={ this.handleClick(0) } className={classnames({ on: !value })}>{ titles[0] }</div>
                    {/* <div onClick={ this.handleClick(1) } className={classnames({ on: value===1 })}>{ titles[1] }</div> */}
                </div>
            </>
            
        )
    }
}

export default ToggleLoginSignup
