import React, { Component } from "react";
import {secondsToTimeStrip} from '../../timeUtils'
import moment from "moment";
import logoimg from "../../images/logo-05.svg";
import timePng from '../../images/clock-1.svg'
// import {Link} from 'react-router-dom';
import {Link, withRouter} from 'react-router-dom';
import navNext from '../../images/next-big-03.svg';
import TooltipText from '../../components/Tooltip/TooltipText'
import imgRunner from "../../images/runner.svg";

import { Swipeable } from 'react-swipeable'

// SAN FRAN
// import cityImg from '../../images/sf-skyline-finish@3x.png'
// import cityImgMobile from '../../images/sf-skyline-mobile.png'

//SACRAMENTO
// import cityImg from '../../images/sacramento-finish@3x.png'
// import cityImgMobile from '../../images/sacramento-mobile.png'
// import classNames from 'classnames'
// import {connect} from 'react-redux'
// import Confetti from 'react-confetti'

import sf_skyline from '../../images/sf-skyline-finish@3x.png';
import sf_skylineMobile from '../../images/sf-skyline-mobile.png';
import sac_skyline from '../../images/sacramento-skyline-finish@3x.png';
import sac_skylineMobile from '../../images/sacramento-mobile.png';
import maui_oceanside_skyline from '../../images/maui-skyline-finish@3x.png';
import maui_oceanside_skylineMobile from '../../images/maui-mobile@3x.png';
import berkeley_skyline from '../../images/berkeley-skyline-finish@3x.png';
import berkeley_skylineMobile from '../../images/berkeley-mobile@3x.png';
// import { motion } from "framer-motion"


let skylines = { // EventCode -> image
  defaultBg: sf_skyline,
  'bhm': sf_skyline,
  'tsfm': sf_skyline,
  'cim': sac_skyline,
  'bhm': berkeley_skyline,
  'mauiom': maui_oceanside_skyline

}
let skylinesMobile = { // EventCode -> image
  defaultBg: sf_skylineMobile,
  'bhm': sf_skylineMobile,
  'tsfm': sf_skylineMobile,
  'cim': sac_skylineMobile,
  'bhm': berkeley_skylineMobile,
  'mauiom': maui_oceanside_skylineMobile,

}


class FinishContent extends Component {

    constructor(props){
        super(props);

        this.containerEl = React.createRef();
        this.state = { clientWidth: 0, clientHeight: 0, offsetTop: 0, offsetLeft: 0 }

    }
    componentDidMount() {
        // this.getContainerSize();
        this.startListening();
        this.timeoutId = window.setTimeout(()=>{
            this.getContainerSize();
        }, 1000)
    }
    

    componentWillUnmount(){
        if(this.timeoutId) window.clearTimeout(this.timeoutId);
        this.stopListening();
    }

    startListening = () => {
        window.addEventListener('resize', this.getContainerSize, false);
        window.document.addEventListener('keyup', this.onKeyup)
    }
    stopListening = () => {
        window.removeEventListener('resize', this.getContainerSize);
        window.document.removeEventListener('keyup', this.onKeyup)
    }

    onKeyup = (e) => {
        const { key } = e;

        const {athlete, settings: { lastSelectedAthleteBib: Bib, lastSelectedRaceCode: RaceCode, lastSelectedEventCode: EventCode }} = this.props;


        // console.log("Bib1",Bib);
        // console.log("RaceCode1",RaceCode);
        // console.log("EventCode1",EventCode);
        if(!Bib || !RaceCode || !EventCode || !athlete) { // Bib, RaceCode, EventCode
            return null;
        }



        if(key==='ArrowLeft'){
            const link = `/segment-analysis/${RaceCode}/athletes/${Bib}`;

            this.props.history.push(link);
            
        }
        if(key==='ArrowRight'){
            
            const link = `/athlete/${athlete.EmailHash}`;
            this.props.history.push(link);

        }
    }
    getContainerSize = () => {
        window.FinishContentEl = this.containerEl.current;

        if(!this.containerEl.current) return;

        const { clientWidth, clientHeight, offsetTop, offsetLeft} = this.containerEl.current;

        const adjustHeight = clientHeight/2;
        const adjustWidth = clientWidth - 460;
        const adjustTop = offsetTop + 280;
        const adjustLeft = offsetLeft + 230;
        // const adjustHeight = clientHeight;
        // console.log('FinishContainer El:', this.containerEl, JSON.stringify({ clientWidth, clientHeight, offsetTop, offsetLeft}))
        this.setState({ clientWidth: adjustWidth, clientHeight: adjustHeight, offsetTop: adjustTop, offsetLeft: adjustLeft})
    }
    
    renderTopImage = () => {
        const {race} = this.props;
        // console.log("img.src",img.src);
        // const image = <img className="FinishTopSponsorImage" alt={`${img.altText}`} src={`/images/${race.EventSeriesCode}/partners/${img.src}` } />
        // const image = <img className="FinishTopSponsorImage" alt={`${img.altText}`} src={`${img.src}` } />
        // if(img.linkURL) {
        //     return (
        //     <a href={img.linkURL} target="_blank" alt={img.altText} title={img.altText} rel="noopener noreferrer" className="FinishTopSponsorLink">{image}</a>
        //     )
        // }

        const raceImage = race  && race.sponsorImages && race.sponsorImages[0];
        // const raceImage = race.RaceDetails.sponsorImages[0].src;
        
        // console.log("raceImage",raceImage);
        const image = raceImage && (
    
            <a href={raceImage.linkURL} target="_blank" alt={raceImage.altText} title={raceImage.altText} rel="noopener noreferrer" className="FinishTopSponsorLink tooltip"><span />
              {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
              <img className="FinishTopSponsorImage" alt={`${raceImage.altText}`} src={`${raceImage.src}` } />
              <TooltipText>Event's Home Page</TooltipText>
            </a> 
    
        )
        
        return image;
    }

    render(){
        // const { athlete, defaultBg, race, settings } = this.props;
        const { athlete, race, settings } = this.props;
        const EventCode = race && race.EventCode;

        const EventCodeSplit = EventCode ? EventCode.split('-') : [];
        const EventCodeBase = EventCodeSplit.length ? EventCodeSplit[0] : '';
        // console.log("EventCodeBase",EventCodeBase)
        // let cityImg = defaultBg || !EventCodeBase ? skylines.defaultBg : skylines[EventCodeBase];
        let cityImg = EventCodeBase && skylines[EventCodeBase] ? skylines[EventCodeBase] : skylines.defaultBg ;
        // let cityImgMobile = defaultBg || !EventCodeBase ? skylinesMobile.defaultBg : skylinesMobile[EventCodeBase];
        let cityImgMobile = EventCodeBase && skylinesMobile[EventCodeBase] ? skylinesMobile[EventCodeBase] : skylinesMobile.defaultBg;
        // const { clientWidth, clientHeight, offsetTop, offsetLeft} = this.state;
        // const athleteRace = this.props;
        // const logoRaceImg = "/images/mvm-ma-2018/partners/sf-logo-lockup-tm@3x.png";

        const { raceLogo } = this.props;
        const { units } = settings;
        // console.log("clientWidth",clientWidth);
        // console.log("clientHeight",clientHeight);
        // console.log("offsetTop",offsetTop);
        // console.log("offsetLeft",offsetLeft);
        

        // console.log("XXXathlete",athlete);
        // console.log("race",race);
        if (!race || !race.RaceDateStart) {
            return null;
        }
        const raceDay = moment(race.RaceDateStart).format('ddd');
        const raceDate = moment(race.RaceDateStart).format('MMM D, YYYY');

        const paceEnglishSeconds = (units === 'metric') ? athlete['run-event-intervals-data'][athlete['run-event-intervals-data'].length-1].CumulativePaceMetricSeconds : athlete['run-event-intervals-data'][athlete['run-event-intervals-data'].length-1].CumulativePaceEnglishSeconds;

        // let cityState = '';
        // if (athlete && athlete.MyRace) {
        //     cityState = athlete.MyRace.City ? athlete.MyRace.City + ', ' + athlete.MyRace.StateName : athlete.MyRace.StateName;
        //     if (cityState.length === 0) {
        //         cityState = athlete.MyRace.CountryName;
        //     }
        // }

        let cityState = '';
        let athleteBib = "";
        let athleteDivision = null;
        let nameLink = null;

        if (athlete && athlete.MyRace) {
       
            if (athlete.MyRace.StateCode) {
                cityState = athlete.MyRace.City + (athlete.MyRace.City ? ', ' : '') + athlete.MyRace.StateCode;
            }  
            else if (athlete.MyRace.City  && athlete.MyRace.CountryThreeCharCode) {
                cityState = athlete.MyRace.City + ', ' + athlete.MyRace.CountryName;
            }

            if (cityState.length === 0) {
                cityState = athlete.MyRace.CountryName;
            }

            if (athlete.MyRace.DivisionEliteGroup) {
                athleteDivision = athlete.MyRace.DivisionEliteGroup === "EliteM" ? "Elite Men" : "Elite Women";
              } else if (athlete.MyRace.DivisionMastersGroup) {
                athleteDivision = athlete.MyRace.DivisionMastersGroup === "MastersM" ? "Masters Men" : "Masters Women";
              } else if (athlete.MyRace.DivisionAthenaClydesdaleGroup) {
                athleteDivision = athlete.MyRace.DivisionAthenaClydesdaleGroup;
              } else if (athlete.MyRace.DivisionVisuallyImpairedGroup) {
                athleteDivision = athlete.MyRace.DivisionVisuallyImpairedGroup === "VisuallyImpairedM" ? "Visually Impaired Men" : "Visually Impaired Women";
              } else {
                athleteDivision = athlete.MyRace.DivisionAgeGroup.replace('-','–');
              }
        
              athleteBib = athlete.MyRace.Bib;
        
                //if (this.props.athlete) {
                if (athlete.EmailHash && athlete.EmailHash.length > 0) {
                    nameLink =  <Link to={`/athlete/${athlete.EmailHash}`} className="athleteName tooltip">
                        <TooltipText>Athlete's <em>Home Page</em></TooltipText>
                        {athlete.FirstName + ' ' + athlete.LastName}</Link>;
                } else {
                    nameLink =  <span className="athleteName">{athlete.FirstName + ' ' + athlete.LastName}</span>;
                }
        }

        

        return(
            <>

                
                {/* <motion.div  */}
                <div className="FinishContentWrapper">
                <Swipeable onSwiped={ event => { 
                    if(event.dir==='Right'){
                        console.log("GO TO PREV");


                        this.props.history.push(`/segment-analysis/${athlete.RaceCode}/athletes/${athlete.Bib}`);
                                

                        
                    } else if(event.dir==='Left'){
                        console.log("GO TO NEXT");

                        this.props.history.push(`/athlete/${athlete.EmailHash}`);
                        

                    }
                    console.log('[SWIPE]', event.dir, event)}}>
                  
               

                    <div 
                        initial={{
                            opacity: 0
                        }}
                        // animate={{ opacity: 1 }} transition={{ duration: 2 }}
                        className="FinishContent" 
                        ref={this.containerEl} 
                        style={{backgroundImage: `url(${ settings.windowW > 801 ? cityImg : cityImgMobile})`}}
                    >
                    {/* <div className={classNames('FinishContent', {
                        open:isSidebarOpen,
                        closed:!isSidebarOpen,
                    })} ref={this.containerEl}> */}
                    {/* {(clientWidth === 0 || clientHeight === 0) ? null : 
                        <Confetti recycle={false} numberOfPieces={100} 
                            // width={clientWidth} height={clientHeight}
                            // confettiSource={{ w: clientWidth, h: clientHeight, y: offsetTop, x: offsetLeft }}
                            confettiSource={{ w: clientWidth, h: clientHeight, y: offsetTop, x: offsetLeft }}
                            />
                    } */}

                    
                        <div className="FinishHeader">
                            {/* <Link to="/" className="FinishHeaderLogoLink">
                                <img src={logoimg} alt="MyRace" />
                            </Link> */}
                            <div>
                            { 
                                raceLogo ? this.renderTopImage(raceLogo) : null
                            }
                            </div>
            
                        
                        </div>

                        <div className="LineTitle">
                            <i />
                            <span>{race.EventName}</span>
                            <i />
                        </div>

                        <div className="SubtitleLine">
                            <div>{ raceDay } <i className="dot">•</i> { raceDate }</div>
                            <div>#{athleteBib} <i className="dot">•</i> {athleteDivision}</div>
                        </div>

                        <div className="AthleteInfo">

                        

                            <Link 
                            // data-tip="Go to Results Summary" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDark" 
                                to={`/segment-analysis/${athlete.RaceCode}/athletes/${athlete.Bib}`} 
                                // to={prevLinkUrl}
                                className="prevPage tooltip" 
                                // onClick={this.beaconDone("pageNavigationWelcomeArrow")}
                                >
                                {/* <Beacon id="pageNavigationWelcomeArrow" text='Proceed to Results Summary' direction="left" style={{ top:'12px', left:'50px'}} /> */}
                                <span className="background" />
                                <img src ={navNext } />
                                <TooltipText>Back to <br/><em>Segment Analysis</em></TooltipText>
                            </Link>
                        

                            <div className="Hometown">
                                
                                <span className="countryFlag">
                                    {(athlete && athlete.MyRace) && 
                                    athlete.MyRace.CountryTwoCharCode && athlete.MyRace.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) }
                                </span>

                                {cityState}
                                
                            </div>

                            {/* <div className="AthleteName"> { athlete.MyRace.FirstName } { athlete.MyRace.LastName } </div> */}

                            <div className="AthleteName">{nameLink}</div>
                            <span className="time" style={{backgroundImage:`url(${timePng})`}}>{ athlete.OfficialTime }</span>
                            <div className="pace"> Avg. pace <strong>{ secondsToTimeStrip(paceEnglishSeconds) }</strong> { units === 'metric' ? 'min/km' : 'min/mi'}</div>
                            <div className="RaceTypeLabelContainer">
                                <div className="RaceTypeLabel">{race.RaceDistance}</div>
                            </div>


                            <Link 
                            // data-tip="Go to Results Summary" data-effect='solid' data-delay-show='1000' data-place='bottom' data-class="MyRaceTooltipDark" 
                                to={`/athlete/${athlete.EmailHash}`} 
                                // to={prevLinkUrl}
                                className="nextPage tooltip" 
                                // onClick={this.beaconDone("pageNavigationWelcomeArrow")}
                                >
                                {/* <Beacon id="pageNavigationWelcomeArrow" text='Proceed to Results Summary' direction="left" style={{ top:'12px', left:'50px'}} /> */}
                                <span className="background" />
                                {/* <img src ={navNext } /> */}
                                <img src ={imgRunner } />
                                <TooltipText>Go to <br/><em>Athlete Home</em></TooltipText>
                            </Link>
                        </div>

                    </div>

                    </Swipeable>
                </div>
                {/* </motion.div> */}
            </>
        )
    }
}

export default withRouter(FinishContent)