import initialState from "./initialState";

export default (state = initialState.favoriteAthletes, action) => {
  switch (action.type) {

    case 'SET_FAVORITE_ATHLETES':
        return {
            ...state,
            [action.RaceCode]: action.favoriteAthletes
        };
        
    default:
      return state;
  }
};
