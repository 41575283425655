import initialState from "./initialState";


function createOrCloneState( state, key ){
    let newState = { ...state };
    if(!newState[key]){
        newState[key] = {
            //  perPage: 50, it willl be in filter
            status: '', // 'pending' OR 'received', 
            totalPages: 0,
            lastIndexes: [0],
            totalItems: 0,
            pages: {
                // 1: [ athlete objects here ]
            }
        }
    } else {
        newState[key] = {...newState[key]}
    }
    return newState;
}



export default (state = initialState.athleteFilterResults, action) => {
  switch (action.type) {

    case 'ATHLETE_FILTER_RESULTS/SET_PAGINATION_DATA': {
        const key = action.dataKey;
        let newState = createOrCloneState( state, key );

        newState[key] = {
            ...newState[key],
            ...action.payload
        }

        console.log('[ATHLETE_FILTER_RESULTS/SET_PAGINATION_DATA]', key, newState[key] )

        return newState;
    }
    
    // case 'SET_PAGINATION_DATA':
    //     if(typeof action.payload === undefined){
        //         return initialState.athleteSearchResults;
        //     }
        //     return {
            //         ...state,
            //         ...action.payload
            //     };
            
    case 'ATHLETE_FILTER_RESULTS/ADD_PAGE_SEARCH_RESULTS': {
        const key = action.dataKey;
        console.log('ATHLETE_FILTER_RESULTS/ADD_PAGE_SEARCH_RESULTS', key, action.payload);

        // raceCode-overallFilter-divisionFilter-splitTypeFilter-itemsPerPage
        // 'raceCode|overall|all|all-splits|50': {  } // <-- EXAMPLE
        /**
        overallFilter: "overall"
        divisionFilter: "all"
        splitTypeFilter: "all-splits"
        perPage: 50
        page: 1
         */
        
        // const raceCode = action.raceSelected.RaceCode;
        // const { overallFilter, divisionFilter, splitTypeFilter, perPage } = action.filter;

        // const key = `${raceCode}|${overallFilter}|${divisionFilter}|${splitTypeFilter}|${perPage}`;



        let newState = createOrCloneState( state, key );




        newState[key].lastIndexes = newState[key].lastIndexes.slice(0);
        newState[key].lastIndexes[action.payload.page] = action.payload.lastIndex;

        newState[key].pages = {
            ...newState[key].pages,
            [action.payload.page]: action.payload.athletes
        }
        return newState;
    }
    
    // case 'ADD_PAGE_SEARCH_RESULTS': {
    //     let newState = {...state};
    //     newState.lastIndexes = state.lastIndexes.slice(0);
    //     newState.lastIndexes[action.payload.page] = action.payload.lastIndex;

    //     newState.pages = {
    //         ...state.pages,
    //         [action.payload.page]: action.payload.athletes
    //     }
        
    //     return newState;
    // }

    case 'ATHLETE_FILTER_RESULTS/SET_TOTAL_PAGES': {
        console.log('ATHLETE_FILTER_RESULTS/SET_TOTAL_PAGES')
        const key = action.dataKey;
        let newState = createOrCloneState( state, key );

        newState[key] = {
            ...newState[key],
            totalPages: action.totalPages, 
            totalItems: action.totalItems 
        }
        
        return newState;
    }
    
    // case 'SET_TOTAL_PAGES': 
    //     return {...state, 
    //         totalPages: action.totalPages, 
    //         totalItems: action.totalItems 
    //     }

    // case "SET_SPLIT_TYPE_FILTER":
    // case "SET_DIVISION_FILTER":
    // case "SET_RACE_ATHLETE_FILTER":
    // case "SET_SELECTED_RACE":
    //     return initialState.athleteSearchResults;

    default:
        return state;
  }
};
