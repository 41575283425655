import React, {Component} from 'react'
import './MapSidebar.scss'
import classNames from 'classnames';
import imgOpenArrowHover from '../../images/page-back-3.svg';
import imgOpenArrow from '../../images/page-back-1.svg';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import * as sponsorsActions from '../../actions/sponsors'
import MapSidebarContent from './MapSidebarContent'
import ReactTooltip from 'react-tooltip'


// const SidebarOpenButton = ({onClick}) => (<div className="SidebarOpenButton" onClick={onClick}>&lt;</div>)
// const SidebarOpenButton = ({onClick}) => (
//     <div className="SidebarOpenButton IconSwappable" onClick={onClick}>
//         <div className="icon" style={{ backgroundImage: `url(${imgOpenArrow})`}}/>
//         <div className="hover" style={{ backgroundImage: `url(${imgOpenArrowHover})`}}/>
//     </div>
// )

const SidebarOpenButton = ({onClick, className}) => (
    <>
        <ReactTooltip  />

        <div data-tip="More" data-effect='solid' data-place='right' data-delay-show='1000' data-class="MyRaceTooltipDarkRightArrow"
            onClick={onClick} className={classNames("SidebarOpenButton","IconSwappable",className)}>
            <div className="icon" style={{ backgroundImage: `url(${imgOpenArrow})`}}/>
            <div className="hover" style={{ backgroundImage: `url(${imgOpenArrowHover})`}}/>
        </div>
    </>
)



class MapSidebar extends Component {

    componentDidMount(){
        this.closeSidebarsOnSmallScreen();
    }
    closeSidebarsOnSmallScreen = () => {
        const { settings: { windowW } } = this.props;
        if(windowW < 600) { this.props.closeSidebar(); }
    }


    render(){
        const { athleteMainData, athleteRankingData, settings, openSidebar, raceCode, allGeoPoints } = this.props;
        const { isMapSidebarOpen, isSearchModalOpen, isPageInfoModalOpen } = settings;
        // console.log("YYY athleteRankingData",athleteRankingData)
        return (
            <>
            <SidebarOpenButton onClick={openSidebar} className={classNames({ hidden: isMapSidebarOpen })}  />
            <div className={classNames("MapSidebar", {
                open:isMapSidebarOpen,
                closed:!isMapSidebarOpen,
                hidden: isSearchModalOpen || isPageInfoModalOpen
                })}>
                {/* !isMapSidebarOpen ? */}
                <MapSidebarContent raceCode={raceCode} athleteMainData={athleteMainData} athleteRankingData={athleteRankingData} allGeoPoints={allGeoPoints} />
            </div>
            </>
        )
    }
}

const mapState = ({settings, raceSelected, sponsors}) => ({settings, raceSelected, sponsors});
const mapDispatch = dispatch => ({
    openSidebar: ()=>dispatch({type:'SET_SETTING', isMapSidebarOpen: true}),
    closeSidebar: ()=>dispatch({type:'SET_SETTING', isMapSidebarOpen: false}),
    sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
})

export default connect(mapState, mapDispatch)(MapSidebar);

