import React, { Component } from "react";
// import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import Login from './Login'
// import SignUp from './SignUp'
// import SignUpLookupEmailHash from './SignUpLookupEmailHash'
import TooltipText from '../../components/Tooltip/TooltipText'
import './LoginPopup.scss'
// import imgLogo from '../../images/logo-05.svg';
import imgRunner from '../../images/signup.svg';
import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';

// import imgLogin from '../../images/login-1.svg';
// import imgLoginHov from '../../images/login-2.svg';
// import AccountForm from "./AccountForm";
// import classNames from 'classnames';
import { Link } from 'react-router-dom'
import ToggleLoginSignup from '../ToggleButton/ToggleLoginSignup'
import ForgotPassword from './ForgotPassword'
import EmailSent from './EmailSent'
import ResetPassword from './ResetPassword'

// import DropdownMenu from './DropdownMenu'




class LoginSignupContent extends Component {
    state = {
        email: ''
    }

    setTab = index => this.props.setTab( index===0 ? 'login' : 'signup' )
    
    
    onRootClick = e => {
        e.stopPropagation();
    }
    
    render = () => {
        // const {fbuser} = this.props;
        // const {tab} = this.state;
        const {tab, close, settings} = this.props;
        const { isLoginPopupOpen } = settings;

        // isLoginPopupOpen: "login" | "forgotpassword" | "resetpassword"

        return (
            <div className="LoginModalContent" onClick={this.onRootClick}>

                <div className="IconSwappable ClearIcon tooltip" onClick={close}>
                    <div className="background" />
                    <TooltipText><em>Close</em></TooltipText>
                    <div className="icon" style={{ backgroundImage: `url(${clearIcon})` }} />
                    <div className="hover" style={{ backgroundImage: `url(${clearIconHover})` }} />
                </div>


                <img src={imgRunner} alt="MyRace" className="runner" />

                {/* {isLoginPopupOpen==="login" && <h1>Log In</h1>}
                {isLoginPopupOpen==="forgotpassword" && <h1>Forgot Password?</h1>}
                {isLoginPopupOpen==="emailsent" && <h1>Email sent</h1>}
                {isLoginPopupOpen==="resetpassword" && <h1>Reset Password</h1>} */}
                

                {isLoginPopupOpen==="login" && <Login close={close} />}
                {isLoginPopupOpen==="forgotpassword" && <ForgotPassword close={close} setEmail={email=>this.setState({email})} />}
                {isLoginPopupOpen==="emailsent" && <EmailSent close={close} email={this.state.email} />}
                {isLoginPopupOpen==="resetpassword" && <ResetPassword close={close} />}


                {/*
                <>
                    <div className="withLine">
                        <ToggleLoginSignup titles={["Log In", "Sign Up"]} onChange={this.setTab} value={tab==='signup'? 1 : 0} />
                    </div>
                    {
                        tab === 'login' && <Login/>
                    }
                    {
                        // tab === 'signup' && <SignUp/>
                        tab === 'signup' && <SignUpLookupEmailHash/>
                    }
                </>
                */}

            </div>
        )
    }
}

const mapState = ({fbuser, settings})=>({fbuser, settings})

export default connect(mapState)(LoginSignupContent);
