import React, { Component } from "react";
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom';
import moment from "moment";
import debounce from 'lodash.debounce';
import algolia from '../../apis/algolia'
import searchIcon from '../../images/search-4.svg'


import filterIcon from '../../images/filter-1.svg'
import filterIconInverted from '../../images/filter-2.svg'

import userIcon from '../../images/user-1.svg'
// import userIconInverted from '../../images/user-2.svg'
import userIconInverted from '../../images/user-3.svg'
import clearIcon from '../../images/close-3.svg'
import clearIconInverted from '../../images/close-4.svg'
// import ReactTooltip from 'react-tooltip'
import TooltipText from "../Tooltip/TooltipText";


class SearchContent extends Component {
    constructor(props){
        super(props);
        this.search = debounce(this.search, 1000);
        this.inputEl = React.createRef();
    }

    state = {
        text: '',
        requestQuery: '',
        resultsQuery: '',
        resultsAthletes: [],
        resultsRaces: [],
        appliedFilters: [], // filter item = { eventCode: , raceCode: ,  }
    }

    componentDidMount() {

        const {raceSelected, match} = this.props;
        const { EventCode, RaceCode, RaceName, RaceDateStart,Location } = raceSelected;

        

        // console.log("raceSelected",raceSelected);
        if( EventCode && RaceCode && match.params.eventCode && match.params.raceCode ) {
            // let title = RaceName + ' | ' + moment.unix(RaceDateStart.seconds).format('YYYY');
            let title = <> 
                            <span>{RaceName}</span>
                            <span className="dot">•</span>
                            <span>{Location}</span>
                            <span className="dot">•</span>
                            <span>{moment(RaceDateStart).format('MMM D, YYYY')}</span>
                            {/* <span>{moment.unix(RaceDateStart.seconds).format('MMM D, YYYY')}</span> */}
                        </>;

            this.setState({ 
                appliedFilters: [{ eventCode: EventCode, raceCode: RaceCode, title }]
            })
        }

        this.focusOnInput();
    }
    
    focusOnInput = () => {
        window.setTimeout(()=>{
            this.inputEl.current && this.inputEl.current.focus();
        },50)
    }
    

    goToAthleteHome = hit => e => {
        e.stopPropagation();
        this.props.resetAthlete();
        // this.props.history.push(`/athlete/${hit.NameGenderSlug}/${hit.RecentHometownSlug}`);
        this.props.history.push(`/athlete/${hit.EmailHash}`);
        this.props.close();
    }
    goToAthlete = hit => e => {
        // console.log('XXX[goToAthlete]', hit)
        if(!hit.Bib && hit.RaceCode){ // this is a race
        // if(!hit.RecentRaceBib){ // this is a race
            
            return this.goToRace(hit)();
        }

        this.props.resetAthlete();
        this.props.history.push(`/welcome/${hit.RaceCode}/athletes/${hit.Bib}`);
        this.props.close();

        // if (hit.NoOfRecords === 1) {
        //     this.props.resetAthlete();
        //     this.props.history.push(`/welcome/${hit.RaceCode}/athletes/${hit.Bib}`);
        //     this.props.close();
        // } else  {
        //     this.props.history.push(`/athlete/${hit.EmailHash}`);
        //     this.props.close();
        // } 
        
        
        // else if (hit.UniqueHometowns.length > 1) {
        //     this.props.history.push(`/athlete-search-home/${hit.objectID}`);
        //     this.props.close();
        // } else {
        //     this.props.history.push(`/athlete-home/${hit.objectID}`);
        //     this.props.close();
        // }
        
    }
    goToRace = hit => e => {
        const rc = !!hit.RaceCode ? hit.RaceCode : hit.RecentRaceCode;
        this.props.history.push(`/select-athlete/${hit.EventCode}/${rc}/All-Genders`)
        // this.props.resetAthlete();
        // this.props.history.push(`/welcome/${hit.RaceCode}/athletes/${hit.Bib}`);
        this.props.close();
    }
    
    onChange = e => {
        this.setState({ text: e.target.value });
        this.search();

    }
    
    clearSearch = e => {
        this.setState({ text: '',
            requestQuery: '',
            resultsQuery: '',
            resultsAthletes: [],
            resultsRaces: [],
        });
        this.focusOnInput();
        // ReactTooltip.hide();

    }

    removeAppliedFilter = index => {
        const { appliedFilters } = this.state;
        this.setState({
            appliedFilters: [...appliedFilters.slice(0, index), ...appliedFilters.slice(index+1)]
        })
        this.focusOnInput();
        // ReactTooltip.hide();
        this.search();
    }

    search = () => {
        const { requestQuery, resultsQuery, text, appliedFilters } = this.state;
        if(requestQuery !== resultsQuery){
            return;
        }
        // console.log('INITIALTE SEARCH', text);
        // console.log('appliedFilters',appliedFilters);

        let AlgoliaIndexName = 'athletes';
        
        let raceFilter = '';
        if (appliedFilters && appliedFilters.length > 0 && appliedFilters[0].raceCode) {
            // console.log("raceFilter!")
            raceFilter = 'RaceCode:' + appliedFilters[0].raceCode;
            // raceFilter = 'UniqueRaceCodes:' + appliedFilters[0].raceCode;
            
        }
        // console.log("raceFilter",raceFilter);

        this.setState({ requestQuery: text });

        // window.setTimeout(this.handleResponse, 1000);

        // const indexAthletes = algolia.initIndex('athletes');
        // const indexAthletes = algolia.initIndex('athletes-slugs-with-hometowns');
        const indexAthletes = algolia.initIndex(AlgoliaIndexName);
        indexAthletes.search({query: text, hitsPerPage: 100, filters:raceFilter}, this.handleResponse('resultsAthletes'));
        
        const indexRaces = algolia.initIndex('races');
        indexRaces.search({query: text, hitsPerPage: 100}, this.handleResponse('resultsRaces'));
    }

    handleResponse = stateNode => (err, content) => {
        const { requestQuery } = this.state;
        // console.log(`ALGOLIA RESULTS FOR ${stateNode} – "${requestQuery}":`, !!content && content.hits);

        if(!content || !content.hits ||  typeof content.hits.length !== 'number'){
            return;
        }

        let hits = content.hits;//.slice(0,30);


        if(stateNode==='resultsAthletes'){
            // group athletes
            let athletes = {};
            
            content.hits.forEach( hit => {
                const group_key = `${hit.objectID}`;
         

                const uniqueHometowns = hit.UniqueHometowns && Object.keys(hit.UniqueHometowns).map( hometownSlug => {
                    const uniqueHometown = hit.UniqueHometowns[hometownSlug];
            
                    const id = `${uniqueHometown.HometownSlug}`;
                    return {...uniqueHometown, id}
                  });
                
                // let hometownList = '';
                // uniqueHometowns.forEach( hometown => {
                //     hometownList += hometown.HometownState;
                // })
                // athletes[group_key].HometownList=uniqueHometowns[0].HometownState;
                let cityStateCountryCodeStart = '';
                let cityStateCountryCodeEnd = '';
                let capsVersionCityStateCountryCodeStart = ''
                
                let capsVersionCityStateCountryCodeEnd = '';
                // console.log("hts",uniqueHometowns);
                // console.log("capsVersionCityStateCountryCodeStart",capsVersionCityStateCountryCodeStart);
                // if (uniqueHometowns && uniqueHometowns.length > 1) {
                //     let i = uniqueHometowns.length - 1;
                    
                //     if (uniqueHometowns[0].HometownCity.length > 0 && uniqueHometowns[0].HometownState.length > 0 && uniqueHometowns[0].HometownCountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeStart = uniqueHometowns[0].HometownCity + ', ' + uniqueHometowns[0].HometownState + ' | ' + uniqueHometowns[0].HometownCountryThreeCharCode;
                //     } else if (uniqueHometowns[0].HometownState.length > 0 && uniqueHometowns[0].HometownCountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeStart = uniqueHometowns[0].HometownState + ' | ' + uniqueHometowns[0].HometownCountryThreeCharCode;
                //     } else if (uniqueHometowns[0].HometownCountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeStart = uniqueHometowns[0].HometownCountryThreeCharCode;
                //     }
            
                //     // capsVersionCityStateCountryCodeStart = cityStateCountryCodeStart.toUpperCase().replace(",","");
                //     capsVersionCityStateCountryCodeStart = cityStateCountryCodeStart;

                //     if (uniqueHometowns[i].HometownState.length > 0 && uniqueHometowns[i].HometownCountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeEnd = uniqueHometowns[i].HometownCity + ', ' + uniqueHometowns[i].HometownState + ' | ' + uniqueHometowns[i].HometownCountryThreeCharCode;
                //     } else if (uniqueHometowns[i].HometownState.length > 0 && uniqueHometowns[i].HometownCountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeEnd = uniqueHometowns[i].HometownState + ' | ' + uniqueHometowns[i].HometownCountryThreeCharCode;
                //     } else if (uniqueHometowns[i].HometownCountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeEnd = uniqueHometowns[i].HometownCountryThreeCharCode;
                //     }

                //     // capsVersionCityStateCountryCodeEnd = cityStateCountryCodeEnd.toUpperCase().replace(",","");
                //     capsVersionCityStateCountryCodeEnd = cityStateCountryCodeEnd;
                //     console.log("capsVersionCityStateCountryCodeEnd",capsVersionCityStateCountryCodeEnd);

                //     // hit.HometownToShow = '...'
                //     // hit.HometownToShow = capsVersionCityStateCountryCodeStart.substring(0, 7) + ' ... ' + capsVersionCityStateCountryCodeEnd.substring(capsVersionCityStateCountryCodeEnd.length - 7);
                //     // hit.HometownToShow = capsVersionCityStateCountryCodeStart.substring(0, 7) + '... , ' + capsVersionCityStateCountryCodeEnd.substring(0,7) + '...';
                //     hit.HometownToShow = capsVersionCityStateCountryCodeStart + ', ' + capsVersionCityStateCountryCodeEnd.substring(0, 12) + '...';
                //     hit.CountryTwoCharCode = ''; // clear this since we do not want to show a flag
                // } else {
                //     // hit.HometownToShow = hit.CityStateCountryCodeAllCaps;
                //     if (hit.City.length > 0 && hit.State.length > 0 && hit.CountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeStart = hit.City + ', ' + hit.State + ' | ' + hit.CountryThreeCharCode;
                //     } else if (hit.State.length > 0 && hit.CountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeStart = hit.State + ' | ' + hit.CountryThreeCharCode;
                //     } else if (hit.CountryTwoCharCode.length > 0) {
                //         cityStateCountryCodeStart = hit.CountryThreeCharCode;
                //     }
            
                //     capsVersionCityStateCountryCodeStart = cityStateCountryCodeStart.toUpperCase().replace(",","");

                //     // hit.HometownToShow = capsVersionCityStateCountryCodeStart;
                //     hit.HometownToShow = cityStateCountryCodeStart;
                // }

             
                if (hit.HometownCity.length > 0 && hit.HometownStateCode.length > 0 && hit.HometownTwoCharCountryCode.length > 0) {
                    cityStateCountryCodeStart = hit.HometownCity + ', ' + hit.HometownStateCode + ' | ' + hit.HometownTwoCharCountryCode;
                } else if (hit.HometownStateCode.length > 0 && hit.HometownTwoCharCountryCode.length > 0) {
                    cityStateCountryCodeStart = hit.HometownStateCode + ' | ' + hit.HometownTwoCharCountryCode;
                } else if (hit.HometownTwoCharCountryCode.length > 0) {
                    cityStateCountryCodeStart = hit.HometownTwoCharCountryCode;
                }
        
                // capsVersionCityStateCountryCodeStart = cityStateCountryCodeStart.toUpperCase().replace(",","");

                // hit.HometownToShow = capsVersionCityStateCountryCodeStart;
                hit.HometownToShow = cityStateCountryCodeStart;

                // if(athletes[group_key]){ // second (or more) encounter
                //     if(!athletes[group_key].NoOfRaces){
                //         athletes[group_key] = { ...athletes[group_key], NoOfRaces: 1 };
                //     }
                //     athletes[group_key].noOfOccurences += 1;
                // } else {
                //     athletes[group_key] = hit;
                // }
                
                athletes[group_key] = hit;
            });



            hits = [];
            for(let key in athletes){
                hits.push(athletes[key]);
            }

            // hits = hits.slice(0,10);
            // console.log("XXXhits",hits);
        }


        // if(stateNode==='resultsAthletes'){
        //     // group athletes
        //     let athletes = {};
            
        //     content.hits.forEach( hit => {
        //         const group_key = `${hit.objectID}`;
        //         if(athletes[group_key]){ // second (or more) encounter
        //             if(!athletes[group_key].NoOfRaces){
        //                 athletes[group_key] = { ...athletes[group_key], NoOfRaces: 1 };
        //             }
        //             athletes[group_key].noOfOccurences += 1;
        //         } else {
        //             athletes[group_key] = hit;
        //         }
        //     });

        //     hits = [];
        //     for(let key in athletes){
        //         if (hits.length < 6) {
        //             hits.push(athletes[key]);
        //         }
        //     }
        // }
    

        this.setState({
            [stateNode]: hits,
            resultsQuery: requestQuery
        })


    }

    
    render(){
        
        const { text, requestQuery, resultsQuery, resultsAthletes, resultsRaces, appliedFilters } = this.state;
        const { close } = this.props;

        // console.log("resultsAthletes",resultsAthletes);

        return (
            <div className="SearchModalContent">

                <div className="SearchModalContentHeader">

                    <div className="close" onClick={close}>close</div>

                    <div className="SearchInputContainer" style={{
                        backgroundImage: `url(${searchIcon})`
                    }}>


                        <input 
                            ref={this.inputEl}
                            type="text"
                            onChange={this.onChange}
                            className="SearchInput"
                            placeholder="Search..."
                            value={text} 
                        />


                        { !!text && 
                
                        <div data-tip="Clear" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDark"
                            className="IconSwappable ClearIcon" onClick={this.clearSearch}>
                            <div className="icon" style={{ backgroundImage: `url(${clearIconInverted})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIcon})`}}/>
                        </div> 

                        }

                        
                    </div>

                    { (appliedFilters && appliedFilters.length>0) && <AppliedFilters appliedFilters={appliedFilters} goToAthlete={this.goToAthlete} removeAppliedFilter={this.removeAppliedFilter} /> }

                    {/* { (!appliedFilters || !appliedFilters.length) && <QuickLinks goToAthlete={this.goToAthlete} /> } */}


                    {/* {requestQuery && !resultsQuery && <p>You are searching for “{text}”</p> } */}

                    {requestQuery !== resultsQuery && <p>Requesting “{requestQuery}”...</p>}

                    {!!resultsQuery && <h5>Suggested Searches</h5>}


                </div>
                <div className="SearchModalContentBody">

                    {!!resultsQuery && <div className="separator"><i/><span>Athletes</span></div>}

                    {/* show athletes results */}
                    {!!resultsQuery && (resultsAthletes && resultsAthletes.length ? (
                        <div className="SearchResults SearchResultsAthletes">
                            {resultsAthletes.map( item => <AthleteResult linkToAthleteHomeInsteadOfToRace={(appliedFilters && appliedFilters.length>0)} goToAthlete={this.goToAthlete} goToAthleteHome={this.goToAthleteHome} key={`${item.objectID}`} {...item} />)}
                        </div>
                    ) : <p>No results.</p>)}



                    {!!resultsQuery && resultsRaces && resultsRaces.length > 0 && <div className="separator"><i/><span>Races</span></div>}
                    
                    {!!resultsQuery && (resultsRaces && resultsRaces.length ? (
                        <div className="SearchResults SearchResultsRaces">
                            {resultsRaces.map( item => <RaceResult gotoRace={this.goToRace} key={`${item.eventCode}_${item.raceCode}`} {...item} />)}
                        </div>
                    ) : null)}
                    
                

                </div>



            </div>
        )
    }
}


const AthleteResult = props => {
    const flag = props.HometownTwoCharCountryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397));
  
    // let location = '';
    // if(props.State) location += props.State;
    // if(props.City) location = `${props.City}, ${location}`;
    const linkToAthleteHome = !props.linkToAthleteHomeInsteadOfToRace;

    
    // let raceDate = moment(props.RaceDateStart);
    let raceDate = moment(props.RaceDate);
    // console.log("raceDate",raceDate);
    // let recentRaceDate = raceDate.format("MMM D, YYYY");  
 
    // recentRace = props.RaceName + '<br/>' + recentRaceDate;
    // let recentRace = '';
    // recentRace = `${props.RaceName} ${recentRaceDate}`;

    let cityStateCountry = props.HometownToShow;

    const athleteHash = props.EmailHash;
    // console.log("props",props);
    const ageGender = props.Age ? ' (' + props.Gender + props.Age + ')' : ' (' + props.Gender + ')'; 
    const athleteColumn = props._highlightResult.Name.value + ageGender;

    const navigationLink = linkToAthleteHome ? props.goToAthleteHome(props) : props.goToAthlete(props);
    // console.log("linkToAthleteHome",linkToAthleteHome)
    return (
        <>
            {/* <ReactTooltip multiline className="MyRaceTooltip"  /> */}
            {/* <div className="SearchResult AthleteSearchResult" onClick={props.goToAthlete(props)}> */}
            <div className="SearchResult AthleteSearchResult" onClick={navigationLink}>
             
                <div className="searchResultIcon" style={{ backgroundImage: `url(${searchIcon})` }}/>
                

                <div className="SearchResultTitle tooltip">
                    {/* <TooltipText>{recentRace}</TooltipText> */}
                    {/* <div data-tip={recentRace} data-effect='float' data-place='bottom' data-class="MyRaceTooltip"  */}
                    <div dangerouslySetInnerHTML={{__html: athleteColumn}} />
                </div>

                {/* <div data-tip={recentRace} data-effect='float' data-place='bottom' data-class="MyRaceTooltip"  */}
                <div className="AthleteNoOfOccurences">
                    <span>#{props.Bib} </span>
                </div>
                <div className="AthleteNoOfOccurences">
                    <span>{props.OfficialTime} </span>
                </div>
                {/* <div className="AthleteNoOfOccurences">       
                    
                    {!!props.NoOfRecords && props.NoOfRecords > 1 &&  <>
                        <span>{props.NoOfRecords} </span>
                        <span className="muted"> records</span>
                    </>}
                </div> */}

                {/* <div data-tip={recentRace} data-effect='float' data-place='bottom' data-class="MyRaceTooltip" */}
                <div className="AthleteFlag tooltip">
                    {/* <TooltipText>{recentRace}</TooltipText> */}
                    {flag}
                </div>

                
                {/* <div data-tip={recentRace} data-effect='float' data-place='bottom' data-class="MyRaceTooltip"  */}
                <div className="AthleteLocation">
                        {cityStateCountry}
                </div>
                {/* <div className="AthleteLocation tooltip" dangerouslySetInnerHTML={{__html: props._highlightResult.HometownCity.value + ", " + props._highlightResult.HometownStateCode.value}}>
   
                </div> */}
                    {/* className="AthleteLocation" dangerouslySetInnerHTML={{__html: cityStateCountry}} /> */}

                { athleteHash && !linkToAthleteHome && (
                    // <div className="IconSwappable UserIcon" data-tip={"Athlete's Page"} data-effect='float' data-place='bottom' data-class="MyRaceTooltip">
                    <div className="IconSwappable UserIcon tooltip">
                        <TooltipText>Athlete's Page</TooltipText>
                        <div className="icon" style={{ backgroundImage: `url(${userIcon})`}}/>
                        <div onClick={navigationLink} className="hover" style={{ backgroundImage: `url(${userIconInverted})`}}/>
                    </div> 
                    )
                }
                {/* { (!props.NoOfRecords || props.NoOfRecords === 1) && (
                    <div className="IconSwappable UserIcon" data-tip={"Athlete's Page"} data-effect='float' data-place='bottom' data-class="MyRaceTooltip">
                        <div className="icon" style={{ backgroundImage: `url(${userIcon})`}}/>
                        <div onClick={props.goToAthleteHome(props)} className="hover" style={{ backgroundImage: `url(${userIconInverted})`}}/>
                    </div> 
                    )
                } */}
                <div className="padder"></div>
            </div>
    </>
)}



const RaceResult = props => (
    <div className="SearchResult RaceSearchResult" onClick={props.gotoRace(props)}>

        <div className="searchResultIcon" style={{ backgroundImage: `url(${searchIcon})` }}/>

        <div className="SearchResultTitle" dangerouslySetInnerHTML={{ __html:  props._highlightResult.RaceNameFull.value }} />
        {/* {props.RaceNameFull} */}
        
    </div>
)


const QuickLinks = ({goToAthlete}) => {

    const quickLinks = [
        { id: 'tsfm-ma-2018_1',type:'athlete', RaceCode: 'tsfm-ma-2018', Bib: '1', title: 'Jorge Maravilla', NoOfRecords:1 },
        { id: 'tsfm-ma-2019',type:'race', RaceCode: 'tsfm-ma-2019', EventCode: 'tsfm-2019', title: 'The 2019 Biofreeze San Francisco Marathon'},
    ]
    return (
        <div className="QuickLinks">
            <h5>Quick Links</h5>
            { quickLinks.map( link => <QuickLink key={`${link.id}`} goToAthlete={goToAthlete} {...link}  />)}
        </div>
    )
}

const QuickLink = props => (
    
    <div className="QuickLink" onClick={props.goToAthlete(props)}>{props.title}</div>
)




const AppliedFilters = props => {
    return (
        <div className="AppliedFilters">
            
            <h5>Applied Filters</h5>
            { props.appliedFilters.map( (filt,index) => <AppliedFilter key={`${index}_${filt.title}`} goToAthlete={props.goToAthlete} removeAppliedFilter={props.removeAppliedFilter} index={index} {...filt} /> )}
        </div>
    )
}

const AppliedFilter = props => (
    <div className="AppliedFilter">
        {/* <ReactTooltip multiline className="MyRaceTooltip"  /> */}
        {/* <div data-tip="Clear" data-effect='float' data-place='bottom' data-class="MyRaceTooltipDark" */}
        <div className="closeAppliedFilter tooltip" onClick={e=>props.removeAppliedFilter(props.index)}>
            <TooltipText>Clear</TooltipText>
            ⨯
        </div>


        <div className="IconSwappable FilterIcon">
            <div className="icon" style={{ backgroundImage: `url(${filterIcon})`}}/>
            <div className="hover" style={{ backgroundImage: `url(${filterIconInverted})`}}/>
        </div> 


        <span className="filterTitle">{props.title}</span>
    </div>
)




const mapStateToProps = state => ({
    // athleteSelected: state.athleteSelected,
    raceSelected: state.raceSelected,
    // athleteSearch: state.athleteRaceSearch,
    // user: state.user
  })
  const mapDispatchToProps = dispatch => ({
    // setSelectedAthlete: athlete => dispatch({type:'SET_SELECTED_ATHLETE', athlete}),
    // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
    // updateSelectedAthlete: athlete => dispatch({type:'UPDATE_SELECTED_ATHLETE', athlete}),
    // updateSelectedRace: race => dispatch({type:'UPDATE_SELECTED_RACE', race}),
    // setAgeGradeFactor: ageGradeFactor => dispatch({type:'SET_AGE_GRADE_FACTOR', ageGradeFactor}),
    // // goToStart: () => dispatch({type:'INTERVAL_ANALYSIS_GO_TO_START'}),
    resetAthlete: () => dispatch({type:'RESET_ATHLETE'}),
  })
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchContent));
