import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import classNames from 'classnames'
// import firebase from 'firebase/app'
import {auth} from '../../config/firebase'
import {Link, withRouter} from 'react-router-dom';
// import imgMenu from '../../images/menu.svg'
import imgMenu from '../../images/slack-01.svg'
import logoimg from "../../images/logo-05.svg";

import userimg from "../../images/login-03.svg";
import finishimg from "../../images/finish-1.svg";
import infoimg from "../../images/info-05.svg";
import helpimg from "../../images/help-05.svg";
import mailimg from "../../images/mail-01.svg";
import commentimg from "../../images/comment-01.svg";
import TooltipText from '../Tooltip/TooltipText'

import clearIcon from '../../images/close-4.svg';
import clearIconHover from '../../images/close-3.svg';



import { bindActionCreators } from "redux";
import { initBeacon } from '../../actions/fbuser'
import {RemoveScroll} from 'react-remove-scroll';
import './Sidebar.scss'

class Sidebar extends Component {
    componentDidMount(){
        this.coverEl = window.document.createElement('div');
        window.document.body.appendChild(this.coverEl);
        this.contentEl = window.document.createElement('div');
        window.document.body.appendChild(this.contentEl);
    }
    componentWillUnmount(){
        window.document.body.removeChild(this.coverEl);
        window.document.body.removeChild(this.contentEl);
    }

    renderModal = () => {
        if (!this.coverEl) return null;
        return (
            ReactDOM.createPortal(
                <RemoveScroll>
                    <div className="SidebarCover" onClick={this.props.closeSidebar}></div>
                </RemoveScroll>
            , this.coverEl)
        )
    }
    renderContent = (content) => {
        if (!this.contentEl) return null;
        return (
            ReactDOM.createPortal( content , this.contentEl)
        )
    }


    renderFinishLinks = () => {
        // const {match, openSidebar, race, athlete, closeSidebar, settings: {isSidebarOpen}} = this.props;
        const {page, openSidebar, closeSidebar, settings: {isSidebarOpen}} = this.props;

        const { settings: { lastSelectedAthleteNameGenderSlug: NameGenderSlug, lastSelectedAthleteHometownSlug: HometownSlug, lastSelectedAthleteBib: Bib, lastSelectedRaceCode: RaceCode, lastSelectedEventCode: EventCode }} = this.props;

        

        // console.log("XXXPAGE",page);
        // console.log("RACE in sidebar",RaceCode);
        // console.log("ATHLETE BIB in sidebar",Bib);
        // console.log("EVENTCODE in sidebar",EventCode);
        // console.log("NAMEGENDERSLUG in sidebar",NameGenderSlug);
        // console.log("HOMETOWN SLUG in sidebar",HometownSlug);
        if(!RaceCode || !EventCode || !NameGenderSlug || !HometownSlug) return null;

        const racePageLink = `/select-athlete/${EventCode}/${RaceCode}/All-Genders`
        // const athleteHomePageLink = athlete ? `/athlete/${NameGenderSlug}/${HometownSlug}` : null;
        const athleteHomePageLink = `/athlete/${NameGenderSlug}/${HometownSlug}`;
        const athleteResultsWelcomeLink = `/welcome/${RaceCode}/athletes/${Bib}`
        const athleteResultsSummaryPageLink = `/results-summary/${RaceCode}/athletes/${Bib}`
        const athleteResultsIntervalLink = `/segment-analysis/${RaceCode}/athletes/${Bib}`
        const athleteResultsCourseMapPageLink = `/course-map/${RaceCode}/athletes/${Bib}`
        const athleteResultsFinishPageLink = `/finish/${RaceCode}/athletes/${Bib}`

        
        const welcomeLinkEnabled = page === "welcome" ? true : false;
        const resultsLinkEnabled = page === "results-summary" ? true : false;
        const segmentLinkEnabled = page === "segment-analysis" ? true : false;
        const courseLinkEnabled = page === "course-map" ? true : false;
        const finishLinkEnabled = page === "finish" ? true : false;
        const eventAthletesLinkEnabled = page === "select-athlete" ? true : false;
        
        
        return (
            <>
                
                <Link onClick={closeSidebar} className={classNames({ SidebarLinkDisabled:welcomeLinkEnabled })} to={athleteResultsWelcomeLink}> Welcome </Link>
                <Link onClick={closeSidebar} className={classNames({ SidebarLinkDisabled:resultsLinkEnabled })} to={athleteResultsSummaryPageLink}> Results Summary </Link>
                <Link onClick={closeSidebar} className={classNames({ SidebarLinkDisabled:segmentLinkEnabled })} to={athleteResultsIntervalLink}> Segment Analysis </Link>
                {/* <Link onClick={closeSidebar} className=({ SidebarLinkDisabled:courseLinkEnabled })} to={athleteResultsCourseMapPageLink}> Course Map </Link> */}
                <Link onClick={closeSidebar} className={classNames({ SidebarLinkDisabled:finishLinkEnabled })} to={athleteResultsFinishPageLink}> Finish Certificate</Link>
                <Link onClick={closeSidebar} className={classNames({ SidebarLinkDisabled:eventAthletesLinkEnabled })} to={racePageLink}> Event & Athletes</Link>
                {/* <Link onClick={closeSidebar} to={athleteHomePageLink}> Athlete </Link> */}
            </>
        )

    }


    openKeepInTouch = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.openKeepInTouch();
    }
    openPartnerWithUs = e => {
        console.log('[openPartnerWithUs]')
        e.preventDefault();
        this.props.closeSidebar();
        this.props.openPartnerWithUs();
    }
    openRequestRace = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.openRequestRace();
    }
    
    openAboutThisPage = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.openAboutThisPage();
    }

    openLogin = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.setLoginPopupOpen('login')
    }

    gotoAthleteHomePage = e => {
        const {fbuserProfile } = this.props;
        e.preventDefault();
        this.props.closeSidebar();
        this.props.history.push(`/${fbuserProfile && fbuserProfile.username}`);
    }

    gotoAccountProfilePage = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.history.push(`/account/profile`);
    }

    logout = e => {
        e.preventDefault();
        this.props.closeSidebar();

        auth.signOut();
        this.props.history.push('/');
    }
    
    
    openBeaconHelp = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.fbuserActions.initBeacon();
        window.Beacon("open");
        window.Beacon("once", "close", ()=>window.Beacon('destroy'));
    }
    
    openBeaconHelp = e => {
        e.preventDefault();
        this.props.closeSidebar();
        this.props.fbuserActions.initBeacon();
        window.Beacon("open");
        window.Beacon("once", "close", ()=>window.Beacon('destroy'));
    }
    
    openFeedback = e => {
        e.preventDefault();
        this.props.closeSidebar();
        window.hj('trigger', 'hotjar-feedback');
    }
    
    
    mobileAdditionalLinks = () => {
        const { settings: {windowW} } = this.props;
        if(windowW > 600) {
            return null;
        }

        return (
            <>
                <div className="Separator" />
                <Link to="#" onClick={this.openKeepInTouch}>
                    <img src={mailimg} className="LinkIcon" />
                    <span>Let's Stay in Touch</span>
                </Link>
                
                <a href="#" onClick={this.openAboutThisPage}>
                    <img src={infoimg} className="LinkIcon" />
                    <span>About this Page</span>
                </a>
                <Link to="#" onClick={this.openBeaconHelp}>
                    
                    <img src={helpimg} className="LinkIcon" />
                    <span>Help</span>
                </Link>
                <Link to="#" onClick={this.openFeedback}>
                    <img src={commentimg} className="LinkIcon" />
                    <span>Feedback</span>
                </Link>
            </>
        )
    }

    


    goToHome = () => this.props.history.push('/')
    
    render(){
        const {page,fbuser, fbuserProfile, openSidebar, race, athlete, closeSidebar, settings: {isSidebarOpen, windowW}} = this.props;
        const homeLinkEnabled = page === "" ? true : false;
        // console.log("page",page);
        // console.log("fbuserProfile",fbuserProfile);

        // const onClick = windowW > 600 ? this.goToHome : openSidebar;
        const onClick = this.goToHome;

        const aboutPageLink = `https://about.myrace.com/article/4-what-is-myrace`;

        // http://localhost:3000/welcome/mvm-ma-2018/athletes/569

        return (
         <div className="Sidebar">
            <div className="SidebarButton tooltip" onClick={onClick}>
                <TooltipText>Home Page</TooltipText>
                <div className="background" />
                <img src={(page==='' || page==='Home') ? logoimg : imgMenu} alt="Menu" />
            </div>
            {
                this.renderContent(
                    <div className={classNames('SidebarContent', {
                        open:isSidebarOpen,
                        closed:!isSidebarOpen,
                    })}>

                        <button className="close" onClick={closeSidebar}>&laquo; close</button>

                        <div className="IconSwappable ClearIcon" onClick={closeSidebar}>
                            <div className="icon" style={{ backgroundImage: `url(${clearIcon})`}}/>
                            <div className="hover" style={{ backgroundImage: `url(${clearIconHover})`}}/>
                        </div>

                        <Link to="/" className="SidebarMyRaceLogo">
                            <img src={logoimg} alt="MyRace" />
                        </Link>

                        <div className="Separator" />

                        <div className="SidebarNavigation">
               

                            <Link className={classNames({ SidebarLinkDisabled:homeLinkEnabled })} onClick={closeSidebar} to="/"> Home </Link>


                            { !fbuser && <Link onClick={this.openLogin} to="#"> Login </Link> }
                            { fbuser && <Link onClick={this.gotoAthleteHomePage} to="#"> My Athlete Home </Link> }
                            { fbuser && <Link onClick={this.gotoAccountProfilePage} to="#"> My Profile </Link> }
                            { fbuser && <Link onClick={this.logout} to="#"> Log out {`( ${fbuserProfile && fbuserProfile.username})`}</Link> }
                            
                            
                            {this.renderFinishLinks()}


                            <div className="Separator" />
                            <Link to="#" onClick={this.openPartnerWithUs}>
                                <img src={finishimg} className="LinkIcon" width="18" height="18" />
                                <span>Partner with Us</span>
                            </Link>
                            
                
                        
                            <Link to="#" onClick={this.openRequestRace}>
                                <img src={userimg} className="LinkIcon" />
                                <span>Request a Race</span>
                            </Link>


                            {this.mobileAdditionalLinks()}

                            <div className="Separator" />
                            <a href={aboutPageLink} target="_blank" alt="About MyRace" title="About MyRace" rel="noopener noreferrer">🏅 About MyRace</a>
  

                        </div>

                    </div>
                )
            }
            { isSidebarOpen && this.renderModal() }
         </div>
        )
    }
}


// const mapState = ({settings}) => ({settings});
const mapState = ({fbuser, settings, fbuserProfile})=>({fbuser, settings, fbuserProfile})

const mapDispatch = dispatch => ({
    setLoginPopupOpen: tab => dispatch({ type: 'SET_SETTING', isLoginPopupOpen: tab }),
    openSidebar: ()=>dispatch({type:'SET_SETTING', isSidebarOpen: true}),
    closeSidebar: ()=>dispatch({type:'SET_SETTING', isSidebarOpen: false}),
    openAboutThisPage: ()=>dispatch({type:'SET_PAGE_INFO_MODAL_OPEN', open: true}),   
    openKeepInTouch: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),  
    openPartnerWithUs: ()=>dispatch({type:'SET_SETTING', isPartnerModalOpen: true}),  
    openRequestRace: ()=>dispatch({type:'SET_SETTING', isRequestRaceModalOpen: true}),  
    fbuserActions: bindActionCreators({initBeacon}, dispatch),
})

export default withRouter(connect(mapState, mapDispatch)(Sidebar));

