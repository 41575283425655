import React, {Component} from 'react'
import LoginSignupContent from '../../components/Login/LoginSignupContent';
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux'

class AccountLogin extends Component {
    render(){
        const {fbuser} = this.props;
        const { from } = this.props.location.state || { from : { pathname: '/' }};
        
        if (fbuser && !fbuser.isAnonymous) {
            return <Redirect to={from} />;
        }
        
        return (
            <div className="AccountLogin">
                <LoginSignupContent />
            </div>
        )
    }
}

const mapState = ({fbuser})=>({fbuser})
export default connect(mapState)(AccountLogin)