import React, { Component, useState } from "react";
import {withRouter, Redirect} from 'react-router-dom'
import {secondsToTimeStrip,secondsToTime,secondsToTimeStripWithSign, roundDistance, formatNumber} from '../../timeUtils'
import moment from "moment";
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer'

import {connect} from 'react-redux'
import steeltoe from 'steeltoe';
import maleIcon from '../../images/m-1-07.svg'
import femaleIcon from '../../images/f-1-07.svg'
import genericIcon from '../../images/user-05.svg'
import favBlack from '../../images/star-01.svg'
import favBlue from '../../images/star-02.svg'
import favGray from '../../images/star-03.svg'
import classNames from 'classnames'
import * as favoriteAthletesActions from '../../actions/favoriteAthletes'


import openGray from '../../images/open-02.svg';
import openBlue from '../../images/open-01.svg';

import imgMail from '../../images/mail-02.svg'
import imgStopWatch2 from '../../images/stopwatch-02.svg';
import imgStopWatch3 from '../../images/stopwatch-03.svg';
import TooltipText from '../../components/Tooltip/TooltipText'
import "./AthleteHomeRaceListPage.scss";
import Headroom from 'react-headroom';
import { bindActionCreators } from "redux";
import * as fbuserActions from '../../actions/fbuser'
import { toast } from 'react-toastify';
import {getAvatarImage} from '../AccountProfile/avatars'

import { getAvatarAndRaces } from '../../actions/users'
import FavIconLink from "../../components/AthleteListTable/FavIconLink";



class AthleteHomeRaceListPage extends Component {

    state = {
        raceCodes: []
    }

        
    componentDidMount() {

      let {athleteHash, username} = this.props.match.params;
      if (!athleteHash && username) {
        this.props.usersActions.getAvatarAndRaces({username});
      } else if (athleteHash && !username) {
        this.props.usersActions.getAvatarAndRaces({EmailHash: athleteHash});
      }
    }

    
    componentDidUpdate(prevProps, prevState){
        const { fbuser } = this.props;
        //   const { from } = this.props.location.state || { from: { pathname: '/' }}



        this.getFavorites();

    
      
      if (prevProps.fbuser != fbuser) {
  
        if (!fbuser) {
          return;
        }
        // console.log("fbuser",fbuser);

        let {athleteHash, username} = this.props.match.params;
        if (!athleteHash && username) {
          this.props.usersActions.getAvatarAndRaces({username});
        } else if (athleteHash && !username) {
          this.props.usersActions.getAvatarAndRaces({EmailHash: athleteHash});
        }
  
      }
  }

    getFavorites = () => {

        if(!this.props.fbuserProfile || !this.props.users) return;
        const {fbuserProfile: {username}, users} = this.props;
        
        
        // let { athleteHash, username } = this.props.match.params;
        //if (!athleteHash && username) {
        let athleteHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username);
        // }

        if(!athleteHash) return;

        //athleteRaceResults.0.RaceCode

        const athleteRaceResults = steeltoe(this.props).get(`users.${athleteHash}.athleteRaceResults`);
        const athleteRaceResultsFiltered = athleteRaceResults && athleteRaceResults.filter(race => (race.RaceDetails !== undefined && (race.RaceDetails.AdminStatus == 'Public' || race.RaceDetails.AdminStatus == 'Public-Unofficial'))); 
    
        

        const raceCodes = athleteRaceResultsFiltered && athleteRaceResultsFiltered.length ? athleteRaceResultsFiltered.map(r => r.RaceCode) : [];
        let newRaceCodes = [];
        raceCodes.forEach(RaceCode => {
            if (!this.state.raceCodes.includes(RaceCode)) {
                newRaceCodes.push(RaceCode);
            }
        });
        if (newRaceCodes.length > 0) {
            this.setState(s => ({ raceCodes: s.raceCodes.concat(newRaceCodes) }));
            newRaceCodes.forEach(RaceCode => {
                this.props.favoriteAthletesActions.getFav(RaceCode);
            });
        }

    }



    goToSelectedRace = ({ card }) => {

        const uri = `/welcome/${card.RaceCode}/athletes/${card.Bib}`
        this.props.history.push(uri);
    }


  

  render() {
    const { match, fbuserProfile, fbuser, settings, users} = this.props;

    let {athleteHash, username} = this.props.match.params;
    // const EmailHash = athleteHash;
      


    if(athleteHash && steeltoe(users).get(`${athleteHash}.username`)) {
      // we are on the route that is based on ahtleteHash
      return <Redirect to={`/${users[athleteHash].username}/racelist`} />
    }
    
    if(!athleteHash && username ){
      athleteHash = Object.keys(users).find(ehash => users[ehash] && users[ehash].username === username );
    }

    let userNotFound = false;
    if (!athleteHash && steeltoe(users).get(`username___${username}.status.getEmailHash`)==='received') {
      userNotFound = true;
    }

    const athleteRaceResults = steeltoe(this.props).get(`users.${athleteHash}.athleteRaceResults`);
    const athleteRaceResultsFiltered = athleteRaceResults && athleteRaceResults.filter(race => (race.RaceDetails !== undefined && (race.RaceDetails.AdminStatus == 'Public' || race.RaceDetails.AdminStatus == 'Public-Unofficial'))); 
    
        
    // const athleteRankingAndPassing = steeltoe(this.props).get(`users.${athleteHash}.athleteRankingAndPassing`);
    const athleteProfile = steeltoe(this.props).get(`users.${athleteHash}.profile`);

    //sort the races 
    athleteRaceResultsFiltered && athleteRaceResultsFiltered.sort((a,b) => {// number sorter
        // console.log('SORT NUMBERS',colId,  direction, a[colId], b[colId])
        if(a.RaceDetails && b.RaceDetails) {
            if(a.RaceDetails.RaceDateStart > b.RaceDetails.RaceDateStart) return -1;
            if(a.RaceDetails.RaceDateStart < b.RaceDetails.RaceDateStart) return 1;
            return 0;
        }
    })
   

    const page = this.props.match.path.split('/')[1];


    // AVATAR
    let avatar = null;
    const uploadedImage = steeltoe(this.props).get(`users.${athleteHash}.avatarImageUrl`);
    
    avatar = getAvatarImage({
      avatarName: steeltoe(this.props).get(`users.${athleteHash}.profile.avatarId`),
      uploadedImage
    })

    const Gender = steeltoe(athleteRaceResultsFiltered).get('0.Gender');
    let isMale = false;
    if(!avatar){
      switch(Gender){
          case 'M': 
            avatar = maleIcon; 
            isMale = true;
            break;
          case 'F': 
            avatar = femaleIcon; 
            isMale = false;
            break;
          default: 
            avatar = genericIcon
            isMale = false;
      }
    }


    let avatarNameToShow = steeltoe(this.props).get(`users.${athleteHash}.profile.avatarId`);
    const isDefaultImageSelected = avatarNameToShow==='imgM7'||avatarNameToShow==='imgF7';

    const performances = athleteRaceResultsFiltered ? athleteRaceResultsFiltered.length : "";
    console.log("performances",performances)
    console.log("performances length",performances.length)

    return (
      <div className="AthleteHome">
        <Headroom>
          <Header title={`ATHLETE HOME`} page={page} isMale={isMale} />
        </Headroom>

        { (!userNotFound && performances) ? 
        <>
        <div className="container flexgrow">

            <div className="FavPageHeader">
                <div></div>
                <div>
                    <span>Performances <i className="dot">•</i> 
                        <strong className="itemsnum">{performances}</strong>
                    </span>
                    {/* <span><a href="#">Favorite All</a></span> */}
                </div>
            </div>
            <div className="AthleteHomeRaceListPage">

            {athleteRaceResultsFiltered && athleteRaceResultsFiltered.map( (card,i) => {
                return (
                    <Card
                        key={card._id}
                        // athleteRankingAndPassing={athleteRankingAndPassing && athleteRankingAndPassing[i]}
                        settings={settings}
                        users={users}
                        card={card} 
                        avatar={avatar}
                        goToSelectedRace={this.goToSelectedRace}
                        // favorite={i===1}
  
                    />
                )
            })}
            

            </div>
        </div>

        </>
      :
      (
        <div className="container flexgrow">
            {/* <h1>User not found</h1>
            <h3>(or no races for this user)</h3> */}
        </div>
      )
      }                

        
        <Footer />

      </div>
    );
  }
}





const Card = ({card, avatar, favorite, settings, goToSelectedRace }) => {
    const raceAthleteCategories = card.RaceDetails && card.RaceDetails.RaceAthleteCategories.split(',');
    // const [category, setCategory] = useState('MA');
    // const [category, setCategory] = useState(raceAthleteCategories && raceAthleteCategories[0]);

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

    const raceDate = card.RaceDetails && moment(card.RaceDetails.RaceDateStart).format('MMM D, YYYY');

    // ************
    let rankingAndPassingData = null;
    const AGon = settings.ageGrade === "age_grade_on";
    const intervalData = (card['run-event-intervals-ag-data']) ? (AGon ? card['run-event-intervals-ag-data'] : card['run-event-intervals-data']) : null;
    
    if (AGon) {
        if (card.RunRankingAndPassing && card.RunRankingAndPassingDetails['run-event-intervals-ag-data']) {
            rankingAndPassingData = card.RunRankingAndPassingDetails['run-event-intervals-ag-data'];
        } 

    } else {
        if (card.RunRankingAndPassingDetails && card.RunRankingAndPassingDetails['run-event-intervals-data']) {
            rankingAndPassingData = card.RankingAndPassingDetails['run-event-intervals-data'];
        } 
    }

    let overallRank="N/A";
    let avgPace = "N/A";
    let divisionGroup = "Unknown";
    let genderGroup = "Unknown";
    let startDistance = "N/A";
    let endDistance = "N/A";
    let totalDistance = "N/A";

    let finishTime = "N/A";
    let firstHalfTime = "N/A";
    let secondHalfTime = "N/A";
    let halfDiffTime = "N/A";
    let positiveSplit = true;
    let overallTotalAthletes = "N/A";
    let overallPercent = "N/A";

    let overallGenderRank = "N/A";
    let overallGenderTotalAthletes = "N/A";
    let overallGenderPercent = "N/A";

    let overallDivision1Rank = "N/A";
    let overallDivision1TotalAthletes = "N/A";
    let overallDivision1Percent = "N/A";

    let overallDivisionAgeGroupRank = "N/A";
    let overallDivisionAgeGroupTotalAthletes = "N/A";
    let overallDivisionAgeGroupPercent = "N/A";

    let overallDivisionEliteRank = "N/A";
    let overallDivisionEliteTotalAthletes = "N/A";
    let overallDivisionElitePercent = "N/A";

    let overallDivisionMastersRank = "N/A";
    let overallDivisionMastersTotalAthletes = "N/A";
    let overallDivisionMastersPercent = "N/A";

    let overallDivisionVisuallyImpairedRank = "N/A";
    let overallDivisionVisuallyImpairedTotalAthletes = "N/A";
    let overallDivisionVisuallyImpairedPercent = "N/A";

    const currentIntervalIndex = intervalData && intervalData.length - 1;
    const showHalfSplits = card.RaceDetails && card.RaceDetails.ShowHalfSplits;

    // console.log("showHalfSplits",showHalfSplits)

    let showAgeGrade = true;

    if (card.RaceDetails && card.RaceDetails.showAgeGrade === false){
        showAgeGrade = false;
    }

    const ageGrade = `${(card.MyRace && card.MyRace.AgeGrade*100).toFixed(0)}%`

    console.log("card",card)

    if (card && card.runtimes) {
        finishTime = AGon ? secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds_AG) : secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds);
        firstHalfTime = AGon ? secondsToTimeStrip(card.runtimes.RunHalf.TimeSeconds_AG) : secondsToTimeStrip(card.runtimes.RunHalf.TimeSeconds);
        secondHalfTime = AGon ? secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds_AG - card.runtimes.RunHalf.TimeSeconds_AG) : secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds - card.runtimes.RunHalf.TimeSeconds);
        let firstHalfTimeSeconds = card.runtimes.RunHalf.TimeSeconds;
        let secondHalfTimeSeconds = card.runtimes.RunTime.TimeSeconds - card.runtimes.RunHalf.TimeSeconds;
        let firstHalfTimeSecondsAG = card.runtimes.RunHalf.TimeSeconds_AG;
        let secondHalfTimeSecondsAG = card.runtimes.RunTime.TimeSeconds_AG - card.runtimes.RunHalf.TimeSeconds_AG;
        let posSplits = "+";
        // let diffSeconds = Math.abs(secondHalfTimeSeconds - firstHalfTimeSeconds);
        // let diffSecondsAG = Math.abs(secondHalfTimeSecondsAG - firstHalfTimeSecondsAG);
        let diffSeconds = secondHalfTimeSeconds - firstHalfTimeSeconds;
        let diffSecondsAG = secondHalfTimeSecondsAG - firstHalfTimeSecondsAG;

        // if (firstHalfTimeSeconds > secondHalfTimeSeconds){
        //     posSplits = "-";
        // }
        
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);
        halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);
        // console.log("halfDiffTime",halfDiffTime)

        // console.log("XXX halfDiffTime",halfDiffTime);
        positiveSplit = diffSeconds > 0 ? false : true; 

        // const intervalCumulTime = secondsToTimeStrip(IntervalCumulativeTimeSeconds);
        // console.log("XXXX",athleteMainData.times);
    } 

      
    if (intervalData && intervalData[currentIntervalIndex]) {
        if (settings.units === 'metric') {
            
 
            avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].CumulativePaceMetricSeconds); 


        } else {
  
            avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].CumulativePaceEnglishSeconds); 

            
        }

    } 
    

    // console.log("About to card",card)
    // console.log("About to",currentIntervalIndex)
    // console.log("About to rankingAndPassingData[currentIntervalIndex]",rankingAndPassingData && rankingAndPassingData[currentIntervalIndex])
    if (rankingAndPassingData && rankingAndPassingData[currentIntervalIndex]) {

      
        overallRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex].All.IntervalCumulativeRank;
        overallGenderTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.All.IntervalCumulativeRank`);
        overallGenderTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.All.TotalAthletes`);
        overallPercent = roundDistance(overallRank / overallTotalAthletes * 100,3);
    
        overallGenderRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.Gender}.IntervalCumulativeRank`);
        
        
        overallGenderTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.Gender}.TotalAthletes`);
        overallGenderPercent = roundDistance(overallGenderRank / overallGenderTotalAthletes * 100,3);
    
        // overallDivision1Rank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].IntervalCumulativeRank;
        // overallDivision1TotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].TotalAthletes;
        // overallDivision1Percent = roundDistance(overallDivision1Rank / overallDivision1TotalAthletes * 100,3);
        
        if (card.MyRace.DivisionAgeGroup) {
            overallDivisionAgeGroupRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionAgeGroup}.IntervalCumulativeRank`);
            overallDivisionAgeGroupTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionAgeGroup}.TotalAthletes`);
            overallDivisionAgeGroupPercent = roundDistance(overallDivisionAgeGroupRank / overallDivisionAgeGroupTotalAthletes * 100,3);
        }

        if (card.MyRace.DivisionEliteGroup) {
            // overallDivisionEliteRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][card.MyRace.DivisionEliteGroup].IntervalCumulativeRank;
            
            overallDivisionEliteRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionEliteGroup}.IntervalCumulativeRank`);
            overallDivisionEliteTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionEliteGroup}.TotalAthletes`);
            overallDivisionElitePercent = roundDistance(overallDivisionEliteRank / overallDivisionEliteTotalAthletes * 100,3);
        }

        if (card.MyRace.DivisionMastersGroup) {
            overallDivisionMastersRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionMastersGroup}.IntervalCumulativeRank`);
            overallDivisionMastersTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionMastersGroup}.TotalAthletes`);
            overallDivisionMastersPercent = roundDistance(overallDivisionMastersRank / overallDivisionMastersTotalAthletes * 100,3);
        }

        if (card.MyRace.DivisionVisuallyImpairedGroup) {
            overallDivisionVisuallyImpairedRank = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionVisuallyImpairedGroup}.IntervalCumulativeRank`);
            overallDivisionVisuallyImpairedTotalAthletes = steeltoe(rankingAndPassingData).get(`${currentIntervalIndex}.${card.MyRace.DivisionVisuallyImpairedGroup}.TotalAthletes`);
            overallDivisionVisuallyImpairedPercent = roundDistance(overallDivisionVisuallyImpairedRank / overallDivisionVisuallyImpairedTotalAthletes * 100,3);
        }

    }

    // console.log("overallGenderRank",overallGenderRank)

    if (!!card.MyRace) {
        if (card.MyRace.Gender === "F") {
            genderGroup = "Females";
        } else if (card.MyRace.Gender === "M") {
            genderGroup = "Males";
        } else {
            genderGroup = "Unknown";
        }
    }

    if (card.runtimes) {
        finishTime = AGon ? secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds_AG) : secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds);
        firstHalfTime = AGon ? secondsToTimeStrip(card.runtimes.RunHalf.TimeSeconds_AG) : secondsToTimeStrip(card.runtimes.RunHalf.TimeSeconds);
        secondHalfTime = AGon ? secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds_AG - card.runtimes.RunHalf.TimeSeconds_AG) : secondsToTimeStrip(card.runtimes.RunTime.TimeSeconds - card.runtimes.RunHalf.TimeSeconds);
        let firstHalfTimeSeconds = card.runtimes.RunHalf.TimeSeconds;
        let secondHalfTimeSeconds = card.runtimes.RunTime.TimeSeconds - card.runtimes.RunHalf.TimeSeconds;
        let firstHalfTimeSecondsAG = card.runtimes.RunHalf.TimeSeconds_AG;
        let secondHalfTimeSecondsAG = card.runtimes.RunTime.TimeSeconds_AG - card.runtimes.RunHalf.TimeSeconds_AG;
        let posSplits = "+";
        // let diffSeconds = Math.abs(secondHalfTimeSeconds - firstHalfTimeSeconds);
        // let diffSecondsAG = Math.abs(secondHalfTimeSecondsAG - firstHalfTimeSecondsAG);
        let diffSeconds = secondHalfTimeSeconds - firstHalfTimeSeconds;
        let diffSecondsAG = secondHalfTimeSecondsAG - firstHalfTimeSecondsAG;

        // if (firstHalfTimeSeconds > secondHalfTimeSeconds){
        //     posSplits = "-";
        // }
        
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? posSplits + secondsToTime(diffSecondsAG).substring(3,8) : posSplits + secondsToTime(diffSeconds).substring(3,8);
        // halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);
        halfDiffTime = AGon ? secondsToTimeStripWithSign(diffSecondsAG) : secondsToTimeStripWithSign(diffSeconds);

        // console.log("XXX halfDiffTime",halfDiffTime);
        positiveSplit = diffSeconds > 0 ? false : true; 

        // const intervalCumulTime = secondsToTimeStrip(IntervalCumulativeTimeSeconds);
        // console.log("XXXX",athleteMainData.times);
    } 

    // ************

    let divList = [];
    let divListNames = [];
    let defaultSelectedOption = null;
    let selectedCategory = null;
    let selectedOptionPlace = null;

    
    if (raceAthleteCategories){

        if (raceAthleteCategories.includes("EL") && card.MyRace.DivisionEliteGroup) {
            divList.push("EL");
            divListNames.push("Elite");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "EL";
        }
        if (raceAthleteCategories.includes("MA") && card.MyRace.DivisionMastersGroup) {
            divList.push("MA");
            divListNames.push("Masters");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "MA";
        }
        if (raceAthleteCategories.includes("WE") && card.MyRace.DivisionAthenaClydesdaleGroup) {
            divList.push("WE");
            divListNames.push("Weight");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "WE";
        }
        if (raceAthleteCategories.includes("VI") && card.MyRace.DivisionVisuallyImpairedGroup) {
            divList.push("VI");
            divListNames.push("Visually Impaired");
            
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "VI";
        }
        if (raceAthleteCategories.includes("AG") && card.MyRace.DivisionAgeGroup) {
            divList.push("AG");
            divListNames.push("Age Group");
            defaultSelectedOption = defaultSelectedOption ? defaultSelectedOption : "AG";
        }
    
        defaultSelectedOption = divList[selectedCategoryIndex];
    
        if (defaultSelectedOption == "EL") {
            selectedCategory = "ELITE • " + card.MyRace.Gender;
            selectedOptionPlace = overallDivisionEliteRank;
        } else if (defaultSelectedOption == "AG") {
            selectedCategory = card.MyRace.DivisionAgeGroup.replace('-','–');
            selectedOptionPlace = overallDivisionAgeGroupRank;
        } else if (defaultSelectedOption == "MA") {
            selectedCategory = "MAS • " + card.MyRace.Gender;
            selectedOptionPlace = overallDivisionMastersRank;
        } else if (defaultSelectedOption == "WE") {
            selectedCategory = card.MyRace.Gender === "F" ? "ATH" : "CL";
            selectedOptionPlace = overallGenderRank;
        } else if (defaultSelectedOption == "VI") {
            selectedCategory = "VI • " + card.MyRace.Gender;
            selectedOptionPlace = overallDivisionVisuallyImpairedRank;
        } 
    }

    // ***************************************
    let cityStateCountryCode = '';
    
    if (card.HometownStateCode) {
        if (card.HometownStateCode.length > 0) {
            cityStateCountryCode = card.HometownCity ? card.HometownCity + ', ' + card.HometownStateCode : card.HometownStateCode;
        } else {
            if (card.HometownCountryName && card.HometownCountryName.length > 0) {
                cityStateCountryCode = card.HometownCity + ', ' + card.HometownCountryName;
            } else {
                
                cityStateCountryCode = card.HometownCity;
            }
            
        }
        if (cityStateCountryCode.length === 0) {
            cityStateCountryCode = card.HometownTwoCharCountryCode;
        }
    }
    
    let hometownCountry = (card.HometownTwoCharCountryCode) ? card.HometownTwoCharCountryCode : null;
    let hometownCountryVal = hometownCountry ? hometownCountry.replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) : null;
    // ***************************************

    return (
        <div className={classNames("Card",{
                favorite: !!favorite
            })}
            onClick={e=>goToSelectedRace({card})}
        >


            <div className="OpenRaceIcon">
                <div className="IconSwappable">
                    <div className="icon" style={{ backgroundImage: `url(${openGray})` }} />
                    <div className="hover" style={{ backgroundImage: `url(${openGray})` }} />
                </div>
            </div>

           
            {/* <div className="FavButton" onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); handleFavorite({card, favorite: !favorite}) }} >
                <img className="icon" src={favGray} />
                <img className="hover" src={favBlue} />
            </div> */}

            <FavIconLink row={card}/>

            <div className="CardHeader">
                <div className="avatar">
                    { avatar && <img src={avatar} />}
                    
                </div>
                <div className="name"> 
                    <div> {card.MyRace.FirstName} </div>
                    <div> {card.MyRace.LastName} </div>
                </div>
                <div className="bib-info">
                    <div className="bib"> #{card.MyRace.Bib} </div>
                    {/* <div className="bib"> &nbsp; </div> */}
                    <div className="gender"> {card.MyRace.Gender === "M" ? "Male" : "Female"} <i className="dot">•</i> {card.MyRace.Age} </div>
                </div>
                <div className="flag">{hometownCountryVal}</div>
                    <div className="hometown">{cityStateCountryCode}</div>
                    <div className="time">{finishTime}</div>
                </div>

            {/* <div className="Label"> <i></i> <span>#{card.MyRace.Bib}</span> <i></i> </div> */}
            <div className="Label"> <i></i> <span>{card.RaceDetails && card.RaceDetails.RaceDistance}</span> <i></i> </div>

            <dl className="infotable">
                <dt>Category</dt>
                <dd>{selectedCategory}</dd>
                <dt>Pace</dt>
                <dd className="right-aligned">{avgPace}</dd>
                <dt>Cat. Place</dt>
                <dd>{formatNumber(selectedOptionPlace)}</dd>
                {showHalfSplits ? 
                    <>
                        <dt>1st Half</dt>
                        <dd className="right-aligned">{firstHalfTime}</dd>
                    </> :

                    <>
                        <dt></dt>
                        <dd className="right-aligned">&nbsp;</dd>
                    </>
                }
                <dt>{genderGroup}</dt>
                <dd>{formatNumber(overallGenderRank)}</dd>
                {showHalfSplits ? 
                    <>
                        <dt>2nd Half</dt>
                        <dd className="right-aligned">{secondHalfTime}</dd>
                    </> :
                    <>
                        <dt></dt>
                        <dd className="right-aligned">&nbsp;</dd>
                    </>
                }
                {showAgeGrade ? 
                    <>
                        <dt>Age Grade</dt>
                        <dd>{ageGrade}</dd>
                    </> :

                    <>
                        <dt></dt>
                        <dd></dd>

                    </>
                }
                {showHalfSplits ? 
                    <>
                        <dt>Difference</dt>
                        <dd className={classNames("splitsResult right-aligned",{
                            plus: positiveSplit,
                            minus: !positiveSplit
                        })}>
                            {halfDiffTime}
                        </dd>
                    </> :
                    <>
                        <dt></dt>
                        <dd className="right-aligned">&nbsp;</dd>
                    </>
                }
            </dl>

            <div className="categories">
                {/* {['MA','VI','AG'].map(btn => <button className={classNames({ */}
                {divList.map((btn,i) => 
                    <button key={i} 
                        // className={classNames({active: btn===category})}
                        // onClick={e => setCategory(btn)}
                        // className={classNames(selectedCategoryIndex===i ? '': 'tooltip',"athleteCategory",{ selected: selectedCategoryIndex===i })} 
                        className={classNames({ active: selectedCategoryIndex===i })} 
                        onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCategoryIndex(i) }}>
                        {divListNames[i]}
                    </button>)}

                {/* <span key={i}
                    // data-tip={div} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipDarkNoArrow"
                    className={classNames(selectedCategoryIndex===i ? '': 'tooltip',"athleteCategory",{ selected: selectedCategoryIndex===i })} 
                    onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setSelectedCategoryIndex(i) }}>
                    {selectedCategoryIndex===i ? '': <span className={classNames("tooltiptext", divList[i]) }>{divListNames[i]}</span>}
                    {div}
                </span> */}
            </div>

            <div className="Label"><i></i></div>

            <div>
            <h3>{card.RaceDetails && card.RaceDetails.RaceName}</h3>
                <div className="infoline">
                    <div>{card.RaceDetails && card.RaceDetails.Location} <i className="dot">•</i> {raceDate}</div>
                    <div>{formatNumber(card.RaceParticipants)}</div>
                </div>
            </div>
            
        </div>
    )
}






const mapStateToProps = state => ({
  // race: state.raceSelected,
  // user: state.user,
  fbuser: state.fbuser,
  fbuserProfile: state.fbuserProfile,
  settings: state.settings,
  users: state.users,
})

const mapDispatchToProps = dispatch => ({
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
  // setRaceEvents: events => dispatch({ type:'SET_RACE_EVENTS', events}),
  fbuserActions: bindActionCreators(fbuserActions, dispatch),
  usersActions: bindActionCreators({getAvatarAndRaces}, dispatch),
  favoriteAthletesActions: bindActionCreators(favoriteAthletesActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AthleteHomeRaceListPage));
