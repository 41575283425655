import firebase, { auth } from '../config/firebase'
import { db } from '../config/firebase-portal'
// import { createStore, applyMiddleware } from 'redux'
// import todos from './reducers'

import steeltoe from 'steeltoe'

const logsRef = db.collection('logs');

export const log = (data) => {
    if(!auth.currentUser){
        return;
    }
    // console.log("about to log")
    const uid = auth.currentUser.uid;
    const isAnonymous = auth.currentUser.isAnonymous;
    
    const created = firebase.firestore.FieldValue.serverTimestamp();
    
    const saveLog = {
        ...data,
        uid, isAnonymous, created
    };
    
    logsRef.add(saveLog).catch(err=>console.log('error adding log', err, saveLog));
    // console.log("finish log")
}


export default function firebaseLogger({ getState }) {
    return next => action => {
        // console.log('----- start dispatch -----');
        
        // 
        
        
        const oldState = getState();
        // console.log('old state', oldState);
        // console.log('will dispatch', action)
        
        // Call the next dispatch method in the middleware chain.
        const returnValue = next(action);

        const state = getState();
        
        // console.log('state after dispatch', getState());
        // console.log('----- end dispatch -----');

        

        if(!oldState.fbuser || oldState.pathname !== state.pathname) {
            logPageView(state);
        }




        // if(steeltoe(oldState).get('raceSelected.RaceCode') !== steeltoe(state).get('raceSelected.RaceCode')) {
        //     // console.log('SELECTED RACECODE CHANGED TO:', steeltoe(state).get('raceSelected.RaceCode'));
        //     log({
        //         type: 'race_selected',
        //         payload: {
        //             RaceCode: steeltoe(state).get('raceSelected.RaceCode'),
        //         }
        //     })
        // }


        


    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}


function logPageView(state){
    const url = state.pathname;
    const urlSplit = url.split('/');

    let payload = { 
        url
     };

    // console.log("url",url)

    const page = urlSplit && urlSplit[1] && urlSplit[1].toLowerCase()
    // console.log("page",page)
    if (url === '/') {
        payload.pageName = "home"
        // payload.pageType = 1
    } else if(page && ['welcome','segment-analysis','results-summary','finish'].includes(page)){
        payload.pageName = page;
        const raceCode = urlSplit[2] && urlSplit[2].toLowerCase();
        payload.raceCode = raceCode;

        let eventSeries = '';
        let eventCode = '';
        if (raceCode.includes("-")){
            let raceCodeArr = raceCode.split('-');
            eventSeries = raceCodeArr[0];
            eventCode = raceCodeArr[0] + '-' + raceCodeArr[raceCodeArr.length-1]
            payload.eventSeries = eventSeries;
            payload.eventCode = eventCode;
        }

        payload.bib = urlSplit[4] && urlSplit[4].toLowerCase();
        // switch(urlSplit[1].toLowerCase()) {
        //     case 'welcome':
        //         payload.pageType = 100
        //         break;
        //     case 'results-summary':
        //         payload.pageType = 101
        //         break;
        //     case 'segment-analysis':
        //         payload.pageType = 102
        //         break;
        //     case 'finish':
        //         payload.pageType = 103
        //         break;
        //     default:
        //         break;

        // }

    } else if(['select-athlete'].includes(page)){
        payload.pageName = urlSplit[1].toLowerCase();
        // payload.pageType = 2;
        const eventCode = urlSplit[2].toLowerCase()
        let eventSeries = '';
        if (eventCode.includes("-")){
            let eventSeriesArr = eventCode.split('-');
            eventSeries = eventSeriesArr[0];
        }
        payload.eventCode = eventCode;
        payload.eventSeries = eventSeries;
        payload.raceCode = urlSplit[3].toLowerCase();
        if (urlSplit[4]) {
            payload.filter = urlSplit[4] && urlSplit[4].toLowerCase();
        }
    }

    log({
        type: 'page_view',
        payload,
    })
}

// const store = createStore(todos, ['Use Redux'], applyMiddleware(logger))

// store.dispatch({
//   type: 'ADD_TODO',
//   text: 'Understand the middleware'
// })
// // (These lines will be logged by the middleware:)
// // will dispatch: { type: 'ADD_TODO', text: 'Understand the middleware' }
// // state after dispatch: [ 'Use Redux', 'Understand the middleware' ]