import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {analytics} from '../../config/firebase'
import moment from "moment";
import Header from "../../components/Header/Header";
import {Link, withRouter} from 'react-router-dom';
import MainAthleteBlock from "../../components/MainAthleteBlock/MainAthleteBlock";
import WelcomeBib from "../../components/WelcomeBib/WelcomeBib";
import Sponsors from "./Sponsors";
import {connect} from 'react-redux';
import * as athletesByRacebibActions from '../../actions/athletesByRacebib'
import * as sponsorsActions from '../../actions/sponsors'
import "./Welcome.scss";
import PageIntroModal from '../../components/PageIntroModal/PageIntroModal'
import Beacon from '../../components/Beacon/Beacon'
import Footer from '../../components/Footer/Footer'
import TooltipText from '../../components/Tooltip/TooltipText'
import Headroom from 'react-headroom';
import InfoCards from './InfoCards';
import FavIconLink from "../../components/AthleteListTable/FavIconLink";

class Welcome extends Component {

  state = {
    tabs: [],
    images: [],
    isTitleHovered: false,
  }
  
  constructor(props){
    super(props);
    
    // default to age grade off
    this.props.setSwitch("age_grade_off");
  }


  componentDidMount() {

    // this.displayAthletes();
    // if(!this.props.athlete.Bib){ // is athlete in the redux?
    // if(this.props.athletesByRacebib === undefined || !this.props.athletesByRacebib || !this.props.athlete.Bib){ // is athlete in the redux?
    // console.log("XXXGETTINGATHLETE",this.props.match.params.raceCode,this.props.match.params.athleteBib);  
    this.props.athletesByRacebibActions.getAthlete(
        this.props.match.params.raceCode,
        this.props.match.params.athleteBib
      );
    // }

    this.loadTabImages();

    window.scrollTo(0, 0);

    // analytics.logEvent('goal_completion', { name: 'lever_puzzle'});
    // analytics.logEvent('welcome_page');
    // analytics().logEvent('welcome_page');

    const { raceCode, athleteBib } = this.props.match.params;

    analytics.logEvent('welcome_page', { raceCode: raceCode, bib: athleteBib })

    // const analytics = firebase.analytics();

    // firebase.auth().onIdTokenChanged(user => {
    //   if (user) {
    //     analytics.setUserId(user.uid);
    //     analytics.setUserProperties({ level: user.claims.level });
    //   }
    // });


   
  }



  // componentDidUpdate(prevProps){
  //   console.log("XXXprevProps",prevProps);
  //   console.log("XXXcurProps",this.props);
  //   // if(!prevProps.race.RaceCode && this.props.race.RaceCode){
  //   //   this.props.athletesByRacebibActions.getAthlete(
  //   //     this.props.match.params.raceCode,
  //   //     this.props.match.params.athleteBib
  //   //   );
  //   // }
  // }


  loadTabImages = () => {
    const { raceCode } = this.props.match.params;

    // console.log("raceCode",raceCode);

    this.props.sponsorsActions.getSponsors(raceCode);

    
  }
  

  toTitleCase = str => {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }



  render() {



    // const { athletesByRacebib, race, user, athletesBySlug, athleteRankingAndPassing } = this.props;
    // const { isTitleHovered } = this.state;
    // const { athletesByRacebib, race, user, match, sponsors, requestsPending } = this.props;
    const { athletesByRacebib, race, match, sponsors, requestsPending } = this.props;
    const { raceCode, athleteBib } = match.params;



    if (requestsPending) {
      return null;
    }

    const sponsorsData = sponsors[raceCode] || {};

    const eventSeriesCode = race.EventSeriesCode; // i.e. tsfm
    let raceDate = '';
    let raceDay = '';


    const slug = raceCode + '--' + athleteBib;
    const athlete = athletesByRacebib[slug] && athletesByRacebib[slug].main_data;

    // console.log("athlete is",athlete);

    let athleteDivisions = [];
    // let athleteDivision = null;
    if (athlete && athlete.MyRace) {
      if (athlete.MyRace.DivisionEliteGroup) {
        athleteDivisions.push(athlete.MyRace.DivisionEliteGroup === "EliteM" ? "Elite Men" : "Elite Women");
      } else if (athlete.MyRace.DivisionProGroup) {
        athleteDivisions.push(athlete.MyRace.DivisionProGroup === "MP" ? "Male Pro" : "Female Pro");
      } else {

      
        if (athlete.MyRace.DivisionMastersGroup) {
          athleteDivisions.push(athlete.MyRace.DivisionMastersGroup === "MastersM" ? "Masters Men" : "Masters Women");
        } 
        if (athlete.MyRace.DivisionAthenaClydesdaleGroup) {
          athleteDivisions.push(athlete.MyRace.DivisionAthenaClydesdaleGroup);
        } 
        if (athlete.MyRace.DivisionVisuallyImpairedGroup) {
          athleteDivisions.push(athlete.MyRace.DivisionVisuallyImpairedGroup === "VisuallyImpairedM" ? "Visually Impaired Men" : "Visually Impaired Women");
        } 
        if (athlete.MyRace.DivisionAgeGroup) {
          athleteDivisions.push(athlete.MyRace.DivisionAgeGroup.replace('-','–'));
        }
      }
      

      // athlete.MyRace.DivisionType1.replace('-','–');
    } 

    // const raceLogo = sponsorsData.raceLogo;
    // let showLogo = false;
    
    // console.log("sponsorsData",sponsorsData);
    // if (sponsorsData && sponsorsData.raceLogo && sponsorsData.raceLogo.linkURL && sponsorsData.raceLogo.altText) {
    //   showLogo = true;
      
    // }
    
    // console.log("showLogo",showLogo);
    const raceImage = race  && race.sponsorImages && race.sponsorImages[0];
    // const raceImage = race.RaceDetails.sponsorImages[0].src;
    
    // console.log("raceImage",raceImage);
    const image = raceImage && (

        <a href={raceImage.linkURL} target="_blank" alt={raceImage.altText} title={raceImage.altText} rel="noopener noreferrer" className="topSponsorLink tooltip"><span />
          {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
          <img className="topSponsorImage" alt={`${raceImage.altText}`} src={`${raceImage.src}` } />
          <TooltipText>Event's Home Page</TooltipText>
        </a> 

    )
    
    // const image = showLogo && (

    //     <a href={raceLogo.linkURL} target="_blank" alt={raceLogo.altText} title={raceLogo.altText} rel="noopener noreferrer" className="topSponsorLink tooltip"><span />
    //       {/* <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`/images/${eventSeriesCode}/partners/${raceLogo.src}` } /> */}
    //       <img className="topSponsorImage" alt={`${raceLogo.altText}`} src={`${raceLogo.src}` } />
    //       <TooltipText>Event's Home Page</TooltipText>
    //     </a> 

    // )

    // console.log("XXXX race",race)
    if (race && race.RaceDateStart) {
      raceDay = moment(race.RaceDateStart).format('ddd');
      raceDate = moment(race.RaceDateStart).format('MMM D, YYYY');

  
    } else {
      raceDate = null;
      raceDay = null;


    }

    const page = this.props.match.path.split('/')[1];
    
    // console.log("XXXWELCOMEpage",page);

    return (
      <div className="Welcome">

        <Headroom>
          <Header title={`WELCOME`} page={page}  race={race} athlete={athlete} />
        </Headroom>

        <div>
          <div className="container">
          {/* <div className="container"> */}
       
          { image }
          </div>
        </div>
        
        
        <MainAthleteBlock athlete={athlete}>

          {/* <FavIconLink row={athlete}/> */}

          <div>
            <Link 
              className="MarathonTitle"
                to={`/select-athlete/${this.props.race.EventCode}/${this.props.race.RaceCode}`}>
                {/* <span data-tip="Open" data-delay-show='1000' data-effect="solid" data-place='bottom' data-class="MyRaceTooltip"> */}
                <span className="tooltip">
                  <TooltipText>Race's <em>Home Page</em></TooltipText>
                  <strong>{this.props.race.EventName}</strong>
                </span>
              </Link> 

              <span className="MarathonDates">{raceDay}<i className="dot">•</i>{raceDate}</span>
              


            <div className="Label">
              <i/>
                <span>
                  {this.props.race.RaceDistance}
                </span>
              <i/>
            </div>

            <div className="EdgesLine">
              <div><strong>#{athleteBib}</strong></div>
              <div><strong>{athleteDivisions.map((div,index) => <div key={index}>{div}</div>)}</strong></div>
              {/* <div>{athleteDivisions[0]}</div> */}
            </div>
          </div>

          <WelcomeBib race={race} athlete={athlete} />
      
          
        </MainAthleteBlock>

        <div className="container flexgrow">
          {/* <Sponsors athlete={athlete} eventSeriesCode={eventSeriesCode} raceCode={raceCode} {...sponsorsData} /> */}

          <InfoCards />
        </div>

        {/* <PageIntroModal id="Welcome" title="Welcome to MyRace!" text={
          <>
            <p>This is the Welcome Page, <strong>it is just the first page</strong> in the set of athlete race results.</p>
            <p>This page sets the stage by surfacing some basic information such as the athlete's name, bib number, hometown and race division.</p>  
            
            <p class="forBeacon">
                <Beacon id="WelcomePage" text='' direction="right" style={{ top:'0px', left:'-30px'}} />
            
                Beacons are sprinkled around the various pages, strategically placed to introduce you to key features; Hover over them to get more info. The beacon will disappear once the key feature is activated.
            </p>
            <hr />
            <p>Click <strong className="isLink"><a href="http://about.myrace.com/article/14-welcome-page" target="_blank" alt="learn more" title="Welcome page" rel="noopener noreferrer">here</a></strong> to learn more about this Welcome Page.</p>
            <p>Click <strong className="isLink"><a href="http://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank" alt="learn more" title="How MyRace gets athlete data" rel="noopener noreferrer">here</a></strong> to learn more about how MyRace gets and compiles the data used in these pages.</p>
          </>
        } /> */}

        <Footer />


      </div>
    );
  }
}

const mapStateToProps = state => ({
  athletesByRacebib: state.athletesByRacebib,
  athleteRankingAndPassing: state.athleteRankingAndPassing,
  race: state.raceSelected,
  settings: state.settings,
  sponsors: state.sponsors,
  user: state.user,
  requestsPending: state.requestsPending
  // athletesBySlug: state.athletesBySlug,
})

const mapDispatchToProps = dispatch => ({

  setSwitch: ageGrade => dispatch({ type: "SET_AGE_GRADE", ageGrade }),
  // athleteBySlugRequested: slug => dispatch({ type: "ATHLETES_BY_SLUG/SET_PENDING", slug}),
  // athleteBySlugReceived: (slug, data) => dispatch({ type: "ATHLETES_BY_SLUG/SET_DATA", slug, data}),

  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
  sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
})


// export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Welcome));
