import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import "./ToggleUnits.scss";

class ToggleUnits extends Component {
  switch = () => {
    this.props.setSwitch(
      this.props.settings.units === "english" ? "metric" : "english"
    );

    // window.analytics.track('Units', {
    //   "Units": this.props.settings.units === "english" ? "metric" : "english"
    // });

    
  };

  switchOn = () => {
    this.props.setSwitch("metric");
  };

  switchOff = () => {
    this.props.setSwitch("english");

  };

  render() {
    const { settings } = this.props;
    const on = settings.units === "metric";
    return (
      <div className={classnames("toggle-units-container", { on })}>
        <label
          className="units-label units-label-english"
          // classname = {classnames("units-label-english", "units-label", { on })}
          onClick={this.switchOff}
        >
          English
        </label>
        <div className="switch" onClick={this.switch}>
          <div className="track" />
          <div className="handle" />
        </div>
        <label
          className="units-label units-label-metric"
          // classname = {classnames("units-label-metric", "units-label", { on })}
          onClick={this.switchOn}
        >
          Metric
        </label>
      </div>
    );
  }
}

const mapStateToProps = s => ({ settings }) => ({ settings }); // this.props.settings

const mapDispatchToProps = dispatch => ({
  setSwitch: units => dispatch({ type: "SET_UNITS", units }) // this.props.setSwitch()
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleUnits);
