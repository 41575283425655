import React from "react";
import {secondsToTimeStrip, roundDistance, formatNumber} from '../../timeUtils'
import classNames from 'classnames';
import "./IntervalAnalysisBib.scss";
import {secondsToLongMinutesSeconds} from '../../timeUtils'
import {Link} from 'react-router-dom';
import ToggleUnits from "../ToggleUnits/ToggleUnits";
import TooltipText from '../../components/Tooltip/TooltipText'

import ToggleAgeGrade from "../ToggleAgeGrade/ToggleAgeGrade";
import SegmentNavigation from "../SegmentNavigation/SegmentNavigation";
import InfoModal from '../InfoModal/InfoModal'

import ElevationGainIcon from '../ElevationGainIcon/ElevationGainIcon'
// import { motion } from "framer-motion"
// import { start } from "repl";
// import memoize from 'lodash.memoize';

const getCalculatedCumulativeElevations = ({ // TODO: memoize(
    intervalData,
    currentIntervalIndex,
    race,
    startDistance,
    endDistance,
    units, // <- 'metric' or 'english', based on the units we'll decide which property to look use in points
}) => {
    if(!race || !race.elevations || !race.elevations.points) return {};

    const distanceProp = units==='metric' ? 'distanceKm' : 'distanceMi';
    const elevationProp = units==='metric' ? 'elevationM' : 'elevationFt';
    const cumulativeDistanceProp = units==='metric' ? 'CumulativeDistanceKm' : 'CumulativeDistanceMi';
    let highestPoint = -100000;
    let lowestPoint = 100000;
    let startPoint;
    let endPoint;
    let startEndDiff;

    let startEndAngle;
    

    // console.log('[getCalculatedCumulativeElevations]', distanceProp, elevationProp, startDistance, endDistance)

    let results = race.elevations.points.reduce((acc, point, index, arr) => {
        const pointIsWithinRange = point[distanceProp] >= startDistance && point[distanceProp] <= endDistance;

        if(pointIsWithinRange && index > 0){
            const prevPoint = arr[index-1];
            if(!startPoint){
                startPoint = point[elevationProp];
            }

            endPoint = point[elevationProp];

            // console.log('[getCalculatedCumulativeElevations] point:', point[elevationProp], '-', prevPoint[elevationProp] )
            
     
            highestPoint = point[elevationProp] > highestPoint ? point[elevationProp] : highestPoint;
            lowestPoint = point[elevationProp] < lowestPoint ? point[elevationProp] : lowestPoint;

            const elevationDiff = point[elevationProp] - prevPoint[elevationProp];
            // if elevationDiff < 0, then add to the cumulativeDescent the absolute value
            // console.log('[getCalculatedCumulativeElevations] elevationDiff:', elevationDiff )
            
            let {cumulativeDescent, cumulativeAscent} = acc;
            if(elevationDiff < 0) cumulativeDescent += Math.abs(elevationDiff);
            else cumulativeAscent += Math.abs(elevationDiff);
            
            // console.log('[getCalculatedCumulativeElevations] cumulativeAscent:', cumulativeAscent , 'cumulativeDescent:', cumulativeDescent)

            return { cumulativeAscent, cumulativeDescent };
        }
        return acc;
    }, { cumulativeAscent: 0 , cumulativeDescent: 0 });

    // console.log('[getCalculatedCumulativeElevations] results:', results)
    
    /*point:
        accumulatedAscentM: 1.6 <--- if units is 'metric'
        accumulatedAscentFt: 5.25 <--- if units is ''

        accumulatedDescentM: -8.36 <--- if units is 'metric'
        accumulatedDescentFt: -27.43 <--- if units is ''

        distanceKm: 2.09 <--- if units is 'metric'
        distanceMi: 1.3 <--- if units is ''

        elevationFt: 30
        elevationM: 9
        elevationRelative: 6.46
        */

    results.netAscent = Math.floor(results.cumulativeDescent - results.cumulativeAscent);
    // results.netAscent = (results.cumulativeAscent - results.cumulativeDescent) < 0 ? `(${(results.cumulativeAscent - results.cumulativeDescent)})` : (results.cumulativeAscent - results.cumulativeDescent);
    results.highestPoint = highestPoint < 0 ? `(${Math.floor(highestPoint)})` :  Math.floor(highestPoint);
    results.lowestPoint = lowestPoint < 0 ? `(${Math.floor(lowestPoint)})` :  Math.floor(lowestPoint);
    results.highLowDiff = Math.floor(lowestPoint - highestPoint);
    // results.highLowDiff = (lowestPoint - highestPoint) < 0 ? `(${(lowestPoint - highestPoint)})` : (lowestPoint - highestPoint);
    results.startPoint = startPoint < 0 ? `(${Math.floor(startPoint)})` :  Math.floor(startPoint);
    results.endPoint = endPoint < 0 ? `(${Math.floor(endPoint)})` :  Math.floor(endPoint);
    results.startEndDiff = Math.floor(endPoint - startPoint);
    // results.startEndDiff = (endPoint - startPoint) < 0 ? `(${(endPoint - startPoint)})` : (endPoint - startPoint);
    
    // console.log("startPoint",startPoint);
    // console.log("endPoint",endPoint);
    // console.log("intervalData[currentIntervalIndex]",intervalData[currentIntervalIndex]);

    // if (intervalData && intervalData[currentIntervalIndex]) {
    //     const percentOfTotalRaceDistance = (intervalData[currentIntervalIndex] - startPoint) / intervalData[currentIntervalIndex][cumulativeDistanceProp];
    //     console.log("percentOfTotalRaceDistance",percentOfTotalRaceDistance)

    // }
    // console.log("results.startEndDiff",results.startEndDiff);
    results.overallAscentAngle = Math.ceil(-results.netAscent / 10); //let's pretend each 2ft is 1 degree
    results.startEndAngle = Math.ceil(-results.startEndDiff / 10); //let's pretend each 2ft is 1 degree
    results.isHighLowUndulating = Math.abs(results.highLowDiff) > 100 ? true : false; //if hgihst and lowest differ by > 200 then it is undulating
    // console.log("results.startEndAngle",results.startEndAngle);
    // console.log("results.overallAscentAngle",results.overallAscentAngle);

    // console.log("startEndAngle",Math.ceil(results.startEndAngle));

    return results;
    // already have startDistance and endDistance
    // iterate over the race.elevations.points 
}
//);



const IntervalAnalysisBib = ({ currentIntervalIndex, race, athleteMainData, athleteRankingData, settings, intervalDisplayOption, noFooter}) => { 

    if(!athleteMainData) return null;


    let rankingAndPassingData = null;

    const toggleUnits = {settings};

    const AGon = settings.ageGrade === "age_grade_on";

    const intervalData = (athleteMainData && athleteMainData['run-event-intervals-ag-data']) ? (AGon ? athleteMainData['run-event-intervals-ag-data'] : athleteMainData['run-event-intervals-data']) : null;
    if (AGon) {
        if (athleteRankingData && athleteRankingData.RunRankingAndPassing && athleteRankingData.RunRankingAndPassing['run-event-intervals-ag-data']) {
            rankingAndPassingData = athleteRankingData.RunRankingAndPassing['run-event-intervals-ag-data'];
        } 

    } else {
        if (athleteRankingData && athleteRankingData.RunRankingAndPassing && athleteRankingData.RunRankingAndPassing['run-event-intervals-data']) {
            rankingAndPassingData = athleteRankingData.RunRankingAndPassing['run-event-intervals-data'];
        } 
    }
    
    let overallRank="N/A";
    let avgPace = "N/A";
    let projectedFinishBasedOnCumulative = "N/A";
    let intervalTime = "N/A";
    let intervalDiffTime = "N/A";
    let startDistance = "N/A";
    let endDistance = "N/A";
    let totalDistance = "N/A";
    let divisionGroup = "Unknown";
    let genderGroup = "Unknown";


    let overallTotalAthletes = "N/A";
    let overallPercent = "N/A";

    let overallGenderRank = "N/A";
    let overallGenderTotalAthletes = "N/A";
    let overallGenderPercent = "N/A";

    let overallDivision1Rank = "N/A";
    let overallDivision1TotalAthletes = "N/A";
    let overallDivision1Percent = "N/A";

    let overallDivisionAgeGroupRank = "N/A";
    let overallDivisionAgeGroupTotalAthletes = "N/A";
    let overallDivisionAgeGroupPercent = "N/A";

    let overallDivisionEliteRank = "N/A";
    let overallDivisionEliteTotalAthletes = "N/A";
    let overallDivisionElitePercent = "N/A";

    let overallDivisionMastersRank = "N/A";
    let overallDivisionMastersTotalAthletes = "N/A";
    let overallDivisionMastersPercent = "N/A";

    let overallDivisionVisuallyImpairedRank = "N/A";
    let overallDivisionVisuallyImpairedTotalAthletes = "N/A";
    let overallDivisionVisuallyImpairedPercent = "N/A";

    let paceDiffPos = false;

    let showAgeGrade = true;

    if (race && race.showAgeGrade === false){
        showAgeGrade = false;
    }



    // console.log("SHowAgeGrade",showAgeGrade);

    // console.log("intervalDisplayOption",intervalDisplayOption);

    if (!!athleteMainData && !!athleteMainData.MyRace) {
        if (race.RaceDivisionType === 1) {
            divisionGroup = athleteMainData.MyRace.DivisionType1.replace('-','–');
        } else if (race.RaceDivisionType === 1) {
            divisionGroup = athleteMainData.MyRace.DivisionType2.replace('-','–');
        } else {
            divisionGroup = "Unknown";
        }
    }
        

        
    if (!!athleteMainData && !!athleteMainData.MyRace) {
        if (athleteMainData.MyRace.Gender === "F") {
            genderGroup = "Females";
        } else if (athleteMainData.MyRace.Gender === "M") {
            genderGroup = "Males";
        } else {
            genderGroup = "Unknown";
        }
    }

// console.log("intervalData",intervalData);
    if (intervalData && intervalData[currentIntervalIndex]) {
        if (settings.units === 'metric') {
            
            if (intervalDisplayOption) {
                    // avgPace = moment().startOf('day').add(moment.duration({s: intervalData[currentIntervalIndex].IntervalPaceMetricSeconds})).format('mm:ss'); 
                avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].IntervalPaceMetricSeconds); 
                intervalTime = secondsToTimeStrip(intervalData[currentIntervalIndex].IntervalTimeSeconds); 
                intervalDiffTime = secondsToLongMinutesSeconds(intervalData[currentIntervalIndex].DiffPaceFromLastIntervalMetricSeconds); 
            } else {  
                avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].CumulativePaceMetricSeconds); 
                intervalTime = secondsToTimeStrip(intervalData[currentIntervalIndex].IntervalCumulativeTimeSeconds); 
                intervalDiffTime = secondsToLongMinutesSeconds(intervalData[currentIntervalIndex].DiffCumulativePaceFromLastIntervalMetricSeconds);
            }

        } else {
            if (intervalDisplayOption) {
                // avgPace = moment().startOf('day').add(moment.duration({s: intervalData[currentIntervalIndex].IntervalPaceMetricSeconds})).format('mm:ss'); 
                avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].IntervalPaceEnglishSeconds); 
                intervalTime = secondsToTimeStrip(intervalData[currentIntervalIndex].IntervalTimeSeconds); 
                intervalDiffTime = secondsToLongMinutesSeconds(intervalData[currentIntervalIndex].DiffPaceFromLastIntervalEnglishSeconds);
            } else {  
                avgPace = secondsToTimeStrip(intervalData[currentIntervalIndex].CumulativePaceEnglishSeconds); 
                intervalTime = secondsToTimeStrip(intervalData[currentIntervalIndex].IntervalCumulativeTimeSeconds);
                intervalDiffTime = secondsToLongMinutesSeconds(intervalData[currentIntervalIndex].DiffCumulativePaceFromLastIntervalEnglishSeconds); 
            }
            
        }

        paceDiffPos = intervalData[currentIntervalIndex].DiffPaceFromLastIntervalEnglishSeconds > 0 ? true : false;


        projectedFinishBasedOnCumulative = secondsToTimeStrip(intervalData[currentIntervalIndex].ProjectedTimeBasedOnCumulativePaceSeconds); 
    
    } 

    // intervalDiffTime = intervalDiffTime.length === 0 ? intervalDiffTime = "-" : intervalDiffTime;

    // console.log("intervalDiffTime",intervalDiffTime)
    console.log("XXX rankingAndPassingData",rankingAndPassingData);
    // console.log("currentIntervalIndex",currentIntervalIndex);
    // console.log("intervalDisplayOption",intervalDisplayOption);

    if (rankingAndPassingData && rankingAndPassingData[currentIntervalIndex]) {

        if (intervalDisplayOption) {
            overallRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex].All.IntervalRank;
            overallTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex].All.TotalAthletes;
            // console.log("(overallRank / overallTotalAthletes * 100)",(overallRank / overallTotalAthletes * 100));
            overallPercent = roundDistance((overallRank / overallTotalAthletes * 100),3);
        
            overallGenderRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.Gender].IntervalRank;
            overallGenderTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.Gender].TotalAthletes;
            overallGenderPercent = roundDistance(overallGenderRank / overallGenderTotalAthletes * 100,3);
        
            overallDivision1Rank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].IntervalRank;
            overallDivision1TotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].TotalAthletes;
            overallDivision1Percent = roundDistance(overallDivision1Rank / overallDivision1TotalAthletes * 100,3);

            if (athleteMainData.MyRace.DivisionAgeGroup) {
                overallDivisionAgeGroupRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionAgeGroup].IntervalRank;
                overallDivisionAgeGroupTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionAgeGroup.replace(' ','')].TotalAthletes;
                overallDivisionAgeGroupPercent = roundDistance(overallDivisionAgeGroupRank / overallDivisionAgeGroupTotalAthletes * 100,3);
            }
            
            if (athleteMainData.MyRace.DivisionEliteGroup) {
                overallDivisionEliteRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionEliteGroup].IntervalRank;
                overallDivisionEliteTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionEliteGroup].TotalAthletes;
                overallDivisionElitePercent = roundDistance(overallDivisionEliteRank / overallDivisionEliteTotalAthletes * 100,3);
            }

            if (athleteMainData.MyRace.DivisionMastersGroup) {
                overallDivisionMastersRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionMastersGroup].IntervalRank;
                overallDivisionMastersTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionMastersGroup].TotalAthletes;
                overallDivisionMastersPercent = roundDistance(overallDivisionMastersRank / overallDivisionMastersTotalAthletes * 100,3);
            }

            if (athleteMainData.MyRace.DivisionVisuallyImpairedGroup) {
                overallDivisionVisuallyImpairedRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionVisuallyImpairedGroup].IntervalRank;
                overallDivisionVisuallyImpairedTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionVisuallyImpairedGroup].TotalAthletes;
                overallDivisionVisuallyImpairedPercent = roundDistance(overallDivisionVisuallyImpairedRank / overallDivisionVisuallyImpairedTotalAthletes * 100,3);
            }

        } else {

            overallRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex].All.IntervalCumulativeRank;
            overallTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex].All.TotalAthletes;
            overallPercent = roundDistance(overallRank / overallTotalAthletes * 100,3);
        
            overallGenderRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.Gender].IntervalCumulativeRank;
            overallGenderTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.Gender].TotalAthletes;
            overallGenderPercent = roundDistance(overallGenderRank / overallGenderTotalAthletes * 100,3);
        
            overallDivision1Rank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].IntervalCumulativeRank;
            overallDivision1TotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionType1.replace(' ','')].TotalAthletes;
            overallDivision1Percent = roundDistance(overallDivision1Rank / overallDivision1TotalAthletes * 100,3);

            if (athleteMainData.MyRace.DivisionAgeGroup) {
                overallDivisionAgeGroupRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionAgeGroup].IntervalCumulativeRank;
                overallDivisionAgeGroupTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionAgeGroup].TotalAthletes;
                overallDivisionAgeGroupPercent = roundDistance(overallDivisionAgeGroupRank / overallDivisionAgeGroupTotalAthletes * 100,3);
            }

            if (athleteMainData.MyRace.DivisionEliteGroup) {
                overallDivisionEliteRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionEliteGroup].IntervalCumulativeRank;
                overallDivisionEliteTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionEliteGroup].TotalAthletes;
                overallDivisionElitePercent = roundDistance(overallDivisionEliteRank / overallDivisionEliteTotalAthletes * 100,3);
            }

            if (athleteMainData.MyRace.DivisionMastersGroup) {
                overallDivisionMastersRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionMastersGroup].IntervalCumulativeRank;
                overallDivisionMastersTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionMastersGroup].TotalAthletes;
                overallDivisionMastersPercent = roundDistance(overallDivisionMastersRank / overallDivisionMastersTotalAthletes * 100,3);
            }

            if (athleteMainData.MyRace.DivisionVisuallyImpairedGroup) {
                overallDivisionVisuallyImpairedRank = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionVisuallyImpairedGroup].IntervalCumulativeRank;
                overallDivisionVisuallyImpairedTotalAthletes = rankingAndPassingData && rankingAndPassingData[currentIntervalIndex][athleteMainData.MyRace.DivisionVisuallyImpairedGroup].TotalAthletes;
                overallDivisionVisuallyImpairedPercent = roundDistance(overallDivisionVisuallyImpairedRank / overallDivisionVisuallyImpairedTotalAthletes * 100,3);
            }
        }
    }

    

// console.log("XXXX",intervalData && intervalData[currentIntervalIndex]);
// console.log("XXXX currentIntervalIndex",currentIntervalIndex);
    startDistance = 0;
    
    if (intervalData && intervalData[currentIntervalIndex]) {
        if (settings.units === 'metric') {
            
            if (intervalDisplayOption) {
                if (currentIntervalIndex === 0) {
                    startDistance = 0;
                } else {

                    startDistance = roundDistance(intervalData[currentIntervalIndex-1].CumulativeDistanceKm,3); 
                }
                endDistance = roundDistance(intervalData[currentIntervalIndex].CumulativeDistanceKm,3); 
                
            }  else {  
                startDistance = 0;

                
                endDistance = roundDistance(intervalData[currentIntervalIndex].CumulativeDistanceKm,3);  
            }

            totalDistance = roundDistance(endDistance - startDistance); 
        } else {
            if (intervalDisplayOption) {
                if (currentIntervalIndex === 0) {
                    startDistance = 0;
                } else {

                    startDistance = roundDistance(intervalData[currentIntervalIndex-1].CumulativeDistanceMi,3); 
                }
                
                endDistance = roundDistance(intervalData[currentIntervalIndex].CumulativeDistanceMi,3); 
            }  else {  
                startDistance = 0;

                endDistance = roundDistance(intervalData[currentIntervalIndex].CumulativeDistanceMi,3);  
            }
            totalDistance = roundDistance(endDistance - startDistance,3); 
            
        }

        
    } 

    
  
    let nameLink = null;

    if (athleteMainData) {
        if (athleteMainData && athleteMainData.EmailHash && athleteMainData.EmailHash.length > 0) {
            nameLink =  <Link to={`/athlete/${athleteMainData.EmailHash}`} className="AthleteNameLink tooltip">
                <TooltipText>Athlete's <em>Home Page</em></TooltipText>
                {athleteMainData.FirstName + ' ' + athleteMainData.LastName}
            </Link>;
        } else {
            nameLink =  <span className="athleteName">{athleteMainData.FirstName + ' ' + athleteMainData.LastName}</span>;
        }
    }

    let cityState = '';
    let divisionMastersGroupRank = null;
    let divisionEliteGroupRank = null;
    let divisionAgeGroupRank = null;
    let divisionVisuallyImpairedGroupRank = null;

    if (athleteMainData && athleteMainData.MyRace) {
        if (athleteMainData.MyRace.DivisionEliteGroup) {
         
            divisionEliteGroupRank = 
            <> 
                <dt>{athleteMainData.MyRace.DivisionEliteGroup === "EliteM" ? "Elite Men" : "Elite Women"}</dt>
                <dd>
                    <strong className="text-space-right numeric">{overallDivisionEliteRank}</strong> <span className="numericFont smallFont">of</span> <span className="slight-bold text-space-left numeric">{formatNumber(overallDivisionEliteTotalAthletes)}</span> <i className="dot">•</i> <span className="numericFont smallFont">Top</span> <strong className="text-space-left numeric">{overallDivisionElitePercent}</strong>%
                </dd>  
            </>;
        }

        if (athleteMainData.MyRace.DivisionAgeGroup) {
            divisionAgeGroupRank = 
            <> 
                <dt>{athleteMainData.MyRace.DivisionAgeGroup.replace('-','–')}</dt>
                <dd>
                    <strong className="text-space-right numericFont">{overallDivisionAgeGroupRank}</strong> <span className="numericFont smallFont">of</span> <span className="slight-bold text-space-left numericFont">{formatNumber(overallDivisionAgeGroupTotalAthletes)}</span> <i className="dot">•</i> <span className="numericFont smallFont">Top</span> <strong className="text-space-left numericFont">{overallDivisionAgeGroupPercent}</strong>%
                </dd>  
            </>;
        }

        if (athleteMainData.MyRace.DivisionMastersGroup) {
         
            divisionMastersGroupRank = 
            <> 
                <dt>{athleteMainData.MyRace.DivisionMastersGroup === "MastersM" ? "Masters Men" : "Masters Women"}</dt>
                <dd>
                    <strong className="text-space-right numericFont">{overallDivisionMastersRank}</strong> <span className="numericFont smallFont">of</span> <span className="slight-bold text-space-left numericFont">{formatNumber(overallDivisionMastersTotalAthletes)}</span> <i className="dot">•</i> <span className="numericFont smallFont">Top</span> <strong className="text-space-left numericFont">{overallDivisionMastersPercent}</strong>%
                </dd>  
            </>;
        }

        if (athleteMainData.MyRace.DivisionVisuallyImpairedGroup) {
         
            divisionVisuallyImpairedGroupRank = 
            <> 
                <dt>{athleteMainData.MyRace.DivisionVisuallyImpairedGroup === "VisuallyImpairedM" ? "Visually Impaired Men" : "Visually Impaired Women"}</dt>
                <dd>
                    <strong className="text-space-right numericFont">{overallDivisionVisuallyImpairedRank}</strong> <span className="numericFont smallFont">of</span> <span className="slight-bold text-space-left numericFont">{formatNumber(overallDivisionVisuallyImpairedTotalAthletes)}</span> <i className="dot">•</i> <span className="numericFont smallFont">Top</span> <strong className="text-space-left numericFont">{overallDivisionVisuallyImpairedPercent}</strong>%
                </dd>  
            </>;
        }

        if (athleteMainData.MyRace.StateCode) {
            cityState = athleteMainData.MyRace.City + (athleteMainData.MyRace.City ? ', ' : '') + athleteMainData.MyRace.StateCode;
        }  
        else if (athleteMainData.MyRace.City  && athleteMainData.MyRace.CountryThreeCharCode) {
            cityState = athleteMainData.MyRace.City + ', ' + athleteMainData.MyRace.CountryName;
        }

        if (cityState.length === 0) {
            cityState = athleteMainData.MyRace.CountryName;
        }
    }


    const { cumulativeAscent, cumulativeDescent, netAscent, highestPoint, lowestPoint, startPoint, endPoint, startEndDiff,startEndAngle,overallAscentAngle,isHighLowUndulating, highLowDiff } = getCalculatedCumulativeElevations({
        intervalData, currentIntervalIndex, race, startDistance, endDistance, units: settings.units
    });
    // const { cumulativeAscent, cumulativeDescent, netAscent } = {};

    return (

    // <motion.div 
    <div 
        className="IntervalAnalysisBib"
        // initial={{
        //     opacity: 0
        // }} animate={{ opacity: 1 }} transition={{ duration: 2 }}

    >

        <div className="MarathonTitle AthleteName">
            {/* <span className="AthleteFlag" data-tip={cityState} data-effect='solid' data-place='bottom' data-class="MyRaceTooltipLight"> */}
            <span className="AthleteFlag">
                {(athleteMainData && athleteMainData.MyRace) && 
                            athleteMainData.MyRace.CountryTwoCharCode && athleteMainData.MyRace.CountryTwoCharCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397)) }
            </span>
            <strong>{nameLink}</strong>
        </div>
        
        <div className="details">
            <dl>
            {/* <dt>Current Segment</dt>
            <dd style={{display:'flex'}}>
                <span><strong>{startDistance}</strong> <span>–</span> <strong className="text-space-right">{endDistance}</strong> {settings.units === 'metric' ? 'km' : 'mi'}</span>
                <SegmentNavigation athleteMainData={athleteMainData} />
                
            </dd> */}
            
            <dt>Time</dt>
            <dd>
                <span><strong className="numericFont">{intervalTime}</strong> <i className="dot">•</i> <strong className="text-space-right numericFont">{totalDistance}</strong> <span className="numericFont smallFont">{settings.units === 'metric' ? 'km' : 'mi'}</span></span>
                
            </dd>
            <dt>Pace</dt>
            <dd>
                <span><strong className="text-space-right numericFont">{avgPace}</strong> <span className="numericFont smallFont">{settings.units === 'metric' ? 'm/km' : 'm/mi'}</span> &nbsp;&nbsp;<span className={classNames("numericFont","smallFont",{ paceDiffPos, paceDiffNeg: !paceDiffPos })} >{intervalDiffTime}</span></span>
            </dd>
            <dt>Projected Time</dt>
            <dd>
            <span><strong className="numericFont">{projectedFinishBasedOnCumulative}</strong></span>
            </dd>

            <dt>Overall</dt>
            <dd>
                <strong className="text-space-right numericFont">{overallRank}</strong> <span className="numericFont smallFont">of</span> <span className="slight-bold text-space-left numericFont">{formatNumber(overallTotalAthletes)}</span> <i className="dot">•</i> <span className="numericFont smallFont">Top</span> <strong className="text-space-left numericFont">{overallPercent}%</strong>
                
            </dd>
            <dt>{genderGroup}</dt>
            <dd>
                <strong className="text-space-right numericFont">{overallGenderRank}</strong> <span className="numericFont smallFont">of</span> <span className="slight-bold text-space-left numericFont">{formatNumber(overallGenderTotalAthletes)}</span> <i className="dot">•</i> <span  className="numericFont smallFont">Top</span> <strong className="text-space-left numericFont">{overallGenderPercent}%</strong>
            </dd>
            {divisionEliteGroupRank}
            {divisionMastersGroupRank}
            {divisionAgeGroupRank}
            {divisionVisuallyImpairedGroupRank}
           
            <dt />
            </dl>


        </div>

        <h6><span>Elevation</span><span/></h6>
        <div className="details">
            <dl>
                {/* <dt>Net Gain</dt>
                <dd>
      
                    <strong>{cumulativeAscent}</strong> {settings.units === 'metric' ? 'm' : 'ft'} – <strong>{cumulativeDescent}</strong> {settings.units === 'metric' ? 'm' : 'ft'} = <strong>{netAscent}</strong> {settings.units === 'metric' ? 'm' : 'ft'}

                    <ElevationGainIcon gain={overallAscentAngle}  />

                    <InfoModal title="Elevation Info" id="results_summary_net_gain">
                        <p>Shows the cumulative elevation ascended (climb) in this segment and the cumulative descent of this segment (and the difference)</p>
                        <p>Elevation Ascent — Elevation Descent = Elevation Net Gain (or Loss)</p>
                    </InfoModal>
                    
                </dd> */}
                <dt>Highest – Lowest</dt>
                <dd>
                
                    <span className="numericFont">
                        <strong>{highestPoint}</strong> 
                            <span className="smallFont">
                                {settings.units === 'metric' ? 'm' : 'ft'}
                            </span> – <strong>{lowestPoint}</strong> 
                            <span className="smallFont">
                                {settings.units === 'metric' ? 'm' : 'ft'}
                            </span> = <strong>{highLowDiff}</strong><span className="smallFont">
                                {settings.units === 'metric' ? 'm' : 'ft'}
                            </span>
                        <ElevationGainIcon isRolling={isHighLowUndulating} />
                        <InfoModal title="Elevation Info" id="interval_summary_high_low_elevation_diff">
                            <p>Shows the elevation of the highest point in this segment and the elevation of the lowest point of this segment (and the difference)</p>
                            <p>High Elevation — Low Elevation = High/Low Difference</p>
                        </InfoModal>
                    </span>
                    
                </dd>
                <dt>Start – End</dt>
                <dd>
                    <span className="numericFont">
                        <strong>{startPoint}</strong>
                            <span className="smallFont">
                                {settings.units === 'metric' ? 'm' : 'ft'}
                            </span> – <strong>{endPoint}</strong> 
                            <span className="smallFont">
                                {settings.units === 'metric' ? 'm' : 'ft'}
                            </span> = <strong>{startEndDiff}</strong><span className="smallFont">
                                {settings.units === 'metric' ? 'm' : 'ft'}
                            </span> 
                        <ElevationGainIcon gain={startEndAngle}  />
                        <InfoModal title="Elevation Info" id="interval_summary_start_end_elevation_diff">
                            <p>Shows the elevation at the start of this segment and the elevation at the end of the segment (and the difference)</p>
                            <p>Start Elevation — End Elevation = Start/End Difference</p>
                        </InfoModal>
                    </span>
                    
                </dd>
            </dl>
        </div>

        { !!noFooter || 
        <div className="IntervalAnalysisFooter">
            <div>
                <ToggleUnits toggleUnits={toggleUnits} />
            </div>
            <div>
                {showAgeGrade ? 
                    <ToggleAgeGrade toggleAgeGrade={AGon} />
                    
                    : null}
            </div>
        </div>
        }
    </div>
    // </motion.div>
    );
};

export default IntervalAnalysisBib;
