import React, { Component } from "react";
import classnames from 'classnames'
import "./ToggleButton.scss"
import Beacon from '../Beacon/Beacon'
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import { setBeaconDone } from '../../actions/settings'

class ToggleGraphTable extends Component {

    handleClick = value => e => {
        this.props.onChange(value)
        this.beaconDone("graphTable")();
        
    }

    beaconDone = beaconId => e => {
        // console.log('beaconDone onclick', beaconId);
        this.props.settingsActions.setBeaconDone(beaconId)
    }
    
    render(){
        const { titles, value } = this.props;

        return (
            <>
                <div className="ToggleButton ToggleGraphTable">
                    <div onClick={ this.handleClick(0) } className={classnames({ on: !value })}>{ titles[0] }</div>
                    <div onClick={ this.handleClick(1) } className={classnames({ on: value===1 })}>{
                        titles[1] }
                        <Beacon id="graphTable" direction="left" text={"Elevation Profile"} style={{ top:'0px', left:'100px'}} />
                    </div>
                </div>
            </>
            
        )
    }
}

const mapDispatch = dispatch => ({
    settingsActions: bindActionCreators({setBeaconDone}, dispatch), // this.props.settingsActions.setBeaconDone(beaconId)
})

export default connect(null, mapDispatch)(ToggleGraphTable)

// export default ToggleGraphTable
