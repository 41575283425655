import React, { Children } from 'react'
import imgStopwatch from '../../images/stopwatch.svg';
import imgRunner from '../../images/runner.svg';
import imgHelp from '../../images/help.svg';
import './InfoCards.scss'

const InfoCard = ({children, icon}) => {
    return (
        <div className="InfoCard" style={{
            backgroundImage: `url(${icon})`
        }}>{children}</div>
    )
}

const InfoCards = props => {
    return (
        <div className="InfoCards">
            <InfoCard icon={imgStopwatch}>
                <h3>Welcome to MyRace</h3>
                <p>This is the Welcome Page, it is just the first page in the set of athlete race results.</p>
                <p><a href="https://about.myrace.com/article/14-welcome-page" target="_blank"
                    alt="Welcome to MyRace" title="Welcome to MyRace" rel="noopener noreferrer"
                    >Learn more</a> about this page.</p>
            </InfoCard>
            <InfoCard icon={imgRunner}>
                <p>This page sets the stage by surfacing basic information about the athlete.</p>
                <ul>
                    <li>name</li>
                    <li>bib number</li>
                    <li>hometown</li>
                    <li>race division(s)</li>
                </ul>
            </InfoCard>
            <InfoCard icon={imgHelp}>
                <p><a href="https://about.myrace.com/article/12-how-obtain-athlete-data" target="_blank"
                    alt="About MyRace" title="About MyRace" rel="noopener noreferrer"
                >Learn more</a> about how MyRace gets and compiles the data used in this and other pages.</p>
            </InfoCard>
        </div>
    )
}

export default InfoCards