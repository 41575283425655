import React, { useState, useEffect } from 'react'

import airshipBlimpPNG from '../../images/craft-07@3x.png'
import craft1 from '../../images/craft-01.svg'
import craft2 from '../../images/craft-02.svg'
import craft3 from '../../images/craft-03.svg'
import craft4 from '../../images/craft-04.svg'
import craft5 from '../../images/craft-05.svg'
import craft6 from '../../images/craft-06.svg'
import craft7 from '../../images/craft-07.svg'
import craft8 from '../../images/craft-08.svg'
import craft9 from '../../images/craft-09.svg'
import craft10 from '../../images/craft-10.svg'
import craft11 from '../../images/craft-11.svg'
import harnessSVG from '../../images/harness.svg'
import imgRunBetter from '../../images/adsImages/RunBetter.png'
import imgGarmin from '../../images/adsImages/garmin.png'
import imgGu from '../../images/adsImages/gu.png'
import imgRevuLogo from '../../images/adsImages/revolugo.png'
import imgCapitalOne from '../../images/adsImages/2_0001sCapital-One.png'
import styled from 'styled-components'
import classnames from 'classnames'


const airshipType = 2;
const imgAds = imgRunBetter; 
// const imgAds = imgGarmin; 
// const imgAds = imgGu; 
// const imgAds = imgRevuLogo; 

let top = 0;
let imgAirshipBody = null;
let speed = 10;
let vertical = false

switch(airshipType) {
    case 1: //small helicopter
        top=5;
        imgAirshipBody=craft1;
        speed = 400;
        break;
    case 2: //drone
        top=5;
        imgAirshipBody=craft2;
        speed = 400;
        vertical = true;
        break;
    case 3: //big helicopter
        top=18;
        imgAirshipBody=craft3;
        speed = 400;
        break;
    case 4: //red airplane
        top=10;
        imgAirshipBody=craft4;
        speed = 400;
        break;
    case 5: //jet fighter
        top=2;
        imgAirshipBody=craft5;
        speed = 200;
        break;
    case 6: //green airplane
        top=16;
        imgAirshipBody=craft6;
        speed = 300;
        break;
    case 7: //blimp
        top=5;
        imgAirshipBody=craft7;
        speed = 500;
        break;
    case 8: //submarine
        top=-14;
        imgAirshipBody=craft8;
        speed = 400;
        break;
    case 9: //hot air balloon
        top=-14;
        imgAirshipBody=craft9;
        speed = 400;
        vertical = true;
        break;
    case 10: //ufo
        top=-5;
        imgAirshipBody=craft10;
        speed = 500;
        vertical = true;
        break;
    case 11: //rocket
        top=0;
        imgAirshipBody=craft11;
        speed = 10;
        break;
    default:
        top=5;
        imgAirshipBody=craft7;
        speed = 30;
        break;

}

const AirshipAnimated = styled.div`
    position: absolute;
    display: flex;
    top: 80px;
    right: 0px;
    z-index: 9999;
    transform: scale(1) translateX(0px);
    transition: transform ${speed}s;
    
    &.move {
        transform: scale(1) translateX(-2000px);
    }
`;


const AirshipBody = styled(({className})=>(
    <div className={className}>
        <img src={imgAirshipBody} />
    </div>
))`
    position: relative;
    top: ${top}px;
    width: 153px;
    display:flex;
    align-items: center;
    img {
        width: 100%;
    }
`;



const AirshipHarness = styled(({className})=>(
    <div className={className}>
        <img src={harnessSVG} />
    </div>
))`
    position: relative;
    display:flex;
    align-items: center;
`;





const BannerStick = styled.div`
    position: relative;
    width: 6px;
    background-color: #bb2764;
    border-radius: 3px;
`;


const Marquee = styled(({className}) => {
    return (
        <div className={className}>
            Run better using real course data.
        </div>
    )
})`
    padding: 10px;
    font-size: 12px;
`;

const AnimatedCard = styled(({className}) => {
    return (
        <div className={className}
            onClick={()=>window.open('https://runbetterapp.com/')}
        >
            <div className="CardContent">
                <div className="Logo">
                    <img src={imgAds} />
                </div>
                <Marquee />                
            </div>
            <div className="CardCut">
            </div>
        </div>
    )
})`
    position:relative;
    font-size: 3em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px rgba(50,50,0,0.2));
    
    .CardContent {
        background-color: #8888ce;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }
    
    .CardCut{
        background-color: #8888ce;
        background-color: #fff;
        clip-path: polygon(100% 0,45% 48%,100% 100%,0 100%,0 0);
        padding: 10px 30px 10px 20px;
    }
    .Logo {
        padding: 0 10px;
        max-width: 200px;
        margin-bottom: -24px;
        img {
            top: -9px;
            position: relative;
            max-width: 100%;
        }
    }
`;




const Airship = () => {
    const [move, setMove]=useState(false);

    useEffect(()=>{
        window.setTimeout(()=>{
            setMove(true)
        },300);
    }, [])
    

    return (
        <AirshipAnimated
            className={classnames({
            move
        })}>
            <AirshipBody />
            <AirshipHarness />
            <BannerStick />
            <AnimatedCard />
        </AirshipAnimated>
    )

}

export default Airship;