import React, { Component } from "react";
import classNames from 'classnames'
import {secondsToTimeStrip, roundDistance} from '../../timeUtils'

class IntervalInfoBlock extends Component {


  render() {
    const { IntervalCumulativeTimeSeconds, 
      IntervalPaceMetricSeconds, 
      IntervalPaceEnglishSeconds,
        IntervalDistanceKm,
        IntervalDistanceMi,
        IsEstimated,
        CumulativeDistanceKm,
        CumulativeDistanceMi,
        units,
        race
       } = this.props;

      //  console.log("props",this.props)
      //  const isEstimatedTime="*";

      const intervalCumulTime = secondsToTimeStrip(IntervalCumulativeTimeSeconds);
      const intervalPace = secondsToTimeStrip(units === 'metric' ? IntervalPaceMetricSeconds : IntervalPaceEnglishSeconds);
      const intervalDistance = units === 'metric' ? roundDistance(IntervalDistanceKm,3) : roundDistance(IntervalDistanceMi,3);
      const intervalCumulDistance = units === 'metric' ? roundDistance(CumulativeDistanceKm,3) : roundDistance(CumulativeDistanceMi,3);
      const unitsLabel = units === 'metric' ? 'm/km' : 'm/mi';
      const distanceUnitsLabel = units === 'metric' ? 'km' : 'mi';
      const unitsFtMeLabel = units === 'metric' ? 'm' : 'ft';
      const elevationData= race ? race.elevations : [];
  
      // console.log("elevationData",elevationData);

      // Find elevation of intervalStart
      const distanceKmStart = CumulativeDistanceKm - IntervalDistanceKm;
      const distanceKmFinish = CumulativeDistanceKm; 
       let startElevationFt = 0;
       let endElevationFt = 0;
       let diffElevationFt = 0;
       let startElevationM = 0;
       let endElevationM = 0;
       let diffElevationM = 0;
       elevationData && elevationData.points && elevationData.points.forEach(data => {
        if (data.distanceKm < distanceKmStart) {
          startElevationFt = data.elevationFt;
          startElevationM = data.elevationM;
        }
        if (data.distanceKm < distanceKmFinish) {
          endElevationFt = data.elevationFt;
          endElevationM = data.elevationM;
        }
      });

      diffElevationFt = endElevationFt - startElevationFt;
      diffElevationM = endElevationM - startElevationM;

      const incline = diffElevationFt >= 0 ? true : false; 

      diffElevationFt = diffElevationFt >= 0 ? "+ " + diffElevationFt : "– " + Math.abs(diffElevationFt); 
      diffElevationM = diffElevationM >= 0 ? "+ " + diffElevationM : "– " + Math.abs(diffElevationM); 
      const finalDiffElevation = units === "metric" ? diffElevationM : diffElevationFt;
      


      // console.log("IsEstimated",IsEstimated);
    return (
      <div className="IntervalInfoBlock">
        <dl>
            <dt>Distance</dt>
            <dd><strong><span className="numericFont">{intervalCumulDistance}</span></strong> <span className="units">{distanceUnitsLabel}</span></dd>
            <dt>Interval</dt>
            <dd><span className="numericFont">{intervalDistance}</span> <span className="units">{distanceUnitsLabel}</span></dd>
            <dt>Pace</dt>
            <dd><span className="numericFont intervalBold">{intervalPace}</span> <span className="units">{unitsLabel}</span></dd>
            <dt>Elevation Δ</dt>
            {/* <dd>{finalDiffElevation} {unitsFtMeLabel}</dd> */}

            <dd><span className={classNames({
                  plus: incline,
                  minus: !incline
                })}>
                  <span className="numericFont">{finalDiffElevation}</span>
                </span> <span className="units">{unitsFtMeLabel}</span>
              </dd>
            
            <dt>Time</dt>
              <dd><span className="numericFont">{intervalCumulTime}</span>{IsEstimated ? '*' : ''}</dd>
        </dl>
      </div>
    );
  }
}

export default IntervalInfoBlock;
