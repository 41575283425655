import initialState from "./initialState";

export default (state = initialState.intervalAnalysis, action) => {
  switch (action.type) {

    case 'INTERVAL_ANALYSIS_GO_TO_PREV':
    
        if (state.distanceDisplayOption === 0) {
            return {...state, 
                currentStartIntervalIndex: state.currentStartIntervalIndex > 0 ? state.currentStartIntervalIndex-1 : 0,
                currentEndIntervalIndex: state.currentEndIntervalIndex > 0 ? state.currentEndIntervalIndex-1 : 0
            }
        } else {
            return {...state, 
                currentStartIntervalIndex: 0,
                currentEndIntervalIndex: state.currentEndIntervalIndex > 0 ? state.currentEndIntervalIndex-1 : 0
            }

        }
    
    case 'INTERVAL_ANALYSIS_GO_TO_NEXT':

        if (state.distanceDisplayOption === 0) {
            return {...state, 
                currentStartIntervalIndex: state.currentStartIntervalIndex < action.max ? state.currentStartIntervalIndex+1 : action.max,
                currentEndIntervalIndex: state.currentStartIntervalIndex < action.max ? state.currentStartIntervalIndex+1 : action.max,
            }
            
        } else {
            return {...state, 
                currentStartIntervalIndex: 0,
                currentEndIntervalIndex: state.currentStartIntervalIndex < action.max ? state.currentEndIntervalIndex+1 : action.max,
            }
        }

    case 'INTERVAL_ANALYSIS_SET_DISTANCE_DISPLAY_OPTION':
        return {
            ...state,
            distanceDisplayOption: action.value, // interval, cumulative
            currentStartIntervalIndex: action.value === 0 ? state.currentEndIntervalIndex : 0
        };
    case 'INTERVAL_ANALYSIS_SET_INTERVAL_INDEX':
        
        return {
            ...state,
            currentStartIntervalIndex : state.distanceDisplayOption === 0 ? action.intervalIndex : 0,
            currentEndIntervalIndex:  action.intervalIndex
        }
        
    case 'RESET_ATHLETE':
    case 'INTERVAL_ANALYSIS_GO_TO_START':

        return {
            ...state,
            currentStartIntervalIndex : 0,
            currentEndIntervalIndex:  0
        }
    case 'INTERVAL_ANALYSIS_GO_TO_FINISH':

        return {
            ...state,
            currentStartIntervalIndex : state.distanceDisplayOption === 0 ? action.max : 0,
            currentEndIntervalIndex:  action.max
        }

    default:
        return state;
  }
};
