import initialState from "./initialState";

export default (state = initialState.allRaces, action) => {
    switch (action.type) {
  
    case "ALL_RACES/SET_ALL_RACES": {
        return action.allRaces;
    }

    default:
      return state;
  }
};
