// import firebase from 'firebase/app'
import {db} from '../config/firebase'


export const destroyBeacon = () => {
    return (dispatch, getState) => {
        const { fbuser } = getState();
        // Close Beacon if User Exists
        if(fbuser){
            window.Beacon('destroy');
        }
    }
}

export const initBeacon = () => {
    return (dispatch, getState) => {
        console.log('INIT BEACON')
        // const { fbuser } = getState();
        window.Beacon('config', { color: "#008dfb" })
        window.Beacon('init', '348e8d61-f31b-49a6-8dd0-22b721d423f2');
        // if(fbuser){
        //     window.Beacon("open");
        // }
        window.Beacon("once", "close", ()=>dispatch(destroyBeacon()));
    }
}





export const saveProfileFields = (o) => {
    const { fbuser, data } = o;
    
    return dispatch => {

        const handleError = e => {
            dispatch({type:'DECREMENT_PENDING_REQUESTS'});
            console.error("Error writing User document: ", e);
        }

        dispatch({type:'INCREMENT_PENDING_REQUESTS'});
        


        let saveData = {};
        Object.keys(data).forEach(key => {
            if(typeof data[key]!=='undefined') saveData[key] = data[key];
        })
        
        db
            .collection("users")
            .doc(fbuser.uid)
            .set(saveData, { merge: true })
            .then(function() {
                dispatch({type:'DECREMENT_PENDING_REQUESTS'});
                // console.log("User Document successfully written!");
            })
            .catch(handleError);
    }
}


