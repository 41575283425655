import React, { Component } from "react";


class ErrorPage extends Component {

  
    render() {
      return (
          <div>
              Page not found
          </div>
      )
    }
  }

  export default ErrorPage;