import React, { Component } from "react";
import classnames from 'classnames'
import "./ToggleButton.scss"

class ToggleGeneric extends Component {

    handleClick = value => e => {
        this.props.onChange(value)
    }
    
    render(){
        const { titles, value } = this.props;

        return (
            <>
                <div className="ToggleButton">
                    {
                        titles.map( (title, i) => (
                            <div
                                key={i}
                                onClick={ this.handleClick(title) }
                                className={classnames({ on: value === title })}>{ title }
                            </div>
                        ))
                    }
                </div>
            </>
            
        )
    }
}

export default ToggleGeneric
