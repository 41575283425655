import React, { Component } from "react";
import "./Pagination.scss";
import classNames from 'classnames'
import {connect} from 'react-redux'
import nextEnabled from '../../images/page-next-1.svg';
import nextDisabled from '../../images/page-next-3.svg';
import prevEnabled from '../../images/page-back-1.svg';
import prevDisabled from '../../images/page-back-3.svg';

import * as athletesByFilterSelectors from '../../re-selectors/athletesByFilterSelectors'
import DotsPageInput from './DotsPageInput'



class Pagination extends Component {
    componentDidMount(){
        if(typeof this.props.totalItems==='number' && this.props.totalPages > 1){
            // console.log('[Pagination] [goToDefaultPageForTheAthlete] in the mount pagination')
            this.goToDefaultPageForTheAthlete();
        }
    }
    
    componentDidUpdate(prevProps, prevState){
        if(typeof this.props.totalItems==='number' && this.props.totalPages > 1){
            this.goToDefaultPageForTheAthlete();
        }
    }

    _done_goToDefaultPageForTheAthlete = false;
    goToDefaultPageForTheAthlete = () => {
        if(this._done_goToDefaultPageForTheAthlete) return;
        const { settings: { lastSelectedAthleteIndexFinish } } = this.props;

        if(typeof lastSelectedAthleteIndexFinish === 'number'){
            const page = Math.floor(lastSelectedAthleteIndexFinish / 50) + 1;
            this.setPage(page)();
        }  
        
        this._done_goToDefaultPageForTheAthlete = true;
    }

    setPage = page => e => {
        // console.log('[Pagination] [setPage]', page);
        this.props.onChange(page)
    }

    
    gotoNextPage = () => {
        if(this.props.page === this.props.totalPages){ return; }
        this.setPage(this.props.page+1)();
    }
    gotoPrevPage = () => {
        if(this.props.page === 1){ return; }
        this.setPage(this.props.page-1)();
    }

    render() {
        const { raceAthleteFilter, totalPages, totalItems } = this.props;

        
        const { page } = raceAthleteFilter;


        if(totalItems==='pending'|| typeof totalItems==='undefined'){
            return (
                <div className="Pagination">
                    <span className="chevron norotate disabled">&lsaquo;</span>
                    <span className="pagenum ellipses" style={{color: '#e1e5e7'}}>0</span>
                    <span className="chevron norotate disabled">&rsaquo;</span>
                </div>
            );
        }
        
        

        const isLast = page === totalPages;
        const isFirst = page === 1;

        // console.log("Current Page = ",page);
        // console.log("lastKnownPage = ",lastKnownPage);
        // console.log("lastPage = ",lastPage);
        // console.log("isLast = ",isLast);


        let pages = [1];
        // for(let i = 1; i<=lastKnownPage; i++){
        //     pages.push(i);
        // }

        // page; 5  ----    1 ... 3, 4, 5

        let numbersBeforeCurrent = 2;
        if(page > totalPages - 5 ){
            numbersBeforeCurrent += -((totalPages - 5) - page + 1); 
        }

        let beforeCurrent = page - numbersBeforeCurrent;
        if(beforeCurrent < 2) beforeCurrent = 2;
        let beforeDots = true; // display dots between 1 and the current page numbers

        if(beforeCurrent === 2) {
            beforeDots = false;
        }
        if(beforeDots){
            pages.push( beforeCurrent===3 ? 2 : '...')
        }
        for(let i = beforeCurrent; i <= page; i++){
            pages.push(i);
        }

        let numbersAfterCurrent = 2;
        if(page < 5 ){ numbersAfterCurrent += 5-page; }

        let afterCurrent = page + numbersAfterCurrent;
        if(afterCurrent > totalPages - 1) afterCurrent = totalPages - 1;
        let afterDots = true;
        if(afterCurrent === totalPages - 1) afterDots = false;
        
        for(let i = page+1; i <= afterCurrent; i++){
            pages.push(i);
        }
        
        if(afterDots) pages.push(  page===totalPages-4 ? totalPages-1 : '...');

        if( totalPages > 1 && totalPages!==page){
            pages.push(totalPages);
        }



        
        return (
            <div className="Pagination">
                <span onClick={page > 1 ? this.gotoPrevPage : null} className="paginationArrow">
                    <img src={ isFirst ? prevDisabled : prevEnabled} alt="previous" />
                </span>
                { pages.map( (p,i) => {
                    // console.log("lastKnownPage = ",lastKnownPage,"p = ",p,"page = ",page);
                    if(p === '...'){
                        const wouldBePage = pages[i-1]+1;
                        return <DotsPageInput
                            key={`${p}_${wouldBePage}_${i}`}
                            nextPageNum={wouldBePage}
                            onSubmit={pagenum=>this.setPage(pagenum)()}
                            totalPages={this.props.totalPages}
                            />
                        // return <span key={`${p}_${i}`} className="pagenum ellipses">...</span>
                    }
                    return (
                        <span key={`${p}_${i}`} className={classNames("pagenum", {
                            current: p===page
                        })} onClick={this.setPage(p)}>{p}</span>
                    )
                    
                })}
                <span className="paginationArrow" onClick={this.gotoNextPage}>
                    <img src={ isLast ? nextDisabled : nextEnabled} alt="next"/>
                    </span>
            </div>
        );
    }
}

const mapStateToProps = state => {

    const { totalPages, totalItems } = athletesByFilterSelectors.getAthletesByFilterState(state);
    
    return {
        // athleteSearchResults: state.athleteSearchResults, // LEGACY

        athleteFilterResults: state.athleteFilterResults,

        raceAthleteFilter: state.raceAthleteFilter,
        raceSelected: state.raceSelected,
        settings: state.settings,
        athletesByFilter: state.athletesByFilter,
        totalPages, 
        totalItems
    }
}

export default connect(mapStateToProps)(Pagination);
