import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux'
import * as athletesByRacebibActions from '../../actions/athletesByRacebib'
import mongoapi from '../../apis/mongoapi'
import Header from "../../components/Header/Header";
import { SelectAthleteHeader } from "..";
import "./SelectAthlete.scss";
import AthleteListTable from "../../components/AthleteListTable/AthleteListTable";
import RaceListFilters from "../../components/RaceListFilters/RaceListFilters";
import * as sponsorsActions from '../../actions/sponsors'
import Footer from '../../components/Footer/Footer'
import Headroom from 'react-headroom';

// ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
// ════════════════════════════════════════════════════════════════════════════════════════════════════


class SelectAthlete extends Component {

  constructor(props){
    super(props);
    const { match } = props;

    // const filter = match.params.athleteFilter === undefined ? "overall" : match.params.athleteFilter;
    
    if(props.raceAthleteFilter.divisionFilter === 'All-DivisionType1s'){
      
      const filter = match.params.athleteFilter || 'All-Genders';
  
      // console.log('[setSelectedRaceAthleteFilter] in SelectAthlete', filter)
      this.props.setSelectedRaceAthleteFilter(filter);

    }
  }


  state = {
    races: null,
    athletes: []
  }

  componentDidMount() {

    this.props.athletesByRacebibActions.getAthlete(
      this.props.match.params.raceCode,
      this.props.match.params.athleteBib
    );

    const eventCode = this.props.match.params.eventCode;

    this.loadTabImages();

    this.getAllRacesData(eventCode);

    window.scrollTo(0, 0);

      
    // this.loadEvent(
    //     this.props.match.params.eventCode,
    //     this.props.match.params.raceCode,
    //     this.props.match.params.athleteFilter
    // );


    // if(window.location.search.includes('stayintouch=true') && !window.sessionStorage.getItem('stayInTouchViewed') ){

    //   this.touchModalTimeout = window.setTimeout(()=>{
    //     this.props.openKeepInTouchModal();
    //     window.sessionStorage.setItem('stayInTouchViewed','y');
    //   }, 5000);
      
    // }
    
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.raceCode !== this.props.match.params.raceCode){
      this.loadTabImages();
    }
  }
  
  

  loadTabImages = () => {
    const { raceCode } = this.props.match.params;

    // console.log("raceCode fin",raceCode);

    this.props.sponsorsActions.getSponsors(raceCode);
  }

  componentWillUnmount() {
    if(this.touchModalTimeout){
      window.clearTimeout(this.touchModalTimeout);
    }
  }

  handleRacesResponse = snap => {
    let raceList = [];
    this.props.decrementPendingRequests();
    snap.forEach(doc => {
      // let race = doc.data();
      // console.log("RACE",doc);
      // raceList.push(doc.data());
      raceList.push(doc);
    });

    // console.log("xxx racelist",raceList);

    this.setState({ races: raceList });

    // this.setEvents(raceList);
  }

  getAllRacesData = (eventCode) => {

    

    this.props.incrementPendingRequests();
   

    mongoapi.run(mongodb=>{
      const coll = mongodb.collection("races");
      let filter = {};
      filter.EventCode = eventCode;

      // const mysort = {};
      // mysort.EventListOrderIndex = -1;

      const options = { sort: { EventListOrderIndex: -1 } };


      // MAKE THE CALL
      coll.find(filter,options).toArray()
        .then(this.handleRacesResponse);
    })

  }




  render() {
    const { races } = this.state;
    // const { raceAthleteFilter } = this.props;
    const { sponsors, match } = this.props;

    const {athletes} = this.state;

    const { raceCode } = match.params;

    const sponsorsData = sponsors[raceCode] || {};

    // console.log("1 raceCode",raceCode);
    // console.log("1 sponsor",sponsorsData);

    const page = this.props.match.path.split('/')[1];

    return (
      <div className="SelectAthlete">
        
        <Headroom>
          <Header title={`EVENT & ATHLETES`} page={page} />
        </Headroom>

        <SelectAthleteHeader races={races} raceCode={raceCode} {...sponsorsData} />

        <div className="flexgrow">

          <div className="container table">
            
    
            <RaceListFilters />
            <AthleteListTable rows={athletes}/>
            
          </div>
        </div>

        {/* <div className="container flexgrow">

        </div> */}
        <Footer />

      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    raceSelected: state.raceSelected,
    raceAthleteFilter: state.raceAthleteFilter,
    user: state.user,
    sponsors: state.sponsors,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSelectedAthlete: athlete => dispatch({type:'SET_SELECTED_ATHLETE', athlete}),
  athletesByRacebibActions: bindActionCreators(athletesByRacebibActions, dispatch),
  // setSelectedRace: race => dispatch({type:'SET_SELECTED_RACE', race}),
  setSelectedRaceWithoutClearingTheData: race => dispatch({type:'SET_SELECTED_RACE_ONLY', race}),
  setSelectedRaceAthleteFilter: filter => {
    dispatch({type:'SET_RACE_ATHLETE_FILTER', filter})

  },
  updateSelectedAthlete: athlete => dispatch({type:'UPDATE_SELECTED_ATHLETE', athlete}),
  updateSelectedRace: race => dispatch({type:'UPDATE_SELECTED_RACE', race}),

  incrementPendingRequests: () => dispatch({type:'INCREMENT_PENDING_REQUESTS'}),
  decrementPendingRequests: () => dispatch({type:'DECREMENT_PENDING_REQUESTS'}),
  openKeepInTouchModal: ()=>dispatch({type:'SET_SETTING', isKeepInTouchModalOpen: true}),
  sponsorsActions: bindActionCreators(sponsorsActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectAthlete));
