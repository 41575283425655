import React from 'react';
import { AreaClosed, Bar } from '@vx/shape';
import { curveMonotoneX } from '@vx/curve';

import { scaleLinear } from '@vx/scale';
import { extent, max } from 'd3-array';


const xStock = a => a.distanceMi;
const yStock = b => b.elevationFt;


class D3ElevationMap extends React.Component {


  render() {
    const {
      width,
      height,
      margin,
      elevationData
    } = this.props;

    const stock =  elevationData && elevationData.points ? elevationData.points : [];

    // console.log("stock",stock);
    // console.log("width",width);
    // console.log("height",height);

    if (width < 10) return null;

    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const xScale = scaleLinear({
      range: [0, xMax],
      domain: extent(stock, xStock),
    });
   
    const yScale = scaleLinear({
      range: [yMax, 0],
      domain: [0, max(stock, yStock) + yMax / 3],
      nice: true,
    });

    return (
      <div className="D3ElevationProfile">
        <svg ref={s => (this.svg = s)} width={width} height={height}>
         
         
          <defs>
            <linearGradient
              id="gradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop
                offset="0%"
                stopColor="#85e020"
                stopOpacity={0.2}
              />
              <stop
                offset="100%"
                stopColor="#85e020"
                stopOpacity={0.2}
              />
            </linearGradient>
          </defs>

          <Bar
            x={0}
            y={0}
            width={width}
            height={height}
            // fill="transparent"
            fill="white"
            rx={14}
            data={stock}
            
          />
          
          <AreaClosed
            data={stock}
            // xScale={xScale}
            x={d => xScale(xStock(d))}
            yScale={yScale}
            y={d => yScale(yStock(d))}

            // x={xStock}
            // y={yStock}
            strokeWidth={1}
            stroke={'url(#gradient)'}
            fill={'url(#gradient)'}
            // fill={'black'}
            // fill={'#113b76'}
            curve={curveMonotoneX}
          />
          
          
        </svg>
        
      </div>
    );
  }
}

export default D3ElevationMap;